<template>
  <div class="SearchHistoryRegistrationComponent">
    <div class="filter-history-registration">
      <TitleComponent class="SearchHistoryRegistrationComponent-title">
        <template v-slot:icon><span class="icon-ico_search"></span></template>
        <template v-slot:content>電気使用申込み履歴検索</template>
      </TitleComponent>
      <CardComponent :width="'960px'" :height="'510px'">
        <Form
          @submit="handleSearch"
          ref="formSearchHistoryRegistration"
          :validation-schema="schema"
        >
          <GroupContentComponent class="group-custom">
            <template v-slot:title> 建物名</template>
            <template v-slot:content>
              <InputComponent
                :small="true"
                name="buildingName"
                placeholder="建物名の一部等を入力"
                inputFilter="fullWidth"
                maxlength="30"
              />
            </template>
          </GroupContentComponent>
          <GroupContentComponent :isTitleTop="true">
            <template v-slot:title>
              <span class="space-top">電気使用申込み状況</span>
            </template>
            <template v-slot:content>
              <div class="group-checkbox-content">
                <div class="group-type">
                  <span class="group-title space-top">申込み種別</span>
                  <div class="main">
                    <div class="group-checkbox space-top">
                      <InputComponent
                        class="checkbox"
                        type="checkbox"
                        name="useStart"
                        ref="useStart"
                        :value="false"
                        @trigle-change="onSetLastCheck($event, 'useStart')"
                      />
                      <label for="useStart">使用開始</label>
                    </div>
                    <div class="group-checkbox space-top">
                      <InputComponent
                        class="checkbox"
                        type="checkbox"
                        name="useStop"
                        :value="false"
                        @trigle-change="onSetLastCheck($event, 'useStop')"
                      />
                      <label for="useStop">使用停止</label>
                    </div>
                    <div class="group-checkbox space-top">
                      <InputComponent
                        class="checkbox"
                        type="checkbox"
                        name="moveIn"
                        :value="false"
                        @trigle-change="onSetLastCheck($event, 'moveIn')"
                      />
                      <label for="moveIn">入居申込み</label>
                    </div>
                  </div>
                </div>
                <!-- status -->
                <div class="group-status">
                  <span class="group-title">処理状況</span>
                  <div class="main">
                    <div class="group-checkbox">
                      <InputComponent
                        class="checkbox"
                        type="checkbox"
                        name="complete"
                        :value="false"
                        @trigle-change="onSetLastCheck($event, 'complete')"
                      />
                      <label for="complete">処理完了</label>
                    </div>
                    <div class="group-checkbox">
                      <InputComponent
                        class="checkbox"
                        type="checkbox"
                        name="waiting"
                        :value="false"
                        @trigle-change="onSetLastCheck($event, 'waiting')"
                      />
                      <label for="waiting">処理待ち</label>
                    </div>
                    <div class="group-checkbox">
                      <InputComponent
                        class="checkbox"
                        type="checkbox"
                        name="process"
                        :value="false"
                        @trigle-change="onSetLastCheck($event, 'process')"
                      />
                      <label for="process">処理中</label>
                    </div>
                    <div class="group-checkbox space-bottom">
                      <InputComponent
                        class="checkbox"
                        type="checkbox"
                        name="unconfirmed"
                        :value="false"
                        @trigle-change="onSetLastCheck($event, 'unconfirmed')"
                      />
                      <label for="unconfirmed">受付不可(未確認)</label>
                    </div>
                    <div class="group-checkbox space-bottom">
                      <InputComponent
                        class="checkbox"
                        type="checkbox"
                        name="confirmed"
                        :value="false"
                        @trigle-change="onSetLastCheck($event, 'confirmed')"
                      />
                      <label for="confirmed">受付不可(確認済み)</label>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent class="group-custom">
            <template v-slot:title> 供給地点特定番号</template>
            <template v-slot:content>
              <InputComponent
                :small="true"
                name="supplyPointId"
                placeholder="22桁の数字を入力"
                maxlength="27"
                inputFilter="roomNumber"
              />
            </template>
          </GroupContentComponent>
          <div class="card-action">
            <ButtonComponent
              @click="onClearFilter($event)"
              type="outline"
              buttonType="button"
              >クリア</ButtonComponent
            >
            <ButtonComponent buttonType="submit">検索</ButtonComponent>
          </div>
        </Form>
      </CardComponent>
    </div>
    <div class="list" v-if="dataForm && !isSearching">
      <div class="title-group">
        <TitleComponent class="SearchHistoryRegistrationComponent-title">
          <template v-slot:icon><span class="icon-ico_list"></span></template>
          <template v-slot:content>検索結果一覧</template>
        </TitleComponent>
      </div>
      <CardComponent
        :isEmpty="!(registrations.length > 0)"
        width="960px"
        :height="registrations.length > 0 ? 'auto' : '460px'"
        emptyText="該当する申込みはございませんでした。"
      >
        <p class="count-item">
          <strong
            v-tableRange="{
              currentPage: pagination?.page || 0,
              currentTotal: registrations?.length || 0,
            }"
          />
          <span class="total">件/{{ total }}件</span>
        </p>
        <table class="table-container">
          <tr>
            <TableHeaderComponent
              :sort="true"
              :active="sort.sortType === 1"
              :alignStart="true"
              class="small-cell"
              @changeOrderSort="changeOrder($event, 1)"
            >
              申込み日時
            </TableHeaderComponent>
            <TableHeaderComponent class="big-cell">
              建物名 <br />
              部屋番号
            </TableHeaderComponent>
            <TableHeaderComponent> 供給地点特定番号 </TableHeaderComponent>
            <TableHeaderComponent
              :sort="true"
              :active="sort.sortType === 2"
              @changeOrderSort="changeOrder($event, 2)"
              class="normal-cell"
            >
              電気使用申込み状況
            </TableHeaderComponent>
            <TableHeaderComponent
              :sort="true"
              :active="sort.sortType === 3"
              :alignStart="true"
              @changeOrderSort="changeOrder($event, 3)"
            >
              使用開始 <br />（停止）日時
            </TableHeaderComponent>
            <TableHeaderComponent
              :sort="true"
              :active="sort.sortType === 4"
              @changeOrderSort="changeOrder($event, 4)"
            >
              受付結果 <br />
              連携日
            </TableHeaderComponent>
            <TableHeaderComponent class="small-cell">
              対応者
            </TableHeaderComponent>
          </tr>
          <div class="table-row-group">
            <TableRowComponent
              v-for="(registration, index) in registrations"
              :key="index"
            >
              <TableCellComponent>
                <div class="create-at-cell">
                  {{ registration.applicationCreateAt }}
                </div>
              </TableCellComponent>
              <TableCellComponent>
                {{ registration.buildingName }}
                <br />
                {{ registration.roomNumber }}
              </TableCellComponent>
              <TableCellComponent>
                <div class="code-address-cell">
                  {{ registration.supplyPointId }}
                </div>
              </TableCellComponent>
              <TableCellComponent>
                <StatusRegistrationComponent
                  @onOpenModal="
                    onChangeStatus(
                      registration.registrationId,
                      registration.status
                    )
                  "
                  :type="registration.registrationType"
                  :status="registration.status"
                  :isButton="true"
                ></StatusRegistrationComponent>
              </TableCellComponent>
              <TableCellComponent class="datetime-cell">
                {{ registration.executeDate }}
                <br />
                {{ registration.executeTime }}
              </TableCellComponent>
              <TableCellComponent>
                {{ registration.resultUpdateDate }}
              </TableCellComponent>
              <TableCellComponent>
                {{ registration.resultUpdateBy }}
              </TableCellComponent>
            </TableRowComponent>
          </div>
        </table>
        <PaginationComponent
          :total-pages="pagination.totalPage"
          :current-page="pagination.page"
          v-if="pagination.totalPage > 1"
          @pagechanged="onPageChange"
        />
      </CardComponent>
    </div>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import * as Yup from "yup";
import RegistrationService from "@/services/registration.service";
import ConfirmResultRegistrationService from "@/services/dialog/confirm-result-registration.service";
import { STATUS, REGISTRATION_STATUS, SORT_ORDER } from "@/globals/enums";

export default {
  name: "SearchHistoryRegistrationComponent",
  components: { Form },
  data: () => ({
    schema: Yup.object().shape({
      buildingName: Yup.string(),
      useStart: Yup.boolean(),
      useStop: Yup.boolean(),
      moveIn: Yup.boolean(),
      complete: Yup.boolean(),
      waiting: Yup.boolean(),
      process: Yup.boolean(),
      unconfirmed: Yup.boolean(),
      confirmed: Yup.boolean(),
      supplyPointId: Yup.string(),
    }),
    registrations: [],
    pagination: { page: 1, number: 10, totalPage: 0 },
    dataForm: null,
    total: 0,
    sort: {
      sortType: 1,
      sortOrder: SORT_ORDER.DESC,
    },
    isSearching: true,
    registrationService: new RegistrationService(),
    confirmResultRegistrationService: new ConfirmResultRegistrationService(),
  }),
  methods: {
    async changeOrder(order, type) {
      this.sort.sortOrder = order;
      this.sort.sortType = type;
      await this.getListHistoryRegistration(
        this.dataForm,
        this.pagination,
        this.sort
      );
    },
    onSetLastCheck(e, nameField) {
      this.$refs.formSearchHistoryRegistration.setFieldValue(
        nameField,
        e.target.checked
      );
    },
    async onPageChange(event) {
      this.pagination.page = event;
      await this.getListHistoryRegistration(
        this.dataForm,
        this.pagination,
        this.sort
      );
    },
    async handleSearch(data) {
      this.dataForm = data;
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
      }
      await this.getListHistoryRegistration(
        data,
        this.pagination,
        this.sort,
        true
      );
    },
    getListHistoryRegistration(dataFilter, pagination, sort, loading) {
      if (loading) {
        this.isSearching = true;
        this.$store.dispatch("global/updateLoadingStatus", true);
      }
      this.registrationService
        .getHistoryRegistration(dataFilter, pagination, sort)
        .then((dataRegistration) => {
          if (loading) {
            this.isSearching = false;
            this.$store.dispatch("global/updateLoadingStatus", false);
          }
          if (dataRegistration.status === STATUS.SUCCESS) {
            this.registrations = dataRegistration.data.registrationList;
            this.total = dataRegistration.data.total;
            this.pagination = {
              page: dataRegistration.data.page,
              totalPage: dataRegistration.data.totalPage,
            };
          }
        });
    },
    onClearFilter(e) {
      e.preventDefault();
      this.abc = true;
      this.registrations = [];
      this.dataForm = null;
      document.getElementById("useStart").checked = false;
      document.getElementById("useStop").checked = false;
      document.getElementById("moveIn").checked = false;
      document.getElementById("complete").checked = false;
      document.getElementById("waiting").checked = false;
      document.getElementById("process").checked = false;
      document.getElementById("unconfirmed").checked = false;
      document.getElementById("confirmed").checked = false;
      this.$refs.formSearchHistoryRegistration.resetForm();
      this.pagination = { page: 1, number: 10, totalPage: 0 };
    },
    onChangeStatus(id, status) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      this.registrationService.getResultRegistration(id).then((result) => {
        this.$store.dispatch("global/updateLoadingStatus", false);
        if (result.status === STATUS.SUCCESS) {
          const title = "申込みを受付できませんでした。";
          const content =
            "不可理由をご確認いただき、再度申込みをお願いいたします。";
          const message = result.data.message;
          const isDetail = status === REGISTRATION_STATUS.UNCONFIRMED.VALUE;
          this.confirmResultRegistrationService.openConfirmResultRegistraionDialog(
            title,
            content,
            message,
            isDetail,
            null,
            () => {
              this.registrationService
                .updateResultRegistration(id)
                .then((result) => {
                  if (result.status === STATUS.SUCCESS) {
                    this.getListHistoryRegistration(
                      this.dataForm,
                      this.pagination,
                      this.sort
                    );
                  }
                });
            }
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.SearchHistoryRegistrationComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  &-title {
    color: var(--venice-blue-color);
    padding: 8px 0;
    &-action {
      display: flex;
      .ButtonComponent {
        margin-left: 15px;
        font-size: 14px;
      }
    }
  }
  .v-card {
    padding: 30px 40px;
  }
  .count-item {
    text-align: right;
    margin: 5px 0;
    .total {
      font-size: 12px;
    }
  }
  .table-container {
    width: 100%;
    .small-cell {
      width: 10% !important;
    }
    .normal-cell {
      width: 15%;
    }
    .big-cell {
      width: 18%;
    }
    .TableHeaderComponent {
      text-align: center;
      padding: 10px 5px !important;
      text-align: center;
      // width: 14%;
    }
    .TableCellComponent {
      padding: 10px 5px !important;
      .code-address-cell {
        width: 120px;
      }
      .create-at-cell {
        color: #7c8185;
      }
    }
    .datetime-cell {
      padding: 10px 12px !important;
    }
  }

  .pagination {
    text-align: center;
    margin-top: 50px;
  }
}
.filter-history-registration,
.list {
  width: 960px;
  margin-top: var(--default-distance-card);
  .InputComponent {
    width: 600px;
  }
  .checkbox {
    width: 20px;
    margin-right: 10px;
  }
}

.list {
  margin-bottom: var(--default-distance-card);
}

.filter-history-registration {
  .GroupContentComponent {
    .code-field {
      width: 80px !important;
      margin: 0 5px;
    }
    .space-top {
      margin-top: 10px !important;
    }
    .space-bottom {
      margin-bottom: 10px !important;
    }
    .group-checkbox {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 55px 20px 0;
    }
  }
  .GroupContentComponent:nth-child(1) {
    border-top: 1px solid #d0d9df;
  }
  .card-action {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    .ButtonComponent {
      margin: 0 10px;
    }
  }
  .group-checkbox-content {
    display: flex;
    flex-direction: column;
  }
  .group-type,
  .group-status {
    display: flex;
    width: 100%;
    .group-title {
      color: #7c8185;
      width: 100px;
      font-weight: normal;
    }
    .main {
      width: 500px;
      .group-checkbox {
        display: inline-flex;
      }
    }
  }
  label {
    cursor: pointer;
  }
  .group-custom {
    height: 70px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
