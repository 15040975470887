<template>
  <div class="CompanyEditTabComponent">
    <CardComponent class="main" :width="'960px'" :height="'auto'">
      <TitleComponent class="underline">
        <template v-slot:content>
          会社情報・支払先情報入力
        </template></TitleComponent
      >
      <Form
        @submit="submit"
        :validation-schema="schemaEdit"
        ref="companyForm"
        v-slot="{ meta, values }"
      >
        <div class="form-groups">
          <div class="form-groups-title">会社情報入力</div>
          <GroupContentComponent :required="true">
            <template v-slot:title> 会社名 </template>
            <template v-slot:content>
              <InputComponent
                name="companyName"
                :isCompanyEdit="true"
                placeholder="会社名"
                maxlength="35"
                inputFilter="fullWidth"
              />
            </template>
          </GroupContentComponent>
          <GroupContentComponent :required="true">
            <template v-slot:title> 会社名（カナ）</template>
            <template v-slot:content>
              <InputComponent
                name="companyKanaName"
                :isCompanyEdit="true"
                placeholder="シャメイ"
                maxlength="17"
                inputFilter="halfWidth"
              />
            </template>
          </GroupContentComponent>
          <AddressFormComponent
            addressTitle="住所"
            :postAddressData="postOfficeData"
            :postAddressSearchNumber="postOfficeNumber"
            @on-choise="updatePostOfficeData"
            @search-again="searchPostOfficeAgain"
            :handleChangePostAddressNumber="
              (e) => {
                postOfficeNumber = e.target.value;
              }
            "
          />
          <GroupContentComponent>
            <template v-slot:title> 私書箱 </template>
            <template v-slot:content>
              <InputComponent
                :isCompanyEdit="true"
                name="mailbox"
                maxlength="7"
                placeholder="私書箱を入力"
                inputFilter="halfWidth"
              />
            </template>
          </GroupContentComponent>
          <GroupContentComponent :required="true">
            <template v-slot:title> 電話番号 </template>
            <template v-slot:content>
              <div class="telephoneGroup">
                <TelephoneInputComponent
                  :groupInput="initCompanySetting.phoneNumberGroup"
                />
                <SelectComponent
                  name="phoneNumberCategory"
                  :options="initCompanySetting.registerPhoneNumberOptions"
                  placeholder="電話番号区分を選択"
                />
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent :required="true">
            <template v-slot:title> 電気料金<br />支払い方法 </template>
            <template v-slot:content>
              <div class="paymentGroup">
                <InputComponent
                  v-for="(item, index) of initCompanySetting.paymentOptions"
                  :key="index"
                  type="radio"
                  name="paymentOptions"
                  :id="`paymentOptions_${item.value}`"
                  :defaultValue="PAYMENT_OPTIONS_DEFAULT_VALUE"
                  :value="item.value"
                  :label="item.label"
                  @trigle-change="handleChangePaymentOptions"
                />
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent :isTitleTop="true" :required="true">
            <template v-slot:title> 入居者向け<br />電気契約申込み </template>
            <template v-slot:content>
              <div class="electricContractAppType">
                <div class="electricContractAppType-groupSelect">
                  <InputComponent
                    type="radio"
                    name="electricContractApp"
                    :id="`electricContractApp_1`"
                    :value="
                      COMPANY_REGISTER_CONTRACT_TYPES.HAS_REGISTER_CONTRACT
                        .VALUE
                    "
                    :defaultValue="
                      COMPANY_REGISTER_CONTRACT_TYPES.DEFAULT_VALUE
                    "
                    :label="
                      COMPANY_REGISTER_CONTRACT_TYPES.HAS_REGISTER_CONTRACT
                        .LABEL
                    "
                    @trigle-change="handleChangeElectricContractApp"
                  />
                  <SelectComponent
                    name="electricContractAppType"
                    :options="initCompanySetting.companyRegisterOptions"
                    :disabled="!isHasCompanyRegisterContractApp"
                  />
                </div>
                <InputComponent
                  type="radio"
                  name="electricContractApp"
                  :id="`electricContractApp_2`"
                  :value="
                    COMPANY_REGISTER_CONTRACT_TYPES.NO_REGISTER_CONTRACT.VALUE
                  "
                  :defaultValue="COMPANY_REGISTER_CONTRACT_TYPES.DEFAULT_VALUE"
                  :label="
                    COMPANY_REGISTER_CONTRACT_TYPES.NO_REGISTER_CONTRACT.LABEL
                  "
                  @trigle-change="handleChangeElectricContractApp"
                />
              </div>
            </template>
          </GroupContentComponent>
        </div>
        <div class="form-groups" v-if="isAccountSettingDisplay">
          <div class="form-groups-title">支払先情報入力</div>
          <GroupContentComponent :required="true">
            <template v-slot:title> 金融機関・支店名 </template>
            <template v-slot:content>
              <div className="bankInfo">
                <InputComponent
                  name="bankName"
                  class="input-field small-custom"
                  :small="true"
                  maxlength="20"
                  placeholder="金融機関名を入力"
                  inputFilter="fullWidth"
                />
                <InputComponent
                  name="bankDepartment"
                  class="input-field small-custom"
                  :small="true"
                  maxlength="20"
                  placeholder="支店名を入力"
                  inputFilter="fullWidth"
                />
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent :isTitleTop="true" :required="true">
            <template v-slot:title> 口座情報 </template>
            <template v-slot:content>
              <div className="account">
                <SelectComponent
                  name="accountType"
                  :isSmall="true"
                  :options="initCompanySetting.bankAcountOptions"
                />
                <div class="bankInfo">
                  <InputComponent
                    name="departmentNumber"
                    class="input-field small-custom"
                    :small="true"
                    maxlength="3"
                    :required="true"
                    placeholder="支店番号を入力"
                    inputFilter="number"
                  />
                  <InputComponent
                    name="accountNumber"
                    class="input-field small-custom"
                    :small="true"
                    placeholder="口座番号を入力"
                    maxlength="7"
                    inputFilter="number"
                  />
                </div>
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent :required="true">
            <template v-slot:title> 口座名義 </template>
            <template v-slot:content>
              <InputComponent
                name="accountName"
                :isCompanyEdit="true"
                placeholder="口座名義を入力"
                inputFilter="fullWidth"
                maxlength="30"
              />
            </template>
          </GroupContentComponent>
        </div>
        <div class="form-groups" v-else>
          <div class="form-groups-title">支払先情報入力</div>
          <AddressFormComponent
            addressTitle="送付先住所"
            :postAddressData="postDeliveryData"
            :postAddressSearchNumber="postDeliveryNumber"
            postOfficeNumberName="postDeliveryNumberName"
            addressDepartmentNumberInputName="deliveryDepartmentNumber"
            addressDepartmentNameInputName="deliveryDepartmentName"
            addressRoomNumberInputName="deliveryRoomNumber"
            @on-choise="updatePostDeliveryData"
            @search-again="searchDeliveryAgain"
            :handleChangePostAddressNumber="
              (e) => {
                postDeliveryNumber = e.target.value;
              }
            "
            :hasSlot="true"
            :isRenderTransferAddressInput="renderAddress"
          >
            <div class="flex-normal">
              <InputComponent
                v-for="(
                  item, index
                ) of initCompanySetting.transferAddressOptions"
                :key="index"
                type="radio"
                name="transferAddressOptions"
                :defaultValue="this.transferAddressOptions"
                :id="`transferAddressOptions_${index}`"
                :value="item.value"
                :label="item.label"
                @trigle-change="handleChangeTransferAddressOptions"
              />
            </div>
          </AddressFormComponent>
        </div>
        <div class="action-group">
          <ButtonComponent
            buttonType="submit"
            :disabled="
              !(
                postOfficeData &&
                meta.valid &&
                isTransferAddressValid(values?.deliveryDepartmentNumber)
              )
            "
          >
            確認
          </ButtonComponent>
        </div>
      </Form>
    </CardComponent>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import CompanyService from "@/services/company.service";
import { mapState } from "vuex";

import {
  COMPANY_REGISTER_CONTRACT_TYPES,
  PAYMENT_OPTIONS_DEFAULT_VALUE,
  TRANSFER_ADDRESS_OPTIONS_DEFAULT,
  MIN_4_CHARACTER,
  COMPANY_SETTING_TAB,
} from "@/globals/enums";
import { companyCreateSchema } from "@/validations/schemas/companyCreateSchema";

export default {
  name: "CompanyEditTabComponent",
  components: {
    Form,
  },
  created() {
    this.COMPANY_REGISTER_CONTRACT_TYPES = COMPANY_REGISTER_CONTRACT_TYPES;
    this.PAYMENT_OPTIONS_DEFAULT_VALUE = PAYMENT_OPTIONS_DEFAULT_VALUE;
    this.TRANSFER_ADDRESS_OPTIONS_DEFAULT = TRANSFER_ADDRESS_OPTIONS_DEFAULT;
  },
  data: () => ({
    companyService: new CompanyService(),
    companyRegisterContractApp: COMPANY_REGISTER_CONTRACT_TYPES.DEFAULT_VALUE,
    postOfficeNumber: "",
    postOfficeData: null,
    postDeliveryData: null,
    postDeliveryNumber: "",
    currentPaymentOptions: PAYMENT_OPTIONS_DEFAULT_VALUE,
    schemaEdit: companyCreateSchema,
    transferAddressOptions: 1,
  }),
  mounted() {
    this.companyService.initCompanyState();
  },
  computed: {
    isHasCompanyRegisterContractApp() {
      return (
        this.companyRegisterContractApp ===
        COMPANY_REGISTER_CONTRACT_TYPES.HAS_REGISTER_CONTRACT.VALUE
      );
    },
    isPostOfficeNumberValid() {
      return this.postOfficeNumber.length >= MIN_4_CHARACTER;
    },
    isPostDeliveryNumberValid() {
      return this.postDeliveryNumber.length >= MIN_4_CHARACTER;
    },
    isAccountSettingDisplay() {
      return this.currentPaymentOptions === PAYMENT_OPTIONS_DEFAULT_VALUE;
    },
    renderAddress() {
      return this.transferAddressOptions === TRANSFER_ADDRESS_OPTIONS_DEFAULT;
    },
    isTransferAddressValid() {
      return (deliveryDepartmentNumber) =>
        this.isAccountSettingDisplay ||
        !this.renderAddress ||
        !!deliveryDepartmentNumber;
    },
    ...mapState({
      initCompanySetting: (state) => state?.company?.initCompanySetting,
      profileInfo: (state) => state?.settings?.profileInfo,
    }),
  },
  methods: {
    submit(data) {
      if (!this.postOfficeData) {
        return;
      }
      this.companyService.previewCurrentEdittingCompanyInfo({
        isAccountSettingDisplay: this.isAccountSettingDisplay,
        postOfficeData: this.postOfficeData,
        postDeliveryData: this.postDeliveryData,
        ...data,
      });
      this.$emit("change-tab", COMPANY_SETTING_TAB.PREVIEW);
    },
    handleChangeElectricContractApp(e) {
      this.companyRegisterContractApp = parseInt(e.target.value);
    },
    handleChangePaymentOptions(e) {
      this.currentPaymentOptions = parseInt(e.target.value);
    },
    handleChangeTransferAddressOptions(e) {
      this.transferAddressOptions = parseInt(e.target.value);
    },
    updatePostDeliveryData(data) {
      this.postDeliveryData = data;
      this.postDeliveryNumber = "";
    },
    searchDeliveryAgain() {
      this.postDeliveryData = null;
      this.postDeliveryNumber = "";
    },
    updatePostOfficeData(data) {
      this.postOfficeData = data;
      this.postOfficeNumber = "";
    },
    searchPostOfficeAgain() {
      this.postOfficeData = null;
      this.postOfficeNumber = "";
    },
  },
  watch: {
    profileInfo: function (val) {
      if (val?.companyId?.length > 0)
        this.$router.push({ name: "HomeComponent" });
    },
  },
};
</script>
<style scoped lang="scss">
.CompanyEditTabComponent {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .main {
    margin: 27px 0 40px 0;
    padding: 30px 40px 29px;
    .TitleComponent {
      width: 255px;
      margin: 0 auto;
    }
    .form-groups {
      margin: 30px 0 30px 0;
      color: #7c8185;
      &-title {
        font-size: 16px;
        padding-bottom: 5px;
        border-bottom: solid 3px #d0d9df;
        color: #000;
      }
      .small {
        &-label {
          font-size: 14px;
          font-weight: 600;
          color: #7c8185;
          margin-right: 6px;
        }
        &-input {
          width: 280px;
          margin-right: 20px;
        }
      }
      .addressCode {
        &-code {
          display: flex;
          .small-input {
            margin-right: 36px;
            color: var(--color-black-text);
            font-size: 14px;
            font-weight: 500;
          }
        }
        &-address {
          margin: 0 0 20px 0;
          color: #000;
          font-size: 14px;
          font-weight: 500;
        }
        &-input {
          padding-bottom: 10px;
          &.small {
            width: 210px;
          }
        }
      }
      .telephoneGroup {
        display: flex;
        gap: 20px;
        margin: 20px 0 15px 0;
      }
      .paymentGroup {
        display: flex;
        gap: 60px;
        .InputComponent {
          margin-top: 20px;
        }
      }
      .electricContractAppType {
        &-groupSelect {
          display: flex;
          gap: 30px;
          align-items: baseline;
        }
      }
      .bankInfo {
        display: flex;
        gap: 30px;
      }
      .account {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }
    }
    .action-group {
      display: flex;
      justify-content: center;
      gap: 30px;
    }
    .flex-normal {
      display: flex;
      gap: 60px;
      color: #000;
    }
  }
}
</style>
