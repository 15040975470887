import { convertBuildingSetting } from "@/store/converter/building.converter";

const state = {
  initBuildingSetting: {},
};

const getters = {
  getInitBuildingSetting() {
    return state.initBuildingSetting;
  },
};

const actions = {
  initBuildingSetting({ commit }, data) {
    commit("initBuildingSetting", convertBuildingSetting(data));
  },
};
const mutations = {
  initBuildingSetting(state, data) {
    state.initBuildingSetting = data;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
