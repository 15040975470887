import { createRouter, createWebHistory } from "vue-router";

import { WEB_STORAGE_KEY } from "@/globals/enums";
import WebStorageService from "@/services/web-storage.service";

import LoginComponent from "@/views/LoginComponent.vue";
import LoginMFAComponent from "@/views/LoginMFAComponent.vue";
import SettingSMSComponent from "@/views/SettingSMSComponent.vue";
import ReSettingSMSComponent from "@/views/ReSettingSMSComponent.vue";
import HomeComponent from "@/views/HomeComponent.vue";
import RegistrationComponent from "@/views/RegistrationComponent.vue";
import SettingMFAComponent from "@/views/SettingMFAComponent.vue";
import SettingAuthAppComponent from "@/views/SettingAuthAppComponent.vue";
import ReSettingAuthAppComponent from "@/views/ReSettingAuthAppComponent.vue";
import StyleGuideComponent from "@/views/StyleGuideComponent.vue";
import ReConfigMFAComponent from "@/views/ReConfigMFAComponent.vue";
import ResetMFAComponent from "@/views/ResetMFAComponent.vue";
import ForgotPasswordComponent from "@/views/ForgotPasswordComponent.vue";
import ResetPasswordComponent from "@/views/ResetPasswordComponent.vue";
import ChangePasswordComponent from "@/views/ChangePasswordComponent.vue";
import RegistrationUserInfoComponent from "@/views/RegistrationUserInfoComponent.vue";
import RegistrationSuccessComponent from "@/views/RegistrationSuccessComponent.vue";
import SearchHistoryRegistrationComponent from "@/views/SearchHistoryRegistrationComponent.vue";
import ProfileInfoComponent from "@/views/ProfileInfoComponent.vue";
import RoomInfoComponent from "@/views/RoomInfoComponent.vue";
import CompanySettingComponent from "@/views/CompanySettingComponent/CompanySettingComponent.vue";
import CompanyInfoComponent from "@/views/CompanySettingComponent/CompanyInfoComponent.vue";
import CompanyEditComponent from "@/views/CompanySettingComponent/CompanyEditComponent.vue";
import BuildingComponent from "@/views/BuildingComponent/BuildingComponent.vue";
import SearchBuildingComponent from "@/views/BuildingComponent/SearchBuildingComponent.vue";
import CreateBuildingComponent from "@/views/BuildingComponent/CreateBuildingComponent.vue";
import RoomComponent from "@/views/RoomComponent.vue";
import ShortTermRegistrationComponent from "@/views/ShortTermRegistrationComponent.vue";
import ResidentRegistrationComponent from "@/views/ResidentRegistrationComponent.vue";
import CreateRoomComponent from "@/views/CreateRoomComponent.vue";
import ListUploadComponent from "@/views/ListUploadComponent.vue";
import ErrorComponent from "@/views/ErrorComponent.vue";
const webStorageService = new WebStorageService();
const routes = [
  {
    path: "/login",
    name: "LoginComponent",
    component: LoginComponent,
    meta: { authPage: true },
  },
  {
    path: "/login-mfa",
    name: "LoginMFAComponent",
    component: LoginMFAComponent,
    meta: { authPage: true },
  },
  {
    path: "/home",
    name: "HomeComponent",
    component: HomeComponent,
  },
  {
    path: "/registration",
    name: "RegistrationComponent",
    component: RegistrationComponent,
    meta: { authPage: true },
  },
  {
    path: "/change-password",
    name: "ChangePasswordComponent",
    component: ChangePasswordComponent,
  },
  {
    path: "/re-config-mfa",
    name: "ReConfigMFAComponent",
    component: ReConfigMFAComponent,
  },
  {
    path: "/reset-mfa",
    name: "ResetMFAComponent",
    component: ResetMFAComponent,
    meta: { authPage: true },
  },
  {
    path: "/setting-mfa",
    name: "SettingMFAComponent",
    component: SettingMFAComponent,
    meta: { authPage: true },
  },
  {
    path: "/re-setting-auth-app",
    name: "ReSettingAuthAppComponent",
    component: ReSettingAuthAppComponent,
  },
  {
    path: "/setting-auth-app",
    name: "SettingAuthAppComponent",
    component: SettingAuthAppComponent,
    meta: { authPage: true },
  },
  {
    path: "/setting-sms",
    name: "SettingSMSComponent",
    component: SettingSMSComponent,
    meta: { authPage: true },
  },
  {
    path: "/re-setting-sms",
    name: "ReSettingSMSComponent",
    component: ReSettingSMSComponent,
    meta: { authPage: true },
  },
  {
    path: "/forgot-password",
    name: "ForgotPasswordComponent",
    component: ForgotPasswordComponent,
    meta: { authPage: true },
  },
  {
    path: "/reset-password",
    name: "ResetPasswordComponent",
    component: ResetPasswordComponent,
    meta: { authPage: true },
  },
  {
    path: "/profile-info",
    name: "ProfileInfoComponent",
    component: ProfileInfoComponent,
  },
  {
    path: "/registration-info-user",
    name: "RegistrationUserInfoComponent",
    component: RegistrationUserInfoComponent,
    meta: { authPage: true },
  },
  {
    path: "/registration-success",
    name: "RegistrationSuccessComponent",
    component: RegistrationSuccessComponent,
  },
  {
    path: "/company-setting",
    name: "CompanySettingComponent",
    component: CompanySettingComponent,
    children: [
      {
        path: "create",
        name: "CompanyEditComponent",
        component: CompanyEditComponent,
        meta: { authPage: true },
      },
      {
        path: "info",
        name: "CompanyInfoComponent",
        component: CompanyInfoComponent,
      },
    ],
  },
  {
    path: "/building",
    name: "BuildingComponent",
    component: BuildingComponent,
    children: [
      {
        path: "search",
        name: "SearchBuildingComponent",
        component: SearchBuildingComponent,
        meta: {
          autoFill: false,
        },
      },
      {
        path: "create",
        name: "CreateBuildingComponent",
        component: CreateBuildingComponent,
      },
    ],
  },
  {
    path: "/search-history-registration",
    name: "SearchHistoryRegistrationComponent",
    component: SearchHistoryRegistrationComponent,
  },
  {
    path: "/rooms/:buildingId",
    name: "RoomComponent",
    component: RoomComponent,
  },
  {
    path: "/create/:buildingId",
    name: "CreateRoomComponent",
    component: CreateRoomComponent,
  },
  {
    path: "/room-info/:roomId",
    name: "RoomInfoComponent",
    component: RoomInfoComponent,
  },
  {
    path: "/short-term-registration",
    name: "ShortTermRegistrationComponent",
    component: ShortTermRegistrationComponent,
  },
  {
    path: "/upload-file",
    name: "ListUploadComponent",
    component: ListUploadComponent,
  },
  {
    path: "/print/:residentId/:printId",
    name: "PrintComponent",
    component: () =>
      import(/* webpackChunkName: "print" */ "../views/PrintComponent.vue"),
  },
  {
    path: "/styleguide",
    name: "StyleGuideComponent",
    component: StyleGuideComponent,
  },
  {
    path: "/resident-registration/:id",
    name: "ResidentRegistrationComponent",
    component: ResidentRegistrationComponent,
  },
  {
    path: "/:patchMatch(.*)",
    name: "ErrorComponent",
    component: ErrorComponent,
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from) {
    if (to.name !== from.name) return { top: 0 };
  },
});

const validateNoAuthPath = (fromPath, toPath) => {
  const isRegisterProcess = toPath === "/registration" && fromPath === "/login";
  const isLoginMFAProcess = toPath === "/login-mfa" && fromPath === "/login";
  const isSettingMFA =
    (toPath === "/setting-mfa" && fromPath === "/registration") ||
    (toPath === "/setting-mfa" && fromPath === "/login") ||
    (toPath === "/setting-mfa" && fromPath === "/setting-auth-app") ||
    (toPath === "/setting-mfa" && fromPath === "/setting-sms");
  const isSettingMFAApp =
    toPath === "/setting-auth-app" && fromPath === "/setting-mfa";
  const isSettingSMS = toPath === "/setting-sms" && fromPath === "/setting-mfa";
  const isForgotPassword = toPath === "/forgot-password";
  const isResetPasswordProcess =
    toPath === "/reset-password" && fromPath === "/forgot-password";
  const isResetMFAProcess =
    (toPath === "/reset-mfa" && fromPath === "/login-mfa") ||
    (toPath === "/login-mfa" && fromPath === "/reset-mfa");
  const isStyleGuide =
    toPath === "/styleguide" && process.env.NODE_ENV === "development";
  const isReSettingSMS = toPath === "/re-setting-sms";
  return (
    isRegisterProcess ||
    isLoginMFAProcess ||
    isResetPasswordProcess ||
    isForgotPassword ||
    isResetMFAProcess ||
    isSettingMFA ||
    isSettingMFAApp ||
    isReSettingSMS ||
    isSettingSMS ||
    isStyleGuide
  );
};

const autoFillParams = (from, to) => {
  return (
    (from.name === "RoomComponent" ||
      from.name === "CreateBuildingComponent" ||
      from.name === "ListUploadComponent" ||
      !from.name) &&
    to.name === "SearchBuildingComponent"
  );
};

router.beforeEach((to, from, next) => {
  const token = webStorageService.getSessionStorage(WEB_STORAGE_KEY.ID_TOKEN);
  const userInfo = webStorageService.getSessionStorage(
    WEB_STORAGE_KEY.USER_INFO
  );
  // TODO: Code refactoring
  if (
    to.path === "/styleguide" &&
    (process.env.NODE_ENV === "development" ||
      process.env.NODE_ENV === "staging")
  ) {
    return next();
  }
  if ((!token || !userInfo) && to.path !== "/login") {
    return validateNoAuthPath(from.path, to.path)
      ? next()
      : next({ name: "LoginComponent" });
  }
  to.meta.autoFill = autoFillParams(from, to);
  const companyInfoValid = webStorageService.getSessionStorage(
    WEB_STORAGE_KEY.COMPANY_INFO_VALID
  );
  if (to.path === "/company-setting/info" && !companyInfoValid) {
    return next({ name: "HomeComponent" });
  }
  return next();
});

export default router;
