<template>
  <div class="TableRowComponent">
    <slot></slot>
  </div>
  <!-- <div style="height: 1px"></div> -->
</template>

<script>
export default {
  name: "TableRowComponent",
};
</script>
<style scoped lang="scss">
.TableRowComponent {
  display: table-row;
  outline: 0;
  background-color: transparent;
}
</style>
