import { STATUS } from "@/globals/enums";
import { convertCreateRegistrationResident } from "@/store/converter/registration.converter";
import { createResident } from "@/services/api/resident.service";

export default class ResidentService {
  async createResident(params) {
    try {
      const result = await createResident(
        convertCreateRegistrationResident(params)
      );
      return {
        status: STATUS.SUCCESS,
        data: {
          residentId: result?.resident_id,
        },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: error?.response,
      };
    }
  }
}
