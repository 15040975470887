<template>
  <div class="AddressFormComponent" v-if="!hasSlot">
    <GroupContentComponent :required="true" v-if="!postAddressData">
      <template v-slot:title> {{ addressTitle }}</template>
      <template v-slot:content>
        <div></div>
        <p class="small-label">〒</p>
        <div class="small-input">
          <InputComponent
            :small="true"
            :name="postOfficeNumberName"
            inputFilter="number"
            placeholder="郵便番号（半角ハイフンなし）を入力"
            @trigle-change="handleChangePostAddressNumber"
            maxlength="7"
          />
        </div>
        <ButtonComponent
          :className="isDisabledButton ? 'disabled noRounded' : 'noRounded'"
          type="link"
          @click="handleSearch"
          >郵便番号から検索</ButtonComponent
        >
      </template>
    </GroupContentComponent>
    <GroupContentComponent :isTitleTop="true" :required="true" v-else>
      <template v-slot:title> {{ addressTitle }}</template>
      <template v-slot:content>
        <div class="addressCode">
          <div class="addressCode-code">
            <p class="small-label small-label-right">〒</p>
            <div
              class="small-input"
              v-formatPostCode="postAddressData.postCode"
            />
            <ButtonComponent
              type="link"
              className="noRounded"
              @click="onSearchAgain()"
              >郵便番号から再検索</ButtonComponent
            >
          </div>
          <div class="addressCode-address">
            {{ postAddressData.address }}
          </div>
          <div class="addressCode-input">
            <InputComponent
              :name="addressDepartmentNumberInputName"
              :isCompanyEdit="true"
              :required="true"
              placeholder="番地号"
              maxlength="30"
              inputFilter="fullWidth"
              :value="addressDepartmentNumber"
            />
          </div>
          <div class="addressCode-input" v-if="showAllField">
            <InputComponent
              :name="addressDepartmentNameInputName"
              :isCompanyEdit="true"
              placeholder="建物名を入力"
              :value="addressDepartmentName"
              maxlength="30"
              inputFilter="fullWidth"
            />
          </div>
          <div class="addressCode-input small" v-if="showAllField">
            <InputComponent
              :name="addressRoomNumberInputName"
              class="input-field w-100"
              :small="true"
              placeholder="部屋番号を入力"
              maxlength="6"
              inputFilter="fullWidth"
              :value="addressRoomNumber"
            />
          </div>
        </div>
      </template>
    </GroupContentComponent>
  </div>
  <div class="AddressFormComponent" v-else>
    <GroupContentComponent
      :isTitleTop="true"
      :required="true"
      v-if="!postAddressData"
    >
      <template v-slot:title> {{ addressTitle }}</template>
      <template v-slot:content>
        <div class="flex-column">
          <slot></slot>
          <div class="flex-normal" v-show="isRenderTransferAddressInput">
            <p class="small-label">〒</p>
            <div class="small-input">
              <InputComponent
                :small="true"
                :name="postOfficeNumberName"
                inputFilter="number"
                placeholder="郵便番号（半角ハイフンなし）を入力"
                @trigle-change="handleChangePostAddressNumber"
                maxlength="7"
                :required="true"
                labelClass="farLabel"
              />
            </div>
            <ButtonComponent
              :className="isDisabledButton ? 'disabled noRounded' : 'noRounded'"
              type="link"
              @click="handleSearch"
              >郵便番号から検索</ButtonComponent
            >
          </div>
        </div>
      </template>
    </GroupContentComponent>
    <GroupContentComponent :isTitleTop="true" :required="true" v-else>
      <template v-slot:title> {{ addressTitle }}</template>
      <template v-slot:content>
        <div class="flex-column">
          <slot></slot>
          <div class="addressCode" v-show="isRenderTransferAddressInput">
            <div class="addressCode-code">
              <p class="small-label small-label-right">〒</p>
              <div
                class="small-input"
                v-formatPostCode="postAddressData.postCode"
              />
              <ButtonComponent
                type="link"
                className="noRounded"
                @click="onSearchAgain()"
                >郵便番号から再検索</ButtonComponent
              >
            </div>
            <div class="addressCode-address">
              {{ postAddressData.address }}
            </div>
            <div class="addressCode-input">
              <InputComponent
                :name="addressDepartmentNumberInputName"
                :isCompanyEdit="true"
                :required="true"
                placeholder="番地号"
                maxlength="30"
                inputFilter="fullWidth"
                :value="addressDepartmentNumber"
              />
            </div>
            <div class="addressCode-input" v-if="showAllField">
              <InputComponent
                :name="addressDepartmentNameInputName"
                :isCompanyEdit="true"
                placeholder="建物名を入力"
                :value="addressDepartmentName"
                maxlength="30"
                inputFilter="fullWidth"
              />
            </div>
            <div class="addressCode-input small" v-if="showAllField">
              <InputComponent
                :name="addressRoomNumberInputName"
                class="input-field w-100"
                :small="true"
                placeholder="部屋番号を入力"
                maxlength="6"
                inputFilter="fullWidth"
                :value="addressRoomNumber"
              />
            </div>
          </div>
        </div>
      </template>
    </GroupContentComponent>
  </div>
</template>
<script>
import CompanyService from "@/services/company.service";
import { STATUS } from "@/globals/enums";
export default {
  name: "AddressFormComponent",
  data: () => ({
    companyService: new CompanyService(),
    isDisabledButton: true,
  }),
  props: {
    addressTitle: {
      type: String,
      default: "住所",
    },
    postAddressData: {
      type: Object,
      default: null,
    },
    postAddressSearchNumber: {
      type: String,
      default: "",
    },
    postOfficeNumberName: {
      type: String,
      default: "postOfficeNumber",
    },
    addressDepartmentNumberInputName: {
      type: String,
      default: "addressDepartmentNumber",
    },
    addressDepartmentNumber: {
      type: String,
      default: "",
    },
    addressDepartmentNameInputName: {
      type: String,
      default: "addressDepartmentName",
    },
    addressDepartmentName: {
      type: String,
      default: "",
    },
    addressRoomNumberInputName: {
      type: String,
      default: "addressRoomNumber",
    },
    addressRoomNumber: {
      type: String,
      default: "",
    },
    handleChangePostAddressNumber: {
      type: Function,
      default: () => {},
    },
    showAllField: {
      type: Boolean,
      default: true,
    },
    hasSlot: {
      type: Boolean,
      default: false,
    },
    isRenderTransferAddressInput: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    async handleSearch() {
      await this.companyService
        .getAddressByPostOfficeNumber(this.postAddressSearchNumber)
        .then((result) => {
          if (result.status === STATUS.SUCCESS) {
            this.$vfm.show({
              component: "SearchAddressDialog",
              bind: {
                addressList: result.data.addressList,
                onChoise: (data) => {
                  this.$emit("on-choise", data);
                },
              },
            });
          }
        });
    },
    onSearchAgain() {
      this.$emit("search-again");
    },
  },
  watch: {
    postAddressSearchNumber: function (val) {
      this.isDisabledButton = !(val && val.length === 7);
    },
  },
};
</script>
<style scoped lang="scss">
.AddressFormComponent {
  .small {
    &-label {
      font-size: 14px;
      font-weight: 500;
      color: #7c8185;
      margin-right: 6px;
    }
    &-label-right {
      margin-right: 16px;
    }
    &-input {
      width: 280px;
      margin-right: 20px;
    }
  }
  .addressCode {
    &-code {
      display: flex;
      align-items: center;
      .small-input {
        margin-right: 30px;
        width: 68px;
        color: var(--color-black-text);
        font-size: 14px;
        font-weight: 500;
        line-height: 0.86;
        letter-spacing: 0.7px;
      }
    }
    &-address {
      margin: 20px 0 20px 0;
      color: #000;
      font-size: 14px;
      font-weight: 500;
    }
    &-input {
      padding-bottom: 10px;
      &.small {
        width: 210px;
      }
    }
  }
  .flex {
    &-column {
      display: flex;
      flex-direction: column;
    }
    &-normal {
      display: flex;
      align-items: center;
    }
  }
}
</style>
