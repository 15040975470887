import {
  convertCompanyInitSetting,
  convertCurrentEdittingData,
  convertGettingSettingCompanyData,
} from "../converter/company.converter";
const state = {
  initCompanySetting: {},
  edittingCompanyData: null,
};

// Getter functions
const getters = {
  getInitCompanySetting() {
    return state.initCompanySetting;
  },
};

// Mutations
const mutations = {
  initCompanySetting(state, data) {
    state.initCompanySetting = data;
  },
  setEdittingCompanyData(state, data) {
    state.edittingCompanyData = data;
  },
};

// Actions
const actions = {
  initCompanySetting({ commit }, data) {
    commit("initCompanySetting", convertCompanyInitSetting(data));
  },
  changeCurrentEdittingCompany({ commit }, { data, isEditting = false }) {
    isEditting
      ? commit("setEdittingCompanyData", convertCurrentEdittingData(data))
      : commit(
          "setEdittingCompanyData",
          convertGettingSettingCompanyData(data)
        );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
