<template>
  <div class="RoomComponent">
    <CompanyInfoComponent
      :isHasEdit="true"
      :isReadOnly="true"
      :isRenderRegistration="true"
      @update-building="updateBuilding"
      :disabledEditButton="disabledEditBuilding"
      :isInRoomDetail="true"
      :initBuildingData="buildingData"
      :initPostOfficeData="postOfficeData"
    />
    <div class="list">
      <div class="title-group" v-show="!isSearching">
        <TitleComponent class="RoomComponent-title">
          <template v-slot:icon> <span class="icon-ico_room"></span></template>
          <template v-slot:content>部屋情報</template>
          <template v-slot:button>
            <div class="RoomComponent-title-action">
              <ButtonComponent
                @click="onCreateRoom"
                width="150px"
                height="30px"
                color="#8d9caa"
              >
                部屋新規登録
              </ButtonComponent>
            </div>
          </template>
        </TitleComponent>
      </div>
      <CardComponent
        v-show="!isSearching"
        :isEmpty="!(roomList.length > 0)"
        :width="'960px'"
        :height="'807px'"
        class="table-room"
        emptyText="部屋情報が登録されていません。"
      >
        <div class="table-main-content">
          <p class="count-item">
            <strong
              v-tableRange="{
                currentPage: pagination?.page || 0,
                currentTotal: roomList?.length || 0,
              }"
            />
            <span class="total">件/{{ total }}件</span>
          </p>
          <table class="table-container">
            <tr>
              <TableHeaderComponent rowspan="2">部屋番号</TableHeaderComponent>
              <TableHeaderComponent rowspan="2"
                >オートロック</TableHeaderComponent
              >
              <TableHeaderComponent rowspan="2">温水器</TableHeaderComponent>
              <TableHeaderComponent class="special-header" colspan="1"
                >電気入居者</TableHeaderComponent
              >
              <TableHeaderComponent class="special-header" colspan="3">
                電気短期
              </TableHeaderComponent>
              <TableHeaderComponent rowspan="2">部屋情報</TableHeaderComponent>
            </tr>
            <tr>
              <TableHeaderComponent class="sub-header border-right">
                申込み
              </TableHeaderComponent>
              <TableHeaderComponent class="sub-header">
                使用開始
              </TableHeaderComponent>
              <TableHeaderComponent class="sub-header">
                使用停止
              </TableHeaderComponent>
              <TableHeaderComponent class="sub-header">
                申込み
              </TableHeaderComponent>
            </tr>
            <div class="table-row-group">
              <template v-for="room in roomList" :key="room.roomId">
                <TableRowComponent>
                  <TableCellComponent class="title-cell">{{
                    room.roomNumber
                  }}</TableCellComponent>
                  <TableCellComponent class="checked-cell">
                    <div>
                      <span>
                        <span v-if="room.autoLock" class="icon-ico_seijyo">
                          <span class="path1"></span>
                          <span class="path2"></span>
                        </span>
                        <span v-else class="icon-check"></span>
                        {{ room.autoLock ? "あり" : "なし" }}
                      </span>
                    </div>
                  </TableCellComponent>
                  <TableCellComponent class="checked-cell">
                    <div>
                      <span v-if="room.waterHeater" class="icon-ico_seijyo">
                        <span class="path1"></span>
                        <span class="path2"></span>
                      </span>
                      <span v-else class="icon-check"></span>
                      {{ room.waterHeater ? "あり" : "なし" }}
                    </div>
                  </TableCellComponent>
                  <TableCellComponent>
                    <StatusComponent :status="room.residentUsingStatus" />
                  </TableCellComponent>
                  <TableCellComponent>
                    <StatusComponent :status="room.shorttermUsingStatus" />
                  </TableCellComponent>
                  <TableCellComponent>
                    <StatusComponent :status="room.shorttermCancellingStatus" />
                  </TableCellComponent>
                  <TableCellComponent>
                    <ButtonComponent
                      :width="'149px'"
                      :height="'30px'"
                      :color="'#328dc2'"
                      :disabled="room.disabledShortTermButton"
                      @click="onOpenRegister(room)"
                    >
                      <span class="icon-ico_plus_circle"></span> 申込対象追加
                    </ButtonComponent>
                  </TableCellComponent>
                  <TableCellComponent>
                    <div class="RoomComponent-action">
                      <ButtonComponent
                        :width="'76px'"
                        :height="'30px'"
                        @click="onDetailRoom(room.roomId)"
                        >参照
                      </ButtonComponent>
                      <ButtonComponent
                        type="outline"
                        :width="'76px'"
                        :height="'30px'"
                        @click="onDeleteRoom(room.roomId)"
                        :disabled="room.disabledButton"
                        >削除
                      </ButtonComponent>
                    </div>
                  </TableCellComponent>
                </TableRowComponent>
              </template>
            </div>
          </table>
        </div>
        <PaginationComponent
          :total-pages="pagination.totalPage"
          :current-page="pagination.page"
          v-if="pagination.totalPage > 1"
          @pagechanged="onPageChange"
        />
      </CardComponent>
    </div>

    <div class="RoomComponent-footer-action">
      <ButtonComponent @click="onReturn" type="outline">
        もどる
      </ButtonComponent>
    </div>
  </div>
</template>

<script>
import WarningDialogService from "@/services/dialog/warning-dialog.service";
import RegistrationElectricRoomDialog from "@/services/dialog/registration-electric-dialog-service";
import CompanyInfoComponent from "@/views/CompanyInfoComponent.vue";
import RoomService from "@/services/room.service";
import RegistrationService from "@/services/registration.service";
import BuildingService from "@/services/building.service";
import { STATUS } from "@/globals/enums";
import CommonService from "@/services/common.service";
import ErrorDialogService from "@/services/dialog/error-dialog.service";

export default {
  name: "RoomComponent",
  components: { CompanyInfoComponent },
  data: () => ({
    pagination: { page: 1, number: 10, totalPage: 0 },
    total: 0,
    roomList: [],
    roomService: new RoomService(),
    registrationService: new RegistrationService(),
    buildingService: new BuildingService(),
    warningDialogService: new WarningDialogService(),
    registrationElectricRoomDialog: new RegistrationElectricRoomDialog(),
    errorDialogService: new ErrorDialogService(),
    commonService: new CommonService(),
    buildingData: null,
    postOfficeData: null,
    errorDeleteRoomDetail: null,
    disabledEditBuilding: true,
    isSearching: true,
  }),
  async mounted() {
    if (!this.$route.params.buildingId) {
      return this.onReturn();
    }
    this.$store.dispatch("global/updateLoadingStatus", true);
    await this.getInfoBuilding(this.$route.params.buildingId);
    await this.getListRooms(this.$route.params.buildingId);
    await this.getBuildingStatus(this.$route.params.buildingId);
    await this.getCurrentRegistrationList();
    this.$store.dispatch("global/updateLoadingStatus", false);
  },
  methods: {
    onPageChange(page) {
      this.pagination.page = page;
      this.getListRooms(this.$route.params.buildingId);
    },
    async getCurrentRegistrationList() {
      return await this.registrationService.getRegistrationList({
        page: 1,
        number: 10,
      });
    },
    async getListRooms(id) {
      await this.roomService
        .getRoomList(id, this.pagination)
        .then((dataRooms) => {
          this.roomList = dataRooms.data.roomList;
          this.total = dataRooms.data.total;
          this.pagination = {
            page: dataRooms.data.page,
            totalPage: dataRooms.data.totalPage,
          };
        });
      this.isSearching = false;
    },
    onReturn() {
      this.$router.push({ name: "SearchBuildingComponent" });
    },

    onCreateRoom() {
      this.$router.push({ path: `/create/${this.$route.params.buildingId}` });
    },

    onDeleteRoom(id) {
      this.warningDialogService.openWarningDialog(
        "部屋情報を削除します",
        "削除した部屋情報は元に戻せません。<br/>本当に削除してよろしいですか？",
        () => {},
        async () => {
          this.roomService.deleteRoom(id).then((result) => {
            if (result.status === STATUS.ERROR && result?.response?.detail) {
              this.$vfm.hideAll();
              this.errorDialogService.openErrorDialog(
                "部屋情報は削除できません",
                result?.response?.detail
              );
              return;
            }
            this.getListRooms(this.$route.params.buildingId);
            this.getCurrentRegistrationList();
          });
        }
      );
    },

    onDetailRoom(id) {
      this.$router.push({ path: `/room-info/${id}` });
    },

    async onOpenRegister(room) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.commonService.getHolidayList();
      const roomElectricRegisterData = {
        roomId: room.roomId,
        departmentName: this.buildingData.buildingName || "",
        roomName: room.roomNumber,
        holidateList: result?.data?.holidateList,
      };
      this.$store.dispatch("global/updateLoadingStatus", false);
      this.registrationElectricRoomDialog.openRegistrationElectricDialogServiceDialog(
        false,
        roomElectricRegisterData,
        () => this.getListRooms(this.$route.params.buildingId),
        this.registrationForm
      );
    },

    async registrationForm(data) {
      return await this.registrationService.createRoomRegistration(data);
    },

    async getBuildingStatus(id) {
      const result = await this.buildingService.getBuildingStatus(id);
      if (result.status === STATUS.SUCCESS) {
        this.disabledEditBuilding = result?.data?.registrationFlg;
      }
    },

    getInfoBuilding(id) {
      this.buildingService.getInfoBuilding(id).then((dataBuilding) => {
        if (!dataBuilding.data) {
          return;
        }
        this.buildingData = dataBuilding.data;
        this.postOfficeData = {
          address: dataBuilding.data.address,
          addressCode: dataBuilding.data.addressCode,
          postCode: dataBuilding.data.postCode,
        };
      });
    },

    updateBuilding(buildingData) {
      this.buildingData = buildingData;
    },
  },
};
</script>

<style lang="scss" scoped>
.RoomComponent {
  &-title {
    color: var(--venice-blue-color);
  }
  .CardComponent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .table-room {
    padding: 25px;
    .table-main-content {
      height: 686px;
      .count-item {
        text-align: right;
        margin: 5px 0;
        .total {
          font-size: 12px;
        }
      }
      .table-container {
        margin: unset;
        width: 100%;
        .TableHeaderComponent {
          text-align: center;
        }
        .TableCellComponent {
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          padding: 14px 0px;
          .title-cell {
            width: 88px;
          }
          .StatusComponent {
            width: 94px;
          }
          .checked-cell {
            width: 75px;
          }
        }
      }
    }
  }

  &-title {
    &-action {
      .ButtonComponent {
        font-size: 14px;
      }
    }
  }
  &-action {
    display: flex;
    .ButtonComponent {
      margin: 0 5px;
    }
  }
  .special-header {
    border-bottom: unset;
    .ErrorDetailComponent {
      font-size: 14px;
      text-align: right;
      margin: 5px;
    }
  }
  .sub-header {
    border-top: none;
    background-color: #f3f6f7;
  }

  .pagination {
    text-align: center;
    margin-top: 25px;
  }

  &-footer {
    &-action {
      margin-bottom: var(--default-distance-button);
      .ButtonComponent {
        text-align: center;
      }
    }
  }
}
.list {
  width: 960px;
  margin: var(--default-distance-card) auto;
  .title-group {
    width: 100%;
  }
  .border-right {
    border-right: 2px solid white;
  }
}
</style>
