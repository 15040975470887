import router from "../router/router";
import store from "./../store/index";
import WebStorageService from "./web-storage.service";
import { WEB_STORAGE_KEY, TYPE_MFA, STATUS } from "../globals/enums";
import { Auth } from "aws-amplify";
import {
  verifyPhoneNumber,
  resetMFA,
  checkToken,
} from "@/services/api/api.service";
import { getProfileInfo } from "./user.service";
import ConfirmDialogService from "./dialog/confirm-dialog.service";
import { ERROR_MESSAGES_API } from "@/globals/error-messages";
import { $vfm } from "vue-final-modal";

const webStorageService = new WebStorageService();
const confirmDialogService = new ConfirmDialogService();
export default class LoginService {
  initLogin() {
    const token = webStorageService.getSessionStorage(WEB_STORAGE_KEY.TOKEN);
    if (token) {
      store.dispatch("settings/changeLoggedIn", true);
      const userId = webStorageService.getSessionStorage(
        WEB_STORAGE_KEY.USER_INFO
      );
      this.getUserData(userId, true);
    }
  }

  async login(user, reRoute = false, query = {}) {
    webStorageService.setSessionStorage(
      WEB_STORAGE_KEY.ID_TOKEN,
      user.signInUserSession.idToken.jwtToken
    );
    webStorageService.setSessionStorage(
      WEB_STORAGE_KEY.ACCESS_TOKEN,
      user.signInUserSession.accessToken.jwtToken
    );
    webStorageService.setSessionStorage(
      WEB_STORAGE_KEY.REFRESH_TOKEN,
      user.signInUserSession.refreshToken.token
    );
    webStorageService.setSessionStorage(
      WEB_STORAGE_KEY.USER_INFO,
      user.username
    );
    const result = await getProfileInfo();
    result?.status === STATUS.SUCCESS &&
      reRoute &&
      router.push({ name: "HomeComponent", query });
  }

  tempLogin(tempUser) {
    store.dispatch("settings/changeTempUser", tempUser);
  }

  saveQrCode(email, code) {
    store.dispatch("settings/changeQRCode", {
      generateQRCode:
        "otpauth://totp/" +
        process.env.VUE_APP_AWS_ISSUER_COMPANY +
        ":" +
        email +
        "?secret=" +
        code +
        "&issuer=" +
        process.env.VUE_APP_AWS_ISSUER_COMPANY,
      code,
    });
  }

  clearQrCode() {
    store.dispatch("settings/changeQRCode", null);
  }

  async getCognitoUserData() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const preferredMFA = await Auth.getPreferredMFA(user, {
        bypassCache: true,
      });
      store.dispatch("settings/changeCurrentUserMFAInfo", {
        ...user,
        preferredMFA,
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async setupTOTP() {
    try {
      const data = store.getters["settings/getTempUser"];
      const token = webStorageService.getSessionStorage(
        WEB_STORAGE_KEY.ID_TOKEN
      );
      const user = token
        ? await Auth.currentAuthenticatedUser()
        : await Auth.signIn(data.emailAddress, data.tempPassword);
      const code = await Auth.setupTOTP(user);
      //save QRCODE to store
      this.saveQrCode(token ? user.attributes.email : data.emailAddress, code);
      return {
        status: STATUS.SUCCESS,
        data: user,
      };
    } catch (error) {
      console.log(error);
      return { status: STATUS.ERROR, error };
    }
  }

  async setPreferredTOTP() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.setPreferredMFA(user, TYPE_MFA.TOTP);
      return { status: STATUS.SUCCESS };
    } catch (error) {
      console.log(error);
      confirmDialogService.openConfirmDialog("エラー", error.message, () => {});
      return { status: STATUS.ERROR, error };
    }
  }

  async registerTOTP(challengeAnswer, user) {
    try {
      await Auth.verifyTotpToken(user, challengeAnswer);
      await Auth.setPreferredMFA(user, TYPE_MFA.TOTP);
      this.logout();
    } catch (error) {
      return {
        status: STATUS.ERROR,
        error,
        response: { detail: ERROR_MESSAGES_API.CODE_MISS_MATCH },
      };
    }
  }

  async registerTOTPAgain(challengeAnswer, user) {
    try {
      await Auth.verifyTotpToken(user, challengeAnswer);
      await Auth.setPreferredMFA(user, TYPE_MFA.TOTP);
      confirmDialogService.openConfirmDialog(
        "セキュリティ設定を保存しました。",
        null,
        () => router.push({ name: "HomeComponent" })
      );
    } catch (error) {
      return {
        status: STATUS.ERROR,
        error,
        response: { detail: ERROR_MESSAGES_API.CODE_MISS_MATCH },
      };
    }
  }

  async registerSMS(phoneNumber) {
    try {
      const data = store.getters["settings/getTempUser"];
      const token = webStorageService.getSessionStorage(
        WEB_STORAGE_KEY.ID_TOKEN
      );
      const user = token
        ? await Auth.currentAuthenticatedUser()
        : await Auth.signIn(data.emailAddress, data.tempPassword);
      await verifyPhoneNumber({
        account_id: user.username,
        phone_number: phoneNumber,
      });
      this.logout();
    } catch (error) {
      console.log(error);
      confirmDialogService.openConfirmDialog("エラー", error.message, () => {});
      return { status: STATUS.ERROR, error };
    }
  }

  async registerSMSAgain(phoneNumber) {
    try {
      const data = store.getters["settings/getTempUser"];
      const token = webStorageService.getSessionStorage(
        WEB_STORAGE_KEY.ID_TOKEN
      );
      const user = token
        ? await Auth.currentAuthenticatedUser()
        : await Auth.signIn(data.emailAddress, data.tempPassword);
      await verifyPhoneNumber({
        account_id: user.username,
        phone_number: phoneNumber,
      });
      confirmDialogService.openConfirmDialog(
        "セキュリティ設定を保存しました。",
        null,
        () => router.push({ name: "HomeComponent" })
      );
    } catch (error) {
      console.log(error);
      confirmDialogService.openConfirmDialog("エラー", null, () => {});
      return { status: STATUS.ERROR, error };
    }
  }

  async reConfigSMS(phoneNumber, accountId) {
    try {
      await verifyPhoneNumber({
        account_id: accountId,
        phone_number: phoneNumber,
      });
      this.logout();
    } catch (error) {
      console.log(error);
      confirmDialogService.openConfirmDialog("エラー", error.message, () => {});
      return { status: STATUS.ERROR, error };
    }
  }

  async loginMFA(user, loginMFAcode, mfaType) {
    await Auth.confirmSignIn(user, loginMFAcode, mfaType);
    await this.login(user, true);
  }

  async verifyMFACode(emailAddress, password, username, user) {
    this.tempLogin({
      emailAddress,
      tempPassword: password,
      username,
      currentUser: user,
    });
    router.push({ name: "LoginMFAComponent" });
  }

  async resetMFASetting(userName) {
    try {
      await resetMFA({
        account_id: userName,
      });
      return { status: STATUS.SUCCESS };
    } catch (error) {
      console.log(error);
      return { status: STATUS.ERROR, error };
    }
  }

  async signIn(
    emailAddress,
    password,
    changePasswordRequired = null,
    loginMFAcode = null
  ) {
    try {
      const data = store.getters["settings/getTempUser"];
      if (
        (data?.currentUser?.challengeName === "SOFTWARE_TOKEN_MFA" ||
          data?.currentUser?.challengeName === "SMS_MFA") &&
        loginMFAcode
      ) {
        await this.loginMFA(
          data.currentUser,
          loginMFAcode,
          data.currentUser.challengeName
        );
        return;
      }
      const user = await Auth.signIn(emailAddress, password);
      if (user) {
        // this.getUserData(user.username);
        if (
          user.challengeName === "SMS_MFA" ||
          user.challengeName === "SOFTWARE_TOKEN_MFA"
        ) {
          this.verifyMFACode(emailAddress, password, user.username, user);
        } else if (
          user.challengeName &&
          user.challengeName === "NEW_PASSWORD_REQUIRED"
        ) {
          if (changePasswordRequired) {
            try {
              await Auth.completeNewPassword(user, changePasswordRequired);
              this.tempLogin({
                emailAddress: emailAddress,
                tempPassword: changePasswordRequired,
              });
              router.push({ name: "SettingMFAComponent" });
            } catch (error) {
              console.log(error);
              throw error;
            }
          } else {
            this.tempLogin({
              emailAddress: emailAddress,
              tempPassword: password,
            });
            router.push({ name: "RegistrationComponent" });
          }
        } else if (user.challengeName === "MFA_SETUP") {
          // This happens when the MFA method is TOTP
          // The user needs to setup the TOTP before using it
          // More info please check the Enabling MFA part
          this.tempLogin({
            emailAddress: emailAddress,
            tempPassword: password,
          });
          router.push({ name: "SettingMFAComponent" });
        } else {
          this.login(user, true);
        }
      }
      return { status: "NO_ACTION" };
    } catch (err) {
      if (loginMFAcode) {
        return {
          status: STATUS.ERROR,
          error: err,
          response: { detail: ERROR_MESSAGES_API.CODE_MISS_MATCH },
        };
      }
      if (err.code === "UserNotConfirmedException") {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === "PasswordResetRequiredException") {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.code === "NotAuthorizedException") {
        return {
          status: STATUS.ERROR,
          error: err,
          response: { detail: ERROR_MESSAGES_API.PASSWORD_NOT_RIGHT },
        };
        // The error happens when the incorrect password is provided
      } else if (err.code === "UserNotFoundException") {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      } else {
        console.log(err);
      }
      return { status: STATUS.ERROR, error: err, response: {} };
    }
  }

  async refreshToken() {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const { refreshToken } = cognitoUser.getSignInUserSession();
      return new Promise((resolve) =>
        cognitoUser.refreshSession(refreshToken, async (err, session) => {
          if (err) {
            throw err;
          }
          console.log("session", session);
          webStorageService.setSessionStorage(
            WEB_STORAGE_KEY.ID_TOKEN,
            session.idToken.jwtToken
          );
          webStorageService.setSessionStorage(
            WEB_STORAGE_KEY.USER_INFO,
            cognitoUser.username
          );
          webStorageService.setSessionStorage(
            WEB_STORAGE_KEY.ACCESS_TOKEN,
            session.accessToken.jwtToken
          );
          webStorageService.setSessionStorage(
            WEB_STORAGE_KEY.REFRESH_TOKEN,
            session.refreshToken.token
          );
          const cognitoUserToken = `CognitoIdentityServiceProvider.${process.env.VUE_APP_COGNITO_CLIENT_ID}.${cognitoUser.username}.idToken`;
          webStorageService.setSessionStorage(
            cognitoUserToken,
            session.idToken.jwtToken
          );
          resolve(session);
        })
      );
    } catch (error) {
      console.log("Unable to refresh Token", error);
      if ($vfm.modals.length === 0) {
        confirmDialogService.openConfirmDialog(
          ERROR_MESSAGES_API.HTTP_ERROR_401.message,
          ERROR_MESSAGES_API.HTTP_ERROR_401.detail,
          () => this.logout()
        );
      }
    }
  }

  async logout() {
    window.clearInterval(window.tokenInterval);
    window.tokenInterval = null;
    await Auth.signOut();
    webStorageService.clearSessionStorage();
    router.push({ name: "LoginComponent" });
  }

  getUserData() {
    const handleUserData = (data) => {
      if (
        data.sysRSecurityGroupUserListUserId &&
        data.sysRSecurityGroupUserListUserId.edges[0]
      ) {
        const securityGroup =
          data.sysRSecurityGroupUserListUserId.edges[0].node
            .relationshipSecurityGroupId;
        const roles =
          securityGroup.sysRSecurityGroupRoleListSecurityGroupId.edges.map(
            (e) => e.node
          );
        store.dispatch("settings/changeRoles", roles);
      }
    };
    const userData = webStorageService.getSessionStorage(
      WEB_STORAGE_KEY.USER_DATA
    );
    if (userData) handleUserData(userData);
  }

  async forgotPassword(email) {
    try {
      await Auth.forgotPassword(email);
      // save email to store
      this.tempLogin({
        emailAddress: email,
      });
      confirmDialogService.openConfirmDialog(
        "認証コードを送信しました。",
        "認証コードをメールアドレスへ送信しました。認証コードを入力して新しいパスワードを再設定してください。",
        () => router.push({ name: "ResetPasswordComponent" })
      );
    } catch (error) {
      console.log(error);
    }
  }

  async resetPassword(emailAddress, code, password) {
    try {
      await Auth.forgotPasswordSubmit(emailAddress, code, password);
      confirmDialogService.openConfirmDialog(
        "パスワードの再設定が完了しました。",
        "パスワードの再設定が完了いたしました。トップページより、新しいパスワードにて再度ログインしてください。",
        () => router.push({ name: "LoginComponent" })
      );
      this.tempLogin(null);
    } catch (error) {
      if (
        error.code === "CodeMismatchException" ||
        error.code === "ExpiredCodeException"
      ) {
        return {
          status: STATUS.ERROR,
          error: error,
          response: { detail: "承認コードが無効です" },
        };
      }
      if (error.code === "LimitExceededException") {
        return {
          status: STATUS.ERROR,
          error: error,
          response: {
            detail: "承認コードが無効です。暫くたってから再度試してください。",
          },
        };
      }
      return {
        status: STATUS.ERROR,
        error: error,
        response: {
          detail:
            "新しいパスワード設定ができませんでした。暫くたってから再度試してください。",
        },
      };
    }
  }

  async changePassword(oldPassword, newPassword) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      confirmDialogService.openConfirmDialog(
        "パスワードの変更が完了しました。",
        null,
        () => router.push({ name: "HomeComponent" })
      );
    } catch (error) {
      if (error.code === "NotAuthorizedException") {
        return {
          status: STATUS.ERROR,
          error: error,
          response: { detail: "現在のパスワードがただしくありません。" },
        };
      }
      if (error.code === "LimitExceededException") {
        return {
          status: STATUS.ERROR,
          error: error,
          response: {
            detail:
              "現在のパスワードがただしくありません。暫くたってから再度試してください。",
          },
        };
      }
      return {
        status: STATUS.ERROR,
        error: error,
        response: {
          detail:
            "パスワード変更が受けられませんでした。暫くたってから再度試してください。",
        },
      };
    }
  }

  async checkToken(token) {
    try {
      const result = await checkToken({ token });
      return { status: STATUS.SUCCESS, data: result };
    } catch (error) {
      console.log(error);
      return { status: STATUS.ERROR, error: error };
    }
  }
}
