<template>
  <Datepicker
    v-model="currentDate"
    locale="ja"
    ref="datePickerRef"
    :enableTimePicker="false"
    :minDate="minDate"
    :maxDate="maxDate"
    :disabled="disabled"
    :disabledDates="disabledList"
  >
    <template #trigger>
      <span
        class="icon-ico_calendar clickable-text"
        :class="iconClassName"
      ></span>
    </template>
    <template #action-select="{ value }">
      <ButtonComponent
        width="50px"
        height="25px"
        color="#328dc2"
        className="ButtonComponent-fontbold"
        class="custom-select"
        @click="selectDate(value)"
        >選択</ButtonComponent
      >
    </template>
  </Datepicker>
</template>

<script>
import Datepicker from "vue3-date-time-picker";
import { addMonths } from "@/plugins/parseDateChoise";
import { ref } from "vue";

export default {
  name: "DatePickerComponent",
  setup(props) {
    const date = ref(props.initDate);
    const minDate = props?.minDateSearch
      ? ref(props.minDateSearch)
      : new Date();
    const fakeDate = new Date(minDate?.value);
    fakeDate.setDate(fakeDate.getDate());
    const maxDate = ref(addMonths(new Date(fakeDate), 3, props.disabledList));
    return {
      date,
      minDate,
      maxDate,
    };
  },
  props: {
    iconClassName: {
      type: String,
      default: "",
    },
    initDate: {
      type: Date,
      default: new Date(),
    },
    minDateSearch: {
      type: Date,
      default: new Date(),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Datepicker,
  },
  data: () => ({
    currentDate: null,
  }),
  methods: {
    selectDate(value) {
      if (this.$refs.datePickerRef) {
        this.$emit("change-date", value);
        this.$refs.datePickerRef.closeMenu();
      }
    },
  },
  watch: {
    initDate: function (val) {
      this.currentDate = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.clickable-text {
  cursor: pointer;
}
.dp__action_buttons {
  text-align: center;
}
.custom-select {
  cursor: pointer;
  margin-left: auto;
  font-size: 12px;
}
.icon-ico_calendar {
  &::before {
    font-size: 19px;
  }
}
.finalLabel {
  margin-right: 32px;
}
</style>
