import {
  createRegistration,
  getRegistrationList,
  editRegistration,
  deleteRegistration,
  updateRegistration,
  getHistoryRegistration,
  getResultRegistration,
  updateResultRegistration,
} from "./api/registration.service";
import {
  convertUpdateRegistrationList,
  convertSendingRegistrationRoom,
  convertUpdateRegistration,
  convertRegistrationHistory,
  convertRegistrationHistoryFilter,
} from "@/store/converter/registration.converter";
import { STATUS } from "@/globals/enums";
import store from "./../store/index";

export default class RegistrationService {
  async createRoomRegistration(params) {
    try {
      await createRegistration(convertSendingRegistrationRoom(params));
      const newResult = await getRegistrationList({
        page: 1,
        number: 10,
      });
      store.dispatch("registrationShortTerm/changeRegistrationList", newResult);
      return {
        status: STATUS.SUCCESS,
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: error?.response,
      };
    }
  }

  async getRegistrationList(pagination) {
    try {
      const result = await getRegistrationList({
        page: pagination.page || 1,
        number: pagination.number || 10,
      });
      store.dispatch("registrationShortTerm/changeRegistrationList", result);
      return {
        status: STATUS.SUCCESS,
      };
    } catch (error) {
      console.log(error);
      return {
        status: STATUS.ERROR,
        message: error.message,
      };
    }
  }

  async editRegistration(id, params, pagination) {
    try {
      await editRegistration(id, convertUpdateRegistration(params));
      const newResult = await getRegistrationList({
        page: pagination.page || 1,
        number: pagination.number || 10,
      });
      store.dispatch("registrationShortTerm/changeRegistrationList", newResult);
      return {
        status: STATUS.SUCCESS,
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: error?.response,
      };
    }
  }

  async deleteRegistration(id, pagination) {
    try {
      await deleteRegistration({ registration_id: id });
      const page =
        pagination.page !== 1 && pagination?.currentLength === 1
          ? pagination.page - 1
          : pagination.page;
      const newResult = await getRegistrationList({
        page: page || 1,
        number: pagination?.number || 10,
      });
      store.dispatch("registrationShortTerm/changeRegistrationList", newResult);
      return {
        status: STATUS.SUCCESS,
      };
    } catch (error) {
      console.log(error);
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: error?.response,
      };
    }
  }

  async deleteAllRegistration() {
    try {
      await deleteRegistration({ delete_all_flg: 1 });
      const newResult = await getRegistrationList({
        page: 1,
        number: 10,
      });
      store.dispatch("registrationShortTerm/changeRegistrationList", newResult);
      return {
        status: STATUS.SUCCESS,
      };
    } catch (error) {
      console.log(error);
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: error?.response,
      };
    }
  }

  async updateRegistration(data, pagination) {
    try {
      await updateRegistration(convertUpdateRegistrationList(data));
      const newResult = await getRegistrationList({
        page: pagination?.page || 1,
        number: pagination?.number || 10,
      });
      store.dispatch("registrationShortTerm/changeRegistrationList", newResult);
      return {
        status: STATUS.SUCCESS,
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: error.response,
      };
    }
  }

  async getHistoryRegistration(data, pagination, sort) {
    try {
      const result = await getHistoryRegistration(
        convertRegistrationHistoryFilter(data, pagination, sort)
      );
      return {
        status: STATUS.SUCCESS,
        data: {
          total: result.total,
          page: result.page,
          totalPage: result.total_page || 0,
          registrationList:
            result.registration_list.map((registration) => {
              return convertRegistrationHistory(registration);
            }) || [],
        },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: error?.response,
      };
    }
  }

  async getResultRegistration(id) {
    try {
      const result = await getResultRegistration(id);
      return {
        status: STATUS.SUCCESS,
        data: result,
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: error?.response,
      };
    }
  }

  async updateResultRegistration(id) {
    try {
      const result = await updateResultRegistration(id);
      return {
        status: STATUS.SUCCESS,
        data: result,
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: error?.response,
      };
    }
  }
}
