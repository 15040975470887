<template>
  <div
    v-if="edittingCompanyData"
    class="CompanyPreviewTabComponent"
    :class="{ inInfo: inInfo }"
  >
    <TitleComponent class="CompanyPreviewTabComponent-title" v-if="inInfo">
      <template v-slot:icon>
        <span class="icon-ico_list"></span>
      </template>
      <template v-slot:content>会社情報・支払先情報</template>
    </TitleComponent>
    <CardComponent class="main" :width="'960px'" :height="'auto'">
      <TitleComponent v-if="!inInfo" class="underline">
        <template v-slot:content>会社情報・支払先情報確認</template>
      </TitleComponent>
      <div class="form-groups">
        <div class="form-groups-title">会社情報</div>
        <GroupContentComponent>
          <template v-slot:title> 会社名 </template>
          <template v-slot:content>
            {{ edittingCompanyData.companyName }}
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> 会社名（カナ）</template>
          <template v-slot:content>
            {{ edittingCompanyData.companyKanaName }}
          </template>
        </GroupContentComponent>
        <GroupContentComponent :isTitleTop="true">
          <template v-slot:title> 住所</template>
          <template v-slot:content>
            <div
              class="postOfficeAddress"
              v-if="edittingCompanyData.postOfficeData"
            >
              <div class="postCode">
                〒
                <div
                  v-formatPostCode="edittingCompanyData.postOfficeData.postCode"
                ></div>
              </div>
              <div class="postOfficeInfo">
                {{ edittingCompanyData.postOfficeData.address }}<br />
                {{ edittingCompanyData.addressDepartmentNumber }}<br />
                {{ edittingCompanyData.addressDepartmentName }}<br />
                {{ edittingCompanyData.addressRoomNumber }}
              </div>
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> 私書箱</template>
          <template v-slot:content>
            {{ edittingCompanyData.mailbox }}
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> 電話番号</template>
          <template v-slot:content>
            {{ edittingCompanyData.firstPathNumbers }}-{{
              edittingCompanyData.secondPathNumbers
            }}-{{ edittingCompanyData.thirtPathNumbers }}
            <div class="phoneNumberCategory">
              {{ edittingCompanyData.phoneNumberCategory.label }}
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> 電気料金支払い方法</template>
          <template v-slot:content>
            {{ edittingCompanyData.paymentOptions.label }}
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> 入居者向け<br />電気契約申込み</template>
          <template v-slot:content>
            <p v-if="edittingCompanyData.electricContractApp">
              {{ edittingCompanyData.electricContractApp.label }}
            </p>
            <p
              class="electricContractAppType"
              v-if="
                edittingCompanyData.electricContractApp.value ===
                  COMPANY_REGISTER_CONTRACT_TYPES.DEFAULT_VALUE &&
                edittingCompanyData.electricContractAppType
              "
            >
              {{ edittingCompanyData.electricContractAppType.label }}
            </p>
          </template>
        </GroupContentComponent>
      </div>
      <div
        class="form-groups"
        v-if="edittingCompanyData.isAccountSettingDisplay"
      >
        <div class="form-groups-title">支払先情報</div>
        <GroupContentComponent>
          <template v-slot:title> 金融機関・支店名 </template>
          <template v-slot:content>
            <p>{{ edittingCompanyData.bankName }}</p>
            <p class="bankDepartment">
              {{ edittingCompanyData.bankDepartment }}
            </p>
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> 口座情報 </template>
          <template v-slot:content>
            <div class="accountInfo" v-if="edittingCompanyData.accountType">
              <p>{{ edittingCompanyData.accountType.label }}</p>
              <p>{{ edittingCompanyData.departmentNumber }}</p>
              <p>{{ edittingCompanyData.accountNumber }}</p>
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> 口座名義 </template>
          <template v-slot:content>
            <p>{{ edittingCompanyData.accountName }}</p>
          </template>
        </GroupContentComponent>
      </div>
      <div class="form-groups" v-else>
        <div class="form-groups-title">支払先情報</div>
        <GroupContentComponent :isTitleTop="true">
          <template v-slot:title> 送付先住所</template>
          <template v-slot:content>
            <div>
              <div
                class="postOfficeAddress"
                v-if="edittingCompanyData?.transferAddressOptions?.label"
              >
                <div>
                  {{ edittingCompanyData.transferAddressOptions.label }}
                </div>
              </div>
              <div
                class="postOfficeAddress"
                v-if="edittingCompanyData.postDeliveryData"
              >
                <div class="postCode">
                  〒
                  <div
                    v-formatPostCode="
                      edittingCompanyData.postDeliveryData.postCode
                    "
                  ></div>
                </div>
                <div v-if="inInfo" class="postOfficeInfo">
                  {{ edittingCompanyData.postDeliveryData.address }}<br />
                  {{
                    edittingCompanyData.postDeliveryData
                      .deliveryDepartmentNumber
                  }}<br />
                  {{
                    edittingCompanyData.postDeliveryData.deliveryDepartmentName
                  }}<br />
                  {{ edittingCompanyData.postDeliveryData.deliveryRoomNumber }}
                </div>
                <div v-else class="postOfficeInfo">
                  {{ edittingCompanyData.postDeliveryData.address }}<br />
                  {{ edittingCompanyData.deliveryDepartmentNumber }}<br />
                  {{ edittingCompanyData.deliveryDepartmentName }}<br />
                  {{ edittingCompanyData.deliveryRoomNumber }}
                </div>
              </div>
            </div>
          </template>
        </GroupContentComponent>
      </div>
      <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
      <div class="action-group" v-if="!inInfo">
        <ButtonComponent @click="onBack()" type="outline">
          戻る
        </ButtonComponent>
        <ButtonComponent @click="submit()"> 登録 </ButtonComponent>
      </div>
      <div class="action-group" v-else>
        <ButtonComponent @click="editCompanyData()"> 編集 </ButtonComponent>
      </div>
    </CardComponent>
    <div class="back-button" v-if="inInfo">
      <ButtonComponent type="outline" @click="onBackInfo()">
        もどる
      </ButtonComponent>
    </div>
  </div>
</template>

<script>
import {
  COMPANY_SETTING_TAB,
  STATUS,
  TRANSFER_ADDRESS_OPTIONS_DEFAULT,
  COMPANY_REGISTER_CONTRACT_TYPES,
} from "@/globals/enums";
import { mapState } from "vuex";
import CompanyService from "@/services/company.service";
export default {
  name: "CompanyPreviewTabComponent",
  props: {
    inInfo: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.TRANSFER_ADDRESS_OPTIONS_DEFAULT = TRANSFER_ADDRESS_OPTIONS_DEFAULT;
    this.COMPANY_REGISTER_CONTRACT_TYPES = COMPANY_REGISTER_CONTRACT_TYPES;
  },
  computed: mapState({
    edittingCompanyData: (state) => state.company.edittingCompanyData,
  }),
  data: () => ({
    companyService: new CompanyService(),
    isEdit: false,
    errorDetail: null,
  }),
  methods: {
    onBack() {
      this.errorDetail = null;
      this.$emit("change-tab", COMPANY_SETTING_TAB.EDIT);
    },
    onBackInfo() {
      this.$router.go(-1);
    },
    async submit() {
      this.$store.dispatch("global/updateLoadingStatus", true);
      this.errorDetail = null;
      await this.companyService
        .saveCompanySetting(this.edittingCompanyData)
        .then((result) => {
          this.$store.dispatch("global/updateLoadingStatus", false);
          if (result.status === STATUS.SUCCESS) {
            this.$emit("change-tab", COMPANY_SETTING_TAB.DONE);
          }
          if (result.status === STATUS.ERROR && result.response?.detail) {
            this.errorDetail = result.response.detail;
          }
        });
    },
    editCompanyData() {
      this.$emit("change-tab", COMPANY_SETTING_TAB.EDIT);
    },
  },
};
</script>
<style scoped lang="scss">
.CompanyPreviewTabComponent {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &-title {
    display: flex;
    width: 960px;
    color: var(--venice-blue-color);
    font-size: 20px;
    font-weight: 500;
  }
  .main {
    margin: 27px 0 40px 0;
    padding: 30px 40px 29px;
    .TitleComponent {
      width: 255px;
      margin: 0 auto;
    }
    .form-groups {
      margin: 30px 0 30px 0;
      font-size: 14px;
      &-title {
        font-size: 16px;
        padding-bottom: 5px;
        border-bottom: solid 3px #d0d9df;
      }
      .postOfficeAddress {
        .postCode {
          display: flex;
        }
      }
      .phoneNumberCategory {
        margin-left: 40px;
      }
      .electricContractAppType {
        margin-left: 120px;
      }
      .bankDepartment {
        margin-left: 30px;
      }
      .accountInfo {
        display: flex;
        gap: 30px;
      }
    }
    .action-group {
      display: flex;
      justify-content: center;
      gap: 30px;
    }
  }
  &.inInfo {
    padding: var(--default-distance-card) 40px 29px;
    .main {
      margin: 0;
    }
  }
  .back-button {
    margin: 30px 0;
  }
}
</style>
