import { parsePhoneNumber } from "@/plugins/parsePhoneNumber";
import {
  REGISTER_PHONE_COMPANY_TYPES,
  COMPANY_REGISTER_TYPES,
  COMPANY_REGISTER_CONTRACT_TYPES,
  PAYMENT_OPTIONS,
  BANK_ACCOUNT_TYPES,
  TRANSFER_ADDRESS_OPTIONS,
  TRANSFER_ADDRESS_OPTIONS_DEFAULT,
} from "@/globals/enums";
const convertRegisterPhoneNumberTypes = () => {
  return REGISTER_PHONE_COMPANY_TYPES.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

const convertCompanyRegisterTypes = () => {
  return COMPANY_REGISTER_TYPES.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

const convertPaymentOptions = () => {
  return PAYMENT_OPTIONS.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

const convertBankAcountOptions = () => {
  return BANK_ACCOUNT_TYPES.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

export const convertAddressList = (data) => {
  return {
    addressList: data.address_list.map((item) => ({
      addressCode: item.address_code,
      address: item.address,
      postCode: item.post_code,
    })),
  };
};

export const convertTranferAddressOptions = () => {
  return TRANSFER_ADDRESS_OPTIONS.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

export const convertCompanyInitSetting = () => {
  return {
    phoneNumberGroup: parsePhoneNumber(""),
    registerPhoneNumberOptions: convertRegisterPhoneNumberTypes(),
    companyRegisterOptions: convertCompanyRegisterTypes(),
    paymentOptions: convertPaymentOptions(),
    bankAcountOptions: convertBankAcountOptions(),
    transferAddressOptions: convertTranferAddressOptions(),
  };
};

const convertAccountType = (accountType) => {
  const findingValue = BANK_ACCOUNT_TYPES.find(
    (type) => type.VALUE === accountType
  );
  return {
    value: findingValue.VALUE,
    label: findingValue.NAME,
  };
};

const convertPhoneNumberCategory = (phoneNumberCategory) => {
  const findingValue = REGISTER_PHONE_COMPANY_TYPES.find(
    (type) => type.VALUE === phoneNumberCategory
  );
  return {
    value: findingValue?.VALUE,
    label: findingValue?.NAME,
  };
};

const convertChoisedPaymentOptions = (paymentOptions) => {
  const findingValue = PAYMENT_OPTIONS.find(
    (type) => type.VALUE === paymentOptions
  );
  return {
    value: findingValue.VALUE,
    label: findingValue.NAME,
  };
};

const convertElectricContractAppType = (electricContractAppType) => {
  const findingValue = COMPANY_REGISTER_TYPES.find(
    (type) => type.VALUE === electricContractAppType
  );
  return {
    value: findingValue.VALUE,
    label: findingValue.NAME,
  };
};

const convertElectricContractApp = (electricContractApp) => {
  return {
    value: electricContractApp,
    label:
      electricContractApp ===
      COMPANY_REGISTER_CONTRACT_TYPES.HAS_REGISTER_CONTRACT.VALUE
        ? COMPANY_REGISTER_CONTRACT_TYPES.HAS_REGISTER_CONTRACT.LABEL
        : COMPANY_REGISTER_CONTRACT_TYPES.NO_REGISTER_CONTRACT.LABEL,
  };
};

const convertCurrentTranferAddressOptions = (transferAddress) => {
  const findingValue = TRANSFER_ADDRESS_OPTIONS.find(
    (type) => type.VALUE === transferAddress
  );
  return {
    value: findingValue.VALUE,
    label: findingValue.NAME,
  };
};

export const convertCurrentEdittingData = (data) => {
  return {
    ...data,
    accountType: data.accountType ? convertAccountType(data.accountType) : null,
    phoneNumberCategory: convertPhoneNumberCategory(data.phoneNumberCategory),
    paymentOptions: convertChoisedPaymentOptions(data.paymentOptions),
    electricContractAppType: convertElectricContractAppType(
      data.electricContractAppType
    ),
    electricContractApp: convertElectricContractApp(data.electricContractApp),
    ...(data.transferAddressOptions && {
      transferAddressOptions: convertCurrentTranferAddressOptions(
        data.transferAddressOptions
      ),
    }),
  };
};

export const convertSendingSettingCompanyData = (data) => {
  let result = {
    company_name: data.companyName,
    company_name_kana: data.companyKanaName,
    company_address_code: data.postOfficeData.addressCode,
    company_post_code: data.postOfficeData.postCode,
    company_address: data.postOfficeData.address,
    company_block_number: data.addressDepartmentNumber,
    company_building_name:
      data.addressDepartmentName?.length > 0
        ? data.addressDepartmentName
        : null,
    company_room_number:
      data.addressRoomNumber?.length > 0 ? data.addressRoomNumber : null,
    ...(data?.mailbox?.length > 0 && { mailbox: data.mailbox }),
    phone_number1: data.firstPathNumbers,
    phone_number2: data.secondPathNumbers,
    phone_number3: data.thirtPathNumbers,
    phone_number_section: data.phoneNumberCategory.value,
    payment_method: data.paymentOptions.value,
    electric_contract: data.electricContractApp.value,
    ...(data?.electricContractApp?.value ===
      COMPANY_REGISTER_CONTRACT_TYPES.DEFAULT_VALUE && {
      application_method: data.electricContractAppType.value,
    }),
  };
  if (data.isAccountSettingDisplay) {
    return {
      ...result,
      financial_institution: data.bankName,
      branch_name: data.bankDepartment,
      account_type: data.accountType.value,
      branch_number: data.departmentNumber,
      bank_account: data.accountNumber,
      account_holder: data.accountName,
    };
  } else if (
    !data.isAccountSettingDisplay &&
    data?.transferAddressOptions?.value === TRANSFER_ADDRESS_OPTIONS_DEFAULT
  ) {
    return {
      ...result,
      transfer_address_type: data?.transferAddressOptions?.value,
      transfer_address_code: data.postDeliveryData.addressCode,
      transfer_post_code: data.postDeliveryData.postCode,
      transfer_address: data.postDeliveryData.address,
      transfer_block_number: data.deliveryDepartmentNumber,
      transfer_building_name:
        data.deliveryDepartmentName?.length > 0
          ? data.deliveryDepartmentName
          : null,
      transfer_room_number:
        data.deliveryRoomNumber?.length > 0 ? data.deliveryRoomNumber : null,
    };
  }
  return {
    ...result,
    transfer_address_type: data?.transferAddressOptions?.value,
  };
};

export const convertGettingSettingCompanyData = (data) => {
  const result = {
    companyName: data.company_name,
    companyKanaName: data.company_name_kana,
    postOfficeData: {
      addressCode: data.company_address_code,
      address: data.company_address,
      postCode: data.company_post_code,
    },
    addressDepartmentNumber: data.company_block_number,
    addressDepartmentName: data.company_building_name,
    addressRoomNumber: data.company_room_number,
    mailbox: data.mailbox,
    firstPathNumbers: data.phone_number1,
    secondPathNumbers: data.phone_number2,
    thirtPathNumbers: data.phone_number3,
    telephone: [
      {
        name: "firstPathNumbers",
        value: data.phone_number1,
        type: "telephoneGroup",
        pattern: "[0-9]+",
        maxlength: "6",
      },
      {
        name: "secondPathNumbers",
        value: data.phone_number2,
        type: "telephoneGroup",
        pattern: "[0-9]+",
        maxlength: "4",
      },
      {
        name: "thirtPathNumbers",
        value: data.phone_number3,
        type: "telephoneGroup",
        pattern: "[0-9]+",
        maxlength: "4",
      },
    ],
    phoneNumberCategory: convertPhoneNumberCategory(data.phone_number_section),
    paymentOptions: convertChoisedPaymentOptions(data.payment_method),
    electricContractApp: convertElectricContractApp(data.electric_contract),
    electricContractAppType: data.application_method
      ? convertElectricContractAppType(data.application_method)
      : null,
  };
  if (data.account_type) {
    return {
      ...result,
      isAccountSettingDisplay: true,
      bankName: data.financial_institution,
      bankDepartment: data.branch_name,
      accountType: convertAccountType(data.account_type),
      departmentNumber: data.branch_number,
      accountNumber: data.bank_account,
      accountName: data.account_holder,
    };
  }
  return {
    ...result,
    isAccountSettingDisplay: false,
    ...(data.transfer_address_type && {
      transferAddressOptions: convertCurrentTranferAddressOptions(
        data.transfer_address_type
      ),
    }),
    postDeliveryData: {
      addressCode: data.transfer_address_code,
      postCode: data.transfer_post_code,
      address: data.transfer_address,
      deliveryDepartmentNumber: data.transfer_block_number,
      deliveryDepartmentName: data.transfer_building_name,
      deliveryRoomNumber: data.transfer_room_number,
    },
  };
};

export const convertUpdateCompanyData = (data) => {
  const result = {
    phone_number1: data.firstPathNumbers,
    phone_number2: data.secondPathNumbers,
    phone_number3: data.thirtPathNumbers,
    phone_number_section: data.phoneNumberCategory,
  };
  if (
    data.postDeliveryData &&
    data.transferAddressOptions === TRANSFER_ADDRESS_OPTIONS_DEFAULT
  ) {
    return {
      ...result,
      transfer_address_type: data.transferAddressOptions,
      transfer_address_code: data.postDeliveryData.addressCode,
      transfer_post_code: data.postDeliveryData.postCode,
      transfer_address: data.postDeliveryData.address,
      transfer_block_number: data.deliveryDepartmentNumber,
      transfer_building_name:
        data.deliveryDepartmentName?.length > 0
          ? data.deliveryDepartmentName
          : null,
      transfer_room_number:
        data.deliveryRoomNumber?.length > 0 ? data.deliveryRoomNumber : null,
    };
  }
  return {
    ...result,
    transfer_address_type: data.transferAddressOptions,
  };
};
