<template>
  <div class="DateTimeInputGroupComponent">
    <SelectComponent
      :isSelectYear="true"
      :name="yearNameSelect"
      :options="yearOptions"
      :initialValue="initYear"
      :disabled="disabled"
      @trigle-change="handleChangeYear"
    />
    <p>年</p>
    <SelectComponent
      :isSelectTime="true"
      :name="monthNameSelect"
      :options="monthOptions"
      :initialValue="initMonth"
      :disabled="disabled"
      @trigle-change="handleChangeMonth"
    />
    <p>月</p>
    <SelectComponent
      :isSelectTime="true"
      :name="dateNameSelect"
      :options="dateOptions"
      :initialValue="initDate"
      :disabled="disabled"
      @trigle-change="handleChangeDate"
    />
    <p>日</p>
    <DatePickerComponent
      :disabled="disabled"
      iconClassName="finalLabel"
      :initDate="currentChoiseDate"
      :disabledList="disabledList"
      @change-date="pickDate"
      :minDateSearch="minDateSearch"
    />
    <SelectComponent
      v-show="isDisplayTime"
      :isSmall="true"
      :name="timeNameSelect"
      :disabled="disabled"
      :initialValue="initTime"
      :options="timeOptions"
    />
  </div>
</template>

<script>
import { convertTimeOptions } from "@/store/converter/registration.converter";
import {
  convertDateToYearMonthDate,
  convertYearMonthDateToDate,
} from "@/plugins/parseDateChoise";
import { TIME_DEFAULT } from "@/globals/enums";
export default {
  name: "DateTimeInputGroupComponent",
  created() {
    this.timeOptions = convertTimeOptions();
  },
  props: {
    groupInput: {
      type: Array,
      default: null,
    },
    dateOptions: {
      type: Array,
      default: () => {},
    },
    monthOptions: {
      type: Array,
      default: () => {},
    },
    yearOptions: {
      type: Array,
      default: () => {},
    },
    yearNameSelect: {
      type: String,
      default: "year",
    },
    monthNameSelect: {
      type: String,
      default: "month",
    },
    dateNameSelect: {
      type: String,
      default: "date",
    },
    timeNameSelect: {
      type: String,
      default: "time",
    },
    initYear: {
      type: [String, Number],
      default: new Date().getFullYear(),
    },
    initMonth: {
      type: [String, Number],
      default: new Date().getMonth() + 1,
    },
    initDate: {
      type: [String, Number],
      default: new Date().getDate(),
    },
    initTime: {
      type: Number,
      default: TIME_DEFAULT,
    },
    minDateSearch: {
      type: Date,
      default: new Date(),
    },
    setFieldValue: {
      type: Function,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledList: {
      type: Array,
      default: () => [],
    },
    isDisplayTime: {
      type: Boolean,
      default: true,
    },
    validateField: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    date: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    currentChoiseDate: new Date(),
  }),
  mounted() {
    if (this.initYear && this.initMonth && this.initDate) {
      this.currentChoiseDate = convertYearMonthDateToDate(
        this.initYear,
        this.initMonth - 1,
        this.initDate
      );
    } else {
      this.currentChoiseDate = this.minDateSearch;
    }
  },
  methods: {
    pickDate(e) {
      const newData = convertDateToYearMonthDate(e);
      this.setFieldValue(this.dateNameSelect, newData.date);
      this.setFieldValue(this.monthNameSelect, newData.month);
      this.setFieldValue(this.yearNameSelect, newData.year);
      this.currentChoiseDate = convertYearMonthDateToDate(
        newData.year,
        newData.month - 1,
        newData.date
      );
    },
    handleChangeYear(e) {
      this.year = parseInt(e.target.value);
      this.currentChoiseDate = convertYearMonthDateToDate(
        e.target.value,
        this.month - 1,
        this.date
      );
      this.validateField(this.yearNameSelect);
      this.validateField(this.monthNameSelect);
      this.validateField(this.dateNameSelect);
    },
    handleChangeMonth(e) {
      this.month = parseInt(e.target.value);
      this.currentChoiseDate = convertYearMonthDateToDate(
        this.year,
        e.target.value - 1,
        this.date
      );
      this.validateField(this.yearNameSelect);
      this.validateField(this.monthNameSelect);
      this.validateField(this.dateNameSelect);
    },
    handleChangeDate(e) {
      this.date = parseInt(e.target.value);
      this.currentChoiseDate = convertYearMonthDateToDate(
        this.year,
        this.month - 1,
        e.target.value
      );
      this.validateField(this.yearNameSelect);
      this.validateField(this.monthNameSelect);
      this.validateField(this.dateNameSelect);
    },
  },
};
</script>

<style lang="scss" scoped>
.DateTimeInputGroupComponent {
  display: flex;
  gap: 8px;
  align-items: center;
  p {
    color: #7c8185;
    &.finalLabel {
      margin-left: 32px;
    }
  }
}
</style>
