import showDialog from "@/services/dialog.service";
import MediationDialog from "@/components/dialogs/MediationDialog.vue";

export default class MediationDialogService {
  openMediationDialog(title, content, residentId, printId, url, callbackClose) {
    showDialog({
      component: MediationDialog,
      bind: {
        title,
        content,
        residentId,
        printId,
        url,
        onClose: callbackClose,
      },
    });
  }
}
