import * as Yup from "yup";
import {
  validatePassword,
  validateVerificationCode,
  validateConfirmPassword,
} from "../common";

export const resetPasswordSchema = Yup.object().shape({
  code: validateVerificationCode,
  password: validatePassword,
  passwordConfirmation: validateConfirmPassword("password"),
});
