<template>
  <th
    @click="changeOrder"
    class="TableHeaderComponent"
    :class="{ pointer: sort }"
  >
    <div
      class="TableHeaderComponent-content"
      :class="{ 'align-start-header': alignStart }"
    >
      <section><slot></slot></section>
      <span
        v-if="sort"
        class="icon"
        :class="{
          'icon-ico_sort_down': this.sortOrder === SORT_ORDER.DESC && active,
          'icon-ico_sort_up': this.sortOrder === SORT_ORDER.ASC && active,
          'icon-ico_sort_disable': !active,
        }"
      />
    </div>
  </th>
</template>

<script>
import { SORT_ORDER } from "@/globals/enums";
export default {
  name: "TableHeaderComponent",
  created() {
    this.SORT_ORDER = SORT_ORDER;
  },
  props: {
    sort: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: true,
    },
    alignStart: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    sortOrder: SORT_ORDER.DESC,
  }),

  methods: {
    changeOrder() {
      if (this.sort) {
        this.sortOrder =
          this.sortOrder === SORT_ORDER.DESC ? SORT_ORDER.ASC : SORT_ORDER.DESC;
        this.$emit("changeOrderSort", this.sortOrder);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.TableHeaderComponent {
  background-clip: padding-box;
  overflow: hidden;
  padding: 6px 20px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #7c8185;
  font-size: 12px;
  font-weight: normal;
  border-top: 1px solid var(--valley-smoke-color);
  border-bottom: 1px solid var(--valley-smoke-color);
  &-content {
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      margin-left: 5px;
    }
  }
}
.align-start-header {
  justify-content: start;
}
.pointer {
  cursor: pointer;
}
</style>
