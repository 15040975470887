<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="RegistrationElectricRoomDialog modal-content"
  >
    <span class="modal__title"></span>

    <div class="modal__content" v-if="roomElectricRegisterData">
      <GroupContentComponent>
        <template v-slot:title> 建物名 </template>
        <template v-slot:content>
          {{ roomElectricRegisterData.departmentName }}
        </template>
      </GroupContentComponent>
      <GroupContentComponent>
        <template v-slot:title> 部屋番号 </template>
        <template v-slot:content>
          {{ roomElectricRegisterData.roomName }}
        </template>
      </GroupContentComponent>
      <Form
        @submit="submitForm"
        :validation-schema="schema"
        ref="form"
        v-slot="{ setFieldValue, validateField, meta, values }"
        v-if="!isEdit"
        autocomplete="off"
      >
        <GroupContentComponent>
          <template v-slot:title> 申込種別 </template>
          <template v-slot:content v-if="roomRegistrationTimeOptions">
            <div class="roomRegistrationTimeOptions">
              <InputComponent
                v-for="item of roomRegistrationTimeOptions"
                :key="item.value"
                type="radio"
                :id="`registrationType_${item.value}`"
                name="registrationType"
                :defaultValue="ELECTRIC_ROOM_REGISTRATION_TIME_DEFAULT_OPTION"
                :value="item.value"
                :label="item.label"
                @trigle-change="handleChangeRoomRegistrationTime"
              />
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent :required="true" :isNoBorderBottom="true">
          <template v-slot:title> ご使用開始日時 </template>
          <template
            v-slot:content
            v-if="roomRegistrationTimeOptions && startTime"
          >
            <DateTimeInputGroupComponent
              :yearOptions="yearOptions"
              :monthOptions="monthOptions"
              :dateOptions="dateOptions"
              :initYear="0"
              :initMonth="0"
              :initDate="0"
              yearNameSelect="startYear"
              monthNameSelect="startMonth"
              dateNameSelect="startDate"
              timeNameSelect="startTime"
              :setFieldValue="setFieldValue"
              :disabled="disabledStartTime"
              :disabledList="[]"
              :validateField="validateField"
              :minDateSearch="startTime.all"
            />
          </template>
        </GroupContentComponent>
        <GroupContentComponent :required="true" :isTitleTop="true">
          <template v-slot:title> ご使用停止日時 </template>
          <template
            v-slot:content
            v-if="roomRegistrationTimeOptions && endTime"
          >
            <div class="roomRegistrationTimeValue">
              <DateTimeInputGroupComponent
                :yearOptions="yearOptions"
                :monthOptions="monthOptions"
                :dateOptions="dateOptions"
                yearNameSelect="endYear"
                monthNameSelect="endMonth"
                dateNameSelect="endDate"
                timeNameSelect="endTime"
                :initYear="0"
                :initMonth="0"
                :initDate="0"
                :setFieldValue="setFieldValue"
                :disabled="disabledEndTime"
                :disabledList="[]"
                :validateField="validateField"
                :minDateSearch="endTime.all"
              />
              ※ご使用停止日は、ご使用開始日と同一日でのご指定はできません。
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> 使用用途 </template>
          <template v-slot:content>
            <div class="purposeUse">
              <InputHalfWidthComponent
                :small="true"
                name="purposeUse"
                maxlength="17"
                placeholder="半角文字入力"
                :disabled="values.registrationType === 3"
              />
            </div>
          </template>
        </GroupContentComponent>
        <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
        <div class="action-group">
          <ButtonComponent
            type="outline"
            buttonType="button"
            width="170px"
            height="50px"
            className="ButtonComponent-fontbold"
            @click="
              (e) => {
                e.preventDefault();
                close();
                onClose();
              }
            "
            >キャンセル</ButtonComponent
          >
          <ButtonComponent
            width="280px"
            height="50px"
            color="#328dc2"
            className="ButtonComponent-fontbold"
            :disabled="!(meta.valid && meta.dirty)"
            >一括申込みリストへ追加</ButtonComponent
          >
        </div>
        リストへの追加のみで申込みは完了していません。
      </Form>
      <Form
        @submit="submitEditForm"
        :validation-schema="editSchema"
        ref="form"
        v-slot="{ setFieldValue, validateField }"
        :initialValues="initValue"
        autocomplete="off"
        v-else
      >
        <GroupContentComponent>
          <template v-slot:title> 申込種別 </template>
          <template v-slot:content v-if="roomRegistrationTimeOptions">
            <div class="roomRegistrationTimeOptions">
              {{ renderRegistrationType }}
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent :required="true" :isNoBorderBottom="true">
          <template v-slot:title> {{ renderRegistrationDateTitle }} </template>
          <template v-slot:content v-if="roomRegistrationTimeOptions">
            <DateTimeInputGroupComponent
              :yearOptions="yearOptions"
              :monthOptions="monthOptions"
              :dateOptions="dateOptions"
              yearNameSelect="year"
              monthNameSelect="month"
              dateNameSelect="date"
              timeNameSelect="time"
              :initYear="roomElectricRegisterData.initYear"
              :initMonth="roomElectricRegisterData.initMonth"
              :initDate="roomElectricRegisterData.initDate"
              :initTime="roomElectricRegisterData.initTime"
              :setFieldValue="setFieldValue"
              :disabled="disabledStartTime"
              :disabledList="[]"
              :validateField="validateField"
              :minDateSearch="startTime.all"
            />
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> 使用用途 </template>
          <template v-slot:content>
            <div class="purposeUse">
              <InputHalfWidthComponent
                :small="true"
                name="purposeUse"
                placeholder="半角文字入力"
                maxlength="17"
                :disabled="
                  roomElectricRegisterData?.registrationType ===
                  ELECTRIC_ROOM_REGISTRATION_END.VALUE
                "
              />
            </div>
          </template>
        </GroupContentComponent>
        <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
        <div class="action-group">
          <ButtonComponent
            type="outline"
            width="170px"
            height="50px"
            className="ButtonComponent-fontbold"
            @click="
              (e) => {
                e.preventDefault();
                close();
                onClose();
              }
            "
            >キャンセル</ButtonComponent
          >
          <ButtonComponent
            width="280px"
            height="50px"
            color="#328dc2"
            className="ButtonComponent-fontbold"
            >一括申込みリストへ追加</ButtonComponent
          >
        </div>
        リストへの追加のみで申込みは完了していません。
      </Form>
    </div>
  </vue-final-modal>
</template>

<script>
import { Form } from "vee-validate";
import {
  INIT_YEAR,
  INIT_MONTH,
  INIT_DATE,
  ELECTRIC_ROOM_REGISTRATION_TIME_DEFAULT_OPTION,
  ELECTRIC_ROOM_REGISTRATION_TIME_START_OPTION,
  ELECTRIC_ROOM_REGISTRATION_TIME_END_OPTION,
  ELECTRIC_ROOM_REGISTRATION_START,
  ELECTRIC_ROOM_REGISTRATION_END,
  STATUS,
} from "@/globals/enums";
import {
  convertDateOptions,
  convertMonthOptions,
  convertRoomRegistrationTimeOptions,
  convertYearOptions,
} from "@/store/converter/registration.converter";
import RegistrationService from "@/services/registration.service";
import { mapState } from "vuex";
import {
  registrationElectricRoomSchema,
  registrationEditElectricRoomSchema,
} from "@/validations/schemas/registrationElectricRoomSchema";
import { parseDateChoise } from "@/plugins/parseDateChoise";

export default {
  name: "RegistrationElectricRoomDialog",
  components: { Form },
  props: {
    roomElectricRegisterData: {
      type: Object,
      default: () => {},
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: [Object, Array],
      default: () => {},
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onChoise: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    schema() {
      return registrationElectricRoomSchema(
        [],
        this.startTime.all,
        this.endTime.all
      );
    },
    editSchema() {
      return registrationEditElectricRoomSchema([], this.startTime.all);
    },
    renderRegistrationType() {
      switch (this.roomElectricRegisterData?.registrationType) {
        case ELECTRIC_ROOM_REGISTRATION_START.VALUE:
          return ELECTRIC_ROOM_REGISTRATION_START.NAME;
        case ELECTRIC_ROOM_REGISTRATION_END.VALUE:
          return ELECTRIC_ROOM_REGISTRATION_END.NAME;
        default:
          return "";
      }
    },
    renderRegistrationDateTitle() {
      switch (this.roomElectricRegisterData?.registrationType) {
        case ELECTRIC_ROOM_REGISTRATION_START.VALUE:
          return "ご使用開始日時";
        default:
          return "ご使用停止日時";
      }
    },
    initValue() {
      return {
        year: this.roomElectricRegisterData?.initYear,
        month: this.roomElectricRegisterData?.initMonth,
        date: this.roomElectricRegisterData?.initDate,
        time: this.roomElectricRegisterData?.initTime,
        purposeUse: this.roomElectricRegisterData?.purposeUse,
      };
    },
    ...mapState({
      currentRegistrationRoomInfo: (state) =>
        state.registrationShortTerm.currentRegistrationRoomInfo,
    }),
  },
  created() {
    this.roomRegistrationTimeOptions = convertRoomRegistrationTimeOptions();
    this.yearOptions = convertYearOptions();
    this.monthOptions = convertMonthOptions();
    this.dateOptions = convertDateOptions();
    this.INIT_YEAR = INIT_YEAR;
    this.INIT_MONTH = INIT_MONTH;
    this.INIT_DATE = INIT_DATE;
    this.ELECTRIC_ROOM_REGISTRATION_TIME_DEFAULT_OPTION =
      ELECTRIC_ROOM_REGISTRATION_TIME_DEFAULT_OPTION;
    this.ELECTRIC_ROOM_REGISTRATION_END = ELECTRIC_ROOM_REGISTRATION_END;
    this.setTime();
  },
  data: () => ({
    registrationService: new RegistrationService(),
    disabledStartTime: false,
    disabledEndTime: false,
    errorDetail: null,
    endTime: null,
    startTime: null,
    onSubmit: false,
  }),
  methods: {
    setTime() {
      const today = new Date();
      const stateDate = parseDateChoise(
        today,
        this.roomElectricRegisterData?.holidateList,
        false
      );
      this.startTime = {
        initDate: stateDate.getDate(),
        initMonth: stateDate.getMonth() + 1,
        initYear: stateDate.getFullYear(),
        all: stateDate,
      };
      const endDate = parseDateChoise(
        stateDate,
        this.roomElectricRegisterData?.holidateList,
        true
      );
      this.endTime = {
        initDate: endDate.getDate(),
        initMonth: endDate.getMonth() + 1,
        initYear: endDate.getFullYear(),
        all: endDate,
      };
    },
    async submitForm(data) {
      if (this.onSubmit) return;
      this.onSubmit = true;
      this.errorDetail = null;
      const result = await this.onChoise({
        roomId: this.roomElectricRegisterData.roomId,
        ...data,
      });
      if (result.status === STATUS.SUCCESS) {
        this.$vfm.hideAll();
      }
      if (result.status === STATUS.ERROR && result.response?.detail) {
        this.errorDetail = result.response.detail;
      }
      this.onClose();
      this.onSubmit = false;
    },
    async submitEditForm(data) {
      this.errorDetail = null;
      const currentPagination = {
        page: this.currentRegistrationRoomInfo.page,
        number: 10,
      };
      if (this.onSubmit) return;
      this.onSubmit = true;
      const result = await this.onChoise(
        this.roomElectricRegisterData.registrationId,
        data,
        currentPagination
      );
      if (result.status === STATUS.SUCCESS) {
        this.$vfm.hideAll();
      }
      if (result.status === STATUS.ERROR && result.response?.detail) {
        this.errorDetail = result.response.detail;
      }
      this.onSubmit = false;
    },
    handleChangeRoomRegistrationTime(e) {
      switch (parseInt(e.target.value)) {
        case ELECTRIC_ROOM_REGISTRATION_TIME_START_OPTION:
          this.disabledStartTime = false;
          this.disabledEndTime = true;
          break;
        case ELECTRIC_ROOM_REGISTRATION_TIME_END_OPTION:
          this.disabledStartTime = true;
          this.disabledEndTime = false;
          break;
        default:
          this.disabledStartTime = false;
          this.disabledEndTime = false;
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.RegistrationElectricRoomDialog) {
  &.modal {
    &-content {
      position: relative;
      background-color: #fefefe;
      margin: auto;
      padding: 30px;
      display: flex;
      flex-direction: column;
      text-align: center;
      border: 1px solid #888;
      width: 860px;
      height: auto;
      border-radius: 5px;
      .modal {
        &__title {
          font-weight: bold;
          text-align: center;
        }
        &__content {
          font-size: 14px;
          text-align: center;
          .GroupContentComponent {
            min-height: 70px;
          }
          .roomRegistrationTimeOptions {
            display: flex;
            gap: 60px;
            .InputComponent-radio {
              margin-bottom: 0 !important;
            }
          }
          .roomRegistrationTimeValue {
            display: flex;
            flex-direction: column;
            gap: 20px;
            text-align: left;
          }
          .action-group {
            margin: 30px 0 20px;
            display: flex;
            justify-content: center;
            gap: 20px;
            .ButtonComponent {
              button {
                font-size: 18px;
              }
            }
          }
        }
        &__close {
          position: absolute;
          top: 10px;
          right: 20px;
          color: var(--color-gray);
          font-size: 20px;
        }
      }
    }
  }
}
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
