<template>
  <div class="SettingSMSComponent">
    <Form @submit="registerSMS" :validation-schema="schema" v-slot="{ meta }">
      <CardComponent class="form-settingSMS" :width="'960px'" :height="'550px'">
        <TitleComponent class="form-settingSMS_title" :hasUnderLine="true">
          <template v-slot:content>電話番号に認証コードを送信する</template>
        </TitleComponent>
        <p class="form-settingSMS_description">
          コードを受信する電話番号を入力してください。
        </p>

        <div class="form-settingSMS_inputGroups">
          <div class="form-settingSMS-inputGroup">
            <span class="form-settingSMS-inputGroup_title">電話番号</span>

            <InputComponent
              name="phoneNumber"
              type="telephone"
              inputFilter="number"
              maxlength="11"
              placeholder="電話番号を入力"
            />
          </div>
          <div class="form-settingSMS-inputGroup_description">
            電話番号はアカウントのセキュリティのために使用されます。
          </div>
        </div>
        <div class="action-group">
          <ButtonComponent
            buttonType="button"
            type="outline"
            width="160px"
            @click="(e) => cancel(e)"
          >
            キャンセル
          </ButtonComponent>
          <ButtonComponent
            buttonType="submit"
            width="160px"
            :disabled="!(meta.valid && meta.dirty)"
          >
            OK
          </ButtonComponent>
        </div>
      </CardComponent>
    </Form>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import LoginService from "@/services/login.service";
import { settingSMSSchema } from "@/validations/schemas/settingSMSSchema";

export default {
  name: "SettingSMSComponent",
  components: {
    Form,
  },
  data: () => ({
    loginService: new LoginService(),
    schema: settingSMSSchema,
  }),
  methods: {
    registerSMS(data) {
      this.loginService.registerSMS(data.phoneNumber);
    },
    cancel(e) {
      e.preventDefault();
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.SettingSMSComponent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    &-settingSMS {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 30px;
      &_title {
        font-size: 20px;
        font-weight: 500;
      }
      &_description {
        font-size: 14px;
        font-weight: 500;
        margin: 20px 0 0;
      }
      &_inputGroups {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 30px 220px 40px;
      }
      &-inputGroup {
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: space-between;
        &_title {
          font-size: 14px;
          font-weight: 500;
          color: #7c8185;
        }
        &_description {
          font-size: 12px;
          color: #7c8185;
          margin-left: auto;
        }
        .InputComponent {
          margin: 0;
        }
      }
    }
  }
  .action-group {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  .card_footer {
    height: 40px;
    padding: 10px 16px;
    background-color: #e7edef;
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
