<template>
  <div
    class="InputHalfWidthComponent"
    :class="{
      'has-error': errorMessage,
      'InputHalfWidthComponent-small': small,
    }"
  >
    <input :name="name" v-model="value" :placeholder="placeholder" />
    <span
      class="help-message"
      :class="{
        noMarginBottomError: noMarginBottomError,
      }"
    >
      <span class="icon-ico_ijyo2">
        <span class="path1" v-if="errorMessage"></span
        ><span class="path2"></span>
      </span>
      {{ errorMessage || successMessage }}
    </span>
  </div>
</template>

<script>
import { useField } from "vee-validate";
export default {
  name: "InputHalfWidthComponent",
  props: {
    type: {
      type: String,
      default: "text",
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: [String, Number],
      default: null,
    },
    successMessage: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: "",
    },
    validateSteps: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: [String, Number, Boolean],
      default: null,
    },
    maxlength: {
      type: String,
      default: null,
    },
    pattern: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Boolean,
      default: false,
    },
    isCompanyEdit: {
      type: Boolean,
      default: false,
    },
    inputFilter: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    requiredLabelClass: {
      type: String,
      default: "label",
    },
    noMarginBottomError: {
      type: Boolean,
      default: false,
    },
    labelClass: {
      type: String,
      default: "",
    },
    showErrorMessage: {
      type: Boolean,
      default: true,
    },
    leftIcon: {
      type: String,
      default: null,
    },
    bigBorder: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showPassword: false,
  }),
  setup(props) {
    const { value, errorMessage } = useField(props.name, undefined, {
      initialValue: props.defaultValue ? props.defaultValue : props.value,
    });
    return {
      value,
      errorMessage,
    };
  },
};
</script>

<style lang="scss" scoped>
.InputHalfWidthComponent {
  position: relative;
  margin-bottom: calc(1em * 1.5);
  width: 360px; //default width
  position: relative;
  label {
    display: block;
    margin-bottom: 4px;
    width: 100%;
  }
  &_validateSteps {
    display: flex;
    position: absolute;
    margin-top: 5px;
    gap: 18px;
    &_step {
      display: flex;
      gap: 5px;
      span {
        font-size: 10px;
        font-weight: 500;
        color: #7c8185;
      }
    }
  }

  input {
    border: 1px solid #d0d9df;
    padding: 15px 10px;
    outline: none;
    background-color: #ffffff;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
    &:focus {
      border-color: #8d9caa !important;
    }
    &::placeholder {
      font-size: 14px;
      font-weight: 500;
      line-height: 0.86;
      color: var(--color-gray);
    }
  }

  .icon-ico_password_open {
    &::before {
      content: "\e932";
      color: #8d9caa;
      font-size: 18px;
    }
  }
}

.help-message {
  position: absolute;
  bottom: calc(-2 * 1em);
  left: 0;
  margin: 0;
  font-size: 10px;
  width: max-content;
  &.noMarginBottomError {
    bottom: calc(-3.5 * 1em);
  }
  .icon-ico_ijyo2 {
    .path1 {
      &::before {
        color: #c51f1f;
      }
    }
  }
}

.InputHalfWidthComponent.has-error input {
  border-color: var(--error-color);
}

.InputHalfWidthComponent.has-error input {
  border-color: var(--error-color);
}

.InputHalfWidthComponent.has-error .help-message {
  color: var(--error-color);
}

.InputHalfWidthComponent.success input {
  background-color: var(--success-bg-color);
}

.InputHalfWidthComponent.success input:focus {
  border-color: var(--success-color);
}

.InputHalfWidthComponent.success .help-message {
  color: var(--success-color);
}

.InputHalfWidthComponent-bigBorder {
  input {
    border: 2px solid #d0d9df !important;
    padding: 15px 10px;
    outline: none;
    background-color: #ffffff;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
    &:focus {
      border-color: #8d9caa !important;
    }
    &::placeholder {
      font-size: 14px;
      font-weight: 500;
      line-height: 0.86;
      color: var(--color-gray);
    }
  }
}
.InputHalfWidthComponent-small {
  width: 100%;
  height: 30px !important;
  margin: 15px 0;
  input {
    height: 30px;
    padding: 0 11px;
  }
  .help-message {
    font-size: 10px;
    bottom: -2em;
    widows: max-content;
  }
}

.small-custom {
  input {
    width: 240px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input::placeholder {
  overflow: visible;
  font-size: 14px;
  font-weight: 500;
}

.label {
  width: 20px;
  height: 15px;
  font-size: 10px;
  font-weight: 500;
  background-color: var(--valley-smoke-color);
  width: 30px;
  height: 16px;
  position: absolute;
  left: -40px;
  top: 5px;
  color: #7c8185;
  text-align: center;
}

.farLabel {
  width: 20px;
  height: 15px;
  font-size: 10px;
  font-weight: 500;
  color: #7c8185;
  background-color: var(--valley-smoke-color);
  width: 30px;
  height: 16px;
  position: absolute;
  left: -60px;
  top: 5px;
  text-align: center;
  &-custom {
    left: -70px;
  }
}

.InputHalfWidthComponent-leftIcon {
  input {
    padding-left: 50px;
    &::placeholder {
      font-size: 14px;
      font-weight: 500;
      line-height: 0.86;
      letter-spacing: 0.7px;
      color: var(--color-gray);
    }
  }
  .leftIcon {
    position: absolute;
    left: 20px;
    top: 17px;
  }
  .icon-ico_key {
    left: 19px;
    top: 17.8px;
  }
}
</style>
