<template>
  <div class="ProfileInfoComponent">
    <TitleComponent class="ProfileInfoComponent-title">
      <template v-slot:icon><span class="icon-ico_user"></span></template>
      <template v-slot:content> プロフィール情報 </template>
    </TitleComponent>
    <CardComponent class="main" :width="'960px'" :height="'auto'">
      <div class="profile" v-if="isEdit">
        <Form
          @submit="editProfile"
          :validation-schema="schema"
          v-slot="{ meta }"
        >
          <div class="profile-detail">
            <GroupContentComponent class="has-border-top">
              <template v-slot:title> メールアドレス </template>
              <template v-slot:content>
                {{ profileInfo.emailAddress }}
              </template>
            </GroupContentComponent>
            <GroupContentComponent :required="isEdit">
              <template v-slot:title> お名前 </template>
              <template v-slot:content>
                <InputComponent
                  name="firstNameKanji"
                  class="input-field"
                  :small="true"
                  :value="profileInfo.firstNameKanji"
                  inputFilter="fullWidth"
                  maxlength="35"
                />
                <InputComponent
                  name="lastNameKanji"
                  class="input-field"
                  :small="true"
                  :value="profileInfo.lastNameKanji"
                  inputFilter="fullWidth"
                  maxlength="35"
                />
              </template>
            </GroupContentComponent>
          </div>
          <div class="profile-action">
            <ButtonComponent
              class="btn"
              type="outline"
              :width="'170px'"
              buttonType="button"
              @click.prevent="toggleEdit"
              >キャンセル
            </ButtonComponent>
            <ButtonComponent
              class="btn"
              buttonType="submit"
              :width="'170px'"
              :disabled="!meta.valid"
              >登録
            </ButtonComponent>
          </div>
        </Form>
      </div>
      <div class="profile" v-else>
        <div class="profile-detail">
          <GroupContentComponent class="has-border-top">
            <template v-slot:title> メールアドレス </template>
            <template v-slot:content>
              {{ profileInfo.emailAddress }}
            </template>
          </GroupContentComponent>
          <GroupContentComponent :required="isEdit">
            <template v-slot:title> お名前 </template>
            <template v-slot:content
              ><span class="profile-detail-name">{{
                profileInfo.firstNameKanji
              }}</span
              ><span class="profile-detail-name">{{
                profileInfo.lastNameKanji
              }}</span>
            </template>
          </GroupContentComponent>
        </div>
        <div class="profile-action">
          <ButtonComponent
            class="btn"
            :width="'170px'"
            @click="toggleEdit"
            buttonType="button"
            >編集
          </ButtonComponent>
        </div>
      </div>
    </CardComponent>
    <div class="back-action">
      <ButtonComponent
        class="ProfileInfoComponent-buttonBack"
        type="outline"
        :width="'170px'"
        v-if="!isEdit"
        buttonType="button"
        @click="onBackInfo()"
        >もどる
      </ButtonComponent>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Form } from "vee-validate";
import UserService from "@/services/user.service";
import { profileInfoSchema } from "@/validations/schemas/profileInfoSchema";
import { STATUS } from "@/globals/enums";
import ConfirmDialogService from "@/services/dialog/confirm-dialog.service";
export default {
  name: "ProfileInfoComponent",
  data: () => ({
    isEdit: false,
    userService: new UserService(),
    schema: profileInfoSchema,
    confirmDialogService: new ConfirmDialogService(),
  }),
  components: {
    Form,
  },
  created() {
    this.userService.getProfileInfo();
  },
  methods: {
    onBackInfo() {
      this.$router.go(-1);
    },
    toggleEdit() {
      this.isEdit = !this.isEdit;
    },
    async editProfile(value) {
      const profile = value;
      profile.emailAddress = this.profileInfo.emailAddress;
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.userService.editProfileInfo(profile);
      this.$store.dispatch("global/updateLoadingStatus", false);
      if (result.status === STATUS.SUCCESS) {
        this.confirmDialogService.openConfirmDialog(
          "プロフィール情報を編集しました。",
          null,
          () => (this.isEdit = !this.isEdit)
        );
      }
    },
  },
  computed: {
    ...mapState({
      profileInfo: (state) => state.settings.profileInfo,
    }),
  },
};
</script>
<style scoped lang="scss">
.ProfileInfoComponent {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: var(--default-distance-card);
  &-title {
    width: 960px;
    margin: 0 auto;
    display: flex;
    color: var(--venice-blue-color) !important;
    .icon-ico_user::before {
      color: var(--venice-blue-color);
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    .profile {
      padding: 25px;
      &-detail {
        .input-field {
          width: 170px;
          margin-right: var(--item-group-distance);
        }
        span:last-child {
          margin-left: 5px;
        }
      }
      &-action {
        width: 100%;
        text-align: center;
        padding: 25px 0 10px 0;
        .btn {
          display: inline-block;
          margin: 0 10px;
        }
      }
    }
  }
  &-buttonBack {
    margin-top: 30px;
    border-radius: 25px;
  }
  .GroupContentComponent {
    min-height: 60px;
    &.has-border-top {
      border-top: 1px solid #d0d9df;
    }
    .text {
      margin-left: 5px;
    }
  }
}
.back-action {
  width: 960px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
</style>
