<template>
  <div class="OverheadComponent">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.OverheadComponent {
  color: var(--venice-blue-color);
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 11px;
}
</style>
