import { TYPE_MFA_MAP } from "@/globals/enums";
import { parsePhoneNumber } from "@/plugins/parsePhoneNumber";
export const filterMFAInfo = (user) => {
  return {
    email: user?.attributes?.email,
    phoneNumber: "",
    phoneNumberGroup: parsePhoneNumber(""),
    MFAType: TYPE_MFA_MAP[user.preferredMFA] || "NO_MFA",
  };
};
