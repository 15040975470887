import {
  searchBuilding,
  createBuilding,
  getBuildingById,
  updateBuilding,
  deleteBuildingById,
  getBuildingStatusById,
} from "@/services/api/building.service";
import { STATUS } from "@/globals/enums";
import store from "./../store/index";
import {
  convertBuildings,
  convertInfoBuilding,
  converterBodySearchBuilding,
} from "./../store/converter/building.converter";
export default class BuildingService {
  initBuildingState() {
    store.dispatch("building/initBuildingSetting");
  }
  async getBuildings(body, pagination) {
    try {
      const result = await searchBuilding({
        ...converterBodySearchBuilding(body, pagination),
      });
      return {
        status: STATUS.SUCCESS,
        data: {
          total: result.total,
          page: result.page,
          totalPage: result.total_page || 0,
          buildingList:
            result.building_list.map((building) => {
              return convertBuildings(building);
            }) || [],
        },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
      };
    }
  }

  async getInfoBuilding(id) {
    try {
      const result = await getBuildingById(id);
      return {
        status: STATUS.SUCCESS,
        data: { ...convertInfoBuilding(result) },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
      };
    }
  }

  async getBuildingStatus(id) {
    try {
      const result = await getBuildingStatusById(id);
      return {
        status: STATUS.SUCCESS,
        data: {
          registrationFlg: result?.registration_flg,
        },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
      };
    }
  }

  async handleCreateBuilding(body) {
    try {
      const result = await createBuilding({
        building_name: body.buildingName,
        block_number: body.deliveryDepartmentNumber,
        ...(body?.addressCode?.length > 0 && {
          address_code: body.addressCode,
        }),
        address: body.address,
        post_code: body.postCode,
      });
      return {
        status: STATUS.SUCCESS,
        data: { ...result },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: error.response,
      };
    }
  }

  async editBuilding(id, body) {
    try {
      const bodyUpdate = {
        building_name: body.buildingName,
        post_code: body.postCode,
        ...(body?.addressCode?.length > 0 && {
          address_code: body.addressCode,
        }),
        address: body.address,
        block_number: body.deliveryDepartmentNumber,
      };
      const result = await updateBuilding(id, bodyUpdate);
      return {
        status: STATUS.SUCCESS,
        data: { ...result },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: error.response,
      };
    }
  }

  async deleteBuilding(id) {
    try {
      const result = await deleteBuildingById(id);
      return {
        status: STATUS.SUCCESS,
        data: { ...result },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: error.response,
      };
    }
  }
}
