import * as Yup from "yup";
import { validateTelephone, validateStringRequired } from "../common";
import { TYPE_MFA } from "@/globals/enums";

export const reConfigMFASchema = Yup.object().shape({
  typeMFA: validateStringRequired,
  telephone: Yup.string().when("typeMFA", {
    is: TYPE_MFA.SMS,
    then: validateTelephone,
    otherwise: Yup.string(),
  }),
});
