<template>
  <div class="ErrorComponent">
    <CardComponent width="960px" height="541px" class="ErrorComponent__content">
      <div class="ErrorComponent__content-left">
        <div class="ErrorComponent__content-left-content">
          <h1 class="status-code">404</h1>
          <h3 class="name-page">Page not found</h3>
          <div class="content-page">
            申し訳ございません。<br />
            お探しのページは見つかりませんでした。
          </div>
          <div class="text-page">
            URLが間違っているか、ページが存在いたしません。お手数をお掛けいたしますが、再度ご確認ください。
          </div>
        </div>
      </div>
      <div class="ErrorComponent__content-right">
        <div class="ErrorComponent__content-right-content">
          <span class="icon-ico_notfound"
            ><span class="path1"></span><span class="path2"></span
            ><span class="path3"></span><span class="path4"></span
            ><span class="path5"></span><span class="path6"></span
            ><span class="path7"></span><span class="path8"></span
            ><span class="path9"></span><span class="path10"></span
            ><span class="path11"></span>
          </span>
        </div>
        <ButtonComponent @click="gotoHome" width="230px" height="50px">
          HOME画面へ
        </ButtonComponent>
      </div>
    </CardComponent>
  </div>
</template>

<script>
export default {
  name: "ErrorComponent",
  methods: {
    gotoHome() {
      this.$router.push({ name: "HomeComponent" });
    },
  },
};
</script>

<style lang="scss" scoped>
.ErrorComponent {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__content {
    display: flex;
    &-left {
      width: 50%;
      height: 100%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      &-content {
        width: 385px;
        text-align: center;
        .status-code {
          color: #294f6e;
          font-size: 120px;
          font-family: "ArciformSans";
        }
        .name-page {
          color: #294f6e;
          letter-spacing: 1.3px;
          font-style: normal;
          line-height: 0.46;
          font-size: 26px;
          font-weight: normal;
        }
        .content-page {
          font-size: 16px;
          font-weight: bold;
          color: #328dc2;
          margin: 45px 0;
        }
        .text-page {
          color: #000;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: 0.7px;
        }
      }
    }
    &-right {
      width: 50%;
      height: 100%;
      background-color: #eff5f5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .icon-ico_notfound {
        font-size: 250px;
      }
      .ButtonComponent {
        margin-top: 50px;
      }
    }
  }
}
</style>
