<template>
  <div class="BuildingComponent">
    <div class="filter-building">
      <TitleComponent class="BuildingComponent-title">
        <template v-slot:icon><span class="icon-ico_search"></span></template>
        <template v-slot:content>管理物件検索</template>
        <template v-slot:button>
          <div class="BuildingComponent-title-action">
            <ButtonComponent
              width="150px"
              height="30px"
              color="#8d9caa"
              @click="onUploadCSV"
              >建物情報一括登録</ButtonComponent
            >
            <ButtonComponent
              width="150px"
              height="30px"
              color="#8d9caa"
              @click="onCreateBuilding"
              >建物新規登録</ButtonComponent
            >
          </div>
        </template>
      </TitleComponent>
      <CardComponent :width="'960px'" :height="'460px'">
        <Form
          @submit="handleSearch"
          ref="formSearchBuilding"
          :validation-schema="schema"
        >
          <GroupContentComponent class="CompanyInfoComponent-content-detail">
            <template v-slot:title> フリーワード</template>
            <template v-slot:content>
              <InputComponent
                :small="true"
                name="keyWord"
                placeholder="建物名の一部等を入力"
                maxlength="30"
                inputFilter="fullWidth"
              />
            </template>
          </GroupContentComponent>
          <GroupContentComponent class="CompanyInfoComponent-content-detail">
            <template v-slot:title> 郵便番号から検索</template>
            <template v-slot:content>
              <span class="postCode">〒</span>
              <InputComponent
                name="firstCode"
                class="code-field"
                maxlength="3"
                inputFilter="number"
                :small="true"
              />
              <span class="postCode">ー</span>
              <InputComponent
                name="lastCode"
                class="code-field"
                maxlength="4"
                inputFilter="number"
                :small="true"
              />
            </template>
          </GroupContentComponent>
          <GroupContentComponent
            :isTitleTop="true"
            class="CompanyInfoComponent-content-detail"
          >
            <template v-slot:title> 住所から検索</template>
            <template v-slot:content>
              <div>
                <SelectComponent
                  name="city"
                  :options="initBuildingSetting.providesOptions"
                  :initialValue="initialValueSelect"
                  placeholder="都道府県を選択"
                ></SelectComponent>
                <InputComponent
                  name="address"
                  :small="true"
                  placeholder="市区町村名を入力"
                  inputFilter="fullWidth"
                  maxlength="80"
                />
                <InputComponent
                  name="blockNumber"
                  :small="true"
                  placeholder="番地を入力"
                  inputFilter="fullWidth"
                  maxlength="30"
                />
              </div>
            </template>
          </GroupContentComponent>
          <div class="card-action">
            <ButtonComponent @click="onClearFilter($event)" type="outline"
              >クリア</ButtonComponent
            >
            <ButtonComponent buttonType="submit">検索</ButtonComponent>
          </div>
        </Form>
      </CardComponent>
    </div>
    <div class="list" v-if="dataForm && !isSearching">
      <TitleComponent class="BuildingComponent-title">
        <template v-slot:icon><span class="icon-ico_list"></span></template>
        <template v-slot:content>検索結果一覧</template>
      </TitleComponent>
      <CardComponent
        :isEmpty="!(buildings.length > 0)"
        :width="'960px'"
        :height="'auto'"
      >
        <div class="table-main-content">
          <div class="count-item">
            <strong
              v-tableRange="{
                currentPage: pagination?.page || 0,
                currentTotal: buildings?.length || 0,
              }"
            />
            <span class="total">件/{{ total }}件</span>
          </div>
          <table class="table-container">
            <div style="display: table-row-group">
              <TableHeaderComponent>住所</TableHeaderComponent>
              <TableHeaderComponent>建物名</TableHeaderComponent>
              <TableHeaderComponent></TableHeaderComponent>
            </div>
            <div class="table-row-group">
              <TableRowComponent
                v-for="building in buildings"
                :key="building.buildingId"
              >
                <TableCellComponent
                  class="content-table"
                  :title="building.address"
                >
                  <div class="content-cell">{{ building.address }}</div>
                </TableCellComponent>
                <TableCellComponent
                  class="content-table"
                  :title="building.buildingName"
                >
                  <div class="content-cell">
                    {{ building.buildingName }}
                  </div>
                </TableCellComponent>
                <TableCellComponent>
                  <ButtonComponent
                    @click="onDetail(building.buildingId)"
                    :width="'76px'"
                    :height="'30px'"
                  >
                    参照
                  </ButtonComponent>
                </TableCellComponent>
              </TableRowComponent>
            </div>
          </table>
        </div>
        <PaginationComponent
          :total-pages="pagination.totalPage"
          :current-page="pagination.page"
          v-if="pagination.totalPage > 1"
          @pagechanged="onPageChange"
        />
      </CardComponent>
    </div>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import { searchBuildingSchema } from "@/validations/schemas/searchBuildingSchema";
import { mapState } from "vuex";
import BuildingService from "@/services/building.service";
import WebStorageService from "@/services/web-storage.service";
import { WEB_STORAGE_KEY } from "@/globals/enums";
export default {
  name: "SearchBuildingComponent",
  components: { Form },
  data: () => ({
    schema: searchBuildingSchema,
    buildingService: new BuildingService(),
    buildings: [],
    pagination: { page: 1, number: 10, totalPage: 0 },
    dataForm: null,
    total: 0,
    initialValueSelect: 0,
    isSearching: true,
    webStorageService: new WebStorageService(),
  }),
  methods: {
    async onPageChange(event) {
      this.pagination.page = event;
      this.webStorageService.setSessionStorage(
        WEB_STORAGE_KEY.SEARCH_BUILDING,
        { ...this.dataForm, page: this.pagination.page }
      );
      await this.getListBuilding(this.dataForm, this.pagination, false);
    },

    async handleSearch(data) {
      this.dataForm = data;
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
      }
      this.webStorageService.setSessionStorage(
        WEB_STORAGE_KEY.SEARCH_BUILDING,
        { ...data, page: this.pagination?.page || 1 }
      );
      await this.getListBuilding(data, this.pagination, true);
    },

    getListBuilding(dataFilter, pagination, loading) {
      if (loading) {
        this.$store.dispatch("global/updateLoadingStatus", true);
        this.isSearching = true;
      }
      this.buildingService
        .getBuildings(dataFilter, pagination)
        .then((dataBuilding) => {
          if (dataBuilding.data) {
            this.buildings = dataBuilding.data.buildingList;
            this.total = dataBuilding.data.total;
            this.pagination = {
              page: dataBuilding.data.page,
              totalPage: dataBuilding.data.totalPage,
              number: 10,
            };
          }
          this.$store.dispatch("global/updateLoadingStatus", false);
          this.isSearching = false;
        });
    },

    onDetail(id) {
      this.$router.push({ path: `/rooms/${id}`, query: {} });
    },

    onClearFilter(e) {
      this.$router.push({
        path: "/building/search",
      });
      e.preventDefault();
      this.buildings = [];
      this.dataForm = null;
      this.$refs.formSearchBuilding.resetForm();
      this.pagination = { page: 1, number: 10, totalPage: 0 };
      this.webStorageService.removeSessionStorage(
        WEB_STORAGE_KEY.SEARCH_BUILDING
      );
    },

    onCreateBuilding() {
      this.$router.push({ name: "CreateBuildingComponent" });
    },

    onUploadCSV() {
      this.$router.push({ name: "ListUploadComponent" });
    },
  },
  mounted() {
    const queryParams = this.webStorageService.getSessionStorage(
      WEB_STORAGE_KEY.SEARCH_BUILDING
    );
    if (this.$route.meta.autoFill && queryParams) {
      queryParams.city = queryParams?.city || this.initialValueSelect;
      this.$refs.formSearchBuilding?.setValues(queryParams);
      this.pagination = {
        page: queryParams?.page || 1,
        number: 10,
        totalPage: 0,
      };
      this.dataForm = queryParams;
      this.getListBuilding(this.dataForm, this.pagination, true);
    }
    this.buildingService.initBuildingState();
  },
  computed: {
    ...mapState({
      initBuildingSetting: (state) => state?.building?.initBuildingSetting,
    }),
  },
};
</script>

<style lang="scss" scoped>
.BuildingComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  &-title {
    color: var(--venice-blue-color);
    padding: 8px 0;
    &-action {
      display: flex;
      .ButtonComponent {
        margin-left: 15px;
        font-size: 14px;
      }
    }
  }
  .v-card {
    padding: 30px 40px;
    .GroupContentComponent:nth-child(1) {
      border-top: 1px solid #d0d9df;
    }
  }
  .table-main-content {
    height: 662px;
    .count-item {
      text-align: right;
      margin: 5px 0;
      .total {
        font-size: 12px;
      }
    }
  }
  .TableHeaderComponent {
    text-align: center;
  }
  .TableCellComponent {
    vertical-align: middle;
    padding: 9px 20px;
    height: 61px;
    .content-cell {
      @include ellipsis(2);
    }
  }
  .content-table {
    width: 820px;
  }
  .pagination {
    text-align: center;
    margin-top: 30px;
  }
  .postCode {
    color: #7c8185;
    font-size: 14px;
    font-weight: 500;
  }
}

.filter-building,
.list {
  width: 960px;
  margin-top: var(--default-distance-card);
  .InputComponent {
    width: 600px;
  }
}
.list {
  margin-bottom: var(--default-distance-card);
}
.filter-building {
  .GroupContentComponent {
    .code-field {
      width: 80px !important;
      margin: 0 5px;
    }
  }
  .card-action {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    .ButtonComponent {
      margin: 0 10px;
    }
  }
}
</style>
