<template>
  <ShortTermRegistrationPreviewComponent
    v-if="currentTab === REGISTRATION_SETTING_TAB.EDIT"
    @change-tab="changeTab"
  />
  <ShortTermRegistrationConfirmComponent
    v-if="currentTab === REGISTRATION_SETTING_TAB.CONFIRM"
    @change-tab="changeTab"
  />
</template>

<script>
import { REGISTRATION_SETTING_TAB } from "@/globals/enums";

export default {
  name: "ShortTermRegistrationComponent",
  created() {
    this.REGISTRATION_SETTING_TAB = REGISTRATION_SETTING_TAB;
  },
  data: () => ({
    currentTab: REGISTRATION_SETTING_TAB.EDIT,
  }),
  methods: {
    changeTab(tab) {
      this.currentTab = tab;
    },
  },
};
</script>
