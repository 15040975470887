<template>
  <div class="SettingAuthAppComponent">
    <Form @submit="handleMFA" :validation-schema="schema" v-slot="{ meta }">
      <CardComponent
        :class="`card ${errorDetail && 'card-error'}`"
        :width="'960px'"
        :height="errorDetail ? 'auto' : '700px'"
      >
        <TitleComponent class="card_title" :hasUnderLine="true">
          <template v-slot:content>認証コードを入力してください</template>
        </TitleComponent>
        <div class="card_body">
          <div class="step">
            <span class="step_title"
              >1.
              認証用のアプリをスマートフォンかPCにダウンロードし、インストールしてください。</span
            >
            <div class="step_description">Microsoft Authenticator</div>
          </div>
          <div class="step">
            <span class="step_title"
              >2.
              認証アプリを起動し、認証アプリ内にアカウントを追加します。</span
            >
          </div>
          <div class="step">
            <span class="step_title">3. 次の画像をスキャンします。</span>
            <div class="step_description">
              <QrcodeVue
                v-if="QRcode"
                :value="QRcode.generateQRCode"
                :size="120"
              />
              <div v-else class="empty"></div>
              <div class="QRcode_description">
                画像をスキャンできない場合は、<br />
                アプリケーションに次の情報を入力してください。
                <p>コード： {{ QRcode?.code }}</p>
              </div>
            </div>
          </div>
          <div class="step">
            <span class="step_title"
              >4.
              アプリ内に表示されている、6桁のコードを以下に入力してください。</span
            >
            <div class="step_description form">
              <InputComponent
                name="code"
                placeholder="認証コードを入力"
                inputFilter="number"
                maxlength="6"
              />
            </div>
          </div>
        </div>
        <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
        <div class="action-group">
          <ButtonComponent
            buttonType="button"
            type="outline"
            width="160px"
            @click="(e) => cancel(e)"
          >
            キャンセル
          </ButtonComponent>
          <ButtonComponent
            buttonType="submit"
            width="160px"
            :disabled="!(meta.valid && meta.dirty)"
          >
            OK
          </ButtonComponent>
        </div>
      </CardComponent>
    </Form>
  </div>
</template>

<script>
import { STATUS } from "@/globals/enums";
import QrcodeVue from "qrcode.vue";
import { mapState } from "vuex";
import LoginService from "@/services/login.service";
import { Form } from "vee-validate";
import { settingMFAAuthAppSchema } from "@/validations/schemas/settingMFAAuthAppSchema";
export default {
  name: "SettingAuthAppComponent",
  components: {
    QrcodeVue,
    Form,
  },
  async created() {
    const result = await this.loginService.setupTOTP();
    if (result.status === STATUS.SUCCESS) {
      this.user = result.data;
    }
  },
  computed: mapState({
    QRcode: (state) => state.settings.QRcode,
  }),
  data: () => ({
    loginService: new LoginService(),
    schema: settingMFAAuthAppSchema,
    errorDetail: null,
  }),
  methods: {
    async handleMFA(data) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.loginService.registerTOTP(data.code, this.user);
      this.$store.dispatch("global/updateLoadingStatus", false);
      if (result?.status === STATUS.ERROR && result?.response?.detail) {
        this.errorDetail = result.response.detail;
      }
    },
    cancel(e) {
      e.preventDefault();
      this.$router.go(-1);
    },
  },
  unmounted() {
    this.loginService.clearQrCode();
  },
};
</script>

<style scoped lang="scss">
.SettingAuthAppComponent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .card {
    &.card-error {
      margin: 20px 0;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    &_title {
      margin-top: 30px;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
    }
    &_description {
      font-size: 14px;
      font-weight: 500;
      margin: 20px 0 0;
    }
    &_body {
      padding-top: 34px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 700px;
      .step {
        &_title {
          font-size: 14px;
          font-weight: 600;
        }
        &_description {
          margin-top: 17px;
          margin: 17px 15px;
          display: flex;
          .QRcode_description {
            margin-left: 27px;
            font-size: 12px;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            gap: 20px;
          }
        }
      }
    }
    .action-group {
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
    }
    &_footer {
      height: 40px;
      padding: 10px 16px;
      background-color: #e7edef;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .empty {
    width: 120px;
    height: 120px;
  }
}
</style>
