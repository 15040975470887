<template>
  <div class="MediationFormConfirmComponent">
    <TitleComponent class="MediationFormConfirmComponent-title">
      <div>
        <span class="icon-ico_riyo_haishi"></span>
        入居者情報
      </div>
    </TitleComponent>
    <CardComponent
      class="main"
      :width="'960px'"
      :height="'auto'"
      v-if="editedData"
    >
      <div class="group">
        <div class="group-title">利用期間設定</div>
        <GroupContentComponent>
          <template v-slot:title>
            {{ isMediation ? "電気ご利用開始日時" : "電気ご利用開始日" }}
          </template>
          <template v-slot:content>
            <div class="flex-30">
              <div v-generateDateJapansese="editedData.date" />
              <div
                v-if="isMediation"
                v-generateTimeJapanseseByEnums="editedData.startTime"
              />
            </div>
          </template>
        </GroupContentComponent>
      </div>
      <div class="group" v-if="editedData.ratePlan">
        <div class="group-title">プラン選択</div>
        <GroupContentComponent :isTitleTop="true">
          <template v-slot:title> お申込み内容 </template>
          <template v-slot:content>
            <div v-if="editedData.ratePlan?.value !== 1">
              【電気温水器あり】{{ editedData.ratePlan.label }}
            </div>
            <div v-else>【電気温水器なし】{{ editedData.ratePlan.label }}</div>
          </template>
        </GroupContentComponent>
      </div>
      <div class="group">
        <div class="group-title">ご契約者情報</div>
        <GroupContentComponent>
          <template v-slot:title>
            <div class="twoline-title">
              <span class="firstline">お名前</span><br /><span
                class="secondline"
                >（ご契約名義）</span
              >
            </div>
          </template>
          <template v-slot:content>
            <div>
              {{ editedData.firstname }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                editedData.lastname
              }}
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> フリガナ </template>
          <template v-slot:content>
            {{ editedData.firstnameKana }}&nbsp;&nbsp;&nbsp;&nbsp;{{
              editedData.lastnameKana
            }}
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> ご契約者電話番号 </template>
          <template v-slot:content>
            <div class="telephoneGroup">
              <div class="flex-30">
                <div>
                  {{ editedData.contractorFirstPathNumbers }}-{{
                    editedData.contractorSecondPathNumbers
                  }}-{{ editedData.contractorThirtPathNumbers }}
                </div>
                <div v-if="editedData.contractorPhoneNumberCategory?.label">
                  {{ editedData.contractorPhoneNumberCategory.label }}
                </div>
              </div>
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> お申込み者と<br />契約名義人の関係 </template>
          <template v-slot:content>
            <div v-if="editedData.connectionWithApplicatedPerson?.label">
              {{ editedData.connectionWithApplicatedPerson.label }}
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent
          v-if="editedData.connectionWithApplicatedPerson.value !== 0"
        >
          <template v-slot:title> お名前（お申込者） </template>
          <template v-slot:content>
            {{ editedData.applicatedPersonFirstname }}&nbsp;&nbsp;&nbsp;&nbsp;{{
              editedData.applicatedPersonLastname
            }}
          </template>
        </GroupContentComponent>
        <GroupContentComponent
          v-if="editedData.connectionWithApplicatedPerson.value !== 0"
        >
          <template v-slot:title> お申込み者<br />連絡先電話番号 </template>
          <template v-slot:content>
            <div class="flex-30">
              <div>
                {{ editedData.applicatedFirstPathNumbers }}-{{
                  editedData.applicatedSecondPathNumbers
                }}-{{ editedData.applicatedThirtPathNumbers }}
              </div>
              <div v-if="editedData.applicatedPersonPhoneNumberCategory?.label">
                {{ editedData.applicatedPersonPhoneNumberCategory.label }}
              </div>
            </div>
          </template>
        </GroupContentComponent>
      </div>
      <div class="group">
        <div class="group-title">お支払い方法</div>
        <GroupContentComponent :isTitleTop="true">
          <template v-slot:title> お支払者のご住所 </template>
          <template v-slot:content>
            <div
              v-if="
                editedData.paymentAddressOptions?.value !==
                RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT
              "
            >
              {{ editedData.paymentAddressOptions.label }}
            </div>
            <div
              v-if="
                editedData.paymentAddressOptions?.value ===
                  RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT &&
                editedData.postDeliveryData
              "
            >
              <div
                v-if="editedData.postDeliveryData.postCode"
                v-formatPostCode="editedData.postDeliveryData.postCode"
              ></div>
              <div>{{ editedData.postDeliveryData.address }}</div>
              <div>
                {{ editedData.deliveryDepartmentNumber }}
              </div>
              <div>
                {{ editedData.deliveryDepartmentName }}
              </div>
              <div>
                {{ editedData.deliveryRoomNumber }}
              </div>
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent :isTitleTop="true">
          <template v-slot:title> お支払者の電話番号 </template>
          <template v-slot:content>
            <div
              v-if="
                editedData.paymentPersonTelephoneOptions?.value !==
                PAYMENT_PERSON_TELEPHONE_DEFAULT
              "
            >
              {{ editedData.paymentPersonTelephoneOptions.label }}
            </div>
            <div v-else class="flex-30">
              <div>
                {{ editedData.paymentFirstPathNumbers }}-{{
                  editedData.paymentSecondPathNumbers
                }}-{{ editedData.paymentThirtPathNumbers }}
              </div>
              <div v-if="editedData.paymentPhoneNumberCategory?.label">
                {{ editedData.paymentPhoneNumberCategory.label }}
              </div>
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> お支払方法 </template>
          <template v-slot:content>
            <div v-if="editedData.residentPaymentOptions">
              {{ editedData.residentPaymentOptions.label }}
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title>
            都市ガスサービス<br />
            案内可否
          </template>
          <template v-slot:content>
            <div v-if="editedData.gasServiceIntroduceOptions">
              {{ editedData.gasServiceIntroduceOptions.label }}
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title>
            九電グループ<br />「QTnet」の光イン<br />ターネット案内可否
          </template>
          <template v-slot:content>
            <div v-if="editedData.kyudenGroupInternetIntroduceOptions">
              {{ editedData.kyudenGroupInternetIntroduceOptions.label }}
            </div>
          </template>
        </GroupContentComponent>
        <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
      </div>
      <div class="action-group">
        <ButtonComponent type="outline" @click="onBack">
          もどる
        </ButtonComponent>
        <ButtonComponent @click="onSubmit"> 申込み </ButtonComponent>
      </div>
    </CardComponent>
  </div>
</template>
<script>
import {
  RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT,
  PAYMENT_PERSON_TELEPHONE_DEFAULT,
  RATE_PLAN_DEFAULT,
  RESIDENT_SETTING_TAB,
  STATUS,
} from "@/globals/enums";
import { mediationFormSchema } from "@/validations/schemas/mediationFormSchema";
import ResidentService from "@/services/resident.service";
import MediationDialogService from "@/services/dialog/mediation-dialog.service";
import ConfirmDialogService from "@/services/dialog/confirm-dialog.service";
import CommonService from "@/services/common.service";

export default {
  name: "MediationFormConfirmComponent",
  created() {
    this.RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT =
      RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT;
    this.PAYMENT_PERSON_TELEPHONE_DEFAULT = PAYMENT_PERSON_TELEPHONE_DEFAULT;
  },
  data: () => ({
    choisingPaymentAddress: RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT,
    choisingPaymentPersonTelephone: PAYMENT_PERSON_TELEPHONE_DEFAULT,
    postDeliveryData: null,
    postDeliveryNumber: "",
    schema: mediationFormSchema,
    residentService: new ResidentService(),
    mediationDialogService: new MediationDialogService(),
    confirmDialogService: new ConfirmDialogService(),
    commonService: new CommonService(),
    printId: "",
    errorDetail: null,
  }),
  props: {
    editedData: {
      type: Object,
      default: () => {},
    },
    isMediation: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.getPrint();
  },
  methods: {
    async getPrint() {
      const result = await this.commonService.getPrint();
      if (result.status === STATUS.SUCCESS && result?.data?.printId) {
        this.printId = result.data.printId;
      }
    },
    updatePostDeliveryData(data) {
      this.postDeliveryData = data;
    },
    searchDeliveryAgain() {
      this.postDeliveryData = null;
    },
    handlePaymentOptions(e) {
      this.choisingPaymentAddress = parseInt(e.target.value);
    },
    handlePersonPaymentTelephoneOptions(e) {
      this.choisingPaymentPersonTelephone = parseInt(e.target.value);
    },
    async onSubmit() {
      this.$store.dispatch("global/updateLoadingStatus", true);
      await this.residentService
        .createResident({ printId: this.printId, ...this.editedData })
        .then((result) => {
          this.$store.dispatch("global/updateLoadingStatus", false);
          if (result.status === STATUS.SUCCESS && this.isMediation) {
            this.mediationDialogService.openMediationDialog(
              "電気申込みが完了しました。",
              "次内容を印刷の上、お客さまへお渡しください。",
              result.data?.residentId,
              this.printId,
              this.sendingUrl(),
              () => this.$router.go(-1)
            );
          }
          if (result.status === STATUS.SUCCESS && !this.isMediation) {
            this.confirmDialogService.openConfirmDialog(
              "電気申込みが完了しました。",
              "後ほど九州電力より連絡がある事を、<br />お客様にお伝えください。",
              () => this.$router.go(-1)
            );
          }
          if (result?.status === STATUS.ERROR && result?.response?.detail) {
            this.errorDetail = result.response.detail;
          }
        });
    },
    onBack() {
      this.$emit("change-tab", {
        tab: RESIDENT_SETTING_TAB.EDIT,
      });
    },
    sendingUrl() {
      if (this.editedData?.ratePlan?.value) {
        return RATE_PLAN_DEFAULT === this.editedData.ratePlan.value
          ? process.env.VUE_APP_MEDIATION_RESIDENT_URL_SMARTPHONE
          : process.env.VUE_APP_MEDIATION_RESIDENT_URL_NOT_SMARTPHONE;
      }
      return "#";
    },
  },
};
</script>
<style scoped lang="scss">
.MediationFormConfirmComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-title {
    width: 960px;
    text-align: left;
    margin-top: 32px;
    color: var(--venice-blue-color);
    padding: 0.5rem 0;
  }
  .main {
    padding: 30px 40px;
    margin-bottom: 30px;
    .group {
      margin-bottom: 30px;
      &-title {
        font-size: 16px;
        padding-bottom: 5px;
        border-bottom: solid 2px #d0d9df;
      }
      .flex-30 {
        display: flex;
        gap: 30px;
      }
    }
    .action-group {
      display: flex;
      gap: 20px;
      justify-content: center;
    }
  }
}
</style>
