import { PHONE_PARSE, PHONE_REGION_START_NUMBER } from "@/globals/enums";

export const parsePhoneNumber = (phoneNumber, parseType = PHONE_PARSE) => {
  const convertRegionPhoneNumber = phoneNumber.replace(
    PHONE_REGION_START_NUMBER,
    "0"
  );
  return parseType.map((item) => ({
    name: item.NAME,
    value:
      convertRegionPhoneNumber?.substring(item.FROM_INDEX, item.TO_INDEX) || "",
    type: item.TYPE,
    pattern: item.PATTERN,
    maxlength: item.MAX_LENGTH,
  }));
};

export const unparsePhoneNumber = (form) => {
  let phoneNumber = "";
  PHONE_PARSE.forEach((path) => {
    phoneNumber += form[path.NAME];
  });
  return phoneNumber;
};
