import { convertGettingRegistrationList } from "../converter/registration.converter";

const state = {
  currentRegistrationRoomInfo: {
    total: 0,
    totalPage: 1,
    page: 1,
    registrationList: [],
  },
  fromRoomRouter: null,
};

// Getter functions
const getters = {
  getCurrentRegistrationRoomInfo() {
    return state.currentRegistrationRoomInfo;
  },
  getFromRoomRouter() {
    return state.fromRoomRouter;
  },
};

// Mutations
const mutations = {
  setRegistrationList(state, data) {
    state.currentRegistrationRoomInfo = data;
  },
  setFromRoomRouter(state, data) {
    state.fromRoomRouter = data;
  },
};

// Actions
const actions = {
  changeRegistrationList({ commit }, data) {
    commit("setRegistrationList", convertGettingRegistrationList(data));
  },
  changeFromRoomRouter({ commit }, data) {
    commit("setFromRoomRouter", data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
