<template>
  <div class="ShortTermRegistrationPreviewComponent">
    <TitleComponent class="ShortTermRegistrationPreviewComponent-title">
      <template v-slot:icon>
        <span class="icon-ico_riyo_haishi"></span>
      </template>
      <template v-slot:content> 電気短期使用・停止一括申込み </template>
    </TitleComponent>
    <CardComponent
      v-if="!isSearching"
      class="main"
      :width="'960px'"
      :height="
        currentRegistrationRoomInfo.registrationList.length > 0
          ? 'auto'
          : '460px'
      "
      :isEmpty="!(currentRegistrationRoomInfo.registrationList.length > 0)"
      emptyText="該当する申込みはございませんでした。"
    >
      <div class="table-main-content">
        <div class="count-item">
          <strong
            v-tableRange="{
              currentPage: currentRegistrationRoomInfo?.page || 0,
              currentTotal:
                currentRegistrationRoomInfo?.registrationList?.length || 0,
            }"
          />
          <span class="total"
            >件/{{ currentRegistrationRoomInfo.total }}件</span
          >
        </div>
        <table class="table-container">
          <TableHeaderComponent>建物名/部屋番号</TableHeaderComponent>
          <TableHeaderComponent>申込み種別</TableHeaderComponent>
          <TableHeaderComponent>使用開始（停止）日時</TableHeaderComponent>
          <TableHeaderComponent>使用用途</TableHeaderComponent>
          <TableHeaderComponent></TableHeaderComponent>
          <div class="table-row-group">
            <TableRowComponent
              v-for="registration in currentRegistrationRoomInfo.registrationList"
              :key="registration.registrationId"
            >
              <TableCellComponent class="title-cell first">
                <div class="first">
                  <p class="building-name-cell">
                    {{ registration.buildingName }}
                  </p>
                  <p>{{ registration.roomNumber }}</p>
                </div>
              </TableCellComponent>
              <TableCellComponent class="title-cell"
                ><div
                  class="registrationType"
                  v-roomRegistrationType="registration.registrationType"
                ></div
              ></TableCellComponent>
              <TableCellComponent class="title-cell date-cell"
                ><span v-generateDateJapansese="registration.date"></span
                >{{ " " }}
                <span v-generateTimeJapanseseByEnums="registration.time"></span
              ></TableCellComponent>
              <TableCellComponent class="title-cell purporse-cell">{{
                registration.purposeUse
              }}</TableCellComponent>
              <TableCellComponent class="last"
                ><div class="button-groups">
                  <ButtonComponent
                    width="76px"
                    height="30px"
                    @click="openRoomModal(registration)"
                    >編集</ButtonComponent
                  >
                  <ButtonComponent
                    width="76px"
                    height="30px"
                    type="outline"
                    @click="deleteRegistration(registration)"
                    >削除</ButtonComponent
                  >
                </div></TableCellComponent
              >
            </TableRowComponent>
          </div>
        </table>
        <div class="after-table">
          <ButtonComponent
            type="outline"
            width="98px"
            height="30px"
            :disabled="
              currentRegistrationRoomInfo.registrationList.length === 0
            "
            @click="deleteAllRegistration()"
            >全て削除</ButtonComponent
          >
        </div>
      </div>

      <PaginationComponent
        :total-pages="currentRegistrationRoomInfo.totalPage"
        :total="currentRegistrationRoomInfo.total"
        :current-page="currentRegistrationRoomInfo.page"
        v-if="currentRegistrationRoomInfo.totalPage > 1"
        @pagechanged="onPageChange"
      />
      <div class="submit-action">
        <ButtonComponent
          width="302px"
          height="50px"
          color="#328dc2"
          @click="changeTab"
          :disabled="currentRegistrationRoomInfo.registrationList.length === 0"
          >電気短期使用・停止申込み</ButtonComponent
        >
      </div>
    </CardComponent>
    <div class="back">
      <ButtonComponent
        width="170px"
        height="50px"
        type="outline"
        className="whiteBackGround"
        @click="back"
        >もどる</ButtonComponent
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RegistrationService from "@/services/registration.service";
import RegistrationElectricRoomDialog from "@/services/dialog/registration-electric-dialog-service";
import ConfirmDialogService from "@/services/dialog/confirm-dialog.service";
import { STATUS, REGISTRATION_SETTING_TAB } from "@/globals/enums";
import CommonService from "@/services/common.service";
export default {
  name: "ShortTermRegistrationPreviewComponent",
  async mounted() {
    this.isSearching = true;
    this.$store.dispatch("global/updateLoadingStatus", true);
    await this.registrationService.getRegistrationList({ page: 1, number: 10 });
    this.$store.dispatch("global/updateLoadingStatus", false);
    this.isSearching = false;
  },
  data: () => ({
    isSearching: true,
    registrationService: new RegistrationService(),
    registrationElectricRoomDialog: new RegistrationElectricRoomDialog(),
    confirmDialogService: new ConfirmDialogService(),
    commonService: new CommonService(),
  }),
  computed: {
    ...mapState({
      currentRegistrationRoomInfo: (state) =>
        state.registrationShortTerm.currentRegistrationRoomInfo,
      fromRoomRouter: (state) =>
        state.registrationShortTerm.fromRoomRouter || "/building/search",
    }),
    isEmptyRegistration() {
      return this.currentRegistrationRoomInfo.total === 0;
    },
    isLastRegistrationInPage() {
      return this.currentRegistrationRoomInfo?.registrationList?.length === 1;
    },
  },
  methods: {
    changeTab() {
      this.$emit("change-tab", REGISTRATION_SETTING_TAB.CONFIRM);
    },
    async onPageChange(event) {
      this.registrationService.getRegistrationList({ page: event, number: 10 });
    },
    back() {
      this.$router.go(-1);
    },
    async openRoomModal(room) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.commonService.getHolidayList();
      this.$store.dispatch("global/updateLoadingStatus", false);
      const initDateMonthYear = new Date(room.date);
      this.registrationElectricRoomDialog.openRegistrationElectricDialogServiceDialog(
        true,
        {
          registrationId: room.registrationId,
          departmentName: room.buildingName,
          roomName: room.roomNumber,
          initYear: initDateMonthYear.getFullYear(),
          initMonth: initDateMonthYear.getMonth() + 1,
          initDate: initDateMonthYear.getDate(),
          initTime: room.time,
          registrationType: room.registrationType,
          purposeUse: room.purposeUse,
          holidateList: result?.data?.holidateList || [],
        },
        () => {},
        this.editRegistration
      );
    },
    async editRegistration(registrationId, data, currentPagination) {
      return await this.registrationService.editRegistration(
        registrationId,
        data,
        currentPagination
      );
    },
    async deleteRegistration(room) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const currentPagination = {
        page: this.currentRegistrationRoomInfo.page,
        number: 10,
        currentLength: this.currentRegistrationRoomInfo.registrationList.length,
      };
      await this.registrationService
        .deleteRegistration(room.registrationId, currentPagination)
        .then((result) => {
          this.$store.dispatch("global/updateLoadingStatus", false);
          if (this.isEmptyRegistration) {
            this.$router.go(-1);
            return;
          }
          if (result.status === STATUS.SUCCESS) {
            this.confirmDialogService.openConfirmDialog(
              "削除完了しました",
              null,
              () => {}
            );
          }
        });
    },

    async deleteAllRegistration() {
      this.$store.dispatch("global/updateLoadingStatus", true);
      await this.registrationService.deleteAllRegistration().then((result) => {
        this.$store.dispatch("global/updateLoadingStatus", false);
        if (result.status === STATUS.SUCCESS) {
          this.$router.go(-1);
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.ShortTermRegistrationPreviewComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-title {
    width: 960px;
    text-align: left;
    margin-top: var(--default-distance-card);
    color: var(--venice-blue-color);
    padding: 0.5rem 0;
  }
  .main {
    padding: 30px 42px 30px 41px;
    .table-main-content {
      .count-item {
        text-align: right;
        margin: 5px 0;
        .total {
          font-size: 12px;
        }
      }
      .table-container {
        width: -webkit-fill-available;
      }
      .TableHeaderComponent {
        text-align: center;
        font-weight: 500;
      }
      .purporse-cell {
        width: 155px;
        text-align: left !important;
      }
      .date-cell {
        width: 185px;
      }
      .TableCellComponent {
        word-break: break-all;
        padding: 13px 5px;
        &.title-cell {
          text-align: center;
          &.first {
            padding: 13px 9px;
          }
          .first {
            display: flex;
            gap: 10px;
            justify-content: left;
            .building-name-cell {
              text-align: left;
              width: 130px;
            }
          }
        }
        &.last {
          padding-right: 11px;
          font-size: 14px;
          font-weight: 500;
        }
        .button-groups {
          display: flex;
          gap: 10px;
          justify-content: flex-end;
        }
        .registrationType {
          display: flex;
          justify-content: center;
          padding: 2px;
          border: solid 1px #d0d9df;
          width: 80px;
          height: 23px;
          font-size: 11px;
        }
      }
      .after-table {
        margin: 16.5px 11px 30px 0;
        text-align: right;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .pagination {
      text-align: center;
      margin-bottom: 30px;
    }
    .submit-action {
      text-align: center;
    }
  }
  .back {
    margin: 30px 0 var(--default-distance-button);
    .ButtonComponent {
      button {
        background-color: #fff;
      }
    }
  }
}
</style>
