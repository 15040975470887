<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="SearchAddressDialog modal-content"
  >
    <button
      class="modal__close"
      @click="
        close();
        onClose();
      "
    >
      <span class="icon-ico_closedialog"></span>
    </button>

    <span class="modal__title">住所選択</span>
    <div v-if="addressList && addressList.length !== 0" class="modal__content">
      <div
        class="modal__content-addressItem"
        v-for="item of addressList"
        :key="item.addressCode"
        :class="{
          active: currentChoise
            ? currentChoise === item.addressCode
            : item.addressCode === addressList[0].addressCode,
        }"
      >
        <InputComponent
          type="radio"
          name="addressCode"
          :id="`addressCode_${item.addressCode}`"
          :defaultValue="addressList[0].addressCode"
          :value="item.addressCode"
          :label="item.address"
          @trigle-change="handleChange"
        />
      </div>
    </div>
    <div v-else>入力された郵便番号に対応する住所が見つかりませんでした。</div>
    <div class="modal__action" v-if="addressList && addressList.length !== 0">
      <ButtonComponent
        type="outline"
        buttonType="button"
        width="150px"
        height="50px"
        @click="
          close();
          onClose();
        "
      >
        キャンセル
      </ButtonComponent>
      <ButtonComponent
        :disabled="!(addressList && addressList.length !== 0)"
        width="150px"
        height="50px"
        @click="selectAddress()"
      >
        選択
      </ButtonComponent>
    </div>
    <div class="modal__action" v-else>
      <ButtonComponent
        buttonType="button"
        width="150px"
        height="50px"
        @click="
          close();
          onClose();
        "
      >
        OK
      </ButtonComponent>
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  name: "SearchAddressDialog",
  props: {
    title: {
      type: String,
      default: null,
    },
    styles: {
      type: [Object, Array],
      default: () => {},
    },
    addressList: {
      type: Array,
      default: null,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onChoise: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    currentChoise: null,
  }),
  methods: {
    handleChange(e) {
      this.currentChoise = e.target.value;
    },
    selectAddress() {
      const address =
        this.addressList.find(
          (item) => this.currentChoise === item.addressCode
        ) || this.addressList[0];
      this.onChoise(address);
      this.$vfm.hideAll();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.SearchAddressDialog) {
  &.modal {
    &-content {
      position: relative;
      background-color: #fefefe;
      margin: auto;
      padding: 40px 50px 29px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      border: 1px solid #888;
      width: 500px;
      max-height: 470px;
      border-radius: 5px;
      .modal {
        &__title {
          font-weight: bold;
          text-align: center;
          margin-bottom: 20px;
        }
        &__content {
          width: 100%;
          font-size: 14px;
          text-align: center;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          gap: 10px;
          &-addressItem {
            height: 60px;
            padding: 20px;
            border: solid 1px #d0d9df;
            display: flex;
            align-items: center;
            .InputComponent {
              margin-bottom: 0;
            }
            &.active {
              background-color: #f3f6f7;
              color: #333;
            }
          }
        }
        &__close {
          position: absolute;
          top: 10px;
          right: 20px;
          color: var(--color-gray);
          font-size: 20px;
        }
        &__action {
          display: flex;
          gap: 20px;
          margin-top: 30px;
        }
      }
    }
  }
}
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
