<template>
  <ResidentRoomInfoComponent
    v-if="dataRoom && dataBuilding"
    :dataRoom="dataRoom"
    :dataBuilding="dataBuilding"
  />
  <div
    class="ResidentRegistrationComponent"
    v-if="isMediation && dataRoom && dataBuilding"
  >
    <MediationFormComponent
      v-show="currentTab === RESIDENT_SETTING_TAB.EDIT"
      :holidateList="holidateList"
      @change-tab="changeTab"
    />
    <MediationFormConfirmComponent
      v-show="currentTab === RESIDENT_SETTING_TAB.CONFIRM"
      @change-tab="changeTab"
      :editedData="editedData"
      :isMediation="isMediation"
    />
  </div>
  <div
    class="ResidentRegistrationComponent"
    v-if="isIntroduce && dataRoom && dataBuilding"
  >
    <IntroduceFormComponent
      v-show="currentTab === RESIDENT_SETTING_TAB.EDIT"
      :holidateList="holidateList"
      @change-tab="changeTab"
    />
    <MediationFormConfirmComponent
      v-show="currentTab === RESIDENT_SETTING_TAB.CONFIRM"
      @change-tab="changeTab"
      :editedData="editedData"
      :isMediation="isMediation"
    />
  </div>
</template>
<script>
import {
  RESIDENT_SETTING_TAB,
  COMPANY_REGISTER_CONTRACT_TYPES,
} from "@/globals/enums";
import { mapState } from "vuex";
import RoomService from "@/services/room.service";
import BuildingService from "@/services/building.service";
import CommonService from "@/services/common.service";

export default {
  name: "ResidentRegistrationComponent",
  async created() {
    this.$store.dispatch("global/updateLoadingStatus", true);
    this.RESIDENT_SETTING_TAB = RESIDENT_SETTING_TAB;
    await this.getInfoRoom();
    const result = await this.commonService.getHolidayList();
    this.holidateList = result?.data?.holidateList;
    this.$store.dispatch("global/updateLoadingStatus", false);
  },
  computed: {
    ...mapState({
      isMediation: (state) =>
        state?.company?.edittingCompanyData?.electricContractApp?.value ===
          COMPANY_REGISTER_CONTRACT_TYPES.HAS_REGISTER_CONTRACT.VALUE &&
        state?.company?.edittingCompanyData?.electricContractAppType?.value ===
          1,
      isIntroduce: (state) =>
        state?.company?.edittingCompanyData?.electricContractApp?.value ===
          COMPANY_REGISTER_CONTRACT_TYPES.HAS_REGISTER_CONTRACT.VALUE &&
        state?.company?.edittingCompanyData?.electricContractAppType?.value ===
          2,
    }),
  },
  data: () => ({
    currentTab: RESIDENT_SETTING_TAB.EDIT,
    roomService: new RoomService(),
    buildingService: new BuildingService(),
    commonService: new CommonService(),
    dataRoom: null,
    dataBuilding: null,
    editedData: null,
  }),
  methods: {
    changeTab(data) {
      if (data.editedData) {
        this.editedData = data.editedData;
      }
      this.currentTab = data.tab;
    },
    getInfoRoom() {
      if (this.$route?.params?.id) {
        this.roomService.getInfoRoom(this.$route.params.id).then((dataRoom) => {
          if (!dataRoom.data) return;
          this.dataRoom = dataRoom.data;
          if (dataRoom.data.buildingId) {
            this.getInfoBuilding(dataRoom.data.buildingId);
          }
        });
      }
    },
    getInfoBuilding(id) {
      this.buildingService.getInfoBuilding(id).then((dataBuilding) => {
        this.dataBuilding = dataBuilding.data;
      });
    },
  },
};
</script>
