import {
  ELECTRIC_ROOM_REGISTRATION_START,
  ELECTRIC_ROOM_REGISTRATION_END,
} from "@/globals/enums";

export const roomRegistrationType = (el, binding) => {
  switch (binding?.value) {
    case ELECTRIC_ROOM_REGISTRATION_START.VALUE:
      el.innerHTML = ELECTRIC_ROOM_REGISTRATION_START.NAME;
      break;
    case ELECTRIC_ROOM_REGISTRATION_END.VALUE:
      el.innerHTML = ELECTRIC_ROOM_REGISTRATION_END.NAME;
      break;
    default:
      el.innerHTML = "";
      break;
  }
};
