import * as Yup from "yup";
import {
  validateKeyword,
  validateBuildingAddress,
  validateBlockNumber,
  validatePostCode,
} from "../common";

export const searchBuildingSchema = Yup.object().shape({
  keyWord: validateKeyword,
  firstCode: validatePostCode.firstPath,
  lastCode: validatePostCode.secondPath,
  address: validateBuildingAddress,
  blockNumber: validateBlockNumber,
});
