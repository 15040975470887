export const convertProfile = (dataProfile) => {
  return {
    firstNameKanji: dataProfile.first_name_kanji || "",
    lastNameKanji: dataProfile.last_name_kanji || "",
    firstNameKana: dataProfile.first_name_kana || "",
    lastNameKana: dataProfile.last_name_kana || "",
    emailAddress: dataProfile.mail_address || "",
    adminFlg: dataProfile.admin_flg || false,
    companyId: dataProfile.company_id || "",
  };
};

export const convertProfileEdit = (dataProfile) => {
  return {
    first_name_kanji: dataProfile.firstNameKanji,
    last_name_kanji: dataProfile.lastNameKanji,
  };
};

export const convertFirstProfileEdit = (dataProfile) => {
  return {
    first_name_kanji: dataProfile.firstNameKanji,
    last_name_kanji: dataProfile.lastNameKanji,
  };
};
