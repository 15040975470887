import {
  searchAddress,
  saveCompanyInfo,
  getCompanyInfo,
  updateCompanyInfo,
} from "./api/company.service";
import { STATUS } from "@/globals/enums";
import {
  convertAddressList,
  convertSendingSettingCompanyData,
  convertUpdateCompanyData,
} from "../store/converter/company.converter";
import store from "./../store/index";

export default class CompanyService {
  initCompanyState() {
    store.dispatch("company/initCompanySetting");
  }
  previewCurrentEdittingCompanyInfo(data) {
    store.dispatch("company/changeCurrentEdittingCompany", {
      data,
      isEditting: true,
    });
  }

  async getAddressByPostOfficeNumber(postOfficeNumber) {
    try {
      const result = await searchAddress({
        post_code: postOfficeNumber,
      });
      return {
        status: STATUS.SUCCESS,
        data: convertAddressList(result),
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
      };
    }
  }

  async saveCompanySetting(data) {
    try {
      const result = await saveCompanyInfo(
        convertSendingSettingCompanyData(data)
      );
      return {
        status: STATUS.SUCCESS,
        data: result,
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: error.response,
      };
    }
  }

  async getUserCompanyInfo(companyId) {
    try {
      const result = await getCompanyInfo(companyId);
      await store.dispatch("company/changeCurrentEdittingCompany", {
        data: result,
        isEditting: false,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async updateCompanyData(companyId, params) {
    try {
      await updateCompanyInfo(companyId, convertUpdateCompanyData(params));
      const result = await getCompanyInfo(companyId);
      await store.dispatch("company/changeCurrentEdittingCompany", {
        data: result,
        isEditting: false,
      });
      return {
        status: STATUS.SUCCESS,
      };
    } catch (error) {
      console.log(error);
      return {
        status: STATUS.ERROR,
      };
    }
  }
}
