import * as Yup from "yup";
import {
  validateTelephoneGroup,
  validateSelectNumber,
  validateAddressForm,
} from "../common";
import { TRANSFER_ADDRESS_OPTIONS_DEFAULT } from "@/globals/enums";

export const companyEditSchemaOnlyTelephone = Yup.object().shape({
  firstPathNumbers: validateTelephoneGroup.firstPathTelephone(
    "firstPathNumbers",
    "secondPathNumbers",
    "thirtPathNumbers"
  ),
  secondPathNumbers: validateTelephoneGroup.secondPathTelephone(
    "firstPathNumbers",
    "secondPathNumbers",
    "thirtPathNumbers"
  ),
  thirtPathNumbers: validateTelephoneGroup.lastPathTelephone(
    "firstPathNumbers",
    "secondPathNumbers",
    "thirtPathNumbers"
  ),
  phoneNumberCategory: validateSelectNumber(0),
});

export const companyEditSchemaWithPostCode = Yup.object().shape({
  firstPathNumbers: validateTelephoneGroup.firstPathTelephone(
    "firstPathNumbers",
    "secondPathNumbers",
    "thirtPathNumbers"
  ),
  secondPathNumbers: validateTelephoneGroup.secondPathTelephone(
    "firstPathNumbers",
    "secondPathNumbers",
    "thirtPathNumbers"
  ),
  thirtPathNumbers: validateTelephoneGroup.lastPathTelephone(
    "firstPathNumbers",
    "secondPathNumbers",
    "thirtPathNumbers"
  ),
  phoneNumberCategory: validateSelectNumber(0),
  postDeliveryNumberName: Yup.string().when("transferAddressOptions", {
    is: TRANSFER_ADDRESS_OPTIONS_DEFAULT,
    then: validateAddressForm.postOfficeNumber,
    otherwise: Yup.string(),
  }),
  deliveryDepartmentNumber: Yup.string().when("transferAddressOptions", {
    is: TRANSFER_ADDRESS_OPTIONS_DEFAULT,
    then: validateAddressForm.addressDepartmentNumber,
    otherwise: Yup.string(),
  }),
  deliveryDepartmentName: Yup.string().when("transferAddressOptions", {
    is: TRANSFER_ADDRESS_OPTIONS_DEFAULT,
    then: validateAddressForm.addressDepartmentName,
    otherwise: Yup.string(),
  }),
  deliveryRoomNumber: Yup.string().when("transferAddressOptions", {
    is: TRANSFER_ADDRESS_OPTIONS_DEFAULT,
    then: validateAddressForm.addressRoomNumber,
    otherwise: Yup.string(),
  }),
});
