import { defineRule } from "vee-validate";
import { emailFormat, numberFormat } from "./../globals/enums";
import {
  convertYearMonthDateToDate,
  addMonths,
} from "../plugins/parseDateChoise";
import { HAS_CHAR_IS_NOT_HALF_WIDTH, CHECK_FULL_WIDTH } from "../globals/enums";
import encoding from "encoding-japanese";

// Required field
defineRule("required", (value) => {
  if (!value || !value.length) {
    return "This field is required";
  }
  return true;
});

// Email
defineRule("email", (value) => {
  if (!value || !value.length) {
    return true;
  }
  if (!emailFormat.test(value)) {
    return "This field must be a valid email";
  }
  return true;
});

// Only number
defineRule("number", (value) => {
  if (!value || !value.length) {
    return true;
  }
  if (!numberFormat.test(value)) {
    return "This field must be a valid number";
  }
});

const getYesterdayDate = () => {
  return new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
};

export const isDatetimeisOlderThanCurrentTime = (year, month, date, time) => {
  if (year == 0 || month == 0 || date == 0) {
    return true;
  }
  if (time) {
    return (
      convertYearMonthDateToDate(year, month - 1, date).getTime() >=
      time.getTime()
    );
  }
  return (
    convertYearMonthDateToDate(year, month - 1, date).getTime() >
    getYesterdayDate().getTime()
  );
};

export const isStartTimeOverThanEndTime = (start, end) => {
  if (
    start.year == 0 ||
    start.month == 0 ||
    start.date == 0 ||
    end.year == 0 ||
    end.month == 0 ||
    end.date == 0
  ) {
    return true;
  }
  return (
    convertYearMonthDateToDate(
      start.year,
      start.month - 1,
      start.date
    ).getTime() <
    convertYearMonthDateToDate(end.year, end.month - 1, end.date).getTime()
  );
};

export const isStartTimeSameEndTime = (start, end) => {
  if (
    start.year == 0 ||
    start.month == 0 ||
    start.date == 0 ||
    end.year == 0 ||
    end.month == 0 ||
    end.date == 0
  ) {
    return true;
  }
  return !(
    start.year === end.year &&
    start.month === end.month &&
    start.date === end.date
  );
};

export const isOverThreeMonth = (year, month, date, time, holidateList) => {
  if (year == 0 || month == 0 || date == 0) {
    return false;
  }
  if (time) {
    const fakeDate = new Date(time);
    fakeDate.setDate(fakeDate.getDate());
    return (
      addMonths(new Date(fakeDate), 3, holidateList).getTime() <
      convertYearMonthDateToDate(year, month - 1, date).getTime()
    );
  }
  return (
    addMonths(new Date(), 3).getTime() <
    convertYearMonthDateToDate(year, month - 1, date).getTime()
  );
};

export const isTimeNotSelect = (year, month, date) => {
  return year == 0 || month == 0 || date == 0;
};

export const dateTimeFormat = (value) => {
  return value && String(value).length === 1 ? `0${value}` : value;
};

export const isTimeInList = (year, month, date) => {
  if (year == 0 || month == 0 || date == 0) {
    return false;
  }
  const searchFormatDate = `${year}/${dateTimeFormat(month)}/${dateTimeFormat(
    date
  )}`;
  const dateTime = new Date(searchFormatDate);
  if (
    dateTime.getFullYear() != year ||
    dateTime.getMonth() != month - 1 ||
    dateTime.getDate() != date
  ) {
    return true;
  }
  return false;
};

export const sjisIsDobuleByteChar = (code) => {
  return (code >= 129 && code <= 159) || (code >= 224 && code <= 252);
};

export const isFullWidth = (value) => {
  if (!value) return true;
  const regex = CHECK_FULL_WIDTH;
  // 第1非水準漢字において、 encoding.jsでUTFからSJISへのマッピングが無い文字は、文字コード変換対象から除外する
  const newValue = value.replaceAll(regex, "");

  const str2array = function (str) {
    var array = [],
      i,
      il = str.length;
    for (i = 0; i < il; i++) array.push(str.charCodeAt(i));
    return array;
  };
  let sjisArray = encoding.convert(str2array(newValue), "SJIS", "UNICODE");
  let i = 0;
  let len = sjisArray.length;
  let code;

  while (i < len) {
    code = sjisArray[i];
    if (sjisIsDobuleByteChar(code)) {
      code = (code << 8) | sjisArray[i + 1];
      i++;
      // console.log(" sjis code : 0x" + code.toString(16) + ",");

      // 許容範囲の文字かチェック
      // 第1水準非漢字 0x8140～0x879e
      // 第1水準漢字   0x889F～0x9872
      // 第2水準漢字   0x989F～0xEAA4
      if (
        !(
          (code >= 0x8140 && code <= 0x879e) ||
          (code >= 0x889f && code <= 0x9872) ||
          (code >= 0x989f && code <= 0xeaa4)
        )
      ) {
        // console.log(" sjis code : 0x" + code.toString(16) + ", NG");
        //deubg_ngCount++;
        //console.log(" NG");
        return false;
        //}else{
        //console.log(" sjis code : 0x" + code.toString(16) +", OK" );
        //deubg_okCount++;
      }
    } else {
      // 1byte文字
      // console.log(" sjis code : 0x" + code.toString(16) + ", NGss");
      //deubg_ngCount++;
      return false;
    }
    i++;
  }
  return true;
};

export const isHalfWidth = (value) => {
  return !HAS_CHAR_IS_NOT_HALF_WIDTH.test(value);
};
