<template>
  <div class="ReSettingSMSComponent">
    <div v-if="isValidToken">
      <Form
        @submit="registerSMS"
        :validation-schema="schema"
        v-slot="{ meta }"
        v-if="!isPreview"
      >
        <CardComponent
          class="form-settingSMS"
          :width="'960px'"
          :height="'550px'"
        >
          <TitleComponent class="form-settingSMS_title" :hasUnderLine="true">
            <template v-slot:content>電話番号に認証コードを送信する</template>
          </TitleComponent>
          <p class="form-settingSMS_description">
            コードを受信する電話番号を入力してください。
          </p>

          <div class="form-settingSMS_inputGroups">
            <div class="form-settingSMS-inputGroup">
              <div class="title">電話番号</div>
              <InputComponent
                name="phoneNumber"
                type="telephone"
                inputFilter="number"
                maxlength="11"
                placeholder="電話番号を入力"
              />
            </div>
            <div class="form-settingSMS-inputGroup_description right">
              電話番号はアカウントのセキュリティのために使用されます。
            </div>
          </div>
          <div class="action-group">
            <ButtonComponent
              buttonType="button"
              type="outline"
              width="160px"
              @click="cancel"
            >
              キャンセル
            </ButtonComponent>
            <ButtonComponent
              buttonType="submit"
              width="160px"
              :disabled="!(meta.valid && meta.dirty)"
            >
              OK
            </ButtonComponent>
          </div>
        </CardComponent>
      </Form>
      <CardComponent
        class="form-settingSMS"
        :width="'960px'"
        :height="'550px'"
        v-else
      >
        <TitleComponent class="form-settingSMS_title" :hasUnderLine="true">
          <template v-slot:content>多要素認証をリセットする</template>
        </TitleComponent>
        <div class="preview-button">
          <div class="inputGroup">
            <ButtonComponent width="358px" height="50px" @click="setView">
              多要素認証(SMSメッセージ)を設定
            </ButtonComponent>
          </div>
          <div class="form-settingSMS-inputGroup_description">
            ※多要素認証リセット直後の多要素認証は、SMSメッセージでの通知のみとなります。<br /><span
              >SMSメッセージ以外に変更したい場合、ログイン後、メニュー＞セキュリティ再設定より変更が行えます。</span
            >
          </div>
        </div>
      </CardComponent>
    </div>
    <CardComponent
      class="form-settingSMS"
      :width="'960px'"
      :height="'550px'"
      v-else
    >
      <TitleComponent class="form-settingSMS_title" :hasUnderLine="true"
        ><template v-slot:content
          >多要素認証をリセットできませんでした</template
        >
      </TitleComponent>
      <p class="form-settingSMS_description_error">
        トークンの有効期限が切れています。
      </p>
      <div class="action-group">
        <ButtonComponent width="230px" @click="cancel">
          ログイン画面へ
        </ButtonComponent>
      </div>
    </CardComponent>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import LoginService from "@/services/login.service";
import { settingSMSSchema } from "@/validations/schemas/settingSMSSchema";
import { STATUS } from "@/globals/enums";

export default {
  name: "ReSettingSMSComponent",
  components: {
    Form,
  },
  async created() {
    this.token = this.$route?.query?.token || null;
    if (this.token) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.loginService.checkToken(this.token);
      if (result.status === STATUS.SUCCESS) {
        this.isValidToken = result.status === STATUS.SUCCESS;
        this.accountId = result.data.account_id;
      }
      this.$store.dispatch("global/updateLoadingStatus", false);
    }
  },
  data: () => ({
    isValidToken: false,
    isPreview: true,
    accountId: null,
    token: null,
    loginService: new LoginService(),
    schema: settingSMSSchema,
  }),
  methods: {
    async registerSMS(data) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      await this.loginService.reConfigSMS(data.phoneNumber, this.accountId);
      this.$store.dispatch("global/updateLoadingStatus", false);
    },
    cancel(e) {
      e.preventDefault();
      this.loginService.logout();
    },
    async setView(e) {
      e.preventDefault();
      this.isPreview = false;
    },
  },
};
</script>

<style scoped lang="scss">
.ReSettingSMSComponent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    &-settingSMS {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 30px;
      &_title {
        font-size: 20px;
        font-weight: 500;
      }
      &_description {
        font-size: 14px;
        font-weight: 500;
        margin: 20px 0 0;
        &_error {
          margin: 21px 0 39px;
          font-size: 14px;
          font-weight: 500;
          color: #000;
        }
      }
      &_inputGroups {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 30px 220px 40px;
      }
      &-inputGroup {
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: space-between;
        &_title {
          font-size: 14px;
          font-weight: 500;
          color: #7c8185;
        }
        &_description {
          font-size: 12px;
          color: #7c8185;
          &.right {
            margin-left: 92px;
          }
        }
        .InputComponent {
          margin: 0;
        }
      }
    }
  }
  .action-group {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  .card_footer {
    height: 40px;
    padding: 10px 16px;
    background-color: #e7edef;
    font-size: 14px;
    font-weight: 600;
  }
  .preview-button {
    padding: 80px;
    text-align: center;
    font-size: 18px;
    .form-settingSMS-inputGroup_description {
      margin-top: 40px;
      text-align: left;
      font-size: 12px;
      color: #7c8185;
      font-weight: normal;
      span {
        margin-left: 12px;
      }
    }
  }
}
</style>
