import {
  ELECTRIC_ROOM_REGISTRATION_TIME_OPTIONS,
  MONTHS,
  DATES,
  TIMES,
  ELECTRIC_ROOM_REGISTRATION_TIME_DEFAULT_OPTION,
  ELECTRIC_ROOM_REGISTRATION_TIME_START_OPTION,
  ELECTRIC_ROOM_REGISTRATION_TIME_END_OPTION,
  NO_ELECTRIC_WATER_HEATER,
  HAS_ELECTRIC_WATER_HEATER,
  REGISTER_PHONE_TYPES,
  RESIDENT_PAYMENT_ADDRESS_OPTIONS,
  CONNECTION_WITH_APPLICATED_PERSON_OPTIONS,
  PAYMENT_PERSON_TELEPHONE_OPTIONS,
  RESIDENT_PAYMENT_OPTIONS,
  GAS_SERVICE_INTRODUCE_OPTIONS,
  KYUDEN_GROUP_INTERNET_INTRODUCE_OPTIONS,
  EXECUTE_TIME,
  REGISTRATION_STATUS,
  REGISTRATION_TYPE,
  PAYER_PHONE_NUMBER_CATEGORY,
  RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT,
  PAYMENT_PERSON_TELEPHONE_DEFAULT,
} from "@/globals/enums";
import { getYearWithAdditionalMonth } from "@/plugins/parseDateChoise";

export const convertRoomRegistrationTimeOptions = () => {
  return ELECTRIC_ROOM_REGISTRATION_TIME_OPTIONS.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

export const convertYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const result = [
    {
      value: parseInt(currentYear),
      label: currentYear,
    },
  ];

  const threeMonthLaterYear = getYearWithAdditionalMonth(3);
  if (threeMonthLaterYear !== currentYear) {
    result.push({
      value: parseInt(threeMonthLaterYear),
      label: threeMonthLaterYear,
    });
  }
  return result;
};

export const convertMonthOptions = () => {
  return MONTHS.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

export const convertDateOptions = () => {
  return DATES.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

export const convertTimeOptions = () => {
  return TIMES.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

const dateTimeFormat = (value) => {
  return value && String(value).length === 1 ? `0${value}` : value;
};

export const convertSendingRegistrationRoom = (data) => {
  return {
    room_id: data.roomId,
    ...(data.purposeUse?.length !== 0 &&
      data.registrationType !== ELECTRIC_ROOM_REGISTRATION_TIME_END_OPTION && {
        purpose_use: data.purposeUse,
      }),
    ...(data.registrationType ===
      ELECTRIC_ROOM_REGISTRATION_TIME_DEFAULT_OPTION && {
      start_date: `${data.startYear}/${dateTimeFormat(
        data.startMonth
      )}/${dateTimeFormat(data.startDate)}`,
      start_time: data.startTime,
      end_date: `${data.endYear}/${dateTimeFormat(
        data.endMonth
      )}/${dateTimeFormat(data.endDate)}`,
      end_time: data.endTime,
    }),
    ...(data.registrationType ===
      ELECTRIC_ROOM_REGISTRATION_TIME_START_OPTION && {
      start_date: `${data.startYear}/${dateTimeFormat(
        data.startMonth
      )}/${dateTimeFormat(data.startDate)}`,
      start_time: data.startTime,
    }),
    ...(data.registrationType ===
      ELECTRIC_ROOM_REGISTRATION_TIME_END_OPTION && {
      end_date: `${data.endYear}/${dateTimeFormat(
        data.endMonth
      )}/${dateTimeFormat(data.endDate)}`,
      end_time: data.endTime,
    }),
  };
};

export const convertGettingRegistrationList = (data) => {
  return {
    page: data.page,
    totalPage: data.total_page,
    total: data.total,
    registrationList: data.registration_list.map((registration) => ({
      registrationResultId: registration.registration_id,
      registrationId: registration.registration_id,
      buildingName: registration.building_name,
      roomNumber: registration.room_number,
      registrationType: registration.registration_type,
      date: registration.execute_date,
      time: registration.execute_time,
      purposeUse: registration.purpose_use,
    })),
  };
};

export const convertRegistrationHistoryFilter = (data, pagination, sort) => {
  const registrationType = [
    data.useStart ? REGISTRATION_TYPE.USE_START.VALUE : null,
    data.useStop ? REGISTRATION_TYPE.USE_STOP.VALUE : null,
    data.moveIn ? REGISTRATION_TYPE.MOVE_IN.VALUE : null,
  ]
    .filter((type) => type)
    .join(",");
  const status = [
    data.complete ? REGISTRATION_STATUS.COMPLETE.VALUE : null,
    data.waiting ? REGISTRATION_STATUS.WAITING.VALUE : null,
    data.process ? REGISTRATION_STATUS.PROCESS.VALUE : null,
    data.unconfirmed ? REGISTRATION_STATUS.UNCONFIRMED.VALUE : null,
    data.confirmed ? REGISTRATION_STATUS.CONFIRMED.VALUE : null,
  ]
    .filter((status) => status)
    .join(",");
  return {
    building_name: data.buildingName,
    registration_type: registrationType,
    status: status,
    supply_point_id: data.supplyPointId,
    sort_type: sort.sortType,
    sort_order: sort.sortOrder,
    page: pagination.page || 1,
    number: pagination.number || 10,
  };
};

export const convertUpdateRegistration = (data) => {
  return {
    execute_date: `${data.year}/${dateTimeFormat(data.month)}/${dateTimeFormat(
      data.date
    )}`,
    execute_time: data.time,
    ...(data.purposeUse?.length !== 0 && { purpose_use: data.purposeUse }),
  };
};

export const convertUpdateRegistrationList = (data) => {
  return {
    registration_id_list: data,
  };
};

export const convertNoElectricWaterHeaterOptions = () => {
  return NO_ELECTRIC_WATER_HEATER.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

export const convertHasElectricWaterHeaterOptions = () => {
  return HAS_ELECTRIC_WATER_HEATER.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

export const convertRegisterPhoneNumberOptions = () => {
  return REGISTER_PHONE_TYPES.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

export const convertPayerPhoneNumberOptions = () => {
  return PAYER_PHONE_NUMBER_CATEGORY.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

export const convertPaymentAddressOptions = () => {
  return RESIDENT_PAYMENT_ADDRESS_OPTIONS.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

export const convertConnectionWithAppicatedOptions = () => {
  return CONNECTION_WITH_APPLICATED_PERSON_OPTIONS.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

export const convertPaymentPersonTelephoneOptions = () => {
  return PAYMENT_PERSON_TELEPHONE_OPTIONS.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

export const convertResidentPaymentOptions = () => {
  return RESIDENT_PAYMENT_OPTIONS.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

export const convertGasServiceIntroduceOptions = () => {
  return GAS_SERVICE_INTRODUCE_OPTIONS.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};

export const convertKyudenGroupInternetIntroduceOptions = () => {
  return KYUDEN_GROUP_INTERNET_INTRODUCE_OPTIONS.map((type) => ({
    value: type.VALUE,
    label: type.NAME,
  }));
};
export const convertRegistrationHistory = (data) => {
  return {
    registrationId: data.registration_id,
    applicationCreateAt: data.application_create_at,
    buildingName: data.building_name,
    roomNumber: data.room_number,
    supplyPointId: data.supply_point_id,
    registrationType: data.registration_type,
    executeDate: data.execute_date,
    status: data.status,
    executeTime: EXECUTE_TIME.find((time) => time.ID === data.execute_time)
      .LABEL,
    resultUpdateDate: data.result_update_date,
    resultUpdateBy: data.result_update_by,
  };
};

export const convertRatePlan = (data, isPrint) => {
  if (data !== NO_ELECTRIC_WATER_HEATER[0].VALUE) {
    const findingValue = HAS_ELECTRIC_WATER_HEATER.find(
      (type) => type.VALUE === data
    );
    return {
      value: findingValue.VALUE,
      label: findingValue.NAME,
    };
  }
  return {
    value: NO_ELECTRIC_WATER_HEATER[0].VALUE,
    label: isPrint
      ? "スマートファミリープラン(2年契約割引適用)"
      : NO_ELECTRIC_WATER_HEATER[0].NAME,
  };
};

const getPhoneNumberCategory = (data, type) => {
  const findingValue = type.find((type) => type.VALUE === data);
  return {
    value: findingValue.VALUE,
    label: findingValue.NAME,
  };
};

const getConnectionWithAppicatedPerson = (data) => {
  const findingValue = CONNECTION_WITH_APPLICATED_PERSON_OPTIONS.find(
    (type) => type.VALUE === data
  );
  return {
    value: findingValue.VALUE,
    label: findingValue.NAME,
  };
};

const getPaymentAddressOptions = (data) => {
  const findingValue = RESIDENT_PAYMENT_ADDRESS_OPTIONS.find(
    (type) => type.VALUE === data
  );
  return {
    value: findingValue.VALUE,
    label: findingValue.NAME,
  };
};

const getPaymentPersonTelephoneOptions = (data) => {
  const findingValue = PAYMENT_PERSON_TELEPHONE_OPTIONS.find(
    (type) => type.VALUE === data
  );
  return {
    value: findingValue.VALUE,
    label: findingValue.NAME,
  };
};

export const getResidentPaymentOptions = (data) => {
  const findingValue = RESIDENT_PAYMENT_OPTIONS.find(
    (type) => type.VALUE === data
  );
  return {
    value: findingValue.VALUE,
    label: findingValue.NAME,
  };
};

export const getGasServiceIntroduceOptions = (data) => {
  const findingValue = GAS_SERVICE_INTRODUCE_OPTIONS.find(
    (type) => type.VALUE === data
  );
  return {
    value: findingValue.VALUE,
    label: findingValue.NAME,
  };
};

export const getKyudenGroupInternetIntroduceOptions = (data) => {
  const findingValue = KYUDEN_GROUP_INTERNET_INTRODUCE_OPTIONS.find(
    (type) => type.VALUE === data
  );
  return {
    value: findingValue.VALUE,
    label: findingValue.NAME,
  };
};

export const convertMediationDataConfirm = (data) => {
  return {
    ...data,
    date: `${data.startYear}/${dateTimeFormat(
      data.startMonth
    )}/${dateTimeFormat(data.startDate)}`,
    applicationMethod: 1,
    ratePlan: convertRatePlan(data.electricWaterHeater),
    contractorPhoneNumberCategory: getPhoneNumberCategory(
      data.contractorPhoneNumberCategory,
      PAYER_PHONE_NUMBER_CATEGORY
    ),
    connectionWithApplicatedPerson: getConnectionWithAppicatedPerson(
      data.connectionWithApplicatedPerson
    ),
    applicatedPersonPhoneNumberCategory: getPhoneNumberCategory(
      data.applicatedPersonPhoneNumberCategory,
      REGISTER_PHONE_TYPES
    ),
    paymentAddressOptions: getPaymentAddressOptions(data.paymentAddressOptions),
    paymentPersonTelephoneOptions: getPaymentPersonTelephoneOptions(
      data.paymentPersonTelephoneOptions
    ),
    residentPaymentOptions: getResidentPaymentOptions(
      data.residentPaymentOptions
    ),
    gasServiceIntroduceOptions: getGasServiceIntroduceOptions(
      data.gasServiceIntroduceOptions
    ),
    kyudenGroupInternetIntroduceOptions: getKyudenGroupInternetIntroduceOptions(
      data.kyudenGroupInternetIntroduceOptions
    ),
    ...(data.paymentPhoneNumberCategory && {
      paymentPhoneNumberCategory: getPhoneNumberCategory(
        data.paymentPhoneNumberCategory,
        PAYER_PHONE_NUMBER_CATEGORY
      ),
    }),
  };
};

export const convertIntroduceDataConfirm = (data) => {
  return {
    ...data,
    date: `${data.startYear}/${dateTimeFormat(
      data.startMonth
    )}/${dateTimeFormat(data.startDate)}`,
    applicationMethod: 2,
    contractorPhoneNumberCategory: getPhoneNumberCategory(
      data.contractorPhoneNumberCategory,
      PAYER_PHONE_NUMBER_CATEGORY
    ),
    connectionWithApplicatedPerson: getConnectionWithAppicatedPerson(
      data.connectionWithApplicatedPerson
    ),
    applicatedPersonPhoneNumberCategory: getPhoneNumberCategory(
      data.applicatedPersonPhoneNumberCategory,
      REGISTER_PHONE_TYPES
    ),
    paymentAddressOptions: getPaymentAddressOptions(data.paymentAddressOptions),
    paymentPersonTelephoneOptions: getPaymentPersonTelephoneOptions(
      data.paymentPersonTelephoneOptions
    ),
    residentPaymentOptions: getResidentPaymentOptions(
      data.residentPaymentOptions
    ),
    gasServiceIntroduceOptions: getGasServiceIntroduceOptions(
      data.gasServiceIntroduceOptions
    ),
    kyudenGroupInternetIntroduceOptions: getKyudenGroupInternetIntroduceOptions(
      data.kyudenGroupInternetIntroduceOptions
    ),
    ...(data.paymentPhoneNumberCategory && {
      paymentPhoneNumberCategory: getPhoneNumberCategory(
        data.paymentPhoneNumberCategory,
        PAYER_PHONE_NUMBER_CATEGORY
      ),
    }),
  };
};

export const convertCreateRegistrationResident = (data) => {
  return {
    start_date: data.date,
    start_time: data.startTime,
    application_method: data.applicationMethod,
    ...(data.ratePlan && {
      rate_plan: data?.ratePlan?.value,
    }),
    contract_last_name_kanji: data.lastname,
    contract_first_name_kanji: data.firstname,
    contract_last_name_kana: data.lastnameKana,
    contract_first_name_kana: data.firstnameKana,
    contract_phone_number_section: data.contractorPhoneNumberCategory?.value,
    contract_phone_number1: data.contractorFirstPathNumbers,
    contract_phone_number2: data.contractorSecondPathNumbers,
    contract_phone_number3: data.contractorThirtPathNumbers,
    contract_relationship: data.connectionWithApplicatedPerson?.value,
    ...(data.connectionWithApplicatedPerson?.value !== 0 && {
      applicant_last_name_kanji: data.applicatedPersonLastname,
      applicant_first_name_kanji: data.applicatedPersonFirstname,
      applicant_phone_number_section:
        data.applicatedPersonPhoneNumberCategory?.value,
      applicant_phone_number1: data.applicatedFirstPathNumbers,
      applicant_phone_number2: data.applicatedSecondPathNumbers,
      applicant_phone_number3: data.applicatedThirtPathNumbers,
    }),
    payer_address_type: data.paymentAddressOptions?.value,
    ...(data.paymentAddressOptions?.value ===
      RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT && {
      payer_address_code: data.postDeliveryData?.addressCode,
      payer_post_code: data.postDeliveryData?.postCode,
      payer_address: data.postDeliveryData?.address,
      payer_block_number: data.deliveryDepartmentNumber,
      payer_building_name: data.deliveryDepartmentName?.length
        ? data.deliveryDepartmentName
        : null,
      payer_room_number: data.deliveryRoomNumber?.length
        ? data.deliveryRoomNumber
        : null,
    }),
    payer_phone_number_type: data.paymentPersonTelephoneOptions?.value,
    ...(data.paymentPersonTelephoneOptions?.value ===
      PAYMENT_PERSON_TELEPHONE_DEFAULT && {
      payer_phone_number_section: data.paymentPhoneNumberCategory?.value,
      payer_phone_number1: data.paymentFirstPathNumbers,
      payer_phone_number2: data.paymentSecondPathNumbers,
      payer_phone_number3: data.paymentThirtPathNumbers,
    }),
    payment_method: data.residentPaymentOptions?.value,
    gas_service_guidance: data.gasServiceIntroduceOptions?.value,
    optical_internet_guidance: data.kyudenGroupInternetIntroduceOptions?.value,
    room_id: data.roomId,
    print_id: data.printId,
  };
};
