import { get, post, put } from "../https/http.service";

export const searchAddress = (params) => {
  return get("common/address", params);
};

export const saveCompanyInfo = (params) => {
  return post("estate/company", params);
};

export const getCompanyInfo = (companyId) => {
  return get(`estate/company/${companyId}`);
};

export const updateCompanyInfo = (companyId, params) => {
  return put(`estate/company/${companyId}`, params);
};
