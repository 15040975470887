<template>
  <div class="styleguide">
    <h1>Styleguide</h1>
    <h2 class="title">Colors</h2>
    <div class="styleguide_colorItem">
      <div class="styleguide_color styleguide_color-white"></div>
      white: #e0eceb
      <br />
    </div>
    <div class="styleguide_colorItem">
      <div class="styleguide_color styleguide_color-white2"></div>
      white: #bcd5d1
      <br />
    </div>
    <div class="styleguide_colorItem">
      <div class="styleguide_color styleguide_color-primary"></div>
      primary: #58968b
      <br />
    </div>
    <div class="styleguide_colorItem">
      <div class="styleguide_color styleguide_color-default"></div>
      default: #FF5252
      <br />
    </div>
    <div class="styleguide_colorItem">
      <div class="styleguide_color styleguide_color-warning"></div>
      warning: #415278
      <br />
    </div>
    <div class="styleguide_colorItem">
      <div class="styleguide_color styleguide_color-error"></div>
      error: #d8942f
      <br />
    </div>

    <!-- Button -->
    <h2 class="title">Button (ButtonComponent)</h2>
    <div>width: Number | String</div>
    <div>hieght: Number | String</div>
    <div>buttonType: String (default, submit,... )</div>
    <div>type: String (default, outline)</div>
    <ButtonComponent>Default</ButtonComponent>
    <ButtonComponent type="outline">Outline</ButtonComponent>

    <!-- Card -->
    <h2 class="title">Card (CardComponent)</h2>
    <div>width: Number | String</div>
    <div>height: Number | String</div>
    <div>center: Boolean</div>
    <CardComponent :center="false" :width="'344px'" :height="'170px'">
      <v-card-header>
        <div>
          <div class="text-overline mb-1">OVERLINE</div>
          <div class="text-h6 mb-1">Headline</div>
          <div class="text-caption">
            Greyhound divisely hello coldly fonwderfully
          </div>
        </div>
      </v-card-header>

      <v-card-actions>
        <ButtonComponent>Default</ButtonComponent>
      </v-card-actions>
    </CardComponent>

    <h2 class="title">
      Table (TableRowComponent, TableHeaderComponent, TableCellComponent)
    </h2>
    <div class="table-container">
      <TableHeaderComponent>Header 1</TableHeaderComponent>
      <TableHeaderComponent>Header 2</TableHeaderComponent>
      <TableHeaderComponent>Header 3</TableHeaderComponent>
      <TableHeaderComponent>Header 4</TableHeaderComponent>
      <TableHeaderComponent>Header 5</TableHeaderComponent>
      <div class="table-row-group">
        <TableRowComponent v-for="item in dataTable" :key="item">
          <TableCellComponent>{{ item.cell_1 }}</TableCellComponent>
          <TableCellComponent>{{ item.cell_2 }}</TableCellComponent>
          <TableCellComponent>{{ item.cell_3 }}</TableCellComponent>
          <TableCellComponent>{{ item.cell_4 }}</TableCellComponent>
          <TableCellComponent>{{ item.cell_5 }}</TableCellComponent>
        </TableRowComponent>
      </div>
    </div>
    <div>
      <span class="icon-ico_fudosan2"></span>
      <span>icon-ico_fudosan2</span>
    </div>

    <div>
      <span class="icon-ico_close">
        <span class="path1"></span><span class="path2"></span>
      </span>
      <span>icon-ico_close</span>
    </div>

    <div>
      <span class="icon-ico_ijyo2">
        <span class="path1"></span><span class="path2"></span>
      </span>
      <span>icon-ico_ijyo2</span>
    </div>
    <div><span class="icon-ico_list"></span><span>icon-ico_list</span></div>

    <div><span class="icon-ico_search"></span><span>icon-ico_search</span></div>

    <div>
      <span class="icon-ico_seijyo">
        <span class="path1"></span>
        <span class="path2"></span>
      </span>
      <span>icon-ico_seijyo</span>
    </div>

    <div>
      <span class="icon-ico_user"></span>
      <span>icon-ico_user</span>
    </div>

    <div>
      <span class="icon-ico_warning">
        <span class="path1"></span>
        <span class="path2"></span><span class="path3"></span>
      </span>
      <span>icon-ico_warning</span>
    </div>

    <div>
      <span class="icon-ico_menu"></span>
      <span>icon-ico_menu</span>
    </div>
    <div>
      <span class="icon-ico_calendar"></span>
      <span>icon-ico_calendar</span>
    </div>

    <div>
      <span class="icon-ico_closedialog"></span>
      <span>icon-ico_closedialog</span>
    </div>

    <div>
      <span class="icon-ico_eye"></span>
      <span>icon-ico_eye</span>
    </div>

    <div>
      <span class="icon-ico_company"></span>
      <span>icon-ico_company</span>
    </div>

    <div>
      <span class="icon-ico_info"></span>
      <span>icon-ico_info</span>
    </div>

    <div>
      <span class="icon-ico_logout"></span>
      <span>icon-ico_logout</span>
    </div>
    <div>
      <span class="icon-ico_menu_arrow"
        ><span class="path1"></span>
        <span class="path2"></span>
        <span class="path3"></span>
      </span>
      <span>icon-ico_menu_arrow</span>
    </div>
    <div>
      <span class="icon-ico_menu_close"></span>
      <span>icon-ico_menu_close</span>
    </div>
    <div>
      <span class="icon-ico_mikanryo"></span>
      <span>icon-ico_mikanryo</span>
    </div>
    <div>
      <span class="icon-ico_newwindow"></span>
      <span>icon-ico_newwindow</span>
    </div>
    <div>
      <span class="icon-ico_password"></span>
      <span>icon-ico_password</span>
    </div>
    <div>
      <span class="icon-ico_password_open"></span>
      <span>icon-ico_password_open</span>
    </div>
    <div>
      <span class="icon-ico_next"></span>
      <span>icon-ico_next</span>
    </div>
    <div>
      <span class="icon-ico_prev"></span>
      <span>icon-ico_prev</span>
    </div>
    <div>
      <span class="icon-ico_riyo_haishi"></span>
      <span>icon-ico_riyo_haishi</span>
    </div>
    <div>
      <span class="icon-ico_search"></span>
      <span>icon-ico_search</span>
    </div>
    <div>
      <span class="icon-ico_upload"></span>
      <span>icon-ico_upload</span>
    </div>
    <div>
      <span class="icon-ico_uploadlist"></span>
      <span>icon-ico_uploadlist</span>
    </div>

    <div>
      <span class="icon-ico_code"></span>
      <span>icon-ico_code</span>
    </div>

    <div>
      <span class="icon-ico_guard"></span>
      <span>icon-ico_guard</span>
    </div>
    <div>
      <span class="icon-check"></span>
      <span>icon-check</span>
    </div>
    <div>
      <span class="icon-ico_room"></span>
      <span>icon-ico-room</span>
    </div>
    <div>
      <span class="icon-ico_lock"></span>
      <span>icon-ico_lock</span>
    </div>
    <div>
      <span class="icon-ico_key"></span>
      <span>icon-ico_key</span>
    </div>
    <div>
      <span class="icon-ico_sort_up"></span>
      <span>icon-ico_sort_up</span>
    </div>
    <div>
      <span class="icon-ico_sort_down"></span>
      <span>icon-ico_sort_down</span>
    </div>
    <div>
      <span class="icon-ico_sort_disable"></span>
      <span>icon-ico_sort_disable</span>
    </div>
    <div style="background: blue">
      <span class="icon-ico_plus_circle"></span>
      <span>icon-ico_plus_circle</span>
    </div>
    <div>
      <span class="icon-ico_notfound"
        ><span class="path1"></span><span class="path2"></span
        ><span class="path3"></span><span class="path4"></span
        ><span class="path5"></span><span class="path6"></span
        ><span class="path7"></span><span class="path8"></span
        ><span class="path9"></span><span class="path10"></span
        ><span class="path11"></span>
      </span>
      <span>icon-ico_notfound</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "StyleGuideComponent",
  data: () => ({
    dataTable: [
      {
        cell_1: "Cell 1",
        cell_2: "Cell 2",
        cell_3: "Cell 3",
        cell_4: "Cell 4",
        cell_5: "Cell 5",
      },
      {
        cell_1: "Cell 1",
        cell_2: "Cell 2",
        cell_3: "Cell 3",
        cell_4: "Cell 4",
        cell_5: "Cell 5",
      },
      {
        cell_1: "Cell 1",
        cell_2: "Cell 2",
        cell_3: "Cell 3",
        cell_4: "Cell 4",
        cell_5: "Cell 5",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.styleguide {
  padding: 30px;
  .title {
    margin-top: 10px;
  }
  &_color {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    &Item {
      display: flex;
      align-items: center;
    }
    &-white {
      background-color: #fff;
    }
    &-white2 {
      background-color: #f2f5f7;
    }
    &-primary {
      background-color: #4c7495;
    }
    &-default {
      background-color: #e0eceb;
    }
    &-warning {
      background-color: #d8942f;
    }
    &-error {
      background-color: #ff5252;
    }
  }
}
</style>
