import dayjs from "dayjs";
import { saveAs } from "file-saver";

import { FORTMAT_DATE_TIME } from "./../globals/enums";

const ExcelJS = require("exceljs");

export const openOrFocusTab = (url, name) => {
  window.open(url, name).focus();
};

/**
 * function check file
 * @param fileName string - file name
 */
export const checkExcelFile = (fileName) => {
  return fileName.match(/(.xls|.xlsx|.csv|.xlsm)/);
};

/**
 * function get content file xlsx xlsm file
 * @param file file
 */
export const readXlsxAndXlsm = (file) => {
  const wb = new ExcelJS.Workbook();
  const reader = new FileReader();

  reader.readAsArrayBuffer(file);
  reader.onload = () => {
    const buffer = reader.result;
    wb.xlsx.load(buffer).then((workbook) => {
      console.log(workbook, "workbook instance");
      workbook.eachSheet((sheet) => {
        sheet.eachRow((row, rowIndex) => {
          console.log(row.values, rowIndex);
        });
      });
    });
  };
};

/**
 * function save csv file
 * @param columns array object - column headers and define column keys and widths
 * @param data array object - data row
 * @param fileName string - export filename
 */
export const exportCSV = (columns = [], data = [], fileName = "") => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet();
  worksheet.columns = columns;
  data.forEach((item) => {
    for (const property in item) {
      if (item[property] && typeof item[property] === "string") {
        item[property] = item[property].replace(/[\r\n]+/g, " "); // 改行コードを半角スペースに変換
      }
    }
    worksheet.addRow(item);
  });
  // save file to local
  workbook.csv
    .writeBuffer()
    .then((data) => {
      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, data], {
        type: "text/csv",
      });
      saveAs(
        blob,
        `${fileName}_${dayjs().format(FORTMAT_DATE_TIME.EXPORT_DATETIME)}.csv`
      );
    })
    .catch((err) => {
      console.log(err);
    });
};
