import ButtonComponent from "@/components/common/ButtonComponent.vue";
import CardComponent from "@/components/common/CardComponent.vue";
import InputComponent from "@/components/common/InputComponent.vue";
import FooterComponent from "@/components/common/FooterComponent.vue";
import TitleComponent from "@/components/common/TitleComponent.vue";
import TableRowComponent from "@/components/common/TableRowComponent";
import TableHeaderComponent from "@/components/common/TableHeaderComponent";
import TableCellComponent from "@/components/common/TableCellComponent";
import IconCheckComponent from "@/components/common/IconCheckComponent.vue";
import HeaderComponent from "@/components/common/HeaderComponent.vue";
import TelephoneInputComponent from "@/components/common/TelephoneInputComponent.vue";
import OverheadComponent from "@/components/common/OverheadComponent.vue";
import GroupContentComponent from "@/components/common/GroupContentComponent.vue";
import DatePickerComponent from "@/components/common/DatePickerComponent.vue";
import DateTimeInputGroupComponent from "@/components/common/DateTimeInputGroupComponent.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import WarningDialog from "@/components/dialogs/WarningDialog.vue";
import RegistrationElectricRoomDialog from "@/components/dialogs/RegistrationElectricRoomDialog.vue";
import PaginationComponent from "@/components/common/PaginationComponent.vue";
import SearchAddressDialog from "@/components/dialogs/SearchAddressDialog.vue";
import CompanyEditTabComponent from "@/components/company/CompanyEditTabComponent.vue";
import CompanyPreviewTabComponent from "@/components/company/CompanyPreviewTabComponent.vue";
import CompanySmallEditTabComponent from "@/components/company/CompanySmallEditTabComponent.vue";
import CompanyEditSuccessComponent from "@/components/company/CompanyEditSuccessComponent.vue";
import SelectComponent from "@/components/common/SelectComponent.vue";
import AddressFormComponent from "@/components/address/AddressFormComponent.vue";
import StatusComponent from "@/components/common/StatusComponent.vue";
import ShortTermRegistrationPreviewComponent from "@/components/shortTermRegistration/ShortTermRegistrationPreviewComponent.vue";
import ShortTermRegistrationConfirmComponent from "@/components/shortTermRegistration/ShortTermRegistrationConfirmComponent.vue";
import MediationFormComponent from "@/components/residentRegistration/MediationFormComponent.vue";
import MediationFormConfirmComponent from "@/components/residentRegistration/MediationFormConfirmComponent.vue";
import IntroduceFormComponent from "@/components/residentRegistration/IntroduceFormComponent.vue";
import ResidentRoomInfoComponent from "@/components/residentRegistration/ResidentRoomInfoComponent.vue";
import AddressSmallFormComponent from "@/components/address/AddressFormSmallComponent.vue";
import StatusRegistrationComponent from "@/components/common/StatusRegistrationComponent";
import LoaderComponent from "@/components/common/LoaderComponent";
import ErrorDetailComponent from "@/components/common/ErrorDetailComponent";
import InputHalfWidthComponent from "@/components/common/InputHalfWidthComponent";
export default {
  install: (app) => {
    app
      .component("ButtonComponent", ButtonComponent)
      .component("CardComponent", CardComponent)
      .component("InputComponent", InputComponent)
      .component("HeaderComponent", HeaderComponent)
      .component("FooterComponent", FooterComponent)
      .component("TitleComponent", TitleComponent)
      .component("TableRowComponent", TableRowComponent)
      .component("TableHeaderComponent", TableHeaderComponent)
      .component("TableCellComponent", TableCellComponent)
      .component("TelephoneInputComponent", TelephoneInputComponent)
      .component("OverheadComponent", OverheadComponent)
      .component("IconCheckComponent", IconCheckComponent)
      .component("ConfirmDialog", ConfirmDialog)
      .component(
        "RegistrationElectricRoomDialog",
        RegistrationElectricRoomDialog
      )
      .component(
        "ShortTermRegistrationPreviewComponent",
        ShortTermRegistrationPreviewComponent
      )
      .component(
        "ShortTermRegistrationConfirmComponent",
        ShortTermRegistrationConfirmComponent
      )
      .component("MediationFormComponent", MediationFormComponent)
      .component("MediationFormConfirmComponent", MediationFormConfirmComponent)
      .component("IntroduceFormComponent", IntroduceFormComponent)
      .component("ResidentRoomInfoComponent", ResidentRoomInfoComponent)
      .component("GroupContentComponent", GroupContentComponent)
      .component("PaginationComponent", PaginationComponent)
      .component("SearchAddressDialog", SearchAddressDialog)
      .component("CompanyEditTabComponent", CompanyEditTabComponent)
      .component("CompanyPreviewTabComponent", CompanyPreviewTabComponent)
      .component("CompanySmallEditTabComponent", CompanySmallEditTabComponent)
      .component("CompanyEditSuccessComponent", CompanyEditSuccessComponent)
      .component("AddressFormComponent", AddressFormComponent)
      .component("AddressSmallFormComponent", AddressSmallFormComponent)
      .component("DatePickerComponent", DatePickerComponent)
      .component("DateTimeInputGroupComponent", DateTimeInputGroupComponent)
      .component("SelectComponent", SelectComponent)
      .component("WarningDialog", WarningDialog)
      .component("StatusComponent", StatusComponent)
      .component("StatusRegistrationComponent", StatusRegistrationComponent)
      .component("LoaderComponent", LoaderComponent)
      .component("ErrorDetailComponent", ErrorDetailComponent)
      .component("InputHalfWidthComponent", InputHalfWidthComponent);
  },
};
