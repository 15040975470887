import * as Yup from "yup";
import {
  validateStringRequired,
  validateCompanyName,
  validateCompanyKanaName,
  validateAddressForm,
  validateTelephoneGroup,
  validateMailbox,
  validateSelectNumber,
} from "../common";
import {
  COMPANY_REGISTER_CONTRACT_TYPES,
  PAYMENT_OPTIONS_DEFAULT_VALUE,
  TRANSFER_ADDRESS_OPTIONS_DEFAULT,
} from "@/globals/enums";
import { ERROR_MESSAGES } from "@/globals/error-messages";
import { isFullWidth } from "@/directives/validations";

export const companyCreateSchema = Yup.object().shape({
  companyName: validateCompanyName,
  companyKanaName: validateCompanyKanaName,
  postOfficeNumber: validateAddressForm.postOfficeNumber,
  addressDepartmentNumber: validateAddressForm.addressDepartmentNumber,
  addressDepartmentName: validateAddressForm.addressDepartmentName,
  addressRoomNumber: validateAddressForm.addressRoomNumber,
  mailbox: validateMailbox,
  firstPathNumbers: validateTelephoneGroup.firstPathTelephone(
    "firstPathNumbers",
    "secondPathNumbers",
    "thirtPathNumbers"
  ),
  secondPathNumbers: validateTelephoneGroup.secondPathTelephone(
    "firstPathNumbers",
    "secondPathNumbers",
    "thirtPathNumbers"
  ),
  thirtPathNumbers: validateTelephoneGroup.lastPathTelephone(
    "firstPathNumbers",
    "secondPathNumbers",
    "thirtPathNumbers"
  ),
  phoneNumberCategory: validateSelectNumber(0),
  electricContractAppType: Yup.number().when("electricContractApp", {
    is: COMPANY_REGISTER_CONTRACT_TYPES.HAS_REGISTER_CONTRACT.VALUE,
    then: validateSelectNumber(0),
    otherwise: Yup.number(),
  }),
  bankName: Yup.string()
    .when("paymentOptions", {
      is: PAYMENT_OPTIONS_DEFAULT_VALUE,
      then: validateStringRequired.max(
        20,
        ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 20)
      ),
      otherwise: Yup.string(),
    })
    .test(
      "isFullWidth",
      ERROR_MESSAGES.PLEASE_INPUT_VALID_FULL_WIDTH,
      (value) => isFullWidth(value)
    ),
  bankDepartment: Yup.string()
    .when("paymentOptions", {
      is: PAYMENT_OPTIONS_DEFAULT_VALUE,
      then: validateStringRequired.max(
        20,
        ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 20)
      ),
      otherwise: Yup.string(),
    })
    .test(
      "isFullWidth",
      ERROR_MESSAGES.PLEASE_INPUT_VALID_FULL_WIDTH,
      (value) => isFullWidth(value)
    ),
  accountType: Yup.number().when("paymentOptions", {
    is: PAYMENT_OPTIONS_DEFAULT_VALUE,
    then: validateSelectNumber(0),
    otherwise: Yup.number(),
  }),
  departmentNumber: Yup.string().when("paymentOptions", {
    is: PAYMENT_OPTIONS_DEFAULT_VALUE,
    then: validateStringRequired.max(
      3,
      ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 3)
    ),
    otherwise: Yup.string(),
  }),
  accountNumber: Yup.string().when("paymentOptions", {
    is: PAYMENT_OPTIONS_DEFAULT_VALUE,
    then: validateStringRequired.max(
      7,
      ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 7)
    ),
    otherwise: Yup.string(),
  }),
  accountName: Yup.string()
    .when("paymentOptions", {
      is: PAYMENT_OPTIONS_DEFAULT_VALUE,
      then: validateStringRequired.max(
        30,
        ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 30)
      ),
      otherwise: Yup.string(),
    })
    .test(
      "isFullWidth",
      ERROR_MESSAGES.PLEASE_INPUT_VALID_FULL_WIDTH,
      (value) => isFullWidth(value)
    ),
  postDeliveryNumberName: Yup.string().when(
    ["paymentOptions", "transferAddressOptions"],
    {
      is: (paymentOptions, transferAddressOptions) =>
        paymentOptions === 1 &&
        transferAddressOptions === TRANSFER_ADDRESS_OPTIONS_DEFAULT,
      then: validateAddressForm.postOfficeNumber,
      otherwise: Yup.string(),
    }
  ),
  deliveryDepartmentNumber: Yup.string().when(
    ["paymentOptions", "transferAddressOptions"],
    {
      is: (paymentOptions, transferAddressOptions) =>
        paymentOptions === 1 &&
        transferAddressOptions === TRANSFER_ADDRESS_OPTIONS_DEFAULT,
      then: validateAddressForm.addressDepartmentNumber,
      otherwise: Yup.string(),
    }
  ),
  deliveryDepartmentName: Yup.string().when(
    ["paymentOptions", "transferAddressOptions"],
    {
      is: (paymentOptions, transferAddressOptions) =>
        paymentOptions === 1 &&
        transferAddressOptions === TRANSFER_ADDRESS_OPTIONS_DEFAULT,
      then: validateAddressForm.addressDepartmentName,
      otherwise: Yup.string(),
    }
  ),
  deliveryRoomNumber: Yup.string().when(
    ["paymentOptions", "transferAddressOptions"],
    {
      is: (paymentOptions, transferAddressOptions) =>
        paymentOptions === 1 &&
        transferAddressOptions === TRANSFER_ADDRESS_OPTIONS_DEFAULT,
      then: validateAddressForm.addressRoomNumber,
      otherwise: Yup.string(),
    }
  ),
});
