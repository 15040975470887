<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="ResultRegistrationDialog modal-content"
  >
    <span class="modal__title"> {{ title }}</span>
    <div>{{ content }}</div>
    <div class="modal__title-box">受付不可理由</div>
    <div class="modal__box">
      {{ message }}
    </div>
    <div class="modal__action">
      <ButtonComponent
        type="outline"
        width="150px"
        height="50px"
        @click="
          close();
          onClose();
        "
      >
        閉じる<span v-if="isDetail" class="child-content">1*</span>
      </ButtonComponent>
      <ButtonComponent
        v-if="isDetail"
        width="150px"
        height="50px"
        @click="onApprove()"
      >
        確認済み
        <span v-if="isDetail" class="child-content">2*</span>
      </ButtonComponent>
    </div>
    <div v-if="isDetail" class="modal__footer">
      <p>1*確認済みとせずに閉じる</p>
      <p>
        2*確認済みとして閉じることで、受付不可対象数のカウントから除外します
      </p>
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  name: "ResultRegistrationDialog",
  props: {
    title: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onChoise: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    onApprove() {
      this.onChoise(true);
      this.$vfm.hideAll();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.ResultRegistrationDialog) {
  &.modal {
    &-content {
      position: relative;
      background-color: #fefefe;
      margin: auto;
      padding: 40px 50px 29px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      border: 1px solid #888;
      width: 560px;
      height: 384px;
      border-radius: 5px;
      .modal {
        &__title {
          font-weight: bold;
          text-align: center;
          margin-bottom: 20px;
          display: inline-flex;
          .icon-ico_info {
            margin-right: 5px;
            font-size: 25px;
          }
        }
        &__title-box {
          width: 460px;
          text-align: left;
          color: var(--venice-blue-color);
          font-weight: bold;
          font-size: 15px;
        }
        &__box {
          width: 460px;
          height: 80px;
          border: solid 1px #d0d9df;
          background-color: #f3f6f7;
          text-align: left;
          font-size: 14px;
          padding: 15px;
        }
        &__close {
          position: absolute;
          top: 10px;
          right: 20px;
          color: var(--color-gray);
          font-size: 20px;
        }
        &__action {
          display: flex;
          gap: 20px;
          margin-top: 30px;
          .ButtonComponent {
            .child-content {
              font-size: 10px;
              position: relative;
              top: -10px;
              right: -10px;
            }
          }
        }
        &__footer {
          font-size: 12px;
          text-align: left;
          margin-top: 10px;
        }
      }
    }
  }
}
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
