export const convertYearMonthDateToDate = (year, month, day) => {
  return new Date(year, month, day, 0, 0, 0, 0);
};

export const convertDateToYearMonthDate = (stringDate) => {
  const date = new Date(stringDate);
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    date: date.getDate(),
  };
};

export const addMonths = (datetime, month) => {
  const currentMaxDate = new Date(
    datetime.setDate(datetime.getDate() + month * 30)
  );
  return currentMaxDate;
};

export const getYearWithAdditionalMonth = (additionalMonth) => {
  const futureDate = addMonths(new Date(), additionalMonth).toString();
  return new Date(futureDate).getFullYear();
};

const dateTimeFormat = (value) => {
  return value && String(value).length === 1 ? `0${value}` : value;
};

export const convertToYYYYMMDD = (datetime) => {
  return `${datetime.getFullYear()}/${dateTimeFormat(
    datetime.getMonth() + 1
  )}/${dateTimeFormat(datetime.getDate())}`;
};

export const parseDateChoise = (
  dateStartCheck,
  holidateList,
  isFindOnlyFirstDate
) => {
  let firstDateInvalid = true;
  const dateString = new Date(dateStartCheck).toLocaleDateString();
  let findingFirstWorkDate = new Date(dateString);
  let i = 1;
  while (firstDateInvalid) {
    let addDate = findingFirstWorkDate.getDate() + i;
    findingFirstWorkDate.setDate(addDate);
    if (
      findingFirstWorkDate.getDay() !== 6 &&
      findingFirstWorkDate.getDay() !== 0
    ) {
      const formatedDate = convertToYYYYMMDD(findingFirstWorkDate);
      if (!holidateList.includes(formatedDate)) {
        firstDateInvalid = false;
      }
    }
  }
  if (isFindOnlyFirstDate) {
    return findingFirstWorkDate;
  }

  let findingSecondWorkDate = findingFirstWorkDate;
  let secondDateInValid = true;
  let j = 1;
  while (secondDateInValid) {
    let addDate = findingSecondWorkDate.getDate() + j;
    findingSecondWorkDate.setDate(addDate);
    if (
      findingSecondWorkDate.getDay() !== 6 &&
      findingSecondWorkDate.getDay() !== 0
    ) {
      const formatedDate = convertToYYYYMMDD(findingSecondWorkDate);
      if (!holidateList.includes(formatedDate)) {
        secondDateInValid = false;
      }
    }
  }
  return findingSecondWorkDate;
};
