<template>
  <div class="ForgotPasswordComponent">
    <Form
      @submit="sendCodeToEmail"
      :validation-schema="schema"
      v-slot="{ meta }"
    >
      <CardComponent
        class="form-forgotPassword"
        :width="'960px'"
        :height="'550px'"
      >
        <TitleComponent class="form-forgotPassword_title" :hasUnderLine="true">
          <template v-slot:content>認証コード発行</template>
        </TitleComponent>
        <p class="form-forgotPassword_description">
          パスワードを再発行するために、認証コードをお送りします。<br />
          認証コードをお送りするためのメールアドレスを入力してください。
        </p>

        <div class="form-forgotPassword_inputGroups">
          <InputComponent name="mail" placeholder="メールアドレス" />
        </div>
        <div class="form-action-group">
          <ButtonComponent
            buttonType="button"
            type="outline"
            width="160px"
            @click="(e) => cancel(e)"
          >
            キャンセル
          </ButtonComponent>
          <ButtonComponent
            buttonType="submit"
            width="160px"
            :disabled="!(meta.valid && meta.dirty)"
          >
            送信
          </ButtonComponent>
        </div>
      </CardComponent>
    </Form>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import LoginService from "@/services/login.service";
import { forgotPasswordSchema } from "@/validations/schemas/forgotPasswordSchema";

export default {
  name: "ForgotPasswordComponent",
  components: {
    Form,
  },
  data: () => ({
    loginService: new LoginService(),
    schema: forgotPasswordSchema,
  }),
  methods: {
    async sendCodeToEmail(data) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      await this.loginService.forgotPassword(data.mail);
      this.$store.dispatch("global/updateLoadingStatus", false);
    },
    async cancel(e) {
      e.preventDefault();
      await this.loginService.logout();
    },
  },
};
</script>

<style scoped lang="scss">
.ForgotPasswordComponent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    &-forgotPassword {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 30px;
      &_title {
        font-size: 20px;
        font-weight: 500;
      }
      &_description {
        font-size: 14px;
        font-weight: 500;
        margin: 20px 0 40px;
        text-align: center;
      }
      &_inputGroups {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 0 220px 40px;
      }
      &-inputGroup {
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: space-between;
        &_title {
          font-size: 14px;
          font-weight: bold;
          color: #7c8185;
        }
        .InputComponent {
          margin: 0;
        }
      }
    }
    &-action-group {
      display: flex;
      gap: 20px;
    }
  }
}
</style>
