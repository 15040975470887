<template>
  <div class="RegistrationComponent">
    <Form
      @submit="createNewPassword"
      :validation-schema="schema"
      v-slot="{ meta }"
    >
      <CardComponent
        class="form-registration"
        :width="'960px'"
        :height="'550px'"
      >
        <TitleComponent class="form-registration_title" :hasUnderLine="true">
          <template v-slot:content>パスワード設定</template>
        </TitleComponent>
        <p class="form-registration-description">
          今後使用するパスワードを設定してください。
        </p>

        <div class="form-registration-inputGroups">
          <div class="form-registration-inputGroup"></div>
          <div class="form-registration-inputGroup">
            <span class="form-registration-inputGroup_title"
              >新しいパスワード</span
            >
            <InputComponent
              name="password"
              type="password"
              maxlength="256"
              :validateSteps="validateSteps"
              placeholder="パスワード"
            />
          </div>
          <div class="form-registration-inputGroup">
            <span class="form-registration-inputGroup_title"
              >新しいパスワード<br />（再入力）</span
            >
            <InputComponent
              name="passwordConfirmation"
              type="password"
              maxlength="256"
              placeholder="新しいパスワードを再入力"
            />
          </div>
        </div>
        <ButtonComponent
          buttonType="submit"
          :width="'160px'"
          :disabled="!(meta.valid && meta.dirty)"
          >登録
        </ButtonComponent>
      </CardComponent>
    </Form>
  </div>
</template>

<script>
import { SPECIAL_STRING, VALIDATES, MIN_8_CHARACTER } from "@/globals/enums";
import { Form } from "vee-validate";
import { mapState } from "vuex";
import LoginService from "@/services/login.service";
import { registrationPasswordSchema } from "@/validations/schemas/registrationPasswordSchema";

export default {
  name: "RegistrationComponent",
  components: {
    Form,
  },
  data: () => ({
    loginService: new LoginService(),
    validateSteps: [
      {
        message: SPECIAL_STRING.OVER_8_CHARACTERS,
        check: (data) => data.length >= MIN_8_CHARACTER,
      },
      {
        message: SPECIAL_STRING.NUMBER,
        check: (data) => VALIDATES.HAS_NUMBER.test(data),
      },
      {
        message: SPECIAL_STRING.UPPER_DOWNER_CHARACTERS,
        check: (data) =>
          VALIDATES.HAS_DOWNER_CHARACTER.test(data) ||
          VALIDATES.HAS_UPPER_CHARACTER.test(data),
      },
    ],
    schema: registrationPasswordSchema,
  }),
  computed: mapState({
    tempUser: (state) => state.settings.tempUser,
  }),
  methods: {
    createNewPassword(value) {
      this.loginService.signIn(
        this.tempUser.emailAddress,
        this.tempUser.tempPassword,
        value.password
      );
    },
  },
};
</script>

<style scoped lang="scss">
.RegistrationComponent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    &-registration {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 30px;
      &_title {
        font-size: 20px;
        font-weight: 500;
      }
      &-description {
        font-size: 14px;
        font-weight: 500;
        margin: 20px 0 0;
      }
      &-inputGroups {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 0 220px 40px;
      }
      &-inputGroup {
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: space-between;
        &_title {
          font-size: 14px;
          // font-weight: bold;
          color: #7c8185;
        }
        .InputComponent {
          margin: 0;
        }
      }
    }
  }
}
</style>
