import settings from "./settings";
import company from "./company";
import building from "./building";
import registrationShortTerm from "./registrationShortTerm";
import global from "./global";
export default {
  settings,
  company,
  building,
  registrationShortTerm,
  global,
};
