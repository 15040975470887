<template>
  <div class="LoginComponent">
    <div class="LoginComponent__content">
      <div class="LoginComponent__content_header">
        <img :src="require('@/assets/images/logo_main.png')" />
        <p>管理物件電気受付サービス</p>
      </div>
      <div class="space" />
      <Form @submit="login" :validation-schema="schema" v-slot="{ meta }">
        <CardComponent
          class="form-login"
          :width="'480px'"
          :height="errorDetail ? '450px' : '415px'"
        >
          <TitleComponent class="form-login_title">
            <template v-slot:content>ログイン</template>
          </TitleComponent>
          <InputComponent
            name="email"
            type="email"
            :bigBorder="true"
            placeholder="メールアドレス"
            leftIcon="icon-ico_user"
            maxlength="254"
            @trigle-change="
              () => {
                errorDetail = null;
              }
            "
          />
          <InputComponent
            name="password"
            type="password"
            :bigBorder="true"
            placeholder="パスワード"
            leftIcon="icon-ico_key"
            maxlength="256"
            @trigle-change="
              () => {
                errorDetail = null;
              }
            "
          />
          <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
          <ButtonComponent
            buttonType="submit"
            :width="'360px'"
            :disabled="!(meta.valid && meta.touched)"
            >ログイン
          </ButtonComponent>
          <div class="text-caption-forget-password" @click="forgotPassword()">
            パスワードをお忘れですか？
          </div>
        </CardComponent>
      </Form>
    </div>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import LoginService from "@/services/login.service";
import UserService from "@/services/user.service";
import { loginSchema } from "@/validations/schemas/loginSchema";
import { STATUS } from "@/globals/enums";
export default {
  name: "LoginComponent",
  components: {
    Form,
  },
  data: () => ({
    loginService: new LoginService(),
    userService: new UserService(),
    schema: loginSchema,
    errorDetail: null,
  }),
  methods: {
    async login(userInfo) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.loginService.signIn(
        userInfo.email,
        userInfo.password
      );
      this.$store.dispatch("global/updateLoadingStatus", false);
      if (result?.status === STATUS.ERROR && result?.response?.detail) {
        this.errorDetail = result.response.detail;
      }
    },
    forgotPassword() {
      this.$router.push({ name: "ForgotPasswordComponent" });
    },
  },
};
</script>

<style scoped lang="scss">
.LoginComponent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &__content {
    display: flex;
    flex-direction: column;
    &_header {
      display: flex;
      gap: 12px;
      align-items: center;
      justify-content: center;
      p {
        font-size: 28px;
        font-weight: bold;
        line-height: 0.43;
        letter-spacing: normal;
        text-align: left;
        color: #294f6e;
      }
    }
    .space {
      width: 100px;
      height: 4px;
      background-color: #8d9caa;
      border-radius: 10px;
      margin: 16px auto 30px;
    }
    .form {
      &-login {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        &_title {
          font-weight: bold;
          font-size: 25px;
        }
        .InputComponent {
          margin-bottom: 10px;
        }
      }
    }
  }
  .text-caption-forget-password {
    cursor: pointer;
    margin-bottom: 12px;
    font-size: 12px;
    font-stretch: normal;
  }
}
</style>
