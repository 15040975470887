import { post, put, get, del } from "../https/http.service";

export const createRegistration = (params) => {
  return post("estate/registration/room", params);
};

export const getRegistrationList = (params) => {
  return get("estate/registration/list", params);
};

export const editRegistration = (id, params) => {
  return put(`estate/registration/room/${id}`, params);
};

export const deleteRegistration = (params) => {
  return del(`estate/registration/room`, params);
};

export const updateRegistration = (params) => {
  return post(`estate/registration/list`, params);
};

export const getHistoryRegistration = (params) => {
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });
  return get("estate/registration/history", params);
};

export const getResultRegistration = (id) => {
  return get(`estate/registration/result/${id}`);
};

export const updateResultRegistration = (id) => {
  return put(`estate/registration/result/${id}`);
};
