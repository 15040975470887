import axios from "../axios/axios.service";
import ConfirmDialogService from "@/services/dialog/confirm-dialog.service";
// import LoginService from "../login.service";
import { REGEX_5XX, STATUS_403, STATUS_409, STATUS_404 } from "@/globals/enums";
import { ERROR_MESSAGES_API } from "@/globals/error-messages";
import router from "../../router/router";
import LoginService from "../login.service";
import { $vfm } from "vue-final-modal";
const confirmDialogService = new ConfirmDialogService();
const loginService = new LoginService();
const baseDomain = process.env.VUE_APP_API_HTTP;

const options = {
  headers: {
    "Content-Type": "application/json",
  },
};

/**
 * Http GET
 */
export async function get(
  url,
  params = {},
  autoHandleError = true,
  exceptHandleErrorStatus = []
) {
  const searchParams = new URLSearchParams();
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      value.forEach((val) => {
        searchParams.append(key, val);
      });
    } else if (value !== null) {
      searchParams.append(key, value);
    }
  }
  const newOptions = {
    ...options,
    params: searchParams,
  };
  try {
    const requestURL = `${baseDomain}${url}`;
    const result = await axios.get(requestURL, newOptions);
    return result.data;
  } catch (error) {
    throw errorWrapper(error, autoHandleError, exceptHandleErrorStatus);
  }
}

/**
 * Http POST
 */
export async function post(
  url,
  params = {},
  autoHandleError = true,
  exceptHandleErrorStatus = []
) {
  const newOptions = {
    ...options,
  };

  try {
    const requestURL = `${baseDomain}${url}`;
    const result = await axios.post(requestURL, params, newOptions);
    return result.data;
  } catch (error) {
    throw errorWrapper(error, autoHandleError, exceptHandleErrorStatus);
  }
}

/**
 * Http PUT
 */
export async function put(
  url,
  params = {},
  autoHandleError = true,
  exceptHandleErrorStatus = []
) {
  const newOptions = {
    ...options,
  };

  try {
    const requestURL = `${baseDomain}${url}`;
    const result = await axios.put(requestURL, params, newOptions);
    return result.data;
  } catch (error) {
    throw errorWrapper(error, autoHandleError, exceptHandleErrorStatus);
  }
}

/**
 * Http UPLOAD
 */
export async function upload(
  url,
  params = {},
  autoHandleError = true,
  exceptHandleErrorStatus = []
) {
  const newOptions = {
    ...options,
  };

  try {
    const requestURL = `${url}`;
    const result = await axios.put(requestURL, params, newOptions);
    return result.data;
  } catch (error) {
    throw errorWrapper(error, autoHandleError, exceptHandleErrorStatus);
  }
}

/**
 * Http DELETE
 */
export async function del(
  url,
  params = {},
  autoHandleError = true,
  exceptHandleErrorStatus = []
) {
  const newOptions = {
    ...options,
    params,
  };

  try {
    const requestURL = `${baseDomain}${url}`;
    const result = await axios.delete(requestURL, newOptions);
    return result.data;
  } catch (error) {
    throw errorWrapper(error, autoHandleError, exceptHandleErrorStatus);
  }
}

const is500ErrorStatus = (status) => {
  return REGEX_5XX.test(status);
};

const is403ErrorStatus = (status) => {
  return status === STATUS_403;
};

const is404ErrorStatus = (status) => {
  return status === STATUS_404;
};

const is409ErrorStatus = (status) => {
  return status === STATUS_409;
};

/**
 * Http Post by token id
 */
export function errorWrapper(error) {
  const errorData = {
    status: error.response.status,
    code: error.response?.code,
    message: error.message || "Error happened!",
  };

  if (is500ErrorStatus(error.response.status)) {
    confirmDialogService.openConfirmDialog(
      ERROR_MESSAGES_API.HTTP_ERROR_500.message,
      ERROR_MESSAGES_API.HTTP_ERROR_500.detail,
      () => router.push({ name: "HomeComponent" })
    );
  }

  if (is403ErrorStatus(error.response.status) && $vfm.modals.length === 0) {
    confirmDialogService.openConfirmDialog(
      ERROR_MESSAGES_API.HTTP_ERROR_403.message,
      ERROR_MESSAGES_API.HTTP_ERROR_403.detail,
      () =>
        error.response?.data?.code === "COMMON-ACCOUNT_ERROR"
          ? loginService.logout()
          : router.push({ name: "HomeComponent" }),
      "modal-403"
    );
  }

  if (
    is404ErrorStatus(error.response.status) &&
    error.response?.data?.code === "CUSTOM-RECORD_NOT_FOUND_ERROR"
  ) {
    confirmDialogService.openConfirmDialog(
      ERROR_MESSAGES_API.HTTP_ERROR_404.message,
      ERROR_MESSAGES_API.HTTP_ERROR_404.detail,
      () => router.push({ name: "HomeComponent" })
    );
  }

  if (is409ErrorStatus(error.response.status)) {
    return {
      ...errorData,
      response: error.response?.data,
    };
  }

  return {
    ...errorData,
  };
}
