<template>
  <div class="IntroduceFormComponent">
    <TitleComponent class="IntroduceFormComponent-title">
      <template v-slot:icon>
        <span class="icon-ico_riyo_haishi"></span>
      </template>
      <template v-slot:content> 入居者情報入力 </template>
    </TitleComponent>
    <CardComponent class="main" :width="'960px'" :height="'auto'">
      <Form
        @submit="onSubmit"
        v-slot="{ setFieldValue, meta, values, validateField }"
        :validation-schema="schema"
        autocomplete="off"
      >
        <div class="group">
          <div class="group-title">利用期間設定</div>
          <GroupContentComponent :required="true" v-if="startTime">
            <template v-slot:title> 電気ご利用開始日 </template>
            <template v-slot:content>
              <DateTimeInputGroupComponent
                :yearOptions="yearOptions"
                :monthOptions="monthOptions"
                :dateOptions="dateOptions"
                yearNameSelect="startYear"
                monthNameSelect="startMonth"
                dateNameSelect="startDate"
                timeNameSelect="startTime"
                :initYear="0"
                :initMonth="0"
                :initDate="0"
                :setFieldValue="setFieldValue"
                :isDisplayTime="false"
                :disabledList="[]"
                :validateField="validateField"
                :minDateSearch="startTime.all"
              />
            </template>
          </GroupContentComponent>
        </div>
        <div class="group">
          <div class="group-title">ご契約者情報</div>
          <GroupContentComponent :required="true">
            <template v-slot:title>
              <div class="twoline-title">
                <span class="firstline">お名前</span><br /><span
                  class="secondline"
                  >（ご契約名義）</span
                >
              </div>
            </template>
            <template v-slot:content>
              <div class="two-input-content">
                <div class="input-content">
                  <InputComponent
                    :small="true"
                    name="firstname"
                    placeholder="山田"
                    maxlength="35"
                    inputFilter="fullWidth"
                  />
                </div>
                <div class="input-content">
                  <InputComponent
                    :small="true"
                    name="lastname"
                    placeholder="美由紀"
                    maxlength="35"
                    inputFilter="fullWidth"
                  />
                </div>
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent :required="true">
            <template v-slot:title> フリガナ </template>
            <template v-slot:content>
              <div class="two-input-content">
                <div class="input-content">
                  <InputComponent
                    :small="true"
                    name="firstnameKana"
                    placeholder="セイ"
                    inputFilter="halfWidth"
                    maxlength="17"
                  />
                </div>
                <div class="input-content">
                  <InputComponent
                    :small="true"
                    name="lastnameKana"
                    placeholder="メイ"
                    inputFilter="halfWidth"
                    maxlength="17"
                  />
                </div>
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent :required="true">
            <template v-slot:title> ご契約者電話番号 </template>
            <template v-slot:content>
              <div class="telephoneGroup">
                <TelephoneInputComponent
                  :groupInput="contractorPhoneNumberOptions"
                />
                <SelectComponent
                  name="contractorPhoneNumberCategory"
                  :options="payerPhoneNumberOptions"
                  placeholder="電話番号区分を選択"
                />
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent>
            <template v-slot:title>
              お申込み者と<br />契約名義人の関係
            </template>
            <template v-slot:content>
              <SelectComponent
                name="connectionWithApplicatedPerson"
                :initialValue="CONNECTION_WITH_APPLICATED_PERSON_DEFAULT"
                :options="connectionWithApplicatedPerson"
                :isSmall="true"
                @trigle-change="handleConectionWithApplicatedPerson"
              />
            </template>
          </GroupContentComponent>
          <GroupContentComponent
            :required="true"
            v-show="connectionWithAppicationPerson !== 0"
          >
            <template v-slot:title> お名前（お申込者） </template>
            <template v-slot:content>
              <div class="two-input-content">
                <div class="input-content">
                  <InputComponent
                    :small="true"
                    name="applicatedPersonFirstname"
                    placeholder="山田"
                    inputFilter="fullWidth"
                    maxlength="35"
                  />
                </div>
                <div class="input-content">
                  <InputComponent
                    :small="true"
                    name="applicatedPersonLastname"
                    placeholder="美由紀"
                    inputFilter="fullWidth"
                    maxlength="35"
                  />
                </div>
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent
            :required="true"
            v-show="connectionWithAppicationPerson !== 0"
          >
            <template v-slot:title> お申込み者<br />連絡先電話番号 </template>
            <template v-slot:content>
              <div class="telephoneGroup">
                <TelephoneInputComponent
                  :groupInput="applicatedPersonNumberOptions"
                />
                <SelectComponent
                  name="applicatedPersonPhoneNumberCategory"
                  :options="registerPhoneNumberOptions"
                  placeholder="電話番号区分を選択"
                />
              </div>
            </template>
          </GroupContentComponent>
        </div>
        <div class="group">
          <div class="group-title">お支払い方法</div>
          <GroupContentComponent :required="true" :isTitleTop="true">
            <template v-slot:title> お支払者のご住所 </template>
            <template v-slot:content>
              <div class="choise-groups">
                <div class="choise-group">
                  <div class="choise-group-body">
                    <InputComponent
                      v-for="(item, index) of paymentAddressOptions"
                      :key="index"
                      type="radio"
                      name="paymentAddressOptions"
                      :id="`paymentAddressOptions_${item.value}`"
                      :defaultValue="RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT"
                      :value="item.value"
                      :label="item.label"
                      @trigle-change="handlePaymentOptions"
                    />
                  </div>
                  <AddressSmallFormComponent
                    v-if="
                      choisingPaymentAddress ===
                      RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT
                    "
                    :showGroupContent="false"
                    addressTitle="送付先住所"
                    :postAddressData="postDeliveryData"
                    :postAddressSearchNumber="postDeliveryNumber"
                    postOfficeNumberName="postDeliveryNumberName"
                    addressDepartmentNumberInputName="deliveryDepartmentNumber"
                    addressDepartmentNameInputName="deliveryDepartmentName"
                    addressRoomNumberInputName="deliveryRoomNumber"
                    @on-choise="updatePostDeliveryData"
                    @search-again="searchDeliveryAgain"
                    :handleChangePostAddressNumber="
                      (e) => {
                        postDeliveryNumber = e.target.value;
                      }
                    "
                  />
                </div>
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent :required="true" :isTitleTop="true">
            <template v-slot:title> お支払者の電話番号 </template>
            <template v-slot:content>
              <div class="choise-groups">
                <div class="choise-group">
                  <div class="choise-group-body">
                    <InputComponent
                      v-for="(item, index) of paymentPersonTelephoneOptions"
                      :key="index"
                      type="radio"
                      name="paymentPersonTelephoneOptions"
                      :id="`paymentPersonTelephoneOptions_${item.value}`"
                      :defaultValue="PAYMENT_PERSON_TELEPHONE_DEFAULT"
                      :value="item.value"
                      :label="item.label"
                      @trigle-change="handlePersonPaymentTelephoneOptions"
                    />
                  </div>
                  <div
                    class="telephoneGroup"
                    v-if="
                      choisingPaymentPersonTelephone ===
                      PAYMENT_PERSON_TELEPHONE_DEFAULT
                    "
                  >
                    <TelephoneInputComponent
                      :groupInput="paymentPersonTelephone"
                      :required="true"
                    />
                    <SelectComponent
                      name="paymentPhoneNumberCategory"
                      :options="payerPhoneNumberOptions"
                      placeholder="電話番号区分を選択"
                    />
                  </div>
                </div>
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent :required="true">
            <template v-slot:title> お支払方法 </template>
            <template v-slot:content>
              <div class="flex-60">
                <InputComponent
                  v-for="(item, index) of residentPaymentOptions"
                  :key="index"
                  type="radio"
                  name="residentPaymentOptions"
                  :id="`residentPaymentOptions_${item.value}`"
                  defaultValue="0"
                  :value="item.value"
                  :label="item.label"
                />
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent :required="true">
            <template v-slot:title>
              都市ガスサービス<br />
              案内可否
            </template>
            <template v-slot:content>
              <div class="flex-60">
                <InputComponent
                  v-for="(item, index) of gasServiceIntroduceOptions"
                  :key="index"
                  type="radio"
                  name="gasServiceIntroduceOptions"
                  :id="`gasServiceIntroduceOptions_${item.value}`"
                  :defaultValue="1"
                  :value="item.value"
                  :label="item.label"
                />
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent :required="true">
            <template v-slot:title>
              九電グループ<br />「QTnet」の光イン<br />ターネット案内可否
            </template>
            <template v-slot:content>
              <div class="flex-60">
                <InputComponent
                  v-for="(item, index) of kyudenGroupInternetIntroduceOptions"
                  :key="index"
                  type="radio"
                  name="kyudenGroupInternetIntroduceOptions"
                  :id="`kyudenGroupInternetIntroduceOptions_${item.value}`"
                  :defaultValue="1"
                  :value="item.value"
                  :label="item.label"
                />
              </div>
            </template>
          </GroupContentComponent>
        </div>
        <div class="action-group">
          <ButtonComponent buttonType="button" type="outline" @click="onBack">
            キャンセル
          </ButtonComponent>
          <ButtonComponent
            :disabled="
              !(meta.valid && isValid(values.deliveryDepartmentNumber))
            "
            buttonType="submit"
          >
            確認
          </ButtonComponent>
        </div>
      </Form>
    </CardComponent>
  </div>
</template>
<script>
import { Form } from "vee-validate";
import {
  convertDateOptions,
  convertMonthOptions,
  convertYearOptions,
  convertNoElectricWaterHeaterOptions,
  convertHasElectricWaterHeaterOptions,
  convertRegisterPhoneNumberOptions,
  convertPayerPhoneNumberOptions,
  convertPaymentAddressOptions,
  convertConnectionWithAppicatedOptions,
  convertPaymentPersonTelephoneOptions,
  convertResidentPaymentOptions,
  convertGasServiceIntroduceOptions,
  convertKyudenGroupInternetIntroduceOptions,
  convertIntroduceDataConfirm,
} from "@/store/converter/registration.converter";
import { parsePhoneNumber } from "@/plugins/parsePhoneNumber";
import {
  CONTRACTOR_PHONE_PARSE,
  APPLICATED_PERSON_PHONE_PARSE,
  RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT,
  CONNECTION_WITH_APPLICATED_PERSON_DEFAULT,
  PAYMENT_PERSON_TELEPHONE_DEFAULT,
  PAYMENT_PERSON_PHONE_PARSE,
  RESIDENT_SETTING_TAB,
} from "@/globals/enums";
import { introduceFormSchema } from "@/validations/schemas/introduceFormSchema";
import { parseDateChoise } from "@/plugins/parseDateChoise";

export default {
  name: "IntroduceFormComponent",
  components: {
    Form,
  },
  props: {
    holidateList: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT =
      RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT;
    this.CONNECTION_WITH_APPLICATED_PERSON_DEFAULT =
      CONNECTION_WITH_APPLICATED_PERSON_DEFAULT;
    this.PAYMENT_PERSON_TELEPHONE_DEFAULT = PAYMENT_PERSON_TELEPHONE_DEFAULT;
    this.yearOptions = convertYearOptions();
    this.monthOptions = convertMonthOptions();
    this.dateOptions = convertDateOptions();
    this.noElectricWaterHeaterOptions = convertNoElectricWaterHeaterOptions();
    this.hasElectricWaterHeaterOptions = convertHasElectricWaterHeaterOptions();
    this.registerPhoneNumberOptions = convertRegisterPhoneNumberOptions();
    this.payerPhoneNumberOptions = convertPayerPhoneNumberOptions();
    this.paymentAddressOptions = convertPaymentAddressOptions();
    this.connectionWithApplicatedPerson =
      convertConnectionWithAppicatedOptions();
    this.contractorPhoneNumberOptions = parsePhoneNumber(
      "",
      CONTRACTOR_PHONE_PARSE
    );
    this.applicatedPersonNumberOptions = parsePhoneNumber(
      "",
      APPLICATED_PERSON_PHONE_PARSE
    );
    this.paymentPersonTelephone = parsePhoneNumber(
      "",
      PAYMENT_PERSON_PHONE_PARSE
    );
    this.paymentPersonTelephoneOptions = convertPaymentPersonTelephoneOptions();
    this.residentPaymentOptions = convertResidentPaymentOptions();
    this.gasServiceIntroduceOptions = convertGasServiceIntroduceOptions();
    this.kyudenGroupInternetIntroduceOptions =
      convertKyudenGroupInternetIntroduceOptions();
    this.setTime();
  },
  data: () => ({
    choisingPaymentAddress: RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT,
    choisingPaymentPersonTelephone: PAYMENT_PERSON_TELEPHONE_DEFAULT,
    connectionWithAppicationPerson: CONNECTION_WITH_APPLICATED_PERSON_DEFAULT,
    postDeliveryData: null,
    postDeliveryNumber: "",
    startTime: null,
  }),
  computed: {
    isValid() {
      return (number) =>
        this.choisingPaymentAddress !==
          RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT || !!number;
    },
    schema() {
      return introduceFormSchema([], this.startTime.all);
    },
  },
  methods: {
    setTime() {
      const stateDate = parseDateChoise(new Date(), this.holidateList, false);
      this.startTime = {
        initDate: stateDate.getDate(),
        initMonth: stateDate.getMonth() + 1,
        initYear: stateDate.getFullYear(),
        all: stateDate,
      };
    },
    handleConectionWithApplicatedPerson(e) {
      this.connectionWithAppicationPerson = parseInt(e.target.value);
    },
    updatePostDeliveryData(data) {
      this.postDeliveryData = data;
      this.postDeliveryNumber = "";
    },
    searchDeliveryAgain() {
      this.postDeliveryData = null;
      this.postDeliveryNumber = "";
    },
    handlePaymentOptions(e) {
      this.choisingPaymentAddress = parseInt(e.target.value);
    },
    handlePersonPaymentTelephoneOptions(e) {
      this.choisingPaymentPersonTelephone = parseInt(e.target.value);
    },
    onSubmit(data) {
      this.$emit("change-tab", {
        editedData: convertIntroduceDataConfirm({
          ...data,
          postDeliveryData: this.postDeliveryData,
          roomId: this.$route.params?.id,
        }),
        tab: RESIDENT_SETTING_TAB.CONFIRM,
      });
    },
    onBack(e) {
      e.preventDefault();
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="scss">
.IntroduceFormComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-title {
    width: 960px;
    text-align: left;
    margin-top: 32px;
    color: var(--venice-blue-color);
    padding: 0.5rem 0;
  }
  .main {
    padding: 30px 40px;
    margin-bottom: 30px;
    .group {
      margin-bottom: 30px;
      &-title {
        font-size: 16px;
        padding-bottom: 5px;
        border-bottom: solid 2px #d0d9df;
      }
      .choise-groups {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .choise-group {
          display: flex;
          flex-direction: column;
          gap: 10px;
          &-body {
            display: flex;
            gap: 20px;
          }
          &-body-custom {
            display: flex;
            gap: 60px;
            .InputComponent {
              input {
                margin-bottom: 0 !important;
              }
            }
          }
        }
      }
      .twoline-title {
        .second-line {
          margin-left: 20px;
        }
      }
      .two-input-content {
        display: flex;
        gap: 20px;
        .input-content {
          width: 170px;
        }
      }
      .telephoneGroup {
        display: flex;
        gap: 20px;
        margin: 20px 0 15px 0;
      }
      .flex-60 {
        display: flex;
        gap: 60px;
        align-items: center;
        padding: 30px 0;
        .InputComponent {
          margin-bottom: 0;
        }
      }
    }
    .action-group {
      display: flex;
      gap: 20px;
      justify-content: center;
    }
  }
  .GroupContentComponent {
    min-height: 80px;
  }
}
</style>
