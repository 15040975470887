<template>
  <div class="SettingMFAComponent">
    <CardComponent class="card" :width="'960px'" :height="'550px'">
      <TitleComponent class="card_title" :hasUnderLine="true">
        <template v-slot:content>多要素認証の確認方法</template>
      </TitleComponent>
      <p class="card_description">多要素認証の確認方法を選択してください。</p>
      <div class="card_body">
        <div class="card_body-button" @click="handleAuth(type.SMS)">
          <span class="icon-ico_code"></span>電話番号に認証コードを送信する
        </div>
        <div class="card_body-button" @click="handleAuth(type.TOTP)">
          <span class="icon-ico_guard"></span
          >Authenticatorアプリの<br />認証コードを使用する
        </div>
      </div>
    </CardComponent>
  </div>
</template>

<script>
import { TYPE_MFA } from "../globals/enums.js";
import LoginService from "@/services/login.service";

export default {
  name: "SettingMFAComponent",
  data: () => ({
    loginService: new LoginService(),
    type: TYPE_MFA,
  }),
  methods: {
    handleAuth(type) {
      type === TYPE_MFA.TOTP
        ? this.$router.push({ name: "SettingAuthAppComponent" })
        : this.$router.push({ name: "SettingSMSComponent" });
    },
  },
};
</script>

<style scoped lang="scss">
.SettingMFAComponent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    &_title {
      margin-top: 30px;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
    }
    &_description {
      font-size: 14px;
      font-weight: 500;
      margin: 20px 0 0;
    }
    &_body {
      padding-top: 50px;
      display: flex;
      flex-direction: column;
      gap: 40px;
      &-button {
        display: flex;
        gap: 27px;
        height: 80px;
        align-items: center;
        justify-content: left;
        border-radius: 4px;
        border: solid 2px #4c7495;
        padding: 28px 50px 28px 27px;
        font-size: 14px;
        font-weight: 500;
        .icon-ico_code {
          font-size: 23px;
        }
        .icon-ico_guard {
          font-size: 27px;
        }
        &:hover {
          cursor: pointer;
          border: solid 2px #4c7495;
        }
      }
    }
  }
}
</style>
