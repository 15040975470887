<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    @click-outside="() => {}"
    :click-to-close="false"
    classes="modal-container"
    content-class="ConfirmDialog modal-content"
  >
    <span class="modal__title">{{ title }}</span>
    <div class="modal__content">
      <p v-html="content" />
      <div class="checkbox-groups">
        <div class="checkbox-group">
          <InputComponent
            type="checkbox"
            name="firstCheck"
            @trigle-change="setFirstCheck"
          />
          お客様控え<a
            :href="`/print/${residentId}/${printId}`"
            rel="opener"
            target="_blank"
            ><span class="icon-ico_newwindow"></span
          ></a>
        </div>
        <div class="checkbox-group">
          <InputComponent
            type="checkbox"
            name="secondCheck"
            @trigle-change="setSecondCheck"
          />
          <div>
            ご説明資料<br />
            <p class="memo">
              ・電気のご契約のご案内
              <a :href="url" rel="opener" target="_blank"
                ><span class="icon-ico_newwindow"></span
              ></a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <ButtonComponent
      width="170px"
      height="50px"
      @click="
        close();
        onClose();
      "
      :disabled="!(firstCheck && secondCheck)"
    >
      OK
    </ButtonComponent>
  </vue-final-modal>
</template>

<script>
export default {
  name: "MediationDialog",
  props: {
    title: {
      type: [String, Number],
      default: null,
    },
    content: {
      type: [String, Number],
      default: null,
    },
    residentId: {
      type: String,
      default: "",
    },
    printId: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "#",
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    firstCheck: false,
    secondCheck: false,
  }),
  methods: {
    setFirstCheck(e) {
      this.firstCheck = e.target.checked;
    },
    setSecondCheck(e) {
      this.secondCheck = e.target.checked;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.ConfirmDialog) {
  &.modal {
    &-content {
      position: relative;
      background-color: #fefefe;
      margin: auto;
      padding: 40px 40px 29px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      border: 1px solid #888;
      border-radius: 5px;
      width: 560px;
      height: 353px;
      .modal {
        &__title {
          font-weight: bold;
          text-align: center;
          max-width: 224px;
          padding-bottom: 20px;
        }
        &__content {
          font-size: 14px;
          padding-bottom: 20px;
          .checkbox-groups {
            border: solid 1px #d0d9df;
            width: 460px;
            height: 124px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            gap: 16px;
            .checkbox-group {
              display: flex;
              gap: 10px;
              font-size: 14px;
              text-align: left;
              a {
                text-decoration: none;
              }
              .icon-ico_newwindow {
                line-height: unset;
              }
              .memo {
                font-size: 12px;
                .icon-ico_newwindow {
                  margin-left: 10px;
                  font-size: 14px;
                }
              }
            }
          }
        }
        &__close {
          position: absolute;
          top: 10px;
          right: 20px;
          color: var(--color-gray);
          font-size: 20px;
        }
      }
    }
  }
}
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
