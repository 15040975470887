import { TIMES } from "@/globals/enums";

//DateFormat YYYY/MM/DD to YYYY年MM月DD日
export const generateDateJapansese = (el, binding) => {
  if (binding.value) {
    const dateArray = binding.value.split("/");
    el.innerHTML = `${dateArray[0]}年${dateArray[1]}月${dateArray[2]}日`;
  }
};

//TimeFormat: HH:MM:SS to HH時MM分
export const generateTimeJapansese = (el, binding) => {
  if (binding.value && typeof binding.value === "string") {
    const timeArray = binding.value.split(":");
    el.innerHTML = `${timeArray[0]}時${
      timeArray[1] !== "00" ? `${timeArray[1]}分` : ""
    }`;
  }
};

//TimeFormatByEnums: 17 to 17時台
export const generateTimeJapanseseByEnums = (el, binding) => {
  if (binding.value) {
    const result = TIMES.find((time) => time.VALUE === binding.value);
    el.innerHTML = result.NAME || "";
  }
};
