<template>
  <div
    v-if="edittingCompanyData"
    class="CompanySmallEditTabComponent"
    :class="{ inInfo: inInfo }"
  >
    <TitleComponent class="CompanySmallEditTabComponent-title" v-if="inInfo">
      <template v-slot:icon><span class="icon-ico_list"></span></template>
      <template v-slot:content>会社情報・支払先情報</template>
    </TitleComponent>
    <CardComponent class="main" :width="'960px'" :height="'auto'">
      <TitleComponent v-if="!inInfo" class="underline">
        <template v-slot:content>会社情報・支払先情報入力</template>
      </TitleComponent>
      <Form
        @submit="editCompanyData"
        :validation-schema="schema"
        v-slot="{ meta }"
        v-if="edittingCompanyData.isAccountSettingDisplay"
      >
        <div class="form-groups">
          <div class="form-groups-title">会社情報</div>
          <GroupContentComponent>
            <template v-slot:title> 会社名 </template>
            <template v-slot:content>
              {{ edittingCompanyData.companyName }}
            </template>
          </GroupContentComponent>
          <GroupContentComponent>
            <template v-slot:title> 会社名（カナ）</template>
            <template v-slot:content>
              {{ edittingCompanyData.companyKanaName }}
            </template>
          </GroupContentComponent>
          <GroupContentComponent :isTitleTop="true">
            <template v-slot:title> 住所</template>
            <template v-slot:content>
              <div
                class="postOfficeAddress"
                v-if="edittingCompanyData.postOfficeData"
              >
                <div class="postCode">
                  〒
                  <div
                    v-formatPostCode="
                      edittingCompanyData.postOfficeData.postCode
                    "
                  ></div>
                </div>
                <div class="postOfficeInfo">
                  {{ edittingCompanyData.postOfficeData.address }}<br />
                  {{ edittingCompanyData.addressDepartmentNumber }}<br />
                  {{ edittingCompanyData.addressDepartmentName }}<br />
                  {{ edittingCompanyData.addressRoomNumber }}
                </div>
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent>
            <template v-slot:title> 私書箱</template>
            <template v-slot:content>
              {{ edittingCompanyData.mailbox }}
            </template>
          </GroupContentComponent>
          <GroupContentComponent :required="true">
            <template v-slot:title> 電話番号</template>
            <template v-slot:content>
              <div class="telephoneGroup">
                <TelephoneInputComponent
                  :groupInput="edittingCompanyData.telephone"
                />
                <SelectComponent
                  name="phoneNumberCategory"
                  :options="initCompanySetting.registerPhoneNumberOptions"
                  placeholder="電話番号区分を選択"
                  :initialValue="edittingCompanyData.phoneNumberCategory.value"
                />
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent>
            <template v-slot:title> 電気料金支払い方法</template>
            <template v-slot:content>
              {{ edittingCompanyData.paymentOptions.label }}
            </template>
          </GroupContentComponent>
          <GroupContentComponent>
            <template v-slot:title> 入居者向け<br />電気契約申込み</template>
            <template v-slot:content>
              <p v-if="edittingCompanyData.electricContractApp">
                {{ edittingCompanyData.electricContractApp.label }}
              </p>
              <p
                class="electricContractAppType"
                v-if="
                  edittingCompanyData.electricContractApp.value ===
                    COMPANY_REGISTER_CONTRACT_TYPES.DEFAULT_VALUE &&
                  edittingCompanyData.electricContractAppType
                "
              >
                {{ edittingCompanyData.electricContractAppType.label }}
              </p>
            </template>
          </GroupContentComponent>
        </div>
        <div class="form-groups">
          <div class="form-groups-title">支払先情報</div>
          <GroupContentComponent>
            <template v-slot:title> 金融機関・支店名 </template>
            <template v-slot:content>
              <p>{{ edittingCompanyData.bankName }}</p>
              <p class="bankDepartment">
                {{ edittingCompanyData.bankDepartment }}
              </p>
            </template>
          </GroupContentComponent>
          <GroupContentComponent>
            <template v-slot:title> 口座情報 </template>
            <template v-slot:content>
              <div class="accountInfo" v-if="edittingCompanyData.accountType">
                <p>{{ edittingCompanyData.accountType.label }}</p>
                <p>{{ edittingCompanyData.departmentNumber }}</p>
                <p>{{ edittingCompanyData.accountNumber }}</p>
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent>
            <template v-slot:title> 口座名義 </template>
            <template v-slot:content>
              <p>{{ edittingCompanyData.accountName }}</p>
            </template>
          </GroupContentComponent>
        </div>
        <div class="action-group">
          <div class="action-group">
            <ButtonComponent @click="(e) => onBack(e)" type="outline">
              キャンセル
            </ButtonComponent>
            <ButtonComponent buttonType="submit" :disabled="!meta.valid">
              登録
            </ButtonComponent>
          </div>
        </div>
      </Form>
      <Form
        ref="formEdit"
        @submit="editCompanyData"
        :validation-schema="customSchema"
        v-slot="{ meta, values }"
        v-else
      >
        <div class="form-groups">
          <div class="form-groups-title">会社情報</div>
          <GroupContentComponent>
            <template v-slot:title> 会社名 </template>
            <template v-slot:content>
              {{ edittingCompanyData.companyName }}
            </template>
          </GroupContentComponent>
          <GroupContentComponent>
            <template v-slot:title> 会社名（カナ）</template>
            <template v-slot:content>
              {{ edittingCompanyData.companyKanaName }}
            </template>
          </GroupContentComponent>
          <GroupContentComponent :isTitleTop="true">
            <template v-slot:title> 住所</template>
            <template v-slot:content>
              <div
                class="postOfficeAddress"
                v-if="edittingCompanyData.postOfficeData"
              >
                <div class="postCode">
                  〒
                  <div
                    v-formatPostCode="
                      edittingCompanyData.postOfficeData.postCode
                    "
                  ></div>
                </div>
                <div class="postOfficeInfo">
                  {{ edittingCompanyData.postOfficeData.address }}<br />
                  {{ edittingCompanyData.addressDepartmentNumber }}<br />
                  {{ edittingCompanyData.addressDepartmentName }}<br />
                  {{ edittingCompanyData.addressRoomNumber }}
                </div>
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent>
            <template v-slot:title> 私書箱</template>
            <template v-slot:content>
              {{ edittingCompanyData.mailbox }}
            </template>
          </GroupContentComponent>
          <GroupContentComponent :required="true">
            <template v-slot:title> 電話番号</template>
            <template v-slot:content>
              <div class="telephoneGroup">
                <TelephoneInputComponent
                  :groupInput="edittingCompanyData.telephone"
                />
                <SelectComponent
                  name="phoneNumberCategory"
                  :options="initCompanySetting.registerPhoneNumberOptions"
                  placeholder="電話番号区分を選択"
                  :initialValue="edittingCompanyData.phoneNumberCategory.value"
                />
              </div>
            </template>
          </GroupContentComponent>
          <GroupContentComponent>
            <template v-slot:title> 電気料金支払い方法</template>
            <template v-slot:content>
              {{ edittingCompanyData.paymentOptions.label }}
            </template>
          </GroupContentComponent>
          <GroupContentComponent>
            <template v-slot:title> 入居者向け<br />電気契約申込み</template>
            <template v-slot:content>
              <p v-if="edittingCompanyData.electricContractApp">
                {{ edittingCompanyData.electricContractApp.label }}
              </p>
              <p
                class="electricContractAppType"
                v-if="
                  edittingCompanyData.electricContractApp.value ===
                    COMPANY_REGISTER_CONTRACT_TYPES.DEFAULT_VALUE &&
                  edittingCompanyData.electricContractAppType
                "
              >
                {{ edittingCompanyData.electricContractAppType.label }}
              </p>
            </template>
          </GroupContentComponent>
        </div>
        <div class="form-groups">
          <div class="form-groups-title">支払先情報</div>
          <AddressFormComponent
            addressTitle="送付先住所"
            :postAddressData="postDeliveryData"
            :postAddressSearchNumber="postDeliveryNumber"
            postOfficeNumberName="postDeliveryNumberName"
            :addressDepartmentNumber="
              postDeliveryData?.deliveryDepartmentNumber || ''
            "
            addressDepartmentNumberInputName="deliveryDepartmentNumber"
            addressDepartmentNameInputName="deliveryDepartmentName"
            :addressDepartmentName="
              postDeliveryData?.deliveryDepartmentName || ''
            "
            addressRoomNumberInputName="deliveryRoomNumber"
            :addressRoomNumber="postDeliveryData?.deliveryRoomNumber || ''"
            @on-choise="updatePostDeliveryData"
            @search-again="searchDeliveryAgain"
            :handleChangePostAddressNumber="
              (e) => {
                postDeliveryNumber = e.target.value;
              }
            "
            :hasSlot="true"
            :isRenderTransferAddressInput="renderAddress"
          >
            <div class="flex-normal">
              <InputComponent
                v-for="(
                  item, index
                ) of initCompanySetting.transferAddressOptions"
                :key="index"
                type="radio"
                name="transferAddressOptions"
                :id="`transferAddressOptions_${item.value}`"
                :defaultValue="
                  transferAddressOptions
                    ? transferAddressOptions
                    : TRANSFER_ADDRESS_OPTIONS_DEFAULT
                "
                :value="item.value"
                :label="item.label"
                @trigle-change="handleChangeTransferAddressOptions"
              />
            </div>
          </AddressFormComponent>
        </div>
        <div class="action-group">
          <div class="action-group">
            <ButtonComponent @click="(e) => onBack(e)" type="outline">
              キャンセル
            </ButtonComponent>
            <ButtonComponent
              buttonType="submit"
              :disabled="
                !(
                  meta.valid &&
                  isTransferAddressValid(values?.deliveryDepartmentNumber)
                )
              "
            >
              登録
            </ButtonComponent>
          </div>
        </div>
      </Form>
    </CardComponent>
  </div>
</template>

<script>
import {
  COMPANY_SETTING_TAB,
  STATUS,
  TRANSFER_ADDRESS_OPTIONS_DEFAULT,
  COMPANY_REGISTER_CONTRACT_TYPES,
} from "@/globals/enums";
import { mapState } from "vuex";
import CompanyService from "@/services/company.service";
import { Form } from "vee-validate";
import {
  companyEditSchemaOnlyTelephone,
  companyEditSchemaWithPostCode,
} from "@/validations/schemas/companyEditSchema";
import ConfirmDialogService from "@/services/dialog/confirm-dialog.service";
export default {
  name: "CompanySmallEditTabComponent",
  props: {
    inInfo: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Form,
  },
  created() {
    this.TRANSFER_ADDRESS_OPTIONS_DEFAULT = TRANSFER_ADDRESS_OPTIONS_DEFAULT;
    this.COMPANY_REGISTER_CONTRACT_TYPES = COMPANY_REGISTER_CONTRACT_TYPES;
  },
  computed: {
    renderAddress() {
      return this.transferAddressOptions === TRANSFER_ADDRESS_OPTIONS_DEFAULT;
    },
    isTransferAddressValid() {
      return (deliveryDepartmentNumber) =>
        this.edittingCompanyData.isAccountSettingDisplay ||
        !this.renderAddress ||
        !!deliveryDepartmentNumber;
    },
    ...mapState({
      profileInfo: (state) => state.settings.profileInfo,
      edittingCompanyData: (state) => state.company.edittingCompanyData,
      initCompanySetting: (state) => state?.company?.initCompanySetting,
    }),
  },
  data: () => ({
    postDeliveryData: null,
    postDeliveryNumber: "",
    companyService: new CompanyService(),
    schema: companyEditSchemaOnlyTelephone,
    customSchema: companyEditSchemaWithPostCode,
    confirmDialogService: new ConfirmDialogService(),
    transferAddressOptions: 2,
  }),
  mounted() {
    this.companyService.initCompanyState();
    if (this.edittingCompanyData) {
      this.postDeliveryData = this.edittingCompanyData.postDeliveryData || null;
      this.transferAddressOptions =
        this.edittingCompanyData.transferAddressOptions?.value ||
        TRANSFER_ADDRESS_OPTIONS_DEFAULT;
    }
  },
  watch: {
    edittingCompanyData: function (val) {
      if (val?.postDeliveryData) {
        this.postDeliveryData = val.postDeliveryData;
      }
    },
  },
  methods: {
    updatePostDeliveryData(data) {
      this.postDeliveryData = data;
      this.transferAddressOptions === TRANSFER_ADDRESS_OPTIONS_DEFAULT;
    },
    searchDeliveryAgain() {
      this.transferAddressOptions === TRANSFER_ADDRESS_OPTIONS_DEFAULT;
      this.postDeliveryData = null;
      this.postDeliveryNumber = "";
      this.$refs.formEdit.setFieldValue(
        "transferAddressOptions",
        TRANSFER_ADDRESS_OPTIONS_DEFAULT
      );
    },
    onBack(e) {
      e.preventDefault();
      this.$emit("change-tab", COMPANY_SETTING_TAB.PREVIEW);
    },
    handleChangeTransferAddressOptions(e) {
      this.transferAddressOptions = parseInt(e.target.value);
    },
    async editCompanyData(data) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      await this.companyService
        .updateCompanyData(this.profileInfo.companyId, {
          postDeliveryData: this.postDeliveryData,
          ...data,
        })
        .then((result) => {
          this.$store.dispatch("global/updateLoadingStatus", false);
          if (result.status === STATUS.SUCCESS) {
            this.confirmDialogService.openConfirmDialog(
              "会社情報・支払先情報を編集しました。",
              null,
              () => {}
            );
            this.$emit("change-tab", COMPANY_SETTING_TAB.PREVIEW);
          }
        });
    },
  },
};
</script>
<style scoped lang="scss">
.CompanySmallEditTabComponent {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &-title {
    display: flex;
    width: 960px;
    color: var(--venice-blue-color);
    font-size: 20px;
    font-weight: 500;
  }
  .main {
    margin: 27px 0 40px 0;
    padding: 30px 40px 29px;
    .TitleComponent {
      width: 255px;
      margin: 0 auto;
    }
    .form-groups {
      margin: 30px 0 30px 0;
      font-size: 14px;
      &-title {
        font-size: 16px;
        padding-bottom: 5px;
        border-bottom: solid 3px #d0d9df;
      }
      .postOfficeAddress {
        .postCode {
          display: flex;
        }
      }
      .telephoneGroup {
        display: flex;
        gap: 20px;
        margin: 20px 0 15px 0;
      }
      .electricContractAppType {
        margin-left: 120px;
      }
      .bankDepartment {
        margin-left: 30px;
      }
      .accountInfo {
        display: flex;
        gap: 30px;
      }
    }
    .action-group {
      display: flex;
      justify-content: center;
      gap: 30px;
    }
  }
  &.inInfo {
    padding: 30px 40px 29px;
    .main {
      margin: 0;
    }
  }
  .back-button {
    margin: 30px 0;
  }
  .flex-normal {
    display: flex;
    gap: 60px;
  }
}
</style>
