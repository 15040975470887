<template>
  <div class="CreateRoomComponent">
    <TitleComponent>
      <template v-slot:icon><span class="icon-ico_room"></span></template>
      <template v-slot:content>{{
        isViewMode ? "部屋新規登録確認" : "部屋新規登録"
      }}</template>
    </TitleComponent>
    <CardComponent width="960px" height="510px">
      <Form
        @submit="handleSubmit"
        :validation-schema="schema"
        v-slot="{ meta }"
      >
        <GroupContentComponent :required="!isViewMode" class="has-border-top">
          <template v-slot:title> 部屋番号 </template>
          <template v-slot:content>
            <InputComponent
              v-show="!isViewMode"
              name="roomNumber"
              :small="true"
              maxlength="6"
              inputFilter="fullWidth"
            />
            <div v-show="isViewMode">
              {{ dataRoom.roomNumber }}
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent :required="!isViewMode">
          <template v-slot:title> オートロック </template>
          <template v-slot:content>
            <div class="conten-radio" v-show="!isViewMode">
              <InputComponent
                type="radio"
                label="有"
                :value="CHECKED.TRUE"
                name="autoLock"
                id="autoLock_1"
                defaultValue="2"
              />
              <InputComponent
                type="radio"
                label="無"
                :value="CHECKED.FALSE"
                name="autoLock"
                id="autoLock_2"
                defaultValue="2"
                :showErrorMessage="false"
              />
            </div>
            <div v-show="isViewMode">
              <span v-if="dataRoom.autoLock === CHECKED.TRUE">
                <span class="icon-ico_seijyo">
                  <span class="path1"></span>
                  <span class="path2"></span>
                </span>
                <span class="text">あり</span>
              </span>
              <span v-else>
                <span class="icon-check"></span>
                <span class="text">なし</span>
              </span>
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent :required="!isViewMode">
          <template v-slot:title> 温水器 </template>
          <template v-slot:content>
            <div class="conten-radio" v-show="!isViewMode">
              <InputComponent
                type="radio"
                name="waterHeater"
                id="waterHeater_1"
                label="有"
                defaultValue="2"
                :value="CHECKED.TRUE"
              />
              <InputComponent
                type="radio"
                name="waterHeater"
                id="waterHeater_2"
                label="無"
                defaultValue="2"
                :showErrorMessage="false"
                :value="CHECKED.FALSE"
              />
            </div>
            <div v-show="isViewMode">
              <span v-if="dataRoom.waterHeater === CHECKED.TRUE">
                <span class="icon-ico_seijyo">
                  <span class="path1"></span>
                  <span class="path2"></span>
                </span>
                <span class="text">あり</span>
              </span>
              <span v-else>
                <span class="icon-check"></span>
                <span class="text">なし</span>
              </span>
            </div>
          </template>
        </GroupContentComponent>
        <div>
          <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
          <div class="CreateRoomComponent-action create">
            <ButtonComponent
              @click="cancelHandle($event)"
              type="outline"
              :width="'170px'"
              :height="'50px'"
            >
              {{ isViewMode ? "もどる" : "キャンセル" }}
            </ButtonComponent>
            <ButtonComponent
              buttonType="submit"
              :width="'170px'"
              :height="'50px'"
              :disabled="!(meta.valid && meta.dirty)"
            >
              {{ isViewMode ? "登録" : "確認" }}
            </ButtonComponent>
          </div>
        </div>
      </Form>
    </CardComponent>
  </div>
</template>

<script>
import ConfirmDialogService from "@/services/dialog/confirm-dialog.service";
import { Form } from "vee-validate";
import RoomService from "@/services/room.service";
import { createRoomSchema } from "@/validations/schemas/createRoomSchema";
import { CHECKED, STATUS } from "@/globals/enums";
export default {
  name: "CreateRoomComponent",
  components: {
    Form,
  },
  data: () => ({
    schema: createRoomSchema,
    roomService: new RoomService(),
    confirmDialogService: new ConfirmDialogService(),
    isViewMode: false,
    dataRoom: {},
    errorDetail: null,
  }),
  mounted() {
    if (!this.$route.params.buildingId) {
      this.router.push({ name: "RoomComponent" });
    }
  },
  created() {
    this.CHECKED = CHECKED;
  },
  methods: {
    handleSubmit(data) {
      this.dataRoom = { buildingId: this.$route.params.buildingId, ...data };
      if (this.isViewMode) {
        this.createRoom();
      } else {
        this.isViewMode = true;
      }
    },

    createRoom() {
      this.errorDetail = null;
      this.roomService.handleCreateRoom(this.dataRoom).then((result) => {
        if (result.status === STATUS.ERROR && result.response?.detail) {
          this.errorDetail = result.response?.detail;
          return;
        }
        this.confirmDialogService.openConfirmDialog(
          "部屋情報の新規登録が完了しました。",
          null,
          () => {
            this.$router.push({ name: "RoomComponent" });
          }
        );
      });
    },

    cancelHandle(e) {
      e.preventDefault();
      this.errorDetail = null;
      if (this.isViewMode) {
        this.isViewMode = false;
        return;
      }
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.CreateRoomComponent {
  margin-top: 20px;
  .TitleComponent {
    width: 960px;
    text-align: left;
    color: var(--venice-blue-color);
    padding: 0 0 11px 0;
    margin: 0 auto;
  }
  .v-card {
    padding: 30px 40px;
    .conten-radio {
      display: flex;
      .InputComponent {
        margin: 15px;
      }
    }
  }
  &-action {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .ButtonComponent {
      margin: 0 10px;
    }
  }
  .GroupContentComponent {
    min-height: 60px;
    &.has-border-top {
      border-top: 1px solid #d0d9df;
    }
    .text {
      margin-left: 5px;
    }
  }
}
</style>
