import { get, post, put, del } from "./../https/http.service";

export const getRoomList = (body) => {
  return get("estate/room/list", body);
};

export const getRoomById = (id) => {
  return get(`estate/room/${id}`);
};

export const createRoom = (body) => {
  return post("estate/room", body);
};

export const updateRoomById = (id, body) => {
  return put(`estate/room/${id}`, body);
};

export const deleteRoomById = (id) => {
  return del(`estate/room/${id}`);
};
