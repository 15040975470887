import { formatPostCode } from "./formatPostCode";
import { inputFilter } from "./inputFilter";
import {
  generateDateJapansese,
  generateTimeJapansese,
  generateTimeJapanseseByEnums,
} from "./generateDateTimeJapansese";
import { roomRegistrationType } from "./roomRegistrationType";
import { tableRange } from "./tableRange";
export default {
  install: (app) => {
    app
      .directive("roomRegistrationType", roomRegistrationType)
      .directive("generateTimeJapansese", generateTimeJapansese)
      .directive("generateDateJapansese", generateDateJapansese)
      .directive("generateTimeJapanseseByEnums", generateTimeJapanseseByEnums)
      .directive("formatPostCode", formatPostCode)
      .directive("inputFilter", inputFilter)
      .directive("tableRange", tableRange);
  },
};
