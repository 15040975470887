<template>
  <img :src="imageSrc" />
</template>

<script>
export default {
  name: "IconCheckComponent",
  props: {
    isChecked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imageSrc() {
      return this.isChecked
        ? require("../../assets/images/icons/ico_check.svg")
        : require("../../assets/images/icons/ico_uncheck.svg");
    },
  },
};
</script>
