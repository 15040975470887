<template>
  <div class="ResetPasswordComponent">
    <Form
      @submit="createNewPassword"
      :validation-schema="schema"
      v-slot="{ meta }"
    >
      <CardComponent
        class="form-resetPassword"
        :width="'960px'"
        :height="errorDetail ? '570px' : '550px'"
      >
        <TitleComponent class="form-registration_title" :hasUnderLine="true">
          <template v-slot:content>パスワード再設定</template>
        </TitleComponent>
        <p class="form-resetPassword-description">
          パスワード再設定用認証コードを入力の上、新しいパスワードを設定してください。
        </p>

        <div class="form-resetPassword-inputGroups">
          <div class="form-resetPassword-inputGroup"></div>
          <div class="form-resetPassword-inputGroup">
            <span class="form-resetPassword-inputGroup_title">認証コード</span>
            <InputComponent
              name="code"
              type="default"
              placeholder="認証コードを入力"
              inputFilter="number"
              maxlength="6"
            />
          </div>
          <div class="form-resetPassword-inputGroup">
            <span class="form-resetPassword-inputGroup_title"
              >新しいパスワード</span
            >
            <InputComponent
              name="password"
              type="password"
              maxlength="256"
              :validateSteps="validateSteps"
              placeholder="パスワード"
            />
          </div>
          <div class="form-resetPassword-inputGroup">
            <span class="form-resetPassword-inputGroup_title"
              >新しいパスワード<br />（再入力）</span
            >
            <InputComponent
              name="passwordConfirmation"
              type="password"
              maxlength="256"
              placeholder="新しいパスワードを再入力"
            />
          </div>
        </div>
        <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
        <ButtonComponent
          buttonType="submit"
          :width="'150px'"
          :disabled="!(meta.valid && meta.dirty)"
          >登録
        </ButtonComponent>
      </CardComponent>
    </Form>
  </div>
</template>

<script>
import {
  SPECIAL_STRING,
  VALIDATES,
  MIN_8_CHARACTER,
  STATUS,
} from "@/globals/enums";
import { Form } from "vee-validate";
import { mapState } from "vuex";
import LoginService from "@/services/login.service";
import { resetPasswordSchema } from "@/validations/schemas/resetPasswordSchema";

export default {
  name: "ResetPasswordComponent",
  components: {
    Form,
  },
  data: () => ({
    loginService: new LoginService(),
    validateSteps: [
      {
        message: SPECIAL_STRING.OVER_8_CHARACTERS,
        check: (data) => data.length >= MIN_8_CHARACTER,
      },
      {
        message: SPECIAL_STRING.NUMBER,
        check: (data) => VALIDATES.HAS_NUMBER.test(data), // check string has number
      },
      {
        message: "英字小文字",
        check: (data) =>
          VALIDATES.HAS_DOWNER_CHARACTER.test(data) ||
          VALIDATES.HAS_UPPER_CHARACTER.test(data),
      },
    ],
    schema: resetPasswordSchema,
    errorDetail: null,
  }),
  computed: mapState({
    tempUser: (state) => state.settings.tempUser,
  }),
  methods: {
    async createNewPassword(value) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.loginService.resetPassword(
        this.tempUser.emailAddress,
        value.code,
        value.password
      );
      this.$store.dispatch("global/updateLoadingStatus", false);
      if (result?.status === STATUS.ERROR && result?.response?.detail) {
        this.errorDetail = result.response.detail;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.ResetPasswordComponent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    &-resetPassword {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 30px;
      &_title {
        font-size: 20px;
        font-weight: 500;
      }
      &-description {
        font-size: 14px;
        font-weight: 500;
        margin: 20px 0 0;
      }
      &-inputGroups {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 0 220px 40px;
      }
      &-inputGroup {
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: space-between;
        &_title {
          font-size: 14px;
          color: #7c8185;
        }
        .InputComponent {
          margin: 0;
        }
      }
    }
  }
}
</style>
