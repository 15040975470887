import { STATUS } from "@/globals/enums";
import { getHome } from "./api/home.service";
import { converterHome } from "./../store/converter/home.converter";
export default class HomeService {
  async getHomeData(pagination) {
    try {
      const result = await getHome({
        page: pagination.page || 1,
        number: 4,
      });
      return {
        status: STATUS.SUCCESS,
        data: { ...converterHome(result) },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
      };
    }
  }
}
