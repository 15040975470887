<template>
  <div class="StatusRegistrationComponent">
    <div class="StatusRegistrationComponent-content">
      <span class="StatusRegistrationComponent-content-type">
        <span v-if="type === 1">{{ REGISTRATION_TYPE.USE_START.TEXT }}</span>
        <span v-if="type === 2">{{ REGISTRATION_TYPE.USE_STOP.TEXT }}</span>
        <span v-if="type === 3">{{ REGISTRATION_TYPE.MOVE_IN.TEXT }}</span>
      </span>
      <span
        class="StatusRegistrationComponent-content-status"
        :class="{
          'color-waiting': status === 1,
          'color-process': status === 2,
          'color-complete': status === 3,
          'color-unconfirmed': status === 4,
          'color-confirmed': status === 5,
        }"
      >
        <span v-if="status === 1" class="icon icon-ico_warning">
          <span class="path1"></span>
          <span class="path2"></span>
          <span class="path3"></span>
        </span>
        <span v-if="status === 2" class="icon icon-ico_ijyo2">
          <span class="path1"></span>
          <span class="path2"></span>
        </span>
        <span v-if="status === 3" class="icon icon-ico_seijyo">
          <span class="path1"></span>
          <span class="path2"></span>
        </span>
        <span v-if="status === 4 || status === 5" class="icon icon-ico_close">
          <span class="path1"></span>
          <span class="path2"></span>
        </span>
        <span v-if="status === 1">{{ REGISTRATION_STATUS.WAITING.TEXT }}</span>
        <span v-if="status === 2">{{ REGISTRATION_STATUS.PROCESS.TEXT }}</span>
        <span v-if="status === 3">{{ REGISTRATION_STATUS.COMPLETE.TEXT }}</span>
        <span v-if="status === 4">{{
          REGISTRATION_STATUS.UNCONFIRMED.TEXT
        }}</span>
        <span v-if="status === 5">{{
          REGISTRATION_STATUS.CONFIRMED.TEXT
        }}</span>
      </span>
    </div>
    <ButtonComponent
      @click="onClick"
      width="50px"
      height="23px"
      color="#328dc2"
      v-if="(status === 4 || status === 5) && isButton"
    >
      確認
    </ButtonComponent>
  </div>
</template>

<script>
import { REGISTRATION_STATUS, REGISTRATION_TYPE } from "@/globals/enums";
export default {
  name: "StatusRegistrationComponent",
  props: {
    status: Number,
    type: Number,
    isButton: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.REGISTRATION_STATUS = REGISTRATION_STATUS;
    this.REGISTRATION_TYPE = REGISTRATION_TYPE;
  },
  methods: {
    onClick() {
      this.$emit("onOpenModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.StatusRegistrationComponent {
  min-width: 150px;
  display: flex;
  align-items: center;
  // justify-content: center;
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    &-type {
      width: 80px;
      height: 23px;
      font-size: 11px;
      border: 1px solid var(--valley-smoke-color);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-status {
      width: 100px;
      .icon {
        margin-right: 5px;
      }
    }
  }
  .ButtonComponent {
    margin-left: 10px;
  }
  .color-waiting {
    color: #dcb46f;
  }
  .color-process {
    color: #748cbb;
  }
  .color-complete {
    color: #74afbb;
  }
  .color-unconfirmed {
    color: #dc906f;
  }
  .color-confirmed {
    color: #dc906f;
  }
}
</style>
