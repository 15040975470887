<template>
  <div class="HomeComponent">
    <div class="HomeComponent__group">
      <CardComponent width="435px" height="194px">
        <div class="HomeComponent__group__title">
          <TitleComponent>
            <template v-slot:icon>
              <span class="icon icon-ico_fudosan2"></span>
            </template>
            <template v-slot:content>登録物件数</template>
          </TitleComponent>
          <ButtonComponent
            @click="navigatePath('SearchBuildingComponent')"
            width="186px"
            height="30px"
            className="textTransformNone"
          >
            物件検索・電気申込み
          </ButtonComponent>
        </div>
        <div class="HomeComponent__group__content">
          <div>
            <div class="title">建物数</div>
            <div class="result">
              <span class="result-number">{{
                homeData?.numberBuilding || 0
              }}</span>
              件
            </div>
          </div>
          <div>
            <div class="title">部屋数</div>
            <div class="result">
              <span class="result-number">{{ homeData?.numberRoom || 0 }}</span>
              件
            </div>
          </div>
        </div>
      </CardComponent>
      <CardComponent width="515px" height="194px">
        <div class="HomeComponent__group__title">
          <TitleComponent>
            <template v-slot:icon>
              <span class="icon icon-ico_upload"></span>
            </template>
            <template v-slot:content>建物一括登録状況</template>
          </TitleComponent>
          <ButtonComponent
            @click="navigatePath('ListUploadComponent')"
            width="156px"
            height="30px"
            className="textTransformNone"
          >
            建物情報一括登録
          </ButtonComponent>
        </div>
        <div class="HomeComponent__group__content">
          <div>
            <div class="title box color-yellow">未処理</div>
            <div class="result">
              <span class="result-number">{{
                homeData?.numberBuildingRegistration || 0
              }}</span>
              件
            </div>
          </div>
          <div>
            <div class="title box color-blue">処理中</div>
            <div class="result">
              <span class="result-number">{{
                homeData?.numberBuildingRegistrationProcessing || 0
              }}</span>
              件
            </div>
          </div>
          <div>
            <div class="title box color-green">処理済み</div>
            <div class="result">
              <span class="result-number">{{
                homeData?.numberBuildingRegistrationProcessed || 0
              }}</span>
              件
            </div>
          </div>
        </div>
      </CardComponent>
    </div>
    <CardComponent class="content__registration" width="960px" height="auto">
      <div class="HomeComponent__group__title">
        <TitleComponent>
          <template v-slot:icon>
            <span class="icon icon-ico_riyo_haishi"></span>
          </template>
          <template v-slot:content>電気使用・停止申込み状況</template>
        </TitleComponent>
        <ButtonComponent
          @click="navigatePath('SearchHistoryRegistrationComponent')"
          width="142px"
          height="30px"
          className="textTransformNone"
        >
          申込み履歴一覧
        </ButtonComponent>
      </div>
      <div class="HomeComponent__group__content">
        <div class="HomeComponent__group__content__sub">
          <span class="icon-main icon-ico_warning">
            <span class="path1"></span>
            <span class="path2"></span>
            <span class="path3"></span>
          </span>
          <div class="title">処理待ち</div>
          <div class="result">
            <span class="result-number">{{
              homeData?.numberElectricityRegistrationWaitProcess || 0
            }}</span>
            件
          </div>
        </div>
        <div class="HomeComponent__group__content__sub">
          <span class="icon-main icon-ico_ijyo2">
            <span class="path1"></span>
            <span class="path2"></span>
          </span>
          <div class="title">処理中</div>
          <div class="result">
            <span class="result-number">{{
              homeData?.numberElectricityRegistrationProcessing || 0
            }}</span>
            件
          </div>
        </div>
        <div class="HomeComponent__group__content__sub">
          <span class="icon-main icon-ico_seijyo">
            <span class="path1"></span>
            <span class="path2"></span>
          </span>
          <div class="title">処理完了</div>
          <div class="result">
            <span class="result-number">{{
              homeData?.numberElectricityRegistrationProcessed || 0
            }}</span>
            件
          </div>
        </div>
        <div class="HomeComponent__group__content__sub">
          <span class="icon-main icon-ico_close">
            <span class="path1"></span>
            <span class="path2"></span>
          </span>
          <div class="title">受付不可</div>
          <div class="result">
            <span class="result-number">{{
              homeData?.numberElectricityRegistrationDecline || 0
            }}</span>
            件
          </div>
        </div>
      </div>
      <div
        v-if="homeData?.incompleteRegistrationList.length > 0"
        class="HomeComponent__group__table"
      >
        <TitleComponent>
          <template v-slot:icon>
            <span class="icon icon-ico_mikanryo"></span>
          </template>
          <template v-slot:content>未完了一覧</template>
        </TitleComponent>
        <div
          class="table-main-content"
          :class="{
            noPagination: homeData?.incompleteRegistrationList?.length < 4,
          }"
        >
          <table class="table-container">
            <tr>
              <TableHeaderComponent class="col-small">
                申込日
              </TableHeaderComponent>
              <TableHeaderComponent>建物名/部屋番号</TableHeaderComponent>
              <TableHeaderComponent class="col-small"
                >電気使用申込み状況</TableHeaderComponent
              >
            </tr>
            <div class="table-row-group">
              <TableRowComponent
                v-for="registration in homeData?.incompleteRegistrationList"
                :key="registration.registraionId"
              >
                <TableCellComponent>
                  <div class="data-cell">{{ registration.createdAt }}</div>
                </TableCellComponent>
                <TableCellComponent>
                  <span
                    :title="
                      registration.buildingName + ' ' + registration.roomNumber
                    "
                    class="building-name"
                  >
                    {{
                      registration.buildingName + "  " + registration.roomNumber
                    }}
                  </span>
                </TableCellComponent>
                <TableCellComponent
                  ><StatusRegistrationComponent
                    :status="registration.status"
                    :type="registration.registrationType"
                  />
                </TableCellComponent>
              </TableRowComponent>
            </div>
          </table>
        </div>

        <PaginationComponent
          :total-pages="pagination.totalPage"
          :current-page="pagination.page"
          v-if="pagination.totalPage > 1"
          @pagechanged="onPageChange"
        />
      </div>
    </CardComponent>
  </div>
</template>

<script>
import HomeService from "@/services/home.service";
import { STATUS } from "@/globals/enums";

export default {
  name: "HomeComponent",
  data: () => ({
    homeService: new HomeService(),
    pagination: { page: 1, number: 10, totalPage: 0 },
    total: 0,
    homeData: null,
  }),
  methods: {
    async onPageChange(event) {
      this.pagination.page = event;
      await this.getHomeData();
    },

    navigatePath(name) {
      this.$router.push({ name });
    },

    getHomeData(loading) {
      loading && this.$store.dispatch("global/updateLoadingStatus", true);
      this.homeService.getHomeData(this.pagination).then((homeData) => {
        if (homeData.status === STATUS.SUCCESS) {
          this.homeData = homeData.data;
          this.total = homeData.data.total;
          this.pagination = {
            page: homeData.data.page,
            totalPage: homeData.data.totalPage,
          };
        }
        loading && this.$store.dispatch("global/updateLoadingStatus", false);
      });
    },
  },

  mounted() {
    this.getHomeData(true);
  },
};
</script>
<style lang="scss" scoped>
.HomeComponent {
  &__group {
    width: 970px;
    margin: auto;
    display: flex;
    .ButtonComponent {
      font-size: 14px;
      font-weight: 500;
    }
    .v-card {
      margin: var(--default-distance-card) 0 10px 0;
    }
    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 35px 10px;
      .TitleComponent {
        .icon {
          margin-right: 5px;
        }
        color: var(--venice-blue-color);
      }
    }
    &__content {
      display: flex;
      justify-content: space-around;
      &__sub {
        text-align: center;
        border-left: 1px solid #dee2e2;
        padding: 45px 80px;
        &:nth-child(1) {
          border: unset;
        }
        .title {
          color: #000 !important;
          font-size: 20px !important;
          font-weight: bold;
        }
        .icon-main {
          font-size: 30px;
        }
      }
      .title {
        color: #7c8185;
        font-size: 14px;
        margin-bottom: 10px;
      }
      .result {
        font-weight: 500;
        text-align: center;
        &-number {
          font-size: 30px;
          font-weight: bold;
        }
      }
    }

    &__table {
      .TitleComponent {
        margin: 20px 35px 10px;
        color: var(--venice-blue-color);
        .icon {
          margin-right: 5px;
        }
      }
      .table-container {
        width: 890px;
        .col-small {
          width: 15%;
        }
        .TableCellComponent {
          padding: 5px 20px;
          vertical-align: middle;
          .data-cell {
            color: #7c8185;
            font-weight: normal;
            text-align: center;
          }
          .building-name {
            @include ellipsis(1);
          }
          .StatusRegistrationComponent {
            justify-content: center;
          }
        }
      }
      .pagination {
        text-align: center;
        margin: 25px;
      }
    }
  }
  .content__registration {
    margin-bottom: var(--default-distance-card);
  }
  .box {
    width: 70px;
    height: 30px;
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .color-yellow {
    border-color: #dcb46f !important;
    color: #dcb46f;
  }

  .color-green {
    border-color: #74afbb !important;
    color: #74afbb;
  }

  .color-blue {
    border-color: #748cbb !important;
    color: #748cbb;
  }
  .noPagination {
    margin-bottom: 30px;
  }
}
</style>
