<template>
  <div class="ReConfigMFAComponent">
    <TitleComponent>
      <template v-slot:icon><span class="icon-ico_guard"></span></template>
      <template v-slot:content>セキュリティ設定</template>
    </TitleComponent>
    <CardComponent
      class="card"
      :width="'960px'"
      :height="'510px'"
      v-if="currentUserMFAInfo.email.length > 0"
    >
      <p class="card__description">
        不正アクセス対策のため、パスワードに加え、登録されているデバイスの認証コードを確認します。
      </p>
      <div class="card__body">
        <div class="card__body_title">セキュリティ設定</div>
        <Form
          @submit="updateMFA"
          :validation-schema="schema"
          v-slot="{ meta, values }"
        >
          <div class="form__radioGroupInput">
            <div class="form__radioGroupInput-left">
              <InputComponent
                type="radio"
                name="typeMFA"
                id="typeMFA_SMS"
                value="SMS"
                label="電話番号に認証コードを送信する"
                :defaultValue="currentUserMFAInfo.MFAType"
                placeholder="電話番号を選択"
                @trigle-change="handleChangeType"
              />
            </div>
            <div class="form__radioGroupInput-right">
              <p class="form__radioGroupInput-right_title">電話番号</p>
              <div class="form__radioGroupInput-right_description">
                <InputComponent
                  name="telephone"
                  :small="true"
                  :defaultValue="currentUserMFAInfo.phoneNumber"
                  inputFilter="number"
                  maxlength="11"
                />
                <p class="desc">
                  電話番号はアカウントのセキュリティのために使用されます。<br />標準のSMS料金が適用されます。
                </p>
              </div>
            </div>
          </div>
          <div class="form__radioGroupInput">
            <div class="form__radioGroupInput-left">
              <InputComponent
                type="radio"
                name="typeMFA"
                id="typeMFA_TOTP"
                value="TOTP"
                label="Authenticatorアプリの認証コードを使用する"
                :defaultValue="currentUserMFAInfo.MFAType"
                @trigle-change="handleChangeType"
              />
            </div>
            <div class="form__radioGroupInput-right auto-left">
              <ButtonComponent
                width="100%"
                height="30px"
                className="textTransformNone"
                :disabled="values.typeMFA === TYPE_MFA.SMS"
                @click="updateAuthApp"
              >
                Authenticatorアプリの設定
              </ButtonComponent>
            </div>
          </div>
          <div class="form_actionGroup">
            <ButtonComponent
              :disabled="
                !(meta.valid && meta.dirty) || values.typeMFA === TYPE_MFA.TOTP
              "
              buttonType="submit"
            >
              保存
            </ButtonComponent>
          </div>
        </Form>
      </div>
    </CardComponent>
  </div>
</template>

<script>
import { TYPE_MFA } from "../globals/enums.js";
import LoginService from "@/services/login.service";
import { Form } from "vee-validate";
import { mapState } from "vuex";
import { reConfigMFASchema } from "../validations/schemas/reConfigMFASchema";

export default {
  name: "ReConfigMFAComponent",
  data: () => ({
    loginService: new LoginService(),
    type: TYPE_MFA,
    schema: reConfigMFASchema,
    userChoiseValue: null,
  }),
  components: {
    Form,
  },
  created() {
    this.TYPE_MFA = TYPE_MFA;
  },
  async mounted() {
    await this.loginService.getCognitoUserData();
  },
  computed: {
    ...mapState({
      currentUserMFAInfo: (state) => state?.settings?.currentUserMFAInfo,
      isUserUsingTOTP: (state) =>
        state?.settings?.currentUserMFAInfo.MFAType === TYPE_MFA.TOTP,
    }),
  },
  methods: {
    updateAuthApp() {
      this.$router.push({ name: "ReSettingAuthAppComponent" });
    },
    async updateMFA(data) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      data.typeMFA === TYPE_MFA.SMS
        ? await this.loginService.registerSMSAgain(data.telephone)
        : await this.loginService.setPreferredTOTP();
      await this.loginService.getCognitoUserData();
      this.$store.dispatch("global/updateLoadingStatus", false);
    },
    handleChangeType(e) {
      this.userChoiseValue = e.target.value;
    },
  },
};
</script>

<style scoped lang="scss">
.ReConfigMFAComponent {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--default-distance-card);
  .TitleComponent {
    width: 960px;
    text-align: left;
    color: var(--venice-blue-color);
    padding: 0 0 11px 0;
    .icon-ico_guard {
      &::before {
        font-size: 29px;
      }
    }
  }
  .card {
    display: flex;
    flex-direction: column;
    padding: 30px 40px 0px;
    &__description {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    &__body {
      &_title {
        padding-bottom: 5px;
        text-align: left;
        color: #000;
        font-size: 16px;
        font-weight: 500;
        border-bottom: solid 2px #d0d9df;
      }
      .form {
        &__radioGroupInput {
          padding: 33px 20px 33px 10px;
          display: flex;
          gap: 58px;
          border-bottom: solid 1px #d0d9df;
          &-left {
            font-weight: 500;
            font-size: 14px;
            line-height: 0.86;
            letter-spacing: 0.7px;
            label {
              font-size: 14px;
              font-weight: 600;
            }
          }
          &-right {
            display: flex;
            gap: 20px;
            &.auto-left {
              margin-left: auto;
              .ButtonComponent {
                width: 260px;
                button {
                  padding: 4px 36px 6px;
                }
              }
            }
            &_title {
              color: #7c8185;
              width: 100px;
              font-size: 14px;
              font-weight: normal;
            }
            &_description {
              color: #7c8185;
              p {
                margin-top: 28px;
                font-size: 12px;
                line-height: 1.5;
                color: #7c8185;
              }
            }
            .InputComponent {
              margin: 0;
              width: 240px;
            }
          }
        }
        &_actionGroup {
          padding-top: 30px;
          .ButtonComponent {
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
