import showDialog from "@/services/dialog.service";
import ResultRegistrationDialog from "@/components/dialogs/ResultRegistrationDialog.vue";

export default class ConfirmResultRegistraionDialogService {
  openConfirmResultRegistraionDialog(
    title,
    content,
    message,
    isDetail,
    callbackClose,
    callbackChoise
  ) {
    showDialog({
      component: ResultRegistrationDialog,
      bind: {
        title,
        content,
        message,
        isDetail,
        onClose: callbackClose,
        onChoise: callbackChoise,
      },
    });
  }
}
