export const converterHome = (homeData) => {
  return {
    numberBuilding: homeData.num_building,
    numberRoom: homeData.num_room,
    numberBuildingRegistration: homeData.num_building_registration_unprocess,
    numberBuildingRegistrationProcessing:
      homeData.num_building_registration_processing,
    numberBuildingRegistrationProcessed:
      homeData.num_building_registration_processed,
    numberElectricityRegistrationWaitProcess:
      homeData.num_electricity_registration_wait_process,
    numberElectricityRegistrationProcessing:
      homeData.num_electricity_registration_processing,
    numberElectricityRegistrationProcessed:
      homeData.num_electricity_registration_processed,
    numberElectricityRegistrationDecline:
      homeData.num_electricity_registration_decline,
    total: homeData.total,
    totalPage: homeData.total_page,
    page: homeData.page,
    incompleteRegistrationList: homeData.incomplete_registration_list.map(
      (resgistration) => ({
        registrationId: resgistration.registration_id,
        createdAt: resgistration.created_at,
        buildingName: resgistration.building_name,
        roomNumber: resgistration.room_number,
        registrationType: resgistration.registration_type,
        status: resgistration.status,
      })
    ),
  };
};
