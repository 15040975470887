import {
  getListFile,
  createFileSignedUrl,
  getInfoFile,
  uploadToServer,
  uploadFile,
} from "./api/upload.service";
import { STATUS } from "@/globals/enums";
export default class UploadService {
  async getListFile(pagination) {
    try {
      const result = await getListFile({
        page: pagination.page || 1,
        number: 10,
      });
      return {
        status: STATUS.SUCCESS,
        data: {
          total: result.total,
          page: result.page,
          totalPage: result.total_page || 0,
          fileList: result.file_list.map((file) => ({
            fileId: file.file_id,
            fileName: file.file_name,
            fileType: file.file_type,
            status: file.status,
            createdAt: file.created_at,
            createdBy: file.created_by,
          })),
        },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
      };
    }
  }

  async createUrl(fileName) {
    try {
      const result = await createFileSignedUrl({
        file_name: fileName,
        file_type: 1,
      });
      return {
        status: STATUS.SUCCESS,
        data: { ...result },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
      };
    }
  }

  async uploadToServer(url, file) {
    try {
      const result = await uploadToServer(url, file);
      return {
        status: STATUS.SUCCESS,
        data: { ...result },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
      };
    }
  }

  async uploadFile(id) {
    try {
      const result = await uploadFile(id, { status: 1 });
      return {
        status: STATUS.SUCCESS,
        data: { ...result },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
      };
    }
  }

  async getInfoFile(id) {
    try {
      const result = await getInfoFile(id);
      return {
        status: STATUS.SUCCESS,
        data: {
          status: result.status,
          detail: result.detail,
        },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
      };
    }
  }
}
