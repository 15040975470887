<template>
  <v-card-title
    class="TitleComponent"
    :class="{ className, underline: hasUnderLine }"
  >
    <div class="left-content">
      <span class="icon-title"><slot name="icon"></slot></span>
      <slot name="content"></slot>
    </div>
    <div class="right-content">
      <slot name="button"></slot>
    </div>
  </v-card-title>
</template>

<script>
export default {
  name: "TitleComponent",
  props: {
    hasUnderLine: {
      type: Boolean,
      default: false,
    },
    className: {
      type: [String],
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.TitleComponent {
  display: flex;
  justify-content: space-between;
  padding: 8px 0 !important;
  .icon-title {
    margin-right: 10px;
  }
  &.underline {
    padding: 0 5px;
    border-bottom: solid 3px #d0d9df;
  }
}
</style>
