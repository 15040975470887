export const WEB_STORAGE_KEY = {
  ID_TOKEN: "idtoken",
  ACCESS_TOKEN: "accesstoken",
  REFRESH_TOKEN: "refreshtoken",
  SERVER_TIME: "server_time",
  CLIENT_TIME: "client_time",
  USER_INFO: "user_info",
  USER_DATA: "user_data",
  INCOMPLETE_REGISTRATION: "incomplete_registration",
  COMPANY_INFO_VALID: "company_info_valid",
  SEARCH_BUILDING: "search_building",
};

export const TYPE_EXCEL = {
  CSV: {
    TYPE: "text/csv",
    FILE: "csv",
  },
  XLSM: {
    TYPE: "text/xlsm",
    FILE: "xlsm",
  },
  XLSX: {
    TYPE: "text/xlsx",
    FILE: "xlsx",
  },
};

export const TYPE_MFA = {
  TOTP: "TOTP",
  SMS: "SMS",
  NOMFA: "NOMFA",
};

export const TYPE_MFA_MAP = {
  SOFTWARE_TOKEN_MFA: "TOTP",
  SMS_MFA: "SMS",
  NO_MFA: "NOMFA",
};

export const USER_CHALLANGE_MAP = {
  SOFTWARE_TOKEN_MFA: "TOTP",
  SMS_MFA: "SMS",
  NO_MFA: "NOMFA",
};

export const STATUS = {
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
  NO_ACTION: "NO_ACTION",
};

export const STATUS_ELECTRICITY = {
  ON_LIST: {
    COLOR: "#dcb46f",
    LABEL: "ー",
    ID: 0,
  },
  WAITING: {
    COLOR: "#dcb46f",
    LABEL: "処理待ち",
    ID: 1,
  },
  PROCESSING: {
    COLOR: "#748cbb",
    LABEL: "処理中",
    ID: 2,
  },
  COMPLETED: {
    COLOR: "#74afbb",
    LABEL: "完了",
    ID: 3,
  },
  UNCONFIRMED: {
    COLOR: "#dc906f",
    LABEL: " 受付不可",
    ID: 4,
  },
  CONFIRMED: {
    COLOR: "#dc906f",
    LABEL: "受付不可",
    ID: 5,
  },
};

export const CHECKED = {
  TRUE: 1,
  FALSE: 0,
};

export const PHONE_REGION_START_NUMBER = "+81";

export const NUMBER_REGEX = "[0-9]+";
// for mapping a phone to 3 path
export const PHONE_PARSE = [
  {
    FROM_INDEX: 0,
    TO_INDEX: 3,
    NAME: "firstPathNumbers",
    MAX_LENGTH: "6",
    TYPE: "telephoneGroup",
    PATTERN: NUMBER_REGEX,
  },
  {
    FROM_INDEX: 3,
    TO_INDEX: 7,
    NAME: "secondPathNumbers",
    MAX_LENGTH: "4",
    TYPE: "telephoneGroup",
    PATTERN: NUMBER_REGEX,
  },
  {
    FROM_INDEX: 7,
    TO_INDEX: 11,
    NAME: "thirtPathNumbers",
    MAX_LENGTH: "4",
    TYPE: "telephoneGroup",
    PATTERN: NUMBER_REGEX,
  },
];

export const FORTMAT_DATE_TIME = {
  EXPORT_DATETIME: "YYYYMMDDHHmmss",
};

export const MAX_EMAIL_LENGTH = 255;

export const MIN_8_CHARACTER = 8;
export const MIN_4_CHARACTER = 4;

export const OTP_LENGTH = 6;

export const VALIDATES = {
  HAS_NUMBER: /[0-9]/,
  HAS_DOWNER_CHARACTER: /[a-z]/,
  HAS_UPPER_CHARACTER: /[A-Z]/,
};

export const SPECIAL_STRING = {
  OVER_8_CHARACTERS: "8文字以上",
  NUMBER: "数字",
  UPPER_DOWNER_CHARACTERS: "英字小文字",
};

//申込電話番号区分
export const REGISTER_PHONE_TYPES = [
  {
    NAME: "電話番号区分を選択",
    VALUE: 0,
  },
  {
    NAME: "自宅",
    VALUE: 1,
  },
  {
    NAME: "携帯",
    VALUE: 2,
  },
  {
    NAME: "家族・親戚",
    VALUE: 3,
  },
  {
    NAME: "配偶者",
    VALUE: 4,
  },
  {
    NAME: "家主・管理人",
    VALUE: 5,
  },
  {
    NAME: "事務所",
    VALUE: 6,
  },
  {
    NAME: "その他",
    VALUE: 9,
  },
];

export const REGISTER_PHONE_COMPANY_TYPES = [
  {
    NAME: "電話番号区分を選択",
    VALUE: 0,
  },
  {
    NAME: "携帯",
    VALUE: 2,
  },
  {
    NAME: "事務所",
    VALUE: 6,
  },
  {
    NAME: "その他",
    VALUE: 9,
  },
];

//申込電話番号区分
export const PAYER_PHONE_NUMBER_CATEGORY = [
  {
    NAME: "電話番号区分を選択",
    VALUE: 0,
  },
  {
    NAME: "自宅",
    VALUE: 1,
  },
  {
    NAME: "携帯",
    VALUE: 2,
  },
  {
    NAME: "家族・親戚",
    VALUE: 3,
  },
  {
    NAME: "配偶者",
    VALUE: 4,
  },
];

export const TRANSFER_ADDRESS_OPTIONS = [
  {
    NAME: "会社情報の登録先住所と同一",
    VALUE: 1,
  },
  {
    NAME: "送付先住所を新たに指定",
    VALUE: 2,
  },
];

export const TRANSFER_ADDRESS_OPTIONS_DEFAULT = 2;

//支払方法
export const PAYMENT_OPTIONS = [
  {
    NAME: "振込",
    VALUE: 1,
  },
  {
    NAME: "口座振替",
    VALUE: 2,
  },
];

export const PAYMENT_OPTIONS_DEFAULT_VALUE = 2;

//申し込み方法
export const COMPANY_REGISTER_TYPES = [
  {
    NAME: "申し込み方法を選択",
    VALUE: 0,
  },
  {
    NAME: "媒介",
    VALUE: 1,
  },
  {
    NAME: "紹介",
    VALUE: 2,
  },
];

export const COMPANY_REGISTER_CONTRACT_TYPES = {
  HAS_REGISTER_CONTRACT: {
    LABEL: "有",
    VALUE: 1,
  },
  NO_REGISTER_CONTRACT: {
    LABEL: "無",
    VALUE: 0,
  },
  DEFAULT_VALUE: 1,
};

//申し込み方法
export const BANK_ACCOUNT_TYPES = [
  {
    NAME: "口座種別を選択",
    VALUE: 0,
  },
  {
    NAME: "普通",
    VALUE: 1,
  },
  {
    NAME: "当座",
    VALUE: 2,
  },
];

// 都道府県を選択
export const PROVIDES = [
  { VALUE: 0, LABEL: "都道府県を選択" },
  { VALUE: "福岡県", LABEL: "福岡県" },
  { VALUE: "佐賀県", LABEL: "佐賀県" },
  { VALUE: "長崎県", LABEL: "長崎県" },
  { VALUE: "熊本県", LABEL: "熊本県" },
  { VALUE: "大分県", LABEL: "大分県" },
  { VALUE: "宮崎県", LABEL: "宮崎県" },
  { VALUE: "鹿児島県", LABEL: "鹿児島県" },
];

export const COMPANY_SETTING_TAB = {
  EDIT: 1,
  PREVIEW: 2,
  DONE: 3,
};

export const REGISTRATION_SETTING_TAB = {
  EDIT: 1,
  CONFIRM: 2,
};

//electric room 申込種別
export const ELECTRIC_ROOM_REGISTRATION_TIME_OPTIONS = [
  { VALUE: 1, NAME: "使用開始・停止" },
  { VALUE: 2, NAME: "使用開始" },
  { VALUE: 3, NAME: "使用停止" },
];

export const ELECTRIC_ROOM_REGISTRATION_TIME_DEFAULT_OPTION = 1;
export const ELECTRIC_ROOM_REGISTRATION_TIME_START_OPTION = 2;
export const ELECTRIC_ROOM_REGISTRATION_TIME_END_OPTION = 3;

export const INIT_YEAR = 2022;

export const YEARS = [
  { VALUE: 2022, NAME: "2022" },
  { VALUE: 2023, NAME: "2023" },
  { VALUE: 2024, NAME: "2024" },
  { VALUE: 2025, NAME: "2025" },
  { VALUE: 2026, NAME: "2026" },
  { VALUE: 2027, NAME: "2027" },
  { VALUE: 2028, NAME: "2028" },
  { VALUE: 2029, NAME: "2029" },
  { VALUE: 2030, NAME: "2030" },
];

export const INIT_MONTH = 1;

export const MONTHS = [
  { VALUE: 1, NAME: "01" },
  { VALUE: 2, NAME: "02" },
  { VALUE: 3, NAME: "03" },
  { VALUE: 4, NAME: "04" },
  { VALUE: 5, NAME: "05" },
  { VALUE: 6, NAME: "06" },
  { VALUE: 7, NAME: "07" },
  { VALUE: 8, NAME: "08" },
  { VALUE: 9, NAME: "09" },
  { VALUE: 10, NAME: "10" },
  { VALUE: 11, NAME: "11" },
  { VALUE: 12, NAME: "12" },
];

export const INIT_DATE = 1;

export const DATES = [
  { VALUE: 1, NAME: "01" },
  { VALUE: 2, NAME: "02" },
  { VALUE: 3, NAME: "03" },
  { VALUE: 4, NAME: "04" },
  { VALUE: 5, NAME: "05" },
  { VALUE: 6, NAME: "06" },
  { VALUE: 7, NAME: "07" },
  { VALUE: 8, NAME: "08" },
  { VALUE: 9, NAME: "09" },
  { VALUE: 10, NAME: "10" },
  { VALUE: 11, NAME: "11" },
  { VALUE: 12, NAME: "12" },
  { VALUE: 13, NAME: "13" },
  { VALUE: 14, NAME: "14" },
  { VALUE: 15, NAME: "15" },
  { VALUE: 16, NAME: "16" },
  { VALUE: 17, NAME: "17" },
  { VALUE: 18, NAME: "18" },
  { VALUE: 19, NAME: "19" },
  { VALUE: 20, NAME: "20" },
  { VALUE: 21, NAME: "21" },
  { VALUE: 22, NAME: "22" },
  { VALUE: 23, NAME: "23" },
  { VALUE: 24, NAME: "24" },
  { VALUE: 25, NAME: "25" },
  { VALUE: 26, NAME: "26" },
  { VALUE: 27, NAME: "27" },
  { VALUE: 28, NAME: "28" },
  { VALUE: 29, NAME: "29" },
  { VALUE: 30, NAME: "30" },
  { VALUE: 31, NAME: "31" },
];

export const TIMES = [
  { VALUE: 9, NAME: "9時台" },
  { VALUE: 10, NAME: "10時台" },
  { VALUE: 11, NAME: "11時台" },
  { VALUE: 12, NAME: "12時台" },
  { VALUE: 13, NAME: "13時台" },
  { VALUE: 14, NAME: "14時台" },
  { VALUE: 15, NAME: "15時台" },
  { VALUE: 16, NAME: "16時台" },
  { VALUE: 17, NAME: "17時台" },
  { VALUE: 96, NAME: "AM" },
  { VALUE: 97, NAME: "PM" },
  { VALUE: 98, NAME: "指定なし" },
];
export const TIME_DEFAULT = 98;

export const ELECTRIC_ROOM_REGISTRATION_START = {
  VALUE: 1,
  NAME: "使用開始",
};

export const ELECTRIC_ROOM_REGISTRATION_END = {
  VALUE: 2,
  NAME: "使用停止",
};

export const RESIDENT_SETTING_TAB = {
  EDIT: 1,
  CONFIRM: 2,
};
export const NO_ELECTRIC_WATER_HEATER = [
  {
    NAME: "スマートファミリープラン",
    VALUE: 1,
  },
];
export const HAS_ELECTRIC_WATER_HEATER = [
  {
    NAME: "電化でナイトセレクト21",
    VALUE: 2,
  },
  {
    NAME: "電化でナイトセレクト22",
    VALUE: 3,
  },
  {
    NAME: "電化でナイトセレクト23",
    VALUE: 4,
  },
];

export const RATE_PLAN_DEFAULT = 1;

export const CONTRACTOR_PHONE_PARSE = [
  {
    FROM_INDEX: 0,
    TO_INDEX: 3,
    NAME: "contractorFirstPathNumbers",
    MAX_LENGTH: "6",
    TYPE: "telephoneGroup",
    PATTERN: NUMBER_REGEX,
  },
  {
    FROM_INDEX: 3,
    TO_INDEX: 7,
    NAME: "contractorSecondPathNumbers",
    MAX_LENGTH: "4",
    TYPE: "telephoneGroup",
    PATTERN: NUMBER_REGEX,
  },
  {
    FROM_INDEX: 7,
    TO_INDEX: 11,
    NAME: "contractorThirtPathNumbers",
    MAX_LENGTH: "4",
    TYPE: "telephoneGroup",
    PATTERN: NUMBER_REGEX,
  },
];

export const APPLICATED_PERSON_PHONE_PARSE = [
  {
    FROM_INDEX: 0,
    TO_INDEX: 3,
    NAME: "applicatedFirstPathNumbers",
    MAX_LENGTH: "6",
    TYPE: "telephoneGroup",
    PATTERN: NUMBER_REGEX,
  },
  {
    FROM_INDEX: 3,
    TO_INDEX: 7,
    NAME: "applicatedSecondPathNumbers",
    MAX_LENGTH: "4",
    TYPE: "telephoneGroup",
    PATTERN: NUMBER_REGEX,
  },
  {
    FROM_INDEX: 7,
    TO_INDEX: 11,
    NAME: "applicatedThirtPathNumbers",
    MAX_LENGTH: "4",
    TYPE: "telephoneGroup",
    PATTERN: NUMBER_REGEX,
  },
];

export const RESIDENT_PAYMENT_ADDRESS_OPTIONS = [
  {
    NAME: "使用場所と同じ",
    VALUE: 1,
  },
  {
    NAME: "使用場所と異なる",
    VALUE: 2,
  },
];

export const RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT = 2;

export const CONNECTION_WITH_APPLICATED_PERSON_OPTIONS = [
  {
    VALUE: 1,
    NAME: "配偶者",
  },
  {
    VALUE: 2,
    NAME: "家族・親戚",
  },
  {
    VALUE: 3,
    NAME: "家主・管理人",
  },
  {
    VALUE: 4,
    NAME: "電気工事店",
  },
  {
    VALUE: 5,
    NAME: "仲介解体業者",
  },
  {
    VALUE: 99,
    NAME: "その他",
  },
  {
    VALUE: 0,
    NAME: "本人",
  },
];

export const CONNECTION_WITH_APPLICATED_PERSON_DEFAULT = 99;

export const PAYMENT_PERSON_TELEPHONE_OPTIONS = [
  {
    NAME: "ご契約者電話番号と同一",
    VALUE: 1,
  },
  {
    NAME: "ご契約者電話番号と異なる",
    VALUE: 2,
  },
];

export const PAYMENT_PERSON_TELEPHONE_DEFAULT = 2;

export const PAYMENT_PERSON_PHONE_PARSE = [
  {
    FROM_INDEX: 0,
    TO_INDEX: 3,
    NAME: "paymentFirstPathNumbers",
    MAX_LENGTH: "6",
    TYPE: "telephoneGroup",
    PATTERN: NUMBER_REGEX,
  },
  {
    FROM_INDEX: 3,
    TO_INDEX: 7,
    NAME: "paymentSecondPathNumbers",
    MAX_LENGTH: "4",
    TYPE: "telephoneGroup",
    PATTERN: NUMBER_REGEX,
  },
  {
    FROM_INDEX: 7,
    TO_INDEX: 11,
    NAME: "paymentThirtPathNumbers",
    MAX_LENGTH: "4",
    TYPE: "telephoneGroup",
    PATTERN: NUMBER_REGEX,
  },
];

export const RESIDENT_PAYMENT_OPTIONS = [
  {
    VALUE: 1,
    NAME: "振込用紙",
  },
  {
    VALUE: 2,
    NAME: "口座振替",
  },
  {
    VALUE: 3,
    NAME: "クレジットカード",
  },
];

export const GAS_SERVICE_INTRODUCE_OPTIONS = [
  {
    VALUE: 1,
    NAME: "可",
  },
  {
    VALUE: 0,
    NAME: "不可",
  },
];

export const KYUDEN_GROUP_INTERNET_INTRODUCE_OPTIONS = [
  {
    VALUE: 1,
    NAME: "可",
  },
  {
    VALUE: 0,
    NAME: "不可",
  },
];

export const REGISTRATION_STATUS = {
  WAITING: {
    VALUE: 1,
    LABEL: "処理待ち",
    TEXT: "処理待ち",
  },
  PROCESS: {
    VALUE: 2,
    LABEL: "処理中",
    TEXT: "処理中",
  },
  COMPLETE: {
    VALUE: 3,
    LABEL: "処理完了",
    TEXT: "処理完了",
  },
  UNCONFIRMED: {
    VALUE: 4,
    LABEL: "受付不可(未確認)",
    TEXT: "受付不可(未)",
  },
  CONFIRMED: {
    VALUE: 5,
    LABEL: "受付不可(確認済み)",
    TEXT: "受付不可(確)",
  },
};

export const REGISTRATION_TYPE = {
  USE_START: {
    VALUE: 1,
    LABEL: "使用開始",
    TEXT: "使用開始",
  },
  USE_STOP: {
    VALUE: 2,
    LABEL: "使用停止",
    TEXT: "使用停止",
  },
  MOVE_IN: {
    VALUE: 3,
    LABEL: "入居申込み",
    TEXT: "入居申込み",
  },
};

export const EXECUTE_TIME = [
  { LABEL: "9時台", ID: 9 },
  { LABEL: "10時台", ID: 10 },
  { LABEL: "11時台", ID: 11 },
  { LABEL: "12時台", ID: 12 },
  { LABEL: "13時台", ID: 13 },
  { LABEL: "14時台", ID: 14 },
  { LABEL: "15時台", ID: 15 },
  { LABEL: "16時台", ID: 16 },
  { LABEL: "17時台", ID: 17 },
  { LABEL: "AM", ID: 96 },
  { LABEL: "PM", ID: 97 },
  { LABEL: "指定なし", ID: 98 },
];

export const FILE_STATUS = {
  TREATED: {
    ID: [6],
    VALUE: "処理済み",
  },
  UDTREATED: {
    ID: [3],
    VALUE: "未処理",
  },
  PROCESS: {
    ID: [5, 7],
    VALUE: "処理中",
  },
};

export const SORT_ORDER = {
  ASC: 1,
  DESC: 2,
};

export const STATUS_409 = 409;

export const STATUS_403 = 403;

export const STATUS_404 = 404;

export const STATUS_401 = 401;

export const REGEX_5XX = /^5\d{2}$/g;
export const UPLOAD_STATUS = {
  SUCCESS: 3,
  FAIL: 4,
};

export const NOT_NUMBER_VALUE = /\D/g;

// eslint-disable-next-line
export const HAS_CHAR_IS_NOT_HALF_WIDTH =
  // eslint-disable-next-line
  /[^(a-zA-Z0-9ｧ-ﾝﾞﾟ)|(ﾞﾟ()･.!"#$%&'()*+,-.\/\-)]/gm;

// eslint-disable-next-line
export const CHECK_FULL_WIDTH =
  // eslint-disable-next-line
  /[—〜¢£＇＂~〳〴〵〻〼ヿゟ⊄⊅⊊⊋∉∅⌅⌆⊕⊖⊗∦｟｠〘〙〖〗≢≃≅≈≶≷↔♮♫♬♩▷▶◁◀↗↘↖↙⇄⇨⇦⇧⇩⤴⤵⦿◉〽﹆﹅◦•∓ℵℏ㏋ℓ℧゠–⧺⧻ゔゕゖ゚ς⓵⓶⓷⓸⓹⓺⓻⓼⓽⓾☖☗〠☎☀☁☂☃♨▱ㇰㇱㇲㇳㇴㇵㇶㇷㇸㇹㇷㇺㇻㇼㇽㇾㇿ⎾⎿⏀⏁⏂⏃⏄⏅⏆⏇⏈⏉⏊⏋⏌ヷヸヹヺ⋚⋛⅓⅔⅕✓⌘␣⏎ⅪⅫ❖☞♤♠♢♦♡♥♧♣㉑㉒㉓㉔㉕㉖㉗㉘㉙㉚㉛㉜㉝㉞㉟㊱㊲㊳㊴㊵㊶㊷㊸㊹㊺㊻㊼㊽㊾㊿        ◐◑◒◓‼⁇⁈⁉ǍǎǐḾḿǸǹǑǒǔǖǘǚǜ  € ¡¤¦©ª«­®¯²³·¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿĀĪŪĒŌāīūēōĄ˘ŁĽŚŠŞŤŹŽŻą˛łľśˇšşťź˝žżŔĂĹĆČĘĚĎŃŇŐŘŮŰŢŕăĺćčęěďđńňőřůűţ˙ĈĜĤĴŜŬĉĝĥĵŝŭɱʋɾʃʒɬɮɹʈɖɳɽʂʐɻɭɟɲʝʎɡŋɰʁħʕʔɦʘǂɓɗʄɠƓœŒɨʉɘɵəɜɞɐɯʊɤʌɔɑɒʍɥʢʡɕʑɺɧɚæ̀ǽὰάɔ̀ɔ́ʌ̀ʌ́ə̀ə́ɚ̀ɚ́ὲέ͡ˈˌːˑ̆‿̋́̄̀̏̌̂˥˦˧˨˩˩˥˥˩̥̬̹̜̟̠̩̯̈̽˞̴̤̰̼̝̞̘̙̪̺̻̃̚         ⁑⁂❶❷❸❹❺❻❼❽❾❿⓫⓬⓭⓮⓯⓰⓱⓲⓳⓴ⅰⅱⅲⅳⅴⅵⅶⅷⅸⅹⅺⅻⓐⓑⓒⓓⓔⓕⓖⓗⓘⓙⓚⓛⓜⓝⓞⓟⓠⓡⓢⓣⓤⓥⓦⓧⓨⓩ㋐㋑㋒㋓㋔㋕㋖㋗㋘㋙㋚㋛㋜㋝㋞㋟㋠㋡㋢㋣㋺㋩㋥㋭㋬]/g;

export const emailFormat =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const numberFormat = /^[0-9]*$/;
