import { STATUS } from "@/globals/enums";
import { getHoliday, getPrint } from "./api/common.service";
export default class CommonService {
  async getHolidayList() {
    try {
      const result = await getHoliday();
      return {
        status: STATUS.SUCCESS,
        data: {
          holidateList: result.holiday_list,
        },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
      };
    }
  }

  async getPrint() {
    try {
      const result = await getPrint();
      return {
        status: STATUS.SUCCESS,
        data: {
          printId: result.print_id,
        },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
      };
    }
  }
}
