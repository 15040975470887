<template>
  <div class="LoginMFAComponent">
    <Form @submit="loginMFA" :validation-schema="schema" v-slot="{ meta }">
      <CardComponent class="form-loginMFA" :width="'960px'" :height="'550px'">
        <TitleComponent class="form-loginMFA_title" :hasUnderLine="true">
          <template v-slot:content>多要素認証のコード入力</template>
        </TitleComponent>
        <p class="form-loginMFA_description">
          送信された認証コードを入力してください。
        </p>

        <div class="form-loginMFA_inputGroups">
          <div class="form-loginMFA-inputGroup"></div>
          <div class="form-loginMFA-inputGroup">
            <InputComponent
              name="code"
              placeholder="認証コードを入力"
              maxlength="6"
              inputFilter="number"
              @trigle-change="errorDetail = null"
            />
          </div>
        </div>
        <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
        <div class="form-loginMFA-action-group">
          <ButtonComponent
            buttonType="button"
            type="outline"
            width="160px"
            @click="cancel"
          >
            キャンセル
          </ButtonComponent>
          <ButtonComponent
            buttonType="submit"
            width="160px"
            :disabled="!(meta.valid && meta.dirty)"
          >
            OK
          </ButtonComponent>
        </div>
        <p class="form-loginMFA-resetMFA" @click="resetMFA">
          <span class="icon-ico_lock"></span> 多要素認証をリセットする
        </p>
      </CardComponent>
    </Form>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import LoginService from "@/services/login.service";
import { mapState } from "vuex";
import { loginMFASchema } from "@/validations/schemas/loginMFASchema";
import { STATUS } from "@/globals/enums";

export default {
  name: "LoginMFAComponent",
  components: {
    Form,
  },
  computed: mapState({
    tempUser: (state) => state.settings.tempUser,
  }),
  data: () => ({
    loginService: new LoginService(),
    schema: loginMFASchema,
    errorDetail: null,
  }),
  methods: {
    async loginMFA(data) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      const result = await this.loginService.signIn(
        this.tempUser.emailAddress,
        this.tempUser.tempPassword,
        null,
        data.code
      );
      this.$store.dispatch("global/updateLoadingStatus", false);
      if (result?.status === STATUS.ERROR && result?.response?.detail) {
        this.errorDetail = result.response.detail;
      }
    },
    async cancel(e) {
      e.preventDefault();
      await this.loginService.logout();
    },
    async resetMFA() {
      await this.$router.push({ name: "ResetMFAComponent" });
    },
  },
};
</script>

<style scoped lang="scss">
.LoginMFAComponent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    &-loginMFA {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 30px;
      &_title {
        font-size: 20px;
        font-weight: 500;
      }
      &_description {
        font-size: 14px;
        font-weight: 500;
        margin: 20px 0 0;
      }
      &_inputGroups {
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 0 220px 40px;
      }
      &-inputGroup {
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: space-between;
        &_title {
          font-size: 14px;
          font-weight: bold;
          color: #7c8185;
        }
        .InputComponent {
          margin: 0;
        }
      }
      &-action-group {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
      }
      &-resetMFA {
        cursor: pointer;
        display: inline-flex;
        color: var(--color-black-text);
        font-weight: 500;
        margin-top: 15px;
        .icon-ico_lock {
          font-size: 25px;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
