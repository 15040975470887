<template>
  <ul class="pagination">
    <li class="pagination-item btn-prev">
      <button
        type="button"
        @click="onClickPreviousPage"
        :disabled="isInFirstPage"
      >
        <span
          :class="{ 'active-arrow': !isInFirstPage }"
          class="icon-ico_prev"
        ></span>
      </button>
    </li>

    <li v-for="page in pages" class="pagination-item" :key="page">
      <button
        type="button"
        class="number-page"
        @click="onClickPage(page.name)"
        v-if="page.name >= 1"
        :disabled="page.isDisabled"
        :class="{ active: isPageActive(page.name) }"
        :aria-label="`Go to page number ${page.name}`"
      >
        {{ page.name }}
      </button>
    </li>

    <li class="pagination-item btn-next">
      <button type="button" @click="onClickNextPage" :disabled="isInLastPage">
        <span
          :class="{ 'active-arrow': !isInLastPage }"
          class="icon-ico_next"
        ></span>
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: "PaginationComponent",
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: false,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons + 1;
      }

      return this.currentPage - 1;
    },
    endPage() {
      return Math.min(
        this.startPage + this.maxVisibleButtons - 1,
        this.totalPages
      );
    },
    pages() {
      const range = [];

      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.currentPage + 1);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  },
};
</script>
<style lang="scss">
.pagination {
  list-style-type: none;
}

.pagination-item {
  display: inline-block;
}

.number-page {
  width: 33.6px;
  height: 33.6px;
  border-radius: 50%;
  margin: 0 10.2px 0 0;
  background-color: #fff;
  color: #d0d9df;
}

.active {
  background-color: #94acbf;
  color: #ffffff;
}

.active-arrow {
  &::before {
    color: #4c7495 !important;
  }
}
.btn-prev {
  margin-right: 29px;
}
.btn-next {
  margin-left: 29px;
}
</style>
