import showDialog from "@/services/dialog.service";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";

export default class ConfirmDialogService {
  openConfirmDialog(title, content, callbackClose, className) {
    showDialog({
      component: ConfirmDialog,
      bind: {
        title,
        content,
        onClose: callbackClose,
        className,
      },
    });
  }
}
