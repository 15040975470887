import { del, get, post, put } from "./../https/http.service";

export const searchBuilding = (params) => {
  Object.keys(params).forEach((key) => {
    if (!params[key]) {
      delete params[key];
    }
  });
  return get("estate/building/list", params);
};

export const createBuilding = (body) => {
  return post("estate/building", body);
};

export const getBuildingById = (id) => {
  return get(`estate/building/${id}`);
};

export const updateBuilding = (id, body) => {
  return put(`estate/building/${id}`, body);
};

export const deleteBuildingById = (id) => {
  return del(`estate/building/${id}`);
};

export const getBuildingStatusById = (id) => {
  return get(`estate/building/status/${id}`);
};
