<template>
  <div class="ListUploadComponent">
    <div class="upload-file">
      <TitleComponent class="ListUploadComponent-title">
        <template v-slot:icon>
          <span class="icon-title icon-ico_upload"></span>
        </template>
        <template v-slot:content>建物情報一括登録</template>
      </TitleComponent>
      <div v-if="isFailUpload" class="fail-upload">
        <div class="notice">
          登録に失敗しました。CSVファイルを修正した上で、再度アップロードをおねがいします。
        </div>
        <div>{{ detailContentFail }}</div>
      </div>
      <CardComponent width="960px" height="269px">
        <GroupContentComponent>
          <template v-slot:title>
            <div class="title-group">専用CSVフォーマット</div>
          </template>
          <template v-slot:content>
            一括登録はこちらのフォーマットをダウンロードの上ご利用ください。
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> ダウンロードファイル </template>
          <template v-slot:content>
            <div class="export-example-file">
              <ButtonComponent
                @click="downloadFile"
                width="128px"
                height="30px"
              >
                ダウンロード
              </ButtonComponent>
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title>
            <div class="title-group">ファイルアップロード</div>
          </template>
          <template v-slot:content>
            建物情報を一括で登録することが可能です。
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> アップロードファイル </template>
          <template v-slot:content>
            <div class="upload-file-action">
              <input
                type="file"
                name=""
                id=""
                ref="file"
                accept=".csv"
                style="display: none"
                v-on:change="onFile($event)"
              />
              <span v-if="fileUpload" class="fileUpload-name">{{
                fileUpload?.name
              }}</span>
              <ButtonComponent
                @click="onChooseFile($event)"
                width="128px"
                height="30px"
                type="outline"
              >
                ファイルを選択
              </ButtonComponent>
              <div class="upload-file-action__upload">
                <ButtonComponent
                  buttonType="button"
                  @click="onCancelUpload($event)"
                  width="128px"
                  height="30px"
                  type="outline"
                >
                  キャンセル
                </ButtonComponent>
                <ButtonComponent
                  :disabled="!fileUpload"
                  width="128px"
                  height="30px"
                  @click="onUpload"
                >
                  アップロード
                </ButtonComponent>
              </div>
            </div>
          </template>
        </GroupContentComponent>
      </CardComponent>
    </div>
    <div class="list" v-if="!isSearching">
      <div class="title-group">
        <TitleComponent class="ListUploadComponent-title">
          <template v-slot:icon>
            <span class="icon-title icon-ico_uploadlist"></span>
          </template>
          <template v-slot:content> アップロード一覧 </template>
        </TitleComponent>
      </div>
      <CardComponent
        :isEmpty="!(fileList.length > 0)"
        :width="'960px'"
        :height="'782px'"
        class="table-upload"
        emptyText="アップロードされていません。"
      >
        <div class="table-main-content">
          <p class="count-item">
            <strong
              v-tableRange="{
                currentPage: pagination?.page || 0,
                currentTotal: fileList?.length || 0,
              }"
            />
            <span class="total">件/{{ total }}件</span>
          </p>
          <table class="table-container">
            <tr>
              <TableHeaderComponent :alignStart="true" class="datetime-cell"
                >アップロード日時</TableHeaderComponent
              >
              <TableHeaderComponent :alignStart="true" class="namefile-cell"
                >ファイル名</TableHeaderComponent
              >
              <TableHeaderComponent :alignStart="true" class="status-cell"
                >ステータス</TableHeaderComponent
              >
              <TableHeaderComponent :alignStart="true" class="updateby-cell"
                >対応者</TableHeaderComponent
              >
            </tr>
            <div class="table-row-group">
              <TableRowComponent v-for="file in fileList" :key="file.fileId">
                <TableCellComponent :title="file.createdAt">
                  <div class="cell-data cell-updated-at">
                    {{ file.createdAt }}
                  </div>
                </TableCellComponent>
                <TableCellComponent :title="file.fileName"
                  ><div class="cell-data">
                    {{ file.fileName }}
                  </div></TableCellComponent
                >
                <TableCellComponent>
                  <!-- TODO -->
                  <div
                    class="cell-data cell-status"
                    v-if="
                      file.status === 3 ||
                      file.status === 5 ||
                      file.status === 6 ||
                      file.status === 7
                    "
                    :class="{
                      'color-yellow': FILE_STATUS.UDTREATED.ID.includes(
                        file.status
                      ),
                      'color-green': FILE_STATUS.TREATED.ID.includes(
                        file.status
                      ),
                      'color-blue': FILE_STATUS.PROCESS.ID.includes(
                        file.status
                      ),
                    }"
                  >
                    {{
                      FILE_STATUS.UDTREATED.ID.includes(file.status)
                        ? FILE_STATUS.UDTREATED.VALUE
                        : ""
                    }}
                    {{
                      FILE_STATUS.TREATED.ID.includes(file.status)
                        ? FILE_STATUS.TREATED.VALUE
                        : ""
                    }}
                    {{
                      FILE_STATUS.PROCESS.ID.includes(file.status)
                        ? FILE_STATUS.PROCESS.VALUE
                        : ""
                    }}
                  </div></TableCellComponent
                >
                <TableCellComponent :title="file.createdBy">
                  <div class="cell-data">{{ file.createdBy }}</div>
                </TableCellComponent>
              </TableRowComponent>
            </div>
          </table>
        </div>

        <PaginationComponent
          :total-pages="pagination.totalPage"
          :current-page="pagination.page"
          v-if="pagination.totalPage > 1"
          @pagechanged="onPageChange"
        />
      </CardComponent>
    </div>
  </div>
</template>

<script>
import UploadService from "@/services/upload.service";
import { STATUS, FILE_STATUS, UPLOAD_STATUS } from "@/globals/enums";
import ConfirmDialogService from "@/services/dialog/confirm-dialog.service";
import csvFileExample from "@/assets/template/csv/template.csv";
import { exportCSV } from "@/utils/utils";
export default {
  name: "ListUploadComponent",
  data: () => ({
    fileList: [],
    pagination: { page: 1, number: 10, totalPage: 0 },
    total: 0,
    uploadService: new UploadService(),
    fileUpload: null,
    isFailUpload: false,
    intervalTime: process.env.VUE_APP_RELOAD_API, // 5s
    confirmDialogService: new ConfirmDialogService(),
    detailContentFail: null,
    isSearching: true,
  }),
  created() {
    this.FILE_STATUS = FILE_STATUS;
  },
  methods: {
    onFile(e) {
      const name = e.target.files[0]?.name;
      const type = name.split(".")[1];
      if (type !== "csv") {
        return;
      }
      this.fileUpload = e.target.files[0];
    },
    onChooseFile(e) {
      e.preventDefault();
      this.$refs.file.click();
    },
    async onPageChange(event) {
      this.pagination.page = event;
      this.getListFile(false);
    },
    async getListFile(loading) {
      if (loading) {
        this.isSearching = true;
        this.$store.dispatch("global/updateLoadingStatus", true);
      }
      this.uploadService.getListFile(this.pagination).then((dataFileList) => {
        if (dataFileList.status === STATUS.SUCCESS) {
          this.fileList = dataFileList.data.fileList;
          this.total = dataFileList.data.total;
          this.pagination = {
            page: dataFileList.data.page,
            totalPage: dataFileList.data.totalPage,
          };
        }
        this.isSearching = false;
        this.$store.dispatch("global/updateLoadingStatus", false);
      });
    },

    onCancelUpload(e) {
      e.preventDefault();
      this.fileUpload = null;
      this.$refs.file.value = "";
    },

    downloadFile() {
      // TODO
      const columns = Object.keys(csvFileExample[0]).map((key) => {
        csvFileExample[0][key] = JSON.parse(csvFileExample[0][key]) || "";
        return {
          header: key,
          key,
        };
      });
      const data = csvFileExample;
      exportCSV(columns, data, "sample");
    },

    onUpload() {
      if (!this.fileUpload) return;
      const fileName = this.fileUpload.name;
      this.$store.dispatch("global/updateLoadingStatus", true);
      this.uploadService
        .createUrl(fileName)
        .then((result) => {
          if (result.status !== STATUS.SUCCESS) {
            this.$store.dispatch("global/updateLoadingStatus", false);
            return;
          }
          const url = result.data.upload_url;
          const fileId = result.data.file_id;
          Promise.all([
            this.uploadService.uploadFile(fileId),
            this.uploadService.uploadToServer(url, this.fileUpload),
          ])
            .then(([dataUpload, dataServer]) => {
              if (
                dataUpload.status !== STATUS.SUCCESS &&
                dataServer.status !== STATUS.SUCCESS
              ) {
                this.$store.dispatch("global/updateLoadingStatus", false);
                return;
              }
              const interval = setInterval(() => {
                this.uploadService
                  .getInfoFile(fileId)
                  .then((result) => {
                    if (result.status !== STATUS.SUCCESS) {
                      this.$store.dispatch("global/updateLoadingStatus", false);
                      return;
                    }
                    switch (result.data.status) {
                      case UPLOAD_STATUS.SUCCESS:
                        this.$store.dispatch(
                          "global/updateLoadingStatus",
                          false
                        );
                        this.confirmDialogService.openConfirmDialog(
                          "ファイルアップロードが完了しました。",
                          "一括登録ファイルのアップロードが完了いたしました。登録された情報は、データ処理完了した上で、物件検索からご確認できるようになります。",
                          this.getListFile
                        );
                        this.fileUpload = null;
                        this.$refs.file.value = "";
                        this.isFailUpload = false;
                        clearInterval(interval);
                        break;
                      case UPLOAD_STATUS.FAIL:
                        this.$store.dispatch(
                          "global/updateLoadingStatus",
                          false
                        );
                        this.detailContentFail = result.data.detail;
                        this.fileUpload = null;
                        this.$refs.file.value = "";
                        this.isFailUpload = true;
                        clearInterval(interval);
                        break;
                      default:
                        break;
                    }
                  })
                  .catch(() => {
                    this.$store.dispatch("global/updateLoadingStatus", false);
                  });
              }, this.intervalTime);
            })
            .catch(() => {
              this.$store.dispatch("global/updateLoadingStatus", false);
            });
        })
        .catch(() => {
          this.$store.dispatch("global/updateLoadingStatus", false);
        });
    },
  },

  mounted() {
    this.getListFile(true);
  },
};
</script>

<style lang="scss" scoped>
.ListUploadComponent {
  &-title {
    color: var(--venice-blue-color) !important;
    width: 960px;
    margin: auto;
  }
  .upload-file {
    .v-card {
      padding: 15px 20px;
      .title-group {
        color: var(--color-black-text);
        font-weight: 500;
      }
    }
  }
  .table-main-content {
    height: 645px;
    .table-container {
      width: 100%;
      .datetime-cell {
        width: 17%;
      }
      .namefile-cell {
        width: 45%;
      }
      .status-cell {
        width: 15%;
      }
      .TableHeaderComponent {
        text-align: left;
      }
      .TableCellComponent {
        padding: 18px 20px;
        .cell-data {
          @include ellipsis(1);
        }
        .cell-updated-at {
          color: #7c8185;
        }
        .cell-status {
          width: 70px;
          height: 23px;
          text-align: center;
          border: solid 1px #74afbb;
        }
      }
    }
  }

  .table-upload {
    padding: 10px 25px;
  }

  .pagination {
    text-align: center;
    margin-top: 50px;
  }
}

.upload-file,
.list {
  margin-top: var(--default-distance-card);
}

.list {
  margin-bottom: var(--default-distance-card);
}

.upload-file {
  &-action {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &__upload {
      display: flex;
      .ButtonComponent {
        margin-left: 10px;
      }
    }
  }
}
.fileUpload-name {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.export-example-file {
  width: 100%;
  text-align: right;
}
.fail-upload {
  background-color: #fef7f6;
  width: 960px;
  height: 90px;
  margin: 10px auto;
  padding: 25px;
  .notice {
    color: #c51f1f;
  }
}
.count-item {
  text-align: right;
  margin: 5px 0;
  .total {
    font-size: 12px;
  }
}

.color-yellow {
  border-color: #dcb46f !important;
  color: #dcb46f;
}

.color-green {
  border-color: #74afbb !important;
  color: #74afbb;
}

.color-blue {
  border-color: #748cbb !important;
  color: #748cbb;
}
</style>
