<template>
  <CompanyInfoComponent :isReadOnly="true" :buildingId="dataRoom.buildingId" />
  <div class="RoomInfoComponent">
    <TitleComponent class="RoomInfoComponent-title">
      <template v-slot:icon><span class="icon-ico_room"></span> </template>
      <template v-slot:content>部屋情報</template>
    </TitleComponent>
    <CardComponent :width="'960px'" :height="'auto'">
      <div class="RoomInfoComponent-content" v-show="!isViewMode">
        <GroupContentComponent class="border-top">
          <template v-slot:title> 部屋番号</template>
          <template v-slot:content> {{ dataRoom.roomNumber }} </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> オートロック</template>
          <template v-slot:content>
            <div class="RoomInfoComponent-content-icon">
              <div v-if="dataRoom.autoLock === CHECKED.TRUE">
                <span class="icon-ico_seijyo">
                  <span class="path1"></span>
                  <span class="path2"></span>
                </span>
                あり
              </div>
              <div v-else>
                <span class="icon-check"></span>
                なし
              </div>
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> 温水器</template>
          <template v-slot:content>
            <div class="RoomInfoComponent-content-icon">
              <div v-if="dataRoom.waterHeater === CHECKED.TRUE">
                <span class="icon-ico_seijyo">
                  <span class="path1"></span>
                  <span class="path2"></span>
                </span>
                あり
              </div>
              <div v-else>
                <span class="icon-check"></span>
                なし
              </div>
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> 供給地点特定番号</template>
          <template v-slot:content> {{ dataRoom.supplyPointId }}</template>
        </GroupContentComponent>
        <GroupContentComponent class="specialGroupContent">
          <template v-slot:title>
            電気申込み状況 <br />（入居者申込み）</template
          >
          <template v-slot:content>
            <div class="RoomInfoComponent-content-status">
              <div class="RoomInfoComponent-content-status-detail">
                <p>処理ステータス</p>
                <StatusComponent
                  :status="dataRoom.residentUsingStatus"
                  :isCompletedRender="true"
                />
                <a
                  v-if="dataRoom?.residentLink && isMediation"
                  class="openLink"
                  :href="dataRoom.residentLink"
                  rel="opener"
                  target="_blank"
                >
                  <ButtonComponent width="133px" height="30px"
                    ><span class="icon-ico_newwindow"></span
                    >お客様控え</ButtonComponent
                  >
                </a>
              </div>
              <div class="RoomInfoComponent-content-status-detail">
                <p>使用開始日</p>
                <div class="text">
                  {{ dataRoom.residentUsingDatetime || "ー" }}
                </div>
              </div>
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title>
            <div class="RoomInfoComponent-content-title">
              電気申込み状況 <br />（短期使用開始・ 停止）
            </div>
          </template>
          <template v-slot:content>
            <div class="scope">
              <div class="RoomInfoComponent-content-status">
                <div class="RoomInfoComponent-content-status-detail">
                  使用開始
                </div>
                <div class="RoomInfoComponent-content-status-detail">
                  <p>処理ステータス</p>
                  <StatusComponent
                    :status="dataRoom.shorttermUsingStatus"
                    :isCompletedRender="true"
                  />
                </div>
                <div class="RoomInfoComponent-content-status-detail">
                  <p>利用開始日</p>
                  <div class="text">
                    {{ dataRoom.shorttermUsingDatetime || "ー" }}
                  </div>
                </div>
              </div>
              <div class="RoomInfoComponent-content-status">
                <div class="RoomInfoComponent-content-status-detail">
                  使用停止
                </div>
                <div class="RoomInfoComponent-content-status-detail">
                  <p>処理ステータス</p>
                  <StatusComponent
                    :status="dataRoom.shorttermCancellingStatus"
                    :isCompletedRender="true"
                  />
                </div>
                <div class="RoomInfoComponent-content-status-detail">
                  <p>利用終了日</p>
                  <div class="text">
                    {{ dataRoom.shorttermCancellingDatetime || "ー" }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </GroupContentComponent>
        <div class="RoomInfoComponent-content-action">
          <ButtonComponent
            width="76px"
            height="30px"
            class="button-action"
            @click="toggleEdit"
            :disabled="dataRoom.disabledButton"
            >編集</ButtonComponent
          >
          <ButtonComponent
            width="76px"
            height="30px"
            type="outline"
            class="button-action"
            :disabled="dataRoom.disabledButton"
            @click="deleteRoom"
            >削除</ButtonComponent
          >
        </div>
        <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
        <ButtonComponent
          v-if="
            edittingCompanyData?.electricContractApp?.value ===
            COMPANY_REGISTER_CONTRACT_TYPES.DEFAULT_VALUE
          "
          class="RoomInfoComponent-content-button"
          width="230px"
          height="50px"
          color="#328dc2"
          @click="goToResident"
          :disabled="dataRoom.disabledResidentButton"
          >電気申込み・入居者</ButtonComponent
        >
      </div>
      <Form
        class="RoomInfoComponent-content"
        ref="roomForm"
        @submit="editRoom"
        v-show="isViewMode"
        :validation-schema="schema"
        v-slot="{ errors }"
      >
        <GroupContentComponent :required="isViewMode">
          <template v-slot:title> 部屋番号</template>
          <template v-slot:content>
            <div class="input-field">
              <InputComponent
                :small="true"
                name="roomNumber"
                inputFilter="fullWidth"
                maxlength="6"
              /></div
          ></template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> オートロック</template>
          <template v-slot:content>
            <div class="RoomInfoComponent-content-radio">
              <InputComponent
                type="radio"
                :defaultValue="dataRoom.autoLock"
                name="autoLock"
                id="autoLock_1"
                label="有"
                :value="CHECKED.TRUE"
              />
            </div>
            <div class="RoomInfoComponent-content-radio">
              <InputComponent
                type="radio"
                :defaultValue="dataRoom.autoLock"
                name="autoLock"
                id="autoLock_2"
                label="無"
                :value="CHECKED.FALSE"
              /></div
          ></template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> 温水器</template>
          <template v-slot:content>
            <div class="RoomInfoComponent-content-radio">
              <InputComponent
                type="radio"
                :defaultValue="dataRoom.waterHeater"
                name="waterHeater"
                id="waterHeater_1"
                label="有"
                :value="CHECKED.TRUE"
              />
            </div>
            <div class="RoomInfoComponent-content-radio">
              <InputComponent
                type="radio"
                :defaultValue="dataRoom.waterHeater"
                name="waterHeater"
                id="waterHeater_2"
                label="無"
                :value="CHECKED.FALSE"
              /></div
          ></template>
        </GroupContentComponent>
        <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
        <div class="RoomInfoComponent-content-action edit">
          <ButtonComponent
            buttonType="button"
            width="170px"
            height="50px"
            class="button-action"
            type="outline"
            @click="toggleEdit"
            >キャンセル</ButtonComponent
          >
          <ButtonComponent
            width="170px"
            height="50px"
            class="button-action"
            buttonType="submit"
            :disabled="Object.keys(errors).length !== 0"
            >反映</ButtonComponent
          >
        </div>
      </Form>
    </CardComponent>
    <div class="RoomInfoComponent-button" v-if="!isViewMode">
      <ButtonComponent
        width="170px"
        height="50px"
        type="outline"
        @click="onReturn"
        >もどる</ButtonComponent
      >
    </div>
  </div>
</template>

<script>
import CompanyInfoComponent from "./CompanyInfoComponent.vue";
import { Form } from "vee-validate";
import { createRoomSchema } from "@/validations/schemas/createRoomSchema";
import RoomService from "@/services/room.service";
import WarningDialogService from "@/services/dialog/warning-dialog.service";
import ErrorDialogService from "@/services/dialog/error-dialog.service";
import {
  STATUS,
  CHECKED,
  COMPANY_REGISTER_CONTRACT_TYPES,
} from "@/globals/enums";
import { mapState } from "vuex";

export default {
  components: { Form, CompanyInfoComponent },
  name: "RoomInfoComponent",
  data: () => ({
    schema: createRoomSchema,
    isViewMode: false,
    dataRoom: {
      dataRoom: "",
      buildingId: "",
    },
    roomService: new RoomService(),
    errorDialogService: new ErrorDialogService(),
    warningDialogService: new WarningDialogService(),
    errorDetail: null,
  }),
  created() {
    this.CHECKED = CHECKED;
    this.COMPANY_REGISTER_CONTRACT_TYPES = COMPANY_REGISTER_CONTRACT_TYPES;
  },
  mounted() {
    this.getGetInfoRoom();
  },
  computed: {
    ...mapState({
      edittingCompanyData: (state) => state.company?.edittingCompanyData,
      isMediation: (state) =>
        state?.company?.edittingCompanyData?.electricContractApp?.value ===
          COMPANY_REGISTER_CONTRACT_TYPES.HAS_REGISTER_CONTRACT.VALUE &&
        state?.company?.edittingCompanyData?.electricContractAppType?.value ===
          1,
    }),
  },
  methods: {
    goToResident() {
      const roomId = this.$route.params.roomId;
      if (roomId) {
        this.$router.push({ path: `/resident-registration/${roomId}` });
      }
    },
    toggleEdit(e) {
      e.preventDefault();
      this.errorDetail = null;
      this.isViewMode = !this.isViewMode;
    },
    editRoom(value) {
      this.errorDetail = null;
      this.roomService
        .handleEditRoom(this.$route.params.roomId, value)
        .then((result) => {
          if (result.status === STATUS.ERROR && result.response?.detail) {
            this.errorDetail = result.response?.detail;
            return;
          }
          this.getGetInfoRoom();
          this.isViewMode = false;
        });
    },
    getGetInfoRoom() {
      this.$store.dispatch("global/updateLoadingStatus", true);
      this.roomService
        .getInfoRoom(this.$route.params.roomId)
        .then((dataRoom) => {
          if (!dataRoom.data) {
            this.$store.dispatch("global/updateLoadingStatus", false);
            return;
          }
          this.dataRoom = dataRoom.data;
          this.$refs.roomForm.setValues({
            roomNumber: this.dataRoom.roomNumber,
            autoLock: this.dataRoom.autoLock,
            waterHeater: this.dataRoom.waterHeater,
          });
          this.$store.dispatch("global/updateLoadingStatus", false);
        });
    },
    onReturn() {
      this.$router.go(-1);
    },
    deleteRoom() {
      const title = "部屋情報を削除します";
      const content =
        "削除した部屋情報は元に戻せません。<br/>本当に削除してよろしいですか？";
      this.warningDialogService.openWarningDialog(title, content, null, () => {
        this.roomService
          .deleteRoom(this.$route.params.roomId)
          .then((result) => {
            if (result.status === STATUS.SUCCESS) {
              this.onReturn();
            }
            if (result.status === STATUS.ERROR && result.response?.detail) {
              this.errorDialogService.openErrorDialog(
                "部屋情報は削除できません",
                result?.response?.detail
              );
            }
          });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.RoomInfoComponent {
  margin-top: var(--default-distance-card);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &-title {
    width: 960px;
    display: flex;
    font-size: 20px;
    font-weight: 500;
    color: var(--venice-blue-color);
    padding-top: 30px;
  }
  &-content {
    padding: 40px;
    display: flex;
    flex-direction: column;
    .border-top {
      border-top: 1px solid var(--valley-smoke-color);
    }
    .scope {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .RoomInfoComponent-content-status {
        width: 50%;
      }
    }
    &-radio {
      display: flex;
      &:first-child {
        margin-right: 75px;
      }
      .InputComponent {
        margin-bottom: 2px;
      }
    }
    &-title {
      width: 125px;
      height: 100%;
    }
    .input-check {
      margin-bottom: 0;
    }
    .input-field {
      width: 170px;
    }
    &-icon {
      display: flex;
      margin-right: 5px;
      span {
        font-size: 17px;
        width: 17px;
        height: 17px;
      }
    }
    &-detail {
      display: flex;
      align-items: flex-start;
    }
    &-status {
      &-detail {
        display: flex;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        p {
          width: 120px;
          font-size: 12px;
          color: #7c8185;
        }
        &-icon {
          display: flex;
          margin-right: 5px;
          span {
            font-size: 17px;
            width: 17px;
            height: 17px;
          }
        }
      }
    }
    &-action {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-top: 16px;
      .button-action:last-child {
        margin-left: 10px;
      }
      &.edit {
        margin-top: 30px;
        justify-content: center;
      }
    }
    &-button {
      margin: 30px auto 0 auto;
    }
  }
  &-button {
    width: 170px;
    height: 50px;
    background-color: white;
    border-radius: 25px;
    margin-top: 30px;
    margin-bottom: var(--default-distance-button);
  }
  .openLink {
    text-decoration: none;
    margin: 10px 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    right: 0;
    top: 5px;
    .icon-ico_newwindow {
      &::before {
        color: #fff;
      }
    }
  }
  .specialGroupContent {
    position: relative;
  }
  .text {
    font-size: 14px;
    margin-left: 5px;
  }
}
</style>
