import store from "./../store/index";
import { getProfile, editProfile } from "./api/user.service";
import {
  convertProfileEdit,
  convertFirstProfileEdit,
} from "@/store/converter/profile.convert";
import CompanyService from "./company.service";
import WebStorageService from "@/services/web-storage.service";
import router from "@/router/router";
import { STATUS, WEB_STORAGE_KEY } from "@/globals/enums";

const companyService = new CompanyService();
const webStorageService = new WebStorageService();

export const getProfileInfo = async () => {
  try {
    const profileInfo = await getProfile();
    store.dispatch("settings/changeProfileInfo", profileInfo);
    webStorageService.setSessionStorage(
      WEB_STORAGE_KEY.COMPANY_INFO_VALID,
      profileInfo.admin_flg && profileInfo.company_id
    );
    if (!profileInfo.first_name_kanji) {
      router.push({ name: "RegistrationUserInfoComponent" });
      return {
        status: STATUS.NO_ACTION,
      };
    } else if (!profileInfo.company_id && profileInfo.admin_flg) {
      router.push({ name: "CompanyEditComponent" });
      return {
        status: STATUS.NO_ACTION,
      };
    }
    if (profileInfo.company_id) {
      await companyService.getUserCompanyInfo(profileInfo.company_id);
    }
    return {
      status: STATUS.SUCCESS,
    };
  } catch (error) {
    console.log(error);
  }
};

export default class UserService {
  async getProfileInfo() {
    const token = webStorageService.getSessionStorage(WEB_STORAGE_KEY.ID_TOKEN);
    if (!token) {
      return;
    }
    try {
      const profileInfo = await getProfile();
      store.dispatch("settings/changeProfileInfo", profileInfo);
      webStorageService.setSessionStorage(
        WEB_STORAGE_KEY.COMPANY_INFO_VALID,
        profileInfo.admin_flg && profileInfo.company_id
      );
      if (!profileInfo.first_name_kanji) {
        router.push({ name: "RegistrationUserInfoComponent" });
      } else if (!profileInfo.company_id && profileInfo.admin_flg) {
        router.push({ name: "CompanyEditComponent" });
      }
      if (profileInfo.company_id) {
        await companyService.getUserCompanyInfo(profileInfo.company_id);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async editProfileInfo(value) {
    try {
      await editProfile(convertProfileEdit(value));
      const profileInfo = await getProfile();
      await store.dispatch("settings/changeProfileInfo", profileInfo);
      return {
        status: STATUS.SUCCESS,
      };
    } catch (error) {
      console.log(error);
    }
  }

  async editFirstProfileInfo(value) {
    try {
      await editProfile(convertFirstProfileEdit(value));
      await this.getProfileInfo();
      return {
        status: STATUS.SUCCESS,
      };
    } catch (error) {
      console.log(error);
      return {
        status: STATUS.ERROR,
      };
    }
  }
}
