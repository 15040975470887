export const ERROR_MESSAGES_API = {
  HTTP_ERROR_401: {
    message: "認証エラー",
    detail: `認証の有効期限切れ、もしくは認証エラーのため<br>
                ログアウトします。`,
  },
  HTTP_ERROR_403: {
    message: "権限エラー",
    detail: `アクセス不可の操作のためリクエストできませんでした。<br>
                問題が解消しない場合は、管理者に問い合わせください。`,
  },
  HTTP_ERROR_404: {
    message: "処理エラー",
    detail: `処理エラー対象のデータが存在しません。<br>再度ご確認ください。`,
  },
  HTTP_ERROR_500: {
    message: "システムエラー",
    detail: `サーバーでエラーが発生しました。<br>
            しばらく経ってから再度ログインし、操作をやり直してください。`,
  },
  CODE_MISS_MATCH: "有効な認証コードを入力してください。",
  PASSWORD_NOT_RIGHT: "メールアドレスまたはパスワードが正しくありません。",
};

export const ERROR_MESSAGES = {
  REQUIRED: "入力してください。",
  INVALID_EMAIL: "メールアドレスが正しい形式ではありません。",
  INVALID_PASSWORD:
    "半角英数字記号(^ $ * . [ ] { } ( ) ? " +
    '" ' +
    "! @ # % & / ¥ , > < ' : ; | _ ~ ` = + -)で入力してください。",
  MAX_LENGTH_IS_N: "N文字以下で入力してください。",
  NAME_IS_SHORTTER_THAN_N: "姓名あわせてN文字以下で入力してください。",
  PLEASE_INPUT_OVER_8_NUMBER: "8文字以上で入力してください。",
  SAME_PASSWORD_REQUIRED: "パスワードが一致しません。",
  OTP_6_NUMBER_REQUIRED: "6文字で入力してください。",
  PLEASE_INPUT_OVER_N_NUMBER: "N文字以上で入力してください。",
  PLEASE_INPUT_N_NUMBER: "N文字で入力してください。",
  PLEASE_INPUT_VALID_FULL_WIDTH: "全角文字で入力してください。",
  PLEASE_INPUT_VALID_HALF_WIDTH: "半角文字で入力してください。",
  PLEASE_INPUT_VALID_HALF_WIDTH_KANA: "半角英数カナで入力ください",
  NOT_VALID_TIME: "指定できない日付です。",
  DATES_CAN_NOT_SAME: "使用開始日と使用停止日は同一日指定はできません。",
  FUTURE_TIME_NOT_VALID: "指定できない未来の日付です。",
  PAST_TIME_NOT_VALID: "指定できない過去の日付です。",
  PLEASE_INPUT_TIME: "指定してください。",
  PLEASE_SELECT: "選択してください。",
  NOT_VALID_SUPPLY_ID: "半角数字(-)で入力してください。",
  INVALID_TELEPHONE: "電話番号が正しい形式ではありません。",
};
