import showDialog from "@/services/dialog.service";
import WarningDialog from "@/components/dialogs/WarningDialog.vue";

export default class WarningDialogService {
  openWarningDialog(title, content, callbackClose, callbackChoise) {
    showDialog({
      component: WarningDialog,
      bind: {
        title,
        content,
        onClose: callbackClose,
        onChoise: callbackChoise,
      },
    });
  }
}
