<template>
  <div
    class="HeaderComponent"
    v-if="isHeader"
    :class="{ 'HeaderComponent-hasBackground': hasMenu }"
  >
    <div class="HeaderComponent__titleGroup" @click="goToHome">
      <img class="HeaderComponent__titleGroup_logo" :src="logo" />
      <div class="space" />
      <p class="HeaderComponent__titleGroup_description">
        管理物件電気受付サービス
      </p>
    </div>
    <div v-if="hasMenu" class="HeaderComponent__userMenu">
      <p class="HeaderComponent__userMenu_user">
        <span class="text" v-if="edittingCompanyData">{{
          edittingCompanyData.companyName
        }}</span
        ><span class="text" v-if="profileInfo"
          >{{ profileInfo.firstNameKanji }} {{ " " }}
          {{ profileInfo.lastNameKanji }}</span
        >
        <button class="btn-openMenu" @click="openNav">
          <span class="icon-ico_menu"></span>
        </button>
      </p>
    </div>
  </div>

  <div ref="backgroundMenu" class="background-menu">
    <div ref="mySidenav" class="sidenav">
      <p class="closebtn" @click="closeNav">
        <span class="icon-ico_menu_close"></span>
      </p>
      <div class="content-menu">
        <h3 class="title">トップページ</h3>
        <span class="icon-ico_menu_arrow">
          <span class="path1"></span>
          <span class="path2"></span>
          <span class="path3"></span>
        </span>
        <a @click="navigatePath('HomeComponent')">HOME</a>
      </div>
      <div class="content-menu">
        <h3 class="title">Myメニュー</h3>
        <ul>
          <li>
            <a @click="navigatePath('ProfileInfoComponent')">
              プロフィール情報
            </a>
          </li>
          <li v-if="profileInfo?.adminFlg">
            <a @click="navigatePath('CompanyInfoComponent')"
              >会社・支払先情報</a
            >
          </li>
          <li>
            <a @click="navigatePath('ChangePasswordComponent')">
              パスワード変更
            </a>
          </li>
          <li>
            <a @click="navigatePath('ReConfigMFAComponent')"
              >セキュリティ再設定</a
            >
          </li>
        </ul>
      </div>
      <div class="content-menu">
        <h3 class="title">
          物件管理・ <br />
          電気使用申込み
        </h3>
        <ul>
          <li>
            <a @click="navigatePath('SearchBuildingComponent')">建物情報参照</a>
          </li>
          <li>
            <a @click="navigatePath('CreateBuildingComponent')">建物情報入力</a>
          </li>
          <li>
            <a @click="navigatePath('ListUploadComponent')">建物情報一括登録</a>
          </li>
        </ul>
      </div>
      <div class="content-menu">
        <h3 class="title">電気使用申込み履歴</h3>
        <ul>
          <li>
            <a @click="navigatePath('SearchHistoryRegistrationComponent')"
              >履歴参照</a
            >
          </li>
        </ul>
      </div>
      <div @click="logout" class="content-menu">
        <h3 class="title"><span class="icon-ico_logout"></span> ログアウト</h3>
      </div>
    </div>
  </div>
</template>

<script>
import LoginService from "@/services/login.service";
import WarningDialogService from "@/services/dialog/warning-dialog.service";
import { mapState } from "vuex";

export default {
  name: "HeaderComponent",
  computed: {
    hasMenu() {
      return !(this.$route.meta && this.$route.meta.authPage);
    },
    isHeader() {
      return this.$route.path !== "/login";
    },
    ...mapState({
      profileInfo: (state) => state?.settings?.profileInfo,
      edittingCompanyData: (state) => state.company?.edittingCompanyData,
    }),
  },
  data() {
    return {
      logo: require("@/assets/images/logo_main.png"),
      loginService: new LoginService(),
      warningDialogService: new WarningDialogService(),
    };
  },
  methods: {
    openNav() {
      this.$refs.mySidenav.style.width = "300px";
      this.$refs.mySidenav.style.overflowY = "scroll";
      this.$refs.backgroundMenu.style.width = "100%";
      document.body.classList.add("body-custom");
    },
    closeNav() {
      this.$refs.mySidenav.style.width = "0";
      this.$refs.mySidenav.style.overflowY = "hidden";
      this.$refs.backgroundMenu.style.width = "0";
      document.body.classList.remove("body-custom");
    },
    navigatePath(name) {
      this.$router.push({ name });
      this.closeNav();
    },
    goToHome() {
      if (this.hasMenu) {
        this.$router.push({ name: "HomeComponent" });
      } else {
        this.$router.push({ name: "LoginComponent" });
      }
    },
    onCloseConfirm() {
      this.closeNav();
      this.loginService.logout();
    },
    logout() {
      const title = "ログアウト";
      const content = "ログアウトしますか";
      this.warningDialogService.openWarningDialog(
        title,
        content,
        null,
        this.onCloseConfirm
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.HeaderComponent {
  width: 100%;
  height: 70px;
  padding: 14px 30px 13px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-hasBackground {
    background-color: #f6f9f9;
  }
  &__titleGroup {
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
    &_logo {
      height: 23px;
    }
    &_description {
      font-weight: bold;
      font-size: 22px;
      color: #294f6e;
    }
    .space {
      width: 2px;
      height: 22px;
      background-color: #d9e0e5;
    }
  }
  &__userMenu {
    font-size: 14px;
    color: #7c8185;
    &_user {
      .text {
        margin-left: 15px;
        font-weight: 500;
      }
    }
  }
}

.btn-openMenu {
  cursor: pointer;
  font-size: 20px;
  padding: 0 15px 0 30px;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  background-color: #e0eceb;
  overflow-x: hidden;
  transition: 0.5s;

  .closebtn {
    margin: 15px 40px;
    text-align: right;
    font-size: 20px;
    width: 224px;
    cursor: pointer;
  }

  .content-menu {
    margin: 0 49px 35px 49px;
    cursor: pointer;
    .icon-ico_menu_arrow {
      margin-right: 5px;
    }
    .title {
      width: 180px;
      font-weight: 500;
    }
    a {
      text-decoration: none;
      font-size: 14px;
      font-stretch: normal;
      line-height: 1.43;
      text-align: left;
      color: #000;
      word-break: keep-all;
      overflow-x: hidden;
    }
    ul {
      border-top: 1px solid #8d9caa;
      width: 200px;
      li {
        list-style: none;
        margin: 12px 20px;
        width: 112px;
      }
    }
  }
}

.background-menu {
  height: 100%;
  width: 0;
  position: absolute;
  z-index: 99;
  background-color: #ffffff87;
  overflow-x: hidden;
  overflow-y: hidden;
}
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
</style>
