import * as Yup from "yup";
import {
  validateDateTimeWithCurrentTime,
  validateSelectNumber,
  validateName,
  validateNameRequiredKana,
  validateTelephoneGroup,
  validateAddressForm,
} from "../common";
import { ERROR_MESSAGES } from "@/globals/error-messages";
import {
  RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT,
  PAYMENT_PERSON_TELEPHONE_DEFAULT,
} from "@/globals/enums";

export const introduceFormSchema = (holidateList, time) =>
  Yup.object().shape({
    startYear: validateDateTimeWithCurrentTime(
      "startYear",
      "startMonth",
      "startDate",
      holidateList,
      time,
      ERROR_MESSAGES.NOT_VALID_TIME,
      ERROR_MESSAGES.NOT_VALID_TIME,
      ERROR_MESSAGES.FUTURE_TIME_NOT_VALID,
      ERROR_MESSAGES.PLEASE_INPUT_TIME,
      ERROR_MESSAGES.PAST_TIME_NOT_VALID
    ),
    startMonth: validateDateTimeWithCurrentTime(
      "startYear",
      "startMonth",
      "startDate",
      holidateList,
      time
    ),
    startDate: validateDateTimeWithCurrentTime(
      "startYear",
      "startMonth",
      "startDate",
      holidateList,
      time
    ),
    firstname: validateName.firstname("lastname"),
    lastname: validateName.lastname("firstname"),
    firstnameKana: validateNameRequiredKana.firstname("lastnameKana"),
    lastnameKana: validateNameRequiredKana.lastname("firstnameKana"),
    contractorFirstPathNumbers: validateTelephoneGroup.firstPathTelephone(
      "contractorFirstPathNumbers",
      "contractorSecondPathNumbers",
      "contractorThirtPathNumbers"
    ),
    contractorSecondPathNumbers: validateTelephoneGroup.secondPathTelephone(
      "contractorFirstPathNumbers",
      "contractorSecondPathNumbers",
      "contractorThirtPathNumbers"
    ),
    contractorThirtPathNumbers: validateTelephoneGroup.lastPathTelephone(
      "contractorFirstPathNumbers",
      "contractorSecondPathNumbers",
      "contractorThirtPathNumbers"
    ),
    contractorPhoneNumberCategory: validateSelectNumber(0),
    applicatedPersonFirstname: Yup.string().when(
      "connectionWithApplicatedPerson",
      {
        is: 0,
        then: Yup.string(),
        otherwise: validateName.firstname("applicatedPersonLastname"),
      }
    ),
    applicatedPersonLastname: Yup.string().when(
      "connectionWithApplicatedPerson",
      {
        is: 0,
        then: Yup.string(),
        otherwise: validateName.lastname("applicatedPersonFirstname"),
      }
    ),
    applicatedFirstPathNumbers: Yup.string().when(
      "connectionWithApplicatedPerson",
      {
        is: 0,
        then: Yup.string(),
        otherwise: validateTelephoneGroup.firstPathTelephone(
          "applicatedFirstPathNumbers",
          "applicatedSecondPathNumbers",
          "applicatedThirtPathNumbers"
        ),
      }
    ),
    applicatedSecondPathNumbers: Yup.string().when(
      "connectionWithApplicatedPerson",
      {
        is: 0,
        then: Yup.string(),
        otherwise: validateTelephoneGroup.secondPathTelephone(
          "applicatedFirstPathNumbers",
          "applicatedSecondPathNumbers",
          "applicatedThirtPathNumbers"
        ),
      }
    ),
    applicatedThirtPathNumbers: Yup.string().when(
      "connectionWithApplicatedPerson",
      {
        is: 0,
        then: Yup.string(),
        otherwise: validateTelephoneGroup.lastPathTelephone(
          "applicatedFirstPathNumbers",
          "applicatedSecondPathNumbers",
          "applicatedThirtPathNumbers"
        ),
      }
    ),
    applicatedPersonPhoneNumberCategory: Yup.number().when(
      "connectionWithApplicatedPerson",
      {
        is: 0,
        then: Yup.number(),
        otherwise: validateSelectNumber(0),
      }
    ),
    postDeliveryNumberName: Yup.string().when("paymentAddressOptions", {
      is: RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT,
      then: validateAddressForm.postOfficeNumber,
      otherwise: Yup.string(),
    }),
    deliveryDepartmentNumber: Yup.string().when("paymentAddressOptions", {
      is: RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT,
      then: validateAddressForm.addressDepartmentNumber,
      otherwise: Yup.string(),
    }),
    deliveryDepartmentName: Yup.string().when("paymentAddressOptions", {
      is: RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT,
      then: validateAddressForm.addressDepartmentName,
      otherwise: Yup.string(),
    }),
    deliveryRoomNumber: Yup.string().when("paymentAddressOptions", {
      is: RESIDENT_PAYMENT_OPTIONS_ADDRESS_DEFAULT,
      then: validateAddressForm.addressRoomNumber,
      otherwise: Yup.string(),
    }),
    paymentFirstPathNumbers: Yup.string().when(
      "paymentPersonTelephoneOptions",
      {
        is: PAYMENT_PERSON_TELEPHONE_DEFAULT,
        then: validateTelephoneGroup.firstPathTelephone(
          "paymentFirstPathNumbers",
          "paymentSecondPathNumbers",
          "paymentThirtPathNumbers"
        ),
        otherwise: Yup.string(),
      }
    ),
    paymentSecondPathNumbers: Yup.string().when(
      "paymentPersonTelephoneOptions",
      {
        is: PAYMENT_PERSON_TELEPHONE_DEFAULT,
        then: validateTelephoneGroup.secondPathTelephone(
          "paymentFirstPathNumbers",
          "paymentSecondPathNumbers",
          "paymentThirtPathNumbers"
        ),
        otherwise: Yup.string(),
      }
    ),
    paymentThirtPathNumbers: Yup.string().when(
      "paymentPersonTelephoneOptions",
      {
        is: PAYMENT_PERSON_TELEPHONE_DEFAULT,
        then: validateTelephoneGroup.lastPathTelephone(
          "paymentFirstPathNumbers",
          "paymentSecondPathNumbers",
          "paymentThirtPathNumbers"
        ),
        otherwise: Yup.string(),
      }
    ),
    paymentPhoneNumberCategory: Yup.number().when(
      "paymentPersonTelephoneOptions",
      {
        is: PAYMENT_PERSON_TELEPHONE_DEFAULT,
        then: validateSelectNumber(0),
        otherwise: Yup.number(),
      }
    ),
    residentPaymentOptions: validateSelectNumber(0),
    gasServiceIntroduceOptions: validateSelectNumber(2),
    kyudenGroupInternetIntroduceOptions: validateSelectNumber(2),
  });
