import { createApp } from "vue";
import Amplify from "aws-amplify";
import { vfmPlugin } from "vue-final-modal";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router/router";
import store from "./store/index";
import components from "./components/index";
import directives from "./directives";
import "@/assets/scss/styles.scss";
import "vue3-date-time-picker/dist/main.css";
import "@/directives/validations";

Amplify.configure({
  Auth: {
    userPoolId: process.env.VUE_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
  },
});

createApp(App)
  .use(vuetify)
  .use(router)
  .use(components)
  .use(directives)
  .use(store)
  .use(
    vfmPlugin({
      key: "$vfm",
      componentName: "VueFinalModal",
      dynamicContainerName: "ModalsContainer",
    })
  )
  .mount("#app");
