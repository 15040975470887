import { get, post, put, upload } from "./../https/http.service";

export const getListFile = (params) => {
  return get("estate/building/file/list", params);
};

export const createFileSignedUrl = (params) => {
  return post("estate/building/file/url", params);
};

export const uploadFile = (id, params) => {
  return put(`estate/building/file/upload/${id}`, params);
};

export const uploadToServer = (url, file) => {
  return upload(url, file);
};

export const getInfoFile = (id) => {
  return get(`estate/building/file/${id}`);
};
