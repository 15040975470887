<template>
  <div class="CompanyInfoComponent">
    <CardComponent
      :width="'960px'"
      :height="'85px'"
      class="RegistrationRooms"
      v-if="isRenderRegistration && currentRegistrationRoomInfo?.total > 0"
    >
      <div class="RegistrationRooms__title">
        電気短期使用・停止一括申込み対象
      </div>
      <div class="RegistrationRooms__totalgroups">
        <p class="RegistrationRooms__totalgroups_totalNumber">
          {{ currentRegistrationRoomInfo.total }}
        </p>
        件
      </div>
      <div class="RegistrationRooms__right">
        <ButtonComponent
          :width="'149px'"
          :height="'30px'"
          color="#328dc2"
          @click="goToShortTermRegistration"
          >確認・申込み</ButtonComponent
        >
      </div>
    </CardComponent>
    <TitleComponent>
      <template v-slot:icon><span class="icon-ico_fudosan2"></span></template>
      <template v-slot:content>{{ renderTitle }}</template>
    </TitleComponent>
    <CardComponent
      :width="'960px'"
      :height="'243px'"
      class="CompanyInfoComponent-content"
    >
      <Form
        v-slot="{ meta, values }"
        @submit="submitForm"
        ref="buildingForm"
        :validation-schema="schema"
      >
        <p
          v-if="dataBuilding.dateUpdate"
          class="CompanyInfoComponent-content-timeUpdate"
        >
          <span>最終更新情報</span>
          <span v-generateDateJapansese="dataBuilding.dateUpdate"></span>
          <span>{{ dataBuilding.timeUpdate }}</span>
          <span>{{ dataBuilding.nameUpdate }}</span>
        </p>
        <GroupContentComponent
          :required="!isViewMode"
          class="CompanyInfoComponent-content-detail first-content"
        >
          <template v-slot:title> 物件名</template>
          <template v-slot:content>
            <div v-show="isViewMode">{{ dataBuilding.buildingName }}</div>
            <InputComponent
              v-show="!isViewMode"
              name="buildingName"
              class="code-field"
              :small="true"
              inputFilter="fullWidth"
              maxlength="30"
            />
          </template>
        </GroupContentComponent>
        <GroupContentComponent
          v-show="isViewMode"
          class="CompanyInfoComponent-content-detail"
        >
          <template v-slot:title>
            <div class="CompanyInfoComponent-content-title">住所</div>
          </template>
          <template v-slot:content>
            <div class="location">
              <div v-formatPostCode="dataBuilding.postCode"></div>
              <div>{{ dataBuilding.address }}</div>
              <div>{{ dataBuilding.deliveryDepartmentNumber }}</div>
            </div>
          </template>
        </GroupContentComponent>
        <AddressFormComponent
          v-show="!isViewMode"
          :showAllField="false"
          :postAddressData="postOfficeData"
          :postAddressSearchNumber="postOfficeNumber"
          :addressDepartmentNumber="dataBuilding.deliveryDepartmentNumber"
          postOfficeNumberName="postDeliveryNumberName"
          addressDepartmentNumberInputName="deliveryDepartmentNumber"
          @on-choise="updatePostOfficeData"
          @search-again="searchPostOfficeAgain"
          :handleChangePostAddressNumber="
            (e) => {
              postOfficeNumber = e.target.value;
            }
          "
        />
        <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
        <!-- edit -->
        <div
          v-if="isHasEdit"
          :class="{ 'flex-center': !isViewMode }"
          class="CompanyInfoComponent-action"
        >
          <!-- on edit -->
          <ButtonComponent
            @click="onEdit($event)"
            v-if="isViewMode"
            :width="'76px'"
            :height="'30px'"
            :disabled="disabledEditButton"
            >編集
          </ButtonComponent>
          <!-- on update  -->
          <ButtonComponent
            buttonType="button"
            type="outline"
            v-else
            @click="onEdit($event)"
            :width="'170px'"
            :height="'50px'"
            >キャンセル
          </ButtonComponent>
          <!-- on delete -->
          <ButtonComponent
            @click="onDelete($event)"
            type="outline"
            v-if="isViewMode"
            :width="'76px'"
            :height="'30px'"
            :disabled="disabledEditButton"
            >削除
          </ButtonComponent>
          <!-- on cancel edit -->
          <ButtonComponent
            buttonType="submit"
            v-else
            :disabled="!(meta.valid && values.deliveryDepartmentNumber)"
            :width="'170px'"
            :height="'50px'"
            >反映
          </ButtonComponent>
        </div>
        <!-- create -->
        <div v-if="isCreateBuilding" class="CompanyInfoComponent-action create">
          <ButtonComponent
            @click="cancelHandle($event)"
            type="outline"
            :width="'170px'"
            :height="'50px'"
          >
            {{ isViewMode ? "もどる" : "キャンセル" }}
          </ButtonComponent>
          <ButtonComponent
            buttonType="submit"
            :disabled="!(meta.valid && values.deliveryDepartmentNumber)"
            :width="'170px'"
            :height="'50px'"
          >
            {{ isViewMode ? "登録" : "確認" }}
          </ButtonComponent>
        </div>
      </Form>
    </CardComponent>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BuildingSevice from "@/services/building.service";
import WarningDialogService from "@/services/dialog/warning-dialog.service";
import ConfirmDialogService from "@/services/dialog/confirm-dialog.service";
import ErrorDialogService from "@/services/dialog/error-dialog.service";
import { Form } from "vee-validate";
import { STATUS } from "@/globals/enums";
import { createBuildingSchema } from "@/validations/schemas/createBuildingSchema";

export default {
  name: "CompanyInfoComponent",
  props: {
    isHasEdit: {
      type: Boolean,
      default: false,
    },
    isCreateBuilding: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    isRenderRegistration: {
      type: Boolean,
      default: false,
    },
    buildingId: {
      type: String,
      default: null,
    },
    disabledEditButton: {
      type: Boolean,
      default: true,
    },
    isInRoomDetail: {
      type: Boolean,
      default: false,
    },
    initPostOfficeData: {
      type: Object,
      default: () => {},
    },
    initBuildingData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Form,
  },
  data: () => ({
    postOfficeNumber: "",
    postOfficeData: null,
    buildingService: new BuildingSevice(),
    warningDialogService: new WarningDialogService(),
    confirmDialogService: new ConfirmDialogService(),
    errorDialogService: new ErrorDialogService(),
    dataBuilding: {
      address: "",
      addressCode: "",
      buildingName: "",
      deliveryDepartmentNumber: "",
      postCode: "",
      dateUpdate: "",
      timeUpdate: "",
      nameUpdate: "",
    },
    isViewMode: false,
    schema: createBuildingSchema,
    errorDetail: null,
  }),
  mounted() {
    this.isViewMode = this.isReadOnly;
    !this.isInRoomDetail && this.getDataHandle();
  },
  methods: {
    updatePostOfficeData(data) {
      this.postOfficeData = data;
      this.postOfficeNumber = "";
    },

    searchPostOfficeAgain() {
      this.postOfficeData = null;
      this.postOfficeNumber = "";
    },

    submitForm(data) {
      this.dataBuilding = { ...data, ...this.postOfficeData };
      if (this.$route.params.buildingId) {
        this.editBuilding();
        return;
      }
      if (this.isViewMode) {
        this.createBuilding();
      } else {
        this.isViewMode = true;
      }
    },

    getDataHandle() {
      if (this.buildingId) {
        this.getInfoBuilding(this.buildingId);
      } else {
        if (this.$route.params.buildingId) {
          this.getInfoBuilding(this.$route.params.buildingId);
        }
      }
    },

    onEdit(e) {
      e.preventDefault();
      this.errorDetail = null;
      this.isViewMode = !this.isViewMode;
    },

    onDelete(e) {
      e.preventDefault();
      const title = "建物情報を削除します";
      const content =
        "部屋情報も合わせて削除されます。削除した情報は元に戻せませんがよろしいでしょうか？";
      this.warningDialogService.openWarningDialog(
        title,
        content,
        null,
        this.deleteBuilding
      );
    },

    createBuilding() {
      this.errorDetail = null;
      this.buildingService
        .handleCreateBuilding(this.dataBuilding)
        .then((result) => {
          if (result.status === STATUS.ERROR && result.response?.detail) {
            this.errorDetail = result.response?.detail;
            return;
          }
          if (result.status === STATUS.SUCCESS) {
            this.showDialog();
          }
        });
    },

    deleteBuilding() {
      this.buildingService
        .deleteBuilding(this.$route.params.buildingId)
        .then((result) => {
          if (result.status === STATUS.SUCCESS) {
            this.$router.go(-1);
          }
          if (result.status === STATUS.ERROR && result.response?.detail) {
            this.errorDialogService.openErrorDialog(
              "建物情報は削除できません",
              result?.response?.detail
            );
            return;
          }
        });
    },

    getInfoBuilding(id) {
      this.$store.dispatch("global/updateLoadingStatus", true);
      this.buildingService.getInfoBuilding(id).then((dataBuilding) => {
        if (!dataBuilding.data) {
          this.$store.dispatch("global/updateLoadingStatus", false);
          return;
        }
        this.dataBuilding = dataBuilding.data;
        this.$emit("update-building", dataBuilding.data);
        this.$refs.buildingForm?.setFieldValue(
          "buildingName",
          this.dataBuilding.buildingName
        );
        this.postOfficeData = {
          address: this.dataBuilding.address,
          addressCode: this.dataBuilding.addressCode,
          postCode: this.dataBuilding.postCode,
        };
        this.$store.dispatch("global/updateLoadingStatus", false);
      });
    },

    cancelHandle(e) {
      e.preventDefault();
      this.errorDetail = null;
      if (this.isViewMode) {
        this.isViewMode = false;
        return;
      }
      this.$router.push({ name: "SearchBuildingComponent" });
    },

    editBuilding() {
      this.errorDetail = null;
      this.buildingService
        .editBuilding(this.$route.params.buildingId, this.dataBuilding)
        .then((result) => {
          if (result.status === STATUS.ERROR && result.response?.detail) {
            this.errorDetail = result.response?.detail;
            return;
          }
          this.isViewMode = result.status === STATUS.SUCCESS;
        });
    },

    showDialog() {
      this.confirmDialogService.openConfirmDialog(
        "建物情報の新規登録が完了いたしました。",
        "部屋情報の登録をお願いいたします。",
        () => {
          this.$router.push({ name: "SearchBuildingComponent" });
        }
      );
    },

    async goToShortTermRegistration() {
      await this.$store.dispatch(
        "registrationShortTerm/changeFromRoomRouter",
        this.$router.currentRoute.value.path
      );
      this.$router.push({ name: "ShortTermRegistrationComponent" });
    },
  },

  computed: {
    renderTitle() {
      if (this.isCreateBuilding) {
        return this.isViewMode ? "建物新規登録確認" : "建物新規登録";
      }
      return "建物情報";
    },
    ...mapState({
      currentRegistrationRoomInfo: (state) =>
        state.registrationShortTerm.currentRegistrationRoomInfo,
    }),
  },

  watch: {
    buildingId: function (val) {
      if (val) {
        this.getInfoBuilding(val);
      }
    },
    initPostOfficeData: function (val) {
      if (val) {
        this.postOfficeData = val;
      }
    },
    initBuildingData: function (val) {
      if (val) {
        this.dataBuilding = val;
        this.$refs.buildingForm?.setFieldValue(
          "buildingName",
          val?.buildingName || ""
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.CompanyInfoComponent {
  margin-top: var(--default-distance-card);
  &-title {
    display: flex;
    font-size: 20px;
    font-weight: 500;
    color: var(--venice-blue-color);
    span {
      margin-right: 10px;
    }
  }
  .TitleComponent {
    width: 960px;
    text-align: left;
    color: var(--venice-blue-color);
    padding: 0 0 11px 0;
    margin: 0 auto;
  }
  &-content {
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    height: auto !important;
    &-timeUpdate {
      width: 100%;
      text-align: right;
      font-size: 12px;
      color: #7c8185;
      margin-bottom: 5px;
      span {
        margin-left: 10px;
      }
    }
    .first-content {
      border-top: solid 1px #d0d9df !important;
    }
    &-detail {
      border-bottom: solid 1px #d0d9df;
      .location {
        display: flex;
        flex-direction: column;
      }
      pre {
        white-space: pre-line;
      }
    }
    &-title {
      height: 100%;
    }
  }
  .create {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0 100px 0;
  }
  &-action {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    .ButtonComponent {
      margin-left: 10px;
    }
  }
  .RegistrationRooms {
    background-color: #fffbef;
    margin-bottom: 10px;
    padding: 20px 40px;
    display: flex;
    align-items: center;
    &__title {
      font-size: 18px;
      font-weight: 500;
      color: #328dc2;
      margin-right: 124px;
    }
    &__totalgroups {
      display: flex;
      gap: 2px;
      align-items: baseline;
      font-size: 20px;
      font-weight: 500;
      p {
        height: 45px;
        font-size: 30px;
        font-weight: bold;
      }
    }
    &__right {
      margin-left: auto;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .flex-center {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }
}
</style>
