<template>
  <CompanyPreviewTabComponent
    v-if="tab === COMPANY_SETTING_TAB.PREVIEW"
    @change-tab="changeTab"
    :inInfo="true"
  />
  <CompanySmallEditTabComponent
    v-if="tab === COMPANY_SETTING_TAB.EDIT"
    @change-tab="changeTab"
    :inInfo="true"
  />
</template>

<script>
import { mapState } from "vuex";
import CompanyService from "@/services/company.service";
import UserService from "@/services/user.service";
import { COMPANY_SETTING_TAB } from "@/globals/enums";

export default {
  name: "CompanyInfoComponent",
  data: () => ({
    companyService: new CompanyService(),
    userService: new UserService(),
    tab: COMPANY_SETTING_TAB.PREVIEW,
  }),
  created() {
    this.COMPANY_SETTING_TAB = COMPANY_SETTING_TAB;
    this.userService.getProfileInfo();
  },
  computed: mapState({
    profileInfo: (state) => state.settings.profileInfo,
  }),
  methods: {
    changeTab(tabId) {
      this.tab = tabId;
    },
  },
};
</script>
<style scoped lang="scss"></style>
