import { post } from "../https/http.service";

export const verifyPhoneNumber = (params) => {
  return post("login/verifyPhoneNumber", params);
};

export const resetMFA = (params) => {
  return post("login/resetMfa", params);
};

export const checkToken = (params) => {
  return post("login/checkToken", params);
};
