<template>
  <CompanyInfoComponent :isCreateBuilding="true"></CompanyInfoComponent>
</template>

<script>
import CompanyInfoComponent from "@/views/CompanyInfoComponent.vue";
export default {
  name: "CreateBuildingComponent",
  components: {
    CompanyInfoComponent,
  },
};
</script>

<style lang="scss" scoped></style>
