<template>
  <v-app>
    <HeaderComponent v-if="showHeaderFooter" />
    <v-main :class="{ 'background-azure': isPrint }">
      <router-view />
    </v-main>
    <FooterComponent v-if="showHeaderFooter" />
    <modals-container></modals-container>
    <LoaderComponent v-if="loadingStatus" />
  </v-app>
</template>

<script>
import UserService from "@/services/user.service";
import { mapState } from "vuex";
import { ModalsContainer } from "vue-final-modal";
export default {
  name: "App",
  components: {
    ModalsContainer,
  },
  data: () => ({
    userService: new UserService(),
  }),
  mounted() {
    this.userService.getProfileInfo();
  },
  computed: {
    ...mapState({
      loadingStatus: (state) => state.global.loadingStatus,
    }),
    showHeaderFooter() {
      return !["PrintComponent"].includes(this.$route.name);
    },
    isPrint() {
      return this.$route.name === "PrintComponent";
    },
  },
};
</script>

<style lang="scss">
.background-azure {
  background-color: #f6f9f9;
}
</style>
