const STEP = 10;

export const tableRange = (el, binding) => {
  if (binding?.value?.currentPage > 0 && binding?.value?.currentTotal > 0) {
    const maxRange = binding?.value?.currentPage * STEP; //ex: 10
    const min = maxRange - STEP + 1; //ex: 1
    const max = maxRange - STEP + binding?.value?.currentTotal;
    el.innerHTML = `${min}-${max}`;
  }
};
