import {
  createRoom,
  deleteRoomById,
  getRoomById,
  getRoomList,
  updateRoomById,
} from "./api/room.service";
import { STATUS, CHECKED, STATUS_ELECTRICITY } from "@/globals/enums";

export default class RoomService {
  checkDisabledButton(
    residentUsingStatus,
    shorttermUsingStatus,
    shorttermCancellingStatus
  ) {
    const disabledButtonStatus = [
      STATUS_ELECTRICITY.PROCESSING.ID,
      STATUS_ELECTRICITY.WAITING.ID,
    ];
    return (
      disabledButtonStatus?.includes(residentUsingStatus) ||
      disabledButtonStatus?.includes(shorttermUsingStatus) ||
      disabledButtonStatus?.includes(shorttermCancellingStatus)
    );
  }

  checkDisabledShortTermButton(
    shorttermUsingStatus,
    shorttermCancellingStatus
  ) {
    const disabledButtonStatus = [
      STATUS_ELECTRICITY.ON_LIST.ID,
      STATUS_ELECTRICITY.PROCESSING.ID,
      STATUS_ELECTRICITY.WAITING.ID,
    ];
    return (
      disabledButtonStatus?.includes(shorttermUsingStatus) &&
      disabledButtonStatus?.includes(shorttermCancellingStatus)
    );
  }

  checkDisplayStatusAndDatetime(status) {
    const displayStatus = [
      STATUS_ELECTRICITY.WAITING.ID,
      STATUS_ELECTRICITY.PROCESSING.ID,
      STATUS_ELECTRICITY.UNCONFIRMED.ID,
      STATUS_ELECTRICITY.CONFIRMED.ID,
    ];
    return displayStatus.includes(status);
  }

  checkDisabledResident(residentUsingStatus) {
    const disabledButtonStatus = [
      STATUS_ELECTRICITY.ON_LIST.ID,
      STATUS_ELECTRICITY.PROCESSING.ID,
      STATUS_ELECTRICITY.WAITING.ID,
    ];
    return disabledButtonStatus.includes(residentUsingStatus);
  }

  async getRoomList(id, pagination) {
    try {
      const result = await getRoomList({
        building_id: id,
        page: pagination.page || 1,
        number: 10,
      });
      return {
        status: STATUS.SUCCESS,
        data: {
          total: result.total,
          page: result.page,
          totalPage: result.total_page || 0,
          roomList: result.room_list.map((room) => ({
            roomId: room?.room_id,
            roomNumber: room?.room_number,
            autoLock: room?.auto_lock === CHECKED.TRUE,
            waterHeater: room?.water_heater === CHECKED.TRUE,
            residentUsingStatus: Number(room?.resident_using_status || 0),
            shorttermUsingStatus: Number(room?.shortterm_using_status || 0),
            shorttermCancellingStatus: Number(
              room?.shortterm_cancelling_status || 0
            ),
            disabledShortTermButton: this.checkDisabledShortTermButton(
              Number(
                room.shortterm_using_status !== null
                  ? room.shortterm_using_status
                  : 99
              ),
              Number(
                room.shortterm_cancelling_status !== null
                  ? room.shortterm_cancelling_status
                  : 99
              )
            ),
            disabledButton: this.checkDisabledButton(
              Number(
                room.resident_using_status !== null
                  ? room.resident_using_status
                  : 99
              ),
              Number(
                room.shortterm_using_status !== null
                  ? room.shortterm_using_status
                  : 99
              ),
              Number(
                room.shortterm_cancelling_status !== null
                  ? room.shortterm_cancelling_status
                  : 99
              )
            ),
          })),
        },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
      };
    }
  }

  async getInfoRoom(id) {
    try {
      const result = await getRoomById(id);
      return {
        status: STATUS.SUCCESS,
        data: {
          roomNumber: result.room_number,
          autoLock: result.auto_lock,
          waterHeater: result.water_heater,
          roomId: result.room_id,
          supplyPointId: result.supply_point_id,
          residentUsingStatus: result.resident_using_status,
          residentUsingDatetime: result.resident_using_date,
          shorttermUsingStatus: result.shortterm_using_status,
          shorttermUsingDatetime: result.shortterm_using_date,
          shorttermCancellingStatus: result.shortterm_cancelling_status,
          shorttermCancellingDatetime: result.shortterm_cancelling_date,
          buildingId: result.building_id,
          ...(result?.print_id &&
            result?.resident_id && {
              residentLink: `/print/${result?.resident_id}/${result?.print_id}`,
            }),
          disabledButton: this.checkDisabledButton(
            Number(
              result.resident_using_status !== null
                ? result.resident_using_status
                : 99
            ),
            Number(
              result.shortterm_using_status !== null
                ? result.shortterm_using_status
                : 99
            ),
            Number(
              result.shortterm_cancelling_status !== null
                ? result.shortterm_cancelling_status
                : 99
            )
          ),
          disabledResidentButton: this.checkDisabledResident(
            result.resident_using_status
          ),
          displayResident: this.checkDisplayStatusAndDatetime(
            result.resident_using_status
          ),
          displayShortTermDisplay: this.checkDisplayStatusAndDatetime(
            result.shortterm_using_status
          ),
          displayShorttermCancelling: this.checkDisplayStatusAndDatetime(
            result.shortterm_cancelling_status
          ),
        },
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
      };
    }
  }

  async handleEditRoom(id, body) {
    try {
      const result = await updateRoomById(id, {
        room_number: body.roomNumber,
        water_heater: body.waterHeater || CHECKED.FALSE,
        auto_lock: body.autoLock || CHECKED.FALSE,
      });
      return {
        status: STATUS.SUCCESS,
        data: result,
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: error.response,
      };
    }
  }

  async handleCreateRoom(body) {
    try {
      const result = await createRoom({
        building_id: body.buildingId,
        room_number: body.roomNumber,
        auto_lock: body.autoLock,
        water_heater: body.waterHeater,
      });
      return {
        status: STATUS.SUCCESS,
        data: result,
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: error.response,
      };
    }
  }

  async deleteRoom(id) {
    try {
      const result = await deleteRoomById(id);
      return {
        status: STATUS.SUCCESS,
        data: result,
      };
    } catch (error) {
      return {
        status: STATUS.ERROR,
        message: error.message,
        response: error.response,
      };
    }
  }
}
