<template>
  <div class="CompanyEditSuccessComponent">
    <CardComponent
      class="form-companyEditSuccess"
      :width="'960px'"
      :height="'550px'"
    >
      <TitleComponent
        class="form-companyEditSuccess-title"
        :hasUnderLine="true"
      >
        <template v-slot:content>会社情報・支払先情報登録完了</template>
      </TitleComponent>
      <p class="form-companyEditSuccess-description">
        会社情報・支払先情報の登録が完了いたしました。
      </p>
      <div class="form-companyEditSuccess-action-group">
        <ButtonComponent @click="goHomePage" width="230px">
          HOME画面へ
        </ButtonComponent>
      </div>
    </CardComponent>
  </div>
</template>

<script>
export default {
  name: "CompanyEditSuccessComponent",
  methods: {
    goHomePage() {
      this.$router.push({ name: "HomeComponent" });
    },
  },
};
</script>

<style scoped lang="scss">
.CompanyEditSuccessComponent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form-companyEditSuccess {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    &-title {
      font-size: 20px;
      font-weight: 500;
      font-weight: 500;
    }
    &-description {
      font-size: 14px;
      font-weight: 500;
      margin: 20px 0 0;
    }
    &-action-group {
      margin-top: 40px;
    }
  }
}
</style>
