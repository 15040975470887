<template>
  <div class="ShortTermRegistrationConfirmComponent">
    <TitleComponent class="ShortTermRegistrationConfirmComponent-title">
      <template v-slot:icon>
        <span class="icon-ico_riyo_haishi"></span>
      </template>
      <template v-slot:content>電気短期使用・停止一括申込み確認</template>
    </TitleComponent>
    <CardComponent
      v-if="!isSearching"
      class="main"
      :width="'960px'"
      :height="
        currentRegistrationRoomInfo.registrationList.length > 0
          ? 'auto'
          : '460px'
      "
      :isEmpty="!(currentRegistrationRoomInfo.registrationList.length > 0)"
      emptyText="該当する申込みはございませんでした。"
    >
      <div class="table-main-content">
        <div class="count-item">使用開始・停止一括申込み内容</div>
        <table class="table-container">
          <TableHeaderComponent>建物名/部屋番号</TableHeaderComponent>
          <TableHeaderComponent>申込み種別</TableHeaderComponent>
          <TableHeaderComponent>使用開始（停止）日時</TableHeaderComponent>
          <TableHeaderComponent>使用用途</TableHeaderComponent>
          <div class="table-row-group">
            <TableRowComponent
              v-for="registration in currentRegistrationRoomInfo.registrationList"
              :key="registration.registrationId"
            >
              <TableCellComponent class="title-cell first">
                <div class="first">
                  <p class="building-name-cell">
                    {{ registration.buildingName }}
                  </p>
                  <p>{{ registration.roomNumber }}</p>
                </div>
              </TableCellComponent>
              <TableCellComponent class="title-cell"
                ><div
                  class="registrationType"
                  v-roomRegistrationType="registration.registrationType"
                ></div
              ></TableCellComponent>
              <TableCellComponent class="title-cell"
                ><span v-generateDateJapansese="registration.date"></span
                >{{ " " }}
                <span v-generateTimeJapanseseByEnums="registration.time"></span
              ></TableCellComponent>
              <TableCellComponent class="purpose-use-cell">{{
                registration.purposeUse
              }}</TableCellComponent>
            </TableRowComponent>
          </div>
        </table>
      </div>

      <PaginationComponent
        :total-pages="currentRegistrationRoomInfo.totalPage"
        :total="currentRegistrationRoomInfo.total"
        :current-page="currentRegistrationRoomInfo.page"
        v-if="currentRegistrationRoomInfo.totalPage > 1"
        @pagechanged="onPageChange"
      />
      <Form @submit="submit">
        <div class="group">
          <div class="group-title">利用条件・規約確認</div>
          <div class="group-body">
            <div class="checkbox-group">
              <InputComponent
                type="checkbox"
                name="firstCheck"
                :value="firstCheck"
                @trigle-change="setFirstCheck"
              />
              <label for="firstCheck"
                >ご契約締結に際して、契約締結前の供給条件等の説明を、以下のリンク先で確認することに同意します。</label
              >
            </div>
            <div class="openLink">
              <a :href="linkUrl" target="_blank"
                ><span class="icon-ico_newwindow"></span>利用条件・規約</a
              >
            </div>
            <div class="checkbox-group">
              <InputComponent
                type="checkbox"
                name="secondCheck"
                @trigle-change="setSecondCheck"
              />
              <label for="secondCheck">
                電気料金のお支払い方法は、本サイト利用登録時に登録した内容とすることに同意します。
              </label>
            </div>
            <div class="currentCompanyPayment">
              <div class="currentCompanyPayment-content">
                <span class="title">登録されているお支払方法</span>
                <span class="content">{{ paymentOptions }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="group">
          <div class="group-title">料金プラン確認</div>
          <div class="group-body">
            <div class="checkbox-group">
              <InputComponent
                type="checkbox"
                name="lastCheck"
                @trigle-change="setLastCheck"
              />
              <label for="lastCheck">
                料金プランについては以下のとおり申込むことに同意します。
              </label>
            </div>
            <div class="paymentAgree">
              <div class="content">
                <p>
                  ①お申込みのお部屋に電気温水器等の夜間蓄熱機器が設置されている場合：電化でナイト・セレクト22
                </p>
                <p>
                  ②①に該当せず、契約電流が60A以下の場合：スマートファミリープラン
                </p>
                <p>
                  ③①に該当せず、契約電流が6ｋVA以上の場合：スマートビジネスプラン
                </p>
              </div>
            </div>
          </div>
        </div>
        <ErrorDetailComponent v-if="errorDetail" :errorDetail="errorDetail" />
        <div class="submit-action">
          <ButtonComponent
            buttonType="submit"
            width="170px"
            height="50px"
            :disabled="!activeButton"
            >申込み</ButtonComponent
          >
        </div>
      </Form>
    </CardComponent>
    <div class="back">
      <ButtonComponent
        width="170px"
        height="50px"
        type="outline"
        className="whiteBackGround"
        @click="changeTab"
        >もどる</ButtonComponent
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import RegistrationService from "@/services/registration.service";
import ConfirmDialogService from "@/services/dialog/confirm-dialog.service";
import { REGISTRATION_SETTING_TAB, STATUS } from "@/globals/enums";
import { Form } from "vee-validate";

export default {
  name: "ShortTermRegistrationConfirmComponent",
  components: {
    Form,
  },
  async mounted() {
    this.isSearching = true;
    await this.registrationService.getRegistrationList({ page: 1, number: 10 });
    this.isSearching = false;
  },
  data: () => ({
    isSearching: true,
    registrationService: new RegistrationService(),
    confirmDialogService: new ConfirmDialogService(),
    firstCheck: false,
    secondCheck: false,
    lastCheck: false,
    errorDetail: null,
    linkUrl: process.env.VUE_APP_SHORT_TERM_REGISTRATION_URL,
  }),
  computed: {
    activeButton() {
      return this.firstCheck && this.secondCheck && this.lastCheck;
    },
    ...mapState({
      currentRegistrationRoomInfo: (state) =>
        state.registrationShortTerm.currentRegistrationRoomInfo,
      fromRoomRouter: (state) =>
        state.registrationShortTerm.fromRoomRouter || "/building/search",
      paymentOptions: (state) =>
        state.company?.edittingCompanyData?.paymentOptions?.label,
    }),
  },
  methods: {
    async onPageChange(event) {
      this.registrationService.getRegistrationList({ page: event, number: 10 });
    },
    changeTab() {
      if (this.currentRegistrationRoomInfo.registrationList.length === 0) {
        this.$router.go(-1);
        return;
      }
      this.$emit("change-tab", REGISTRATION_SETTING_TAB.EDIT);
    },
    setFirstCheck(e) {
      this.firstCheck = e.target.checked;
    },
    setSecondCheck(e) {
      this.secondCheck = e.target.checked;
    },
    setLastCheck(e) {
      this.lastCheck = e.target.checked;
    },
    submit() {
      let data = this.currentRegistrationRoomInfo.registrationList.map(
        (registration) => registration.registrationId
      );
      this.registrationService.updateRegistration(data).then((result) => {
        if (result.status === STATUS.SUCCESS) {
          this.confirmDialogService.openConfirmDialog(
            "電気短期使用・停止一括申込みが完了いたしました。",
            "申込み状況は履歴よりご確認いただけます。",
            () => {
              this.$router.push({ path: this.fromRoomRouter });
            }
          );
        }
        if (result.status === STATUS.ERROR && result.response?.detail) {
          this.errorDetail = result.response.detail;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.ShortTermRegistrationConfirmComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-title {
    width: 960px;
    text-align: left;
    margin-top: var(--default-distance-card);
    color: var(--venice-blue-color);
    padding: 0.5rem 0;
  }
  .main {
    padding: 30px 42px 30px 41px;
    .table-main-content {
      .count-item {
        text-align: left;
        font-size: 16px;
        font-weight: 500;
        margin: 5px 0;
      }
      .table-container {
        width: -webkit-fill-available;
        border-top: solid 1px #d0d9df;
      }
      .TableHeaderComponent {
        text-align: center;
        font-weight: 500;
      }

      .purpose-use-cell {
        width: 200px;
        text-align: center;
      }
      .TableCellComponent {
        .content-cell {
          @include ellipsis(2);
        }
        &.title-cell {
          text-align: center;
          &.first {
            padding: 13px 9px;
          }
          .first {
            display: flex;
            gap: 20px;
            justify-content: left;
            .building-name-cell {
              text-align: left;
              width: 180px;
            }
          }
        }
        &.last {
          padding-right: 11px;
          font-size: 14px;
          font-weight: 500;
        }
        .button-groups {
          display: flex;
          gap: 10px;
          justify-content: flex-end;
        }
        .registrationType {
          margin: 0 auto;
          padding: 2px;
          border: solid 1px #d0d9df;
          width: 80px;
          height: 23px;
          font-size: 11px;
        }
      }
      .after-table {
        margin: 16.5px 11px 30px 0;
        text-align: right;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .pagination {
      text-align: center;
      margin: 30px;
    }
    .submit-action {
      text-align: center;
      margin-top: 30px;
    }
    .group {
      margin-top: 30px;
      label {
        cursor: pointer;
      }
      &-title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
      }
      &-body {
        border-top: solid 2px #d0d9df;
        padding: 20px 40px 0;
        padding: 20px 40px 0;
        .checkbox-group {
          display: flex;
          gap: 10px;
          align-items: center;
          font-size: 14px;
        }
        .openLink {
          text-decoration: none;
          margin: 10px 0 20px;
          height: 90px;
          background-color: #f3f6f7;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          .icon-ico_newwindow {
            &::before {
              color: #fff;
            }
          }
          a {
            background-color: #4c7495;
            color: white;
            caret-color: white;
            height: 30px;
            width: 162px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            gap: 6px;
            font-size: 14px;
            font-weight: 500;
            border-radius: 25px;
          }
        }
        .currentCompanyPayment {
          margin-top: 10px;
          height: 80px;
          background-color: #f3f6f7;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          &-content {
            display: flex;
            gap: 60px;
            .title {
              color: #7c8185;
            }
          }
        }
        .paymentAgree {
          margin-top: 10px;
          height: 148px;
          background-color: #f3f6f7;
          padding: 30px 0 0 30px;
          .content {
            display: flex;
            gap: 15px;
            flex-direction: column;
            font-size: 14px;
          }
        }
      }
    }
  }
  .back {
    margin: 30px 0 var(--default-distance-button);
    .ButtonComponent {
      button {
        background-color: #fff;
      }
    }
  }
}
</style>
