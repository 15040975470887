import showDialog from "@/services/dialog.service";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";

export default class ErrorDialogService {
  openErrorDialog(title, content, callbackClose) {
    showDialog({
      component: ErrorDialog,
      bind: {
        title,
        content,
        onClose: callbackClose,
      },
    });
  }
}
