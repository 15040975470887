<template>
  <td class="TableCellComponent">
    <slot></slot>
  </td>
</template>

<script>
export default {
  name: "TableCellComponent",
};
</script>
<style scoped lang="scss">
.TableCellComponent {
  display: table-cell;
  padding: 13px 20px;
  color: #000;
  font-size: 14px;
  border-bottom: 1px solid #d0d9df;
}
</style>
