<template>
  <div class="ResidentRoomInfoComponent">
    <TitleComponent class="ResidentRoomInfoComponent-title">
      <template v-slot:icon>
        <span class="icon-ico_riyo_haishi"></span>
      </template>
      <template v-slot:content> お申し込み住所 </template>
    </TitleComponent>
    <CardComponent class="main" :width="'960px'" :height="'auto'">
      <div class="group">
        <div class="group-title">建物情報</div>
        <GroupContentComponent>
          <template v-slot:title> 物件名 </template>
          <template v-slot:content>
            {{ dataBuilding.buildingName }}
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> 住所 </template>
          <template v-slot:content>
            <div class="postOfficeAddress">
              <div class="postCode">
                〒
                <div v-formatPostCode="dataBuilding.postCode"></div>
              </div>
              <div class="postOfficeInfo">
                {{ dataBuilding.address }}<br />
                {{ dataBuilding.deliveryDepartmentNumber }}
              </div>
            </div>
          </template>
        </GroupContentComponent>
      </div>
      <div class="group">
        <div class="group-title">部屋情報</div>
        <GroupContentComponent>
          <template v-slot:title> 部屋番号 </template>
          <template v-slot:content>
            {{ dataRoom.roomNumber }}
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> オートロック </template>
          <template v-slot:content>
            <div class="RoomInfoComponent-content-icon">
              <div v-if="dataRoom.autoLock === CHECKED.TRUE">
                <span class="icon-ico_seijyo">
                  <span class="path1"></span>
                  <span class="path2"></span>
                </span>
                あり
              </div>
              <div v-else>
                <span class="icon-check"></span>
                なし
              </div>
            </div>
          </template>
        </GroupContentComponent>
        <GroupContentComponent>
          <template v-slot:title> 温水器</template>
          <template v-slot:content>
            <div class="RoomInfoComponent-content-icon">
              <div v-if="dataRoom.waterHeater === CHECKED.TRUE">
                <span class="icon-ico_seijyo">
                  <span class="path1"></span>
                  <span class="path2"></span>
                </span>
                あり
              </div>
              <div v-else>
                <span class="icon-check"></span>
                なし
              </div>
            </div>
          </template>
        </GroupContentComponent>
      </div>
    </CardComponent>
  </div>
</template>
<script>
import { CHECKED } from "@/globals/enums";
export default {
  name: "ResidentRoomInfoComponent",
  created() {
    this.CHECKED = CHECKED;
  },
  props: {
    dataRoom: {
      type: Object,
      default: () => {},
    },
    dataBuilding: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style scoped lang="scss">
.ResidentRoomInfoComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-title {
    width: 960px;
    text-align: left;
    margin-top: 32px;
    color: var(--venice-blue-color);
    padding: 0.5rem 0;
  }
  .main {
    padding: 30px 40px;
    .group {
      margin-bottom: 30px;
      &-title {
        font-size: 16px;
        padding-bottom: 5px;
        border-bottom: solid 2px #d0d9df;
      }
      .postOfficeAddress {
        .postCode {
          display: flex;
        }
      }
    }
  }
}
</style>
