<template>
  <div
    class="SelectComponent"
    :class="{
      'has-error': !!errorMessage || errors.length !== 0,
      isSmall: isSmall,
      isSelectYear: isSelectYear,
      isSelectTime: isSelectTime,
    }"
  >
    <Field
      as="select"
      :name="name"
      @change="onChange($event)"
      :disabled="disabled"
    >
      <option v-for="(item, index) in options" :key="index" :value="item.value">
        {{ item.label }}
      </option>
    </Field>
    <p class="help-message" v-if="!!errorMessage">
      <span class="icon-ico_ijyo2">
        <span class="path1" v-if="errorMessage"></span
        ><span class="path2"></span>
      </span>
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { useField, Field } from "vee-validate";
export default {
  name: "SelectComponent",
  components: {
    Field,
  },
  props: {
    type: {
      type: String,
      default: "default",
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: null,
    },
    initialValue: {
      type: [Number || String],
      default: 0,
    },
    currentValue: {
      type: [Number || String],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isSelectYear: {
      type: Boolean,
      default: false,
    },
    isSelectTime: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onChange(e) {
      this.$emit("trigle-change", e);
    },
  },
  setup(props) {
    const { errorMessage, errors, handleBlur, handleChange, meta } = useField(
      props.name,
      undefined,
      {
        initialValue: props.initialValue,
        currentValue: props.currentValue,
      }
    );

    return {
      handleChange,
      handleBlur,
      errorMessage,
      errors,
      meta,
    };
  },
};
</script>

<style lang="scss" scoped>
.SelectComponent {
  position: relative;
  select {
    width: 210px;
    height: 30px;
    padding: 3px 8px 3px;
    border: solid 1px #d0d9df;
    border-radius: none;
    background-color: #fff;
    -webkit-appearance: auto;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    &:focus {
      border-color: #8d9caa !important;
    }
    .option {
      font-size: 14px;
      font-weight: 500;
      border: solid 1px #d0d9df;
    }
  }
  &.isSmall {
    select {
      width: 170px;
    }
  }
  &.isSelectYear {
    select {
      width: 80px;
    }
  }
  &.isSelectTime {
    select {
      width: 60px;
    }
  }
  &.has-error {
    select {
      border: solid 1px var(--error-color);
      padding: 3px 8px 3px;
    }
  }
  .help-message {
    position: absolute;
    bottom: -2em;
    left: 0;
    margin: 0;
    font-size: 10px;
    color: var(--error-color);
    width: max-content;
    .icon-ico_ijyo2 {
      .path1 {
        &::before {
          color: #c51f1f;
        }
      }
    }
  }
}
</style>
