<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="WarningDialog modal-content"
  >
    <button
      class="modal__close"
      @click="
        close();
        onClose();
      "
    >
      <span class="icon-ico_closedialog"></span>
    </button>

    <span class="modal__title"
      ><span class="icon-ico_info"></span> {{ title }}</span
    >
    <div class="content-custom" v-html="content"></div>
    <div class="modal__action">
      <ButtonComponent
        type="outline"
        width="150px"
        height="50px"
        buttonType="button"
        @click="
          close();
          onClose();
        "
      >
        キャンセル
      </ButtonComponent>
      <ButtonComponent width="150px" height="50px" @click="onApprove()">
        OK
      </ButtonComponent>
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  name: "WarningDialog",
  props: {
    title: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    onChoise: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    onApprove() {
      this.onChoise(true);
      this.$vfm.hideAll();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.WarningDialog) {
  &.modal {
    &-content {
      position: relative;
      background-color: #fefefe;
      margin: auto;
      padding: 40px 50px 29px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      border: 1px solid #888;
      width: 500px;
      height: 236px;
      border-radius: 5px;
      .modal {
        &__title {
          font-weight: bold;
          text-align: center;
          margin-bottom: 20px;
          color: #c51f1f;
          display: inline-flex;
          .icon-ico_info {
            margin-right: 5px;
            font-size: 25px;
          }
        }
        &__content {
          width: 100%;
          font-size: 14px;
          text-align: center;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          gap: 10px;
          &-addressItem {
            height: 60px;
            padding: 20px;
            border: solid 1px #d0d9df;
            display: flex;
            align-items: center;
            .InputComponent {
              margin-bottom: 0;
            }
            &.active {
              background-color: #f3f6f7;
              color: #333;
            }
          }
        }
        &__close {
          position: absolute;
          top: 10px;
          right: 20px;
          color: var(--color-gray);
          font-size: 20px;
        }
        &__action {
          display: flex;
          gap: 20px;
          margin-top: 30px;
        }
      }
    }
  }
}
::v-deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-custom {
  font-size: 16px;
  font-weight: bold;
}
</style>
