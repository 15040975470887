import { get, post } from "../https/http.service";

export const getPrintInformation = (print_id) => {
  return get(`common/print?print_id=${print_id}`);
};

export const createResident = (body) => {
  return post("estate/resident", body);
};

export const getResidentInformation = (resident_id) => {
  return get(`estate/resident/${resident_id}`);
};
