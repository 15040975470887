import { PROVIDES } from "@/globals/enums";

export const convertBuildings = (dataBuilding) => {
  return {
    buildingId: dataBuilding?.building_id,
    address: dataBuilding?.address,
    buildingName: dataBuilding?.building_name,
  };
};

export const convertProvidesOptions = () => {
  return PROVIDES.map((type) => ({
    value: type.VALUE,
    label: type.LABEL,
  }));
};

export const convertBuildingSetting = () => {
  return {
    providesOptions: convertProvidesOptions(),
  };
};

export const convertInfoBuilding = (dataInfoBuilding) => {
  return {
    buildingName: dataInfoBuilding.building_name || "",
    address: dataInfoBuilding.address || "",
    addressCode: dataInfoBuilding.address_code || "",
    postCode: dataInfoBuilding.post_code || "",
    deliveryDepartmentNumber: dataInfoBuilding.block_number || "",
    dateUpdate: dataInfoBuilding.updated_at.split(" ")[0],
    timeUpdate: dataInfoBuilding.updated_at.split(" ")[1],
    nameUpdate: dataInfoBuilding.updated_by || "",
  };
};

export const converterBodySearchBuilding = (body, pagination) => {
  const city = body?.city && body?.city != "0" ? body.city : "";
  return {
    key_word: body.keyWord || null,
    post_code:
      (body.firstCode || "").trim() + (body.lastCode || "").trim() || null,
    address: city + (body.address || "").trim() || null,
    block_number: body.blockNumber || null,
    page: pagination.page || 1,
    number: pagination.number || 10,
  };
};
