import { filterMFAInfo } from "../converter/settings";
import { convertProfile } from "../converter/profile.convert";
const state = {
  currentUserMFAInfo: {
    email: "",
    phoneNumber: "",
    MFAType: "NO_MFA",
  },
  tempUser: null,
  QRcode: null,
  profileInfo: {},
};

// Getter functions
const getters = {
  getCurrentUserMFAInfo() {
    return state.currentUserMFAInfo;
  },
  getTempUser() {
    return state.tempUser;
  },
  getQRcode() {
    return state.QRcode;
  },
  getProfileInfo() {
    return state.profileInfo;
  },
};

// Mutations
const mutations = {
  setCurrentUserMFAInfo(state, data) {
    state.currentUserMFAInfo = data;
  },
  setTempUser(state, data) {
    state.tempUser = data;
  },
  setQRCode(state, data) {
    state.QRcode = data;
  },
  setProfileInfo(state, data) {
    state.profileInfo = data;
  },
};

// Actions
const actions = {
  changeCurrentUserMFAInfo({ commit }, data) {
    commit("setCurrentUserMFAInfo", filterMFAInfo(data));
  },
  changeTempUser({ commit }, data) {
    commit("setTempUser", data);
  },
  changeQRCode({ commit }, data) {
    commit("setQRCode", data);
  },
  changeProfileInfo({ commit }, data) {
    commit("setProfileInfo", convertProfile(data));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
