<template>
  <div class="TelephoneInputComponent">
    <div
      class="TelephoneInputComponent_item"
      v-for="(item, index) in groupInput"
      :key="index"
    >
      <InputComponent
        :required="index === 0 && required"
        :name="item.name"
        :type="item.type"
        :value="item.value"
        :pattern="item.pattern"
        :maxlength="item.maxlength"
        inputFilter="number"
      />
      <p v-if="index !== groupInput.length - 1">ー</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TelephoneInputComponent",
  props: {
    groupInput: {
      type: Array,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.TelephoneInputComponent {
  display: flex;
  gap: 8px;
  align-items: center;
  &_item {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}
</style>
