import * as Yup from "yup";
import {
  validateEndDateTimewithStartDateTime,
  validateDateTimeWithCurrentTime,
  validateStartDateTimewithEndTime,
  validatePurposeUse,
} from "../common";
import { ERROR_MESSAGES } from "@/globals/error-messages";
import {
  ELECTRIC_ROOM_REGISTRATION_TIME_DEFAULT_OPTION,
  ELECTRIC_ROOM_REGISTRATION_TIME_START_OPTION,
  ELECTRIC_ROOM_REGISTRATION_TIME_END_OPTION,
} from "@/globals/enums";

export const registrationElectricRoomSchema = (
  holidateList,
  startTime,
  endTime
) => {
  return Yup.object().shape({
    startYear: Yup.string()
      .when("registrationType", {
        is: ELECTRIC_ROOM_REGISTRATION_TIME_DEFAULT_OPTION,
        then: validateStartDateTimewithEndTime(
          "startYear",
          "startMonth",
          "startDate",
          "endYear",
          "endMonth",
          "endDate",
          holidateList,
          startTime,
          ERROR_MESSAGES.NOT_VALID_TIME,
          ERROR_MESSAGES.DATES_CAN_NOT_SAME,
          ERROR_MESSAGES.NOT_VALID_TIME,
          ERROR_MESSAGES.FUTURE_TIME_NOT_VALID,
          ERROR_MESSAGES.PLEASE_INPUT_TIME,
          ERROR_MESSAGES.PAST_TIME_NOT_VALID
        ),
        otherwise: Yup.string(),
      })
      .when("registrationType", {
        is: ELECTRIC_ROOM_REGISTRATION_TIME_START_OPTION,
        then: validateDateTimeWithCurrentTime(
          "startYear",
          "startMonth",
          "startDate",
          holidateList,
          startTime,
          ERROR_MESSAGES.NOT_VALID_TIME,
          ERROR_MESSAGES.NOT_VALID_TIME,
          ERROR_MESSAGES.FUTURE_TIME_NOT_VALID,
          ERROR_MESSAGES.PLEASE_INPUT_TIME,
          ERROR_MESSAGES.PAST_TIME_NOT_VALID
        ),
        otherwise: Yup.string(),
      }),
    startMonth: Yup.string()
      .when("registrationType", {
        is: ELECTRIC_ROOM_REGISTRATION_TIME_DEFAULT_OPTION,
        then: validateStartDateTimewithEndTime(
          "startYear",
          "startMonth",
          "startDate",
          "endYear",
          "endMonth",
          "endDate",
          holidateList,
          startTime
        ),
        otherwise: Yup.string(),
      })
      .when("registrationType", {
        is: ELECTRIC_ROOM_REGISTRATION_TIME_START_OPTION,
        then: validateDateTimeWithCurrentTime(
          "startYear",
          "startMonth",
          "startDate",
          holidateList,
          startTime
        ),
        otherwise: Yup.string(),
      }),
    startDate: Yup.string()
      .when("registrationType", {
        is: ELECTRIC_ROOM_REGISTRATION_TIME_DEFAULT_OPTION,
        then: validateStartDateTimewithEndTime(
          "startYear",
          "startMonth",
          "startDate",
          "endYear",
          "endMonth",
          "endDate",
          holidateList,
          startTime
        ),
        otherwise: Yup.string(),
      })
      .when("registrationType", {
        is: ELECTRIC_ROOM_REGISTRATION_TIME_START_OPTION,
        then: validateDateTimeWithCurrentTime(
          "startYear",
          "startMonth",
          "startDate",
          holidateList,
          startTime
        ),
        otherwise: Yup.string(),
      }),
    endYear: Yup.string()
      .when("registrationType", {
        is: ELECTRIC_ROOM_REGISTRATION_TIME_DEFAULT_OPTION,
        then: validateEndDateTimewithStartDateTime(
          "startYear",
          "startMonth",
          "startDate",
          "endYear",
          "endMonth",
          "endDate",
          holidateList,
          endTime,
          ERROR_MESSAGES.NOT_VALID_TIME,
          ERROR_MESSAGES.DATES_CAN_NOT_SAME,
          ERROR_MESSAGES.NOT_VALID_TIME,
          ERROR_MESSAGES.FUTURE_TIME_NOT_VALID,
          ERROR_MESSAGES.PLEASE_INPUT_TIME,
          ERROR_MESSAGES.PAST_TIME_NOT_VALID
        ),
        otherwise: Yup.string(),
      })
      .when("registrationType", {
        is: ELECTRIC_ROOM_REGISTRATION_TIME_END_OPTION,
        then: validateDateTimeWithCurrentTime(
          "endYear",
          "endMonth",
          "endDate",
          holidateList,
          endTime,
          ERROR_MESSAGES.NOT_VALID_TIME,
          ERROR_MESSAGES.NOT_VALID_TIME,
          ERROR_MESSAGES.FUTURE_TIME_NOT_VALID,
          ERROR_MESSAGES.PLEASE_INPUT_TIME,
          ERROR_MESSAGES.PAST_TIME_NOT_VALID
        ),
        otherwise: Yup.string(),
      }),
    endMonth: Yup.string()
      .when("registrationType", {
        is: ELECTRIC_ROOM_REGISTRATION_TIME_DEFAULT_OPTION,
        then: validateEndDateTimewithStartDateTime(
          "startYear",
          "startMonth",
          "startDate",
          "endYear",
          "endMonth",
          "endDate",
          holidateList,
          endTime
        ),
        otherwise: Yup.string(),
      })
      .when("registrationType", {
        is: ELECTRIC_ROOM_REGISTRATION_TIME_END_OPTION,
        then: validateDateTimeWithCurrentTime(
          "endYear",
          "endMonth",
          "endDate",
          holidateList,
          endTime
        ),
        otherwise: Yup.string(),
      }),
    endDate: Yup.string()
      .when("registrationType", {
        is: ELECTRIC_ROOM_REGISTRATION_TIME_DEFAULT_OPTION,
        then: validateEndDateTimewithStartDateTime(
          "startYear",
          "startMonth",
          "startDate",
          "endYear",
          "endMonth",
          "endDate",
          holidateList,
          endTime
        ),
        otherwise: Yup.string(),
      })
      .when("registrationType", {
        is: ELECTRIC_ROOM_REGISTRATION_TIME_END_OPTION,
        then: validateDateTimeWithCurrentTime(
          "endYear",
          "endMonth",
          "endDate",
          holidateList,
          endTime
        ),
        otherwise: Yup.string(),
      }),
    purposeUse: validatePurposeUse,
  });
};

export const registrationEditElectricRoomSchema = (holidateList, time) => {
  return Yup.object().shape({
    year: validateDateTimeWithCurrentTime(
      "year",
      "month",
      "date",
      holidateList,
      time,
      ERROR_MESSAGES.NOT_VALID_TIME,
      ERROR_MESSAGES.NOT_VALID_TIME,
      ERROR_MESSAGES.FUTURE_TIME_NOT_VALID,
      ERROR_MESSAGES.PLEASE_INPUT_TIME,
      ERROR_MESSAGES.PAST_TIME_NOT_VALID
    ),
    month: validateDateTimeWithCurrentTime(
      "year",
      "month",
      "date",
      holidateList,
      time
    ),
    date: validateDateTimeWithCurrentTime(
      "year",
      "month",
      "date",
      holidateList,
      time
    ),
    purposeUse: validatePurposeUse,
  });
};
