<template>
  <CompanyEditTabComponent
    v-show="tab === COMPANY_SETTING_TAB.EDIT"
    @change-tab="changeTab"
  />
  <CompanyPreviewTabComponent
    v-show="tab === COMPANY_SETTING_TAB.PREVIEW"
    @change-tab="changeTab"
  />
  <CompanyEditSuccessComponent v-show="tab === COMPANY_SETTING_TAB.DONE" />
</template>

<script>
import { COMPANY_SETTING_TAB } from "@/globals/enums";

export default {
  name: "CompanyEditComponent",
  created() {
    this.COMPANY_SETTING_TAB = COMPANY_SETTING_TAB;
  },
  data: () => ({
    tab: COMPANY_SETTING_TAB.EDIT,
  }),
  methods: {
    changeTab(tabId) {
      this.tab = tabId;
    },
  },
};
</script>
