<template>
  <div
    class="InputComponent"
    :class="{
      success: meta.valid,
      'has-error': !!errorMessage || errors.length !== 0,
      'InputComponent-radio': isRadio,
      'InputComponent-checkbox': isCheckbox,
      'InputComponent-telephone': isTelephoneGroup,
      'InputComponent-companyEdit': isCompanyEdit,
      'InputComponent-small': small,
      'InputComponent-max-width': maxWidth,
      'InputComponent-password': isPassword,
      'InputComponent-leftIcon': leftIcon,
      'InputComponent-bigBorder': bigBorder,
    }"
  >
    <div v-if="required" class="label" :class="labelClass">必須</div>
    <span v-if="leftIcon" class="leftIcon" :class="leftIcon" />
    <input
      v-if="type !== 'checkbox' && type !== 'radio'"
      v-inputFilter="inputFilter"
      :name="name"
      :id="id || name"
      :type="inputType"
      :value="renderValue"
      :placeholder="placeholder"
      :pattern="pattern"
      :maxlength="maxlength"
      :checked="value === defaultValue"
      :disabled="disabled"
      @focus="onFocus"
      @input="handleChange"
      @blur="onBlur"
      @keyup="trigleChange"
    />
    <input
      v-else-if="type === 'radio'"
      :name="name"
      :id="id || name"
      :type="inputType"
      :value="renderValue"
      :placeholder="placeholder"
      :pattern="pattern"
      :maxlength="maxlength"
      :checked="value === defaultValue"
      @input="handleChange"
      @blur="onBlur"
      @change="trigleChange"
      @keyup="trigleChange"
    />
    <label v-else :for="id || name" class="labelContainer">
      <input
        :name="name"
        :id="name"
        :type="inputType"
        :value="renderValue"
        :placeholder="placeholder"
        :pattern="pattern"
        :maxlength="maxlength"
        :checked="value === defaultValue"
        @input="handleChange"
        @blur="handleChange"
        @change="trigleChange"
        @keyup="trigleChange"
      />
      <span class="checkmark"></span>
    </label>
    <span
      v-if="type === 'password' && !leftIcon"
      class="eye"
      :class="{
        'icon-ico_password_open': showPassword,
        'icon-ico_eye eye': !showPassword,
      }"
      @click="setShowPassword()"
    />
    <label class="label-radio" v-if="type === 'radio'" :for="id || name">
      {{ label }}</label
    >

    <span
      v-if="!validateSteps"
      class="help-message"
      v-show="errorMessage && showErrorMessage && meta.touched"
      :class="{
        noMarginBottomError: noMarginBottomError,
      }"
    >
      <span class="icon-ico_ijyo2">
        <span class="path1" v-if="errorMessage"></span
        ><span class="path2"></span>
      </span>
      {{ errorMessage || successMessage }}
    </span>
    <div class="InputComponent_validateSteps" v-else>
      <div
        v-for="(validate, index) of validateSteps"
        :key="index"
        class="InputComponent_validateSteps_step"
      >
        <IconCheckComponent :isChecked="validate.check(inputValue)" />
        <span>{{ validate.message }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { useField } from "vee-validate";
export default {
  name: "InputComponent",
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: [String, Number, Boolean],
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: [String, Number],
      default: null,
    },
    successMessage: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: "",
    },
    validateSteps: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: [String, Number, Boolean],
      default: null,
    },
    maxlength: {
      type: String,
      default: null,
    },
    pattern: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Boolean,
      default: false,
    },
    isCompanyEdit: {
      type: Boolean,
      default: false,
    },
    inputFilter: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    requiredLabelClass: {
      type: String,
      default: "label",
    },
    noMarginBottomError: {
      type: Boolean,
      default: false,
    },
    labelClass: {
      type: String,
      default: "",
    },
    showErrorMessage: {
      type: Boolean,
      default: true,
    },
    leftIcon: {
      type: String,
      default: null,
    },
    bigBorder: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showPassword: false,
  }),
  computed: {
    isRadio() {
      return this.type === "radio";
    },
    isCheckbox() {
      return this.type === "checkbox";
    },
    isPassword() {
      return this.type === "password";
    },
    isTelephoneGroup() {
      return this.type === "telephoneGroup";
    },
    renderValue() {
      return this.type === "radio" ? this.value : this.inputValue;
    },
    inputType() {
      return this.showPassword ? "text" : this.type;
    },
  },
  methods: {
    setShowPassword() {
      this.showPassword = !this.showPassword;
    },
    trigleChange(e) {
      this.$emit("trigle-change", e);
    },
  },
  setup(props) {
    const {
      value: inputValue,
      errorMessage,
      errors,
      handleBlur,
      handleChange,
      validate,
      setTouched,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.defaultValue ? props.defaultValue : props.value,
      validateOnValueUpdate: false,
    });
    const onFocus = async () => {
      await setTouched(false);
    };
    const onBlur = async (e) => {
      await validate(e);
      await handleBlur(e);
    };

    return {
      handleChange,
      handleBlur,
      onFocus,
      onBlur,
      errorMessage,
      errors,
      inputValue,
      meta,
    };
  },
};
</script>

<style lang="scss" scoped>
.InputComponent {
  position: relative;
  margin-bottom: calc(1em * 1.5);
  width: 360px; //default width
  position: relative;
  label {
    display: block;
    margin-bottom: 4px;
    width: 100%;
  }
  &_validateSteps {
    display: flex;
    position: absolute;
    margin-top: 5px;
    gap: 18px;
    &_step {
      display: flex;
      gap: 5px;
      span {
        font-size: 10px;
        font-weight: 500;
        color: #7c8185;
      }
    }
  }

  input {
    border: 1px solid #d0d9df;
    padding: 15px 10px;
    outline: none;
    background-color: #ffffff;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
    &:focus {
      border-color: #8d9caa !important;
    }
    &::placeholder {
      font-size: 14px;
      font-weight: 500;
      line-height: 0.86;
      color: var(--color-gray);
    }
  }

  .icon-ico_password_open {
    &::before {
      content: "\e932";
      color: #8d9caa;
      font-size: 18px;
    }
  }
}

.help-message {
  position: absolute;
  bottom: calc(-2 * 1em);
  left: 0;
  margin: 0;
  font-size: 10px;
  width: max-content;
  &.noMarginBottomError {
    bottom: calc(-3.5 * 1em);
  }
  .icon-ico_ijyo2 {
    .path1 {
      &::before {
        color: #c51f1f;
      }
    }
  }
}

.InputComponent.has-error input {
  border-color: var(--error-color);
}

.InputComponent.has-error input {
  border-color: var(--error-color);
}

.InputComponent.has-error .help-message {
  color: var(--error-color);
}

.InputComponent.success input {
  background-color: var(--success-bg-color);
}

.InputComponent.success input:focus {
  border-color: var(--success-color);
}

.InputComponent.success .help-message {
  color: var(--success-color);
}

.InputComponent-bigBorder {
  input {
    border: 2px solid #d0d9df !important;
    padding: 15px 10px;
    outline: none;
    background-color: #ffffff;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
      background-color 0.3s ease-in-out;
    &:focus {
      border-color: #8d9caa !important;
    }
    &::placeholder {
      font-size: 14px;
      font-weight: 500;
      line-height: 0.86;
      color: var(--color-gray);
    }
  }
}
.InputComponent-radio {
  display: flex;
  align-items: center;
  gap: 10px;
  width: max-content;
  input {
    accent-color: #4c7495;
    width: unset;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
  }
  label {
    margin-bottom: 0px;
  }
  .label-radio {
    cursor: pointer;
  }
  .help-message {
    width: max-content;
  }
}
.InputComponent-telephone {
  width: 80px;
  height: 30px;
  margin-bottom: 0;
  input {
    height: 30px;
    padding: 0 10px;
  }
  &-large {
    width: 80px;
    height: 30px;
    margin-bottom: 0;
    input {
      padding: 0 5px;
      height: 30px;
    }
  }
  .help-message {
    white-space: nowrap;
    font-size: 10px;
    bottom: -2em;
    widows: max-content;
  }
}
.InputComponent-small {
  width: 100%;
  height: 30px !important;
  margin: 15px 0;
  input {
    height: 30px;
    padding: 0 11px;
  }
  .help-message {
    font-size: 10px;
    bottom: -2em;
    widows: max-content;
  }
}

.InputComponent-companyEdit {
  width: 600px;
  height: 30px !important;
  margin: 15px 0;
  input {
    height: 30px;
    padding: 0 11px;
  }
  .help-message {
    font-size: 12px;
    bottom: -2em;
  }
}

.small-custom {
  input {
    width: 240px;
  }
}

.InputComponent-max-width {
  width: 100%;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input::placeholder {
  overflow: visible;
  font-size: 14px;
  font-weight: 500;
}

.label {
  width: 20px;
  height: 15px;
  font-size: 10px;
  font-weight: 500;
  background-color: var(--valley-smoke-color);
  width: 30px;
  height: 16px;
  position: absolute;
  left: -40px;
  top: 5px;
  color: #7c8185;
  text-align: center;
}

.farLabel {
  width: 20px;
  height: 15px;
  font-size: 10px;
  font-weight: 500;
  color: #7c8185;
  background-color: var(--valley-smoke-color);
  width: 30px;
  height: 16px;
  position: absolute;
  left: -60px;
  top: 5px;
  text-align: center;
  &-custom {
    left: -70px;
  }
}

.InputComponent-password {
  input {
    padding-right: 33px;
  }
}
.InputComponent-leftIcon {
  input {
    padding-left: 50px;
    &::placeholder {
      font-size: 14px;
      font-weight: 500;
      line-height: 0.86;
      letter-spacing: 0.7px;
      color: var(--color-gray);
    }
  }
  .leftIcon {
    position: absolute;
    left: 20px;
    top: 17px;
  }
  .icon-ico_key {
    left: 19px;
    top: 17.8px;
  }
}

.eye {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.InputComponent-checkbox {
  width: 15px;
  height: 15px;
  position: relative;
  margin-bottom: unset;
  .labelContainer {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .labelContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: var(--white-color);
    border-radius: 2px;
    border: solid 1px #d0d9df;
  }

  .labelContainer input:checked ~ .checkmark {
    background-color: var(--primary-color);
    border-radius: 2px;
    border: solid 1px #d0d9df;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .labelContainer input:checked ~ .checkmark:after {
    display: block;
  }

  .labelContainer .checkmark:after {
    left: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
</style>
