import showDialog from "@/services/dialog.service";
import RegistrationElectricRoomDialog from "@/components/dialogs/RegistrationElectricRoomDialog.vue";

export default class RegistrationElectricDialogService {
  openRegistrationElectricDialogServiceDialog(
    isEdit,
    roomElectricRegisterData,
    callbackClose,
    callbackChoise
  ) {
    showDialog({
      component: RegistrationElectricRoomDialog,
      bind: {
        isEdit,
        roomElectricRegisterData,
        onClose: callbackClose,
        onChoise: callbackChoise,
      },
    });
  }
}
