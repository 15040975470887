const state = {
  loadingStatus: false,
};

const getters = {
  loadingStatus(state) {
    return state.loadingStatus;
  },
};

const actions = {
  updateLoadingStatus({ commit }, data) {
    commit("loadingStatus", data);
  },
};

const mutations = {
  loadingStatus(state, newLoadingStatus) {
    state.loadingStatus = newLoadingStatus;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
