import * as Yup from "yup";
import { ERROR_MESSAGES } from "@/globals/error-messages";
import { MIN_8_CHARACTER } from "@/globals/enums";
import {
  isStartTimeSameEndTime,
  isDatetimeisOlderThanCurrentTime,
  isTimeInList,
  isOverThreeMonth,
  isTimeNotSelect,
  isHalfWidth,
  isFullWidth,
} from "@/directives/validations";

export const validateStringRequired = Yup.string().required(
  ERROR_MESSAGES.REQUIRED
);

export const validatePassword = Yup.string()
  .required(ERROR_MESSAGES.REQUIRED)
  .min(MIN_8_CHARACTER, ERROR_MESSAGES.PLEASE_INPUT_OVER_8_NUMBER)
  .max(256, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 256))
  .matches(
    /^(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[a-zA-Z]).*$/,
    ERROR_MESSAGES.INVALID_PASSWORD
  );

export const validateConfirmPassword = (passwordInput) =>
  Yup.string().oneOf(
    [Yup.ref(passwordInput), null],
    ERROR_MESSAGES.SAME_PASSWORD_REQUIRED
  );

export const validateEmail = Yup.string()
  .required(ERROR_MESSAGES.REQUIRED)
  .min(MIN_8_CHARACTER, ERROR_MESSAGES.PLEASE_INPUT_OVER_8_NUMBER)
  .max(256, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 256))
  .matches(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
    ERROR_MESSAGES.INVALID_EMAIL
  );

export const validateName = {
  firstname: (lastnameInput) =>
    Yup.string()
      .required(ERROR_MESSAGES.REQUIRED)
      .test(
        "firstname",
        ERROR_MESSAGES.NAME_IS_SHORTTER_THAN_N.replace("N", 35),
        (value, ctx) =>
          (value.length || 0) + ctx.parent[lastnameInput]?.length <= 35
      )
      .test(
        "isFullWidth",
        ERROR_MESSAGES.PLEASE_INPUT_VALID_FULL_WIDTH,
        (value) => isFullWidth(value)
      ),
  lastname: (fistnameInput) =>
    Yup.string()
      .required(ERROR_MESSAGES.REQUIRED)
      .test(
        "lastname",
        "",
        (value, ctx) =>
          (value.length || 0) + ctx.parent[fistnameInput].length <= 35
      )
      .test(
        "isFullWidth",
        ERROR_MESSAGES.PLEASE_INPUT_VALID_FULL_WIDTH,
        (value) => isFullWidth(value)
      ),
};

// fistname + lastname < 17
export const validateNameNotRequiredKana = {
  firstname: (lastnameInput) =>
    Yup.string()
      .test(
        "firstnameKana",
        ERROR_MESSAGES.NAME_IS_SHORTTER_THAN_N.replace("N", 17),
        (value, ctx) =>
          (ctx.parent[lastnameInput]?.length || 0) + (value.length || 0) <= 17
      )
      .test(
        "isHalfWidth",
        ERROR_MESSAGES.PLEASE_INPUT_VALID_HALF_WIDTH,
        (value) => isHalfWidth(value)
      ),
  lastname: (firstnameinput) =>
    Yup.string()
      .test(
        "lastNameKana",
        "",
        (value, ctx) =>
          (ctx.parent[firstnameinput]?.length || 0) + (value.length || 0) <= 17
      )
      .test(
        "isHalfWidth",
        ERROR_MESSAGES.PLEASE_INPUT_VALID_HALF_WIDTH,
        (value) => isHalfWidth(value)
      ),
};

// fistname + lastname < 17 and required
export const validateNameRequiredKana = {
  firstname: (lastnameInput) =>
    Yup.string()
      .required(ERROR_MESSAGES.REQUIRED)
      .test(
        "firstnameKana",
        ERROR_MESSAGES.NAME_IS_SHORTTER_THAN_N.replace("N", 17),
        (value, ctx) =>
          (ctx.parent[lastnameInput]?.length || 0) + (value.length || 0) <= 17
      )
      .test(
        "isHalfWidth",
        ERROR_MESSAGES.PLEASE_INPUT_VALID_HALF_WIDTH,
        (value) => isHalfWidth(value)
      ),
  lastname: (firstnameinput) =>
    Yup.string()
      .required(ERROR_MESSAGES.REQUIRED)
      .test(
        "lastNameKana",
        "",
        (value, ctx) =>
          (ctx.parent[firstnameinput]?.length || 0) + (value.length || 0) <= 17
      )
      .test(
        "isHalfWidth",
        ERROR_MESSAGES.PLEASE_INPUT_VALID_HALF_WIDTH,
        (value) => isHalfWidth(value)
      ),
};

export const validateTelephoneGroup = {
  firstPathTelephone: (firstPath, secondPath, lastPath) =>
    Yup.string()
      .required(ERROR_MESSAGES.REQUIRED)
      .max(6, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 6))
      .test(
        "minLengthPhone",
        ERROR_MESSAGES.PLEASE_INPUT_OVER_N_NUMBER.replace("N", 10),
        (value, ctx) => {
          if (
            ctx.parent[firstPath]?.length === 0 ||
            ctx.parent[secondPath]?.length === 0 ||
            ctx.parent[lastPath]?.length === 0
          ) {
            return true;
          }
          const telephoneLength =
            (ctx.parent[firstPath]?.length || 0) +
            (ctx.parent[secondPath]?.length || 0) +
            (ctx.parent[lastPath]?.length || 0);
          return telephoneLength >= 10;
        }
      )
      .test(
        "maxLengthPhone",
        ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 11),
        (value, ctx) => {
          if (
            ctx.parent[firstPath]?.length === 0 ||
            ctx.parent[secondPath]?.length === 0 ||
            ctx.parent[lastPath]?.length === 0
          ) {
            return true;
          }
          const telephoneLength =
            (ctx.parent[firstPath]?.length || 0) +
            (ctx.parent[secondPath]?.length || 0) +
            (ctx.parent[lastPath]?.length || 0);
          return telephoneLength <= 11;
        }
      )
      .test("telephone", ERROR_MESSAGES.INVALID_TELEPHONE, (value, ctx) => {
        if (
          ctx.parent[firstPath]?.length === 0 ||
          ctx.parent[secondPath]?.length === 0 ||
          ctx.parent[lastPath]?.length === 0
        ) {
          return true;
        }
        return value.charAt(0) === "0";
      }),
  secondPathTelephone: (firstPath, secondPath, lastPath) =>
    Yup.string()
      .required(ERROR_MESSAGES.REQUIRED)
      .max(4, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 4))
      .test("minLengthPhone", "", (value, ctx) => {
        if (
          ctx.parent[firstPath]?.length === 0 ||
          ctx.parent[secondPath]?.length === 0 ||
          ctx.parent[lastPath]?.length === 0
        ) {
          return true;
        }
        const telephoneLength =
          (ctx.parent[firstPath]?.length || 0) +
          (ctx.parent[secondPath]?.length || 0) +
          (ctx.parent[lastPath]?.length || 0);
        return telephoneLength >= 10;
      })
      .test("maxLengthPhone", "", (value, ctx) => {
        if (
          ctx.parent[firstPath]?.length === 0 ||
          ctx.parent[secondPath]?.length === 0 ||
          ctx.parent[lastPath]?.length === 0
        ) {
          return true;
        }
        const telephoneLength =
          (ctx.parent[firstPath]?.length || 0) +
          (ctx.parent[secondPath]?.length || 0) +
          (ctx.parent[lastPath]?.length || 0);
        return telephoneLength <= 11;
      }),
  lastPathTelephone: (firstPath, secondPath, lastPath) =>
    Yup.string()
      .required(ERROR_MESSAGES.REQUIRED)
      .max(4, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 4))
      .test("minLengthPhone", "", (value, ctx) => {
        if (
          ctx.parent[firstPath]?.length === 0 ||
          ctx.parent[secondPath]?.length === 0 ||
          ctx.parent[lastPath]?.length === 0
        ) {
          return true;
        }
        const telephoneLength =
          (ctx.parent[firstPath]?.length || 0) +
          (ctx.parent[secondPath]?.length || 0) +
          (ctx.parent[lastPath]?.length || 0);
        return telephoneLength >= 10;
      })
      .test("maxLengthPhone", "", (value, ctx) => {
        if (
          ctx.parent[firstPath]?.length === 0 ||
          ctx.parent[secondPath]?.length === 0 ||
          ctx.parent[lastPath]?.length === 0
        ) {
          return true;
        }
        const telephoneLength =
          (ctx.parent[firstPath]?.length || 0) +
          (ctx.parent[secondPath]?.length || 0) +
          (ctx.parent[lastPath]?.length || 0);
        return telephoneLength <= 11;
      }),
};

export const validateTelephone = Yup.string()
  .required(ERROR_MESSAGES.REQUIRED)
  .min(10, ERROR_MESSAGES.PLEASE_INPUT_OVER_N_NUMBER.replace("N", 10))
  .max(11, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 11))
  .test(
    "telephone",
    ERROR_MESSAGES.INVALID_TELEPHONE,
    (value) => value.charAt(0) === "0"
  );

// code is number and length === 6
export const validateVerificationCode = Yup.string()
  .required(ERROR_MESSAGES.REQUIRED)
  .test(
    "verificationCode",
    ERROR_MESSAGES.OTP_6_NUMBER_REQUIRED,
    (value) => /^-?\d+$/.test(value) && value.length === 6
  );

export const validateAddressForm = {
  postOfficeNumber: Yup.string().test(
    "postOfficeNumber",
    ERROR_MESSAGES.PLEASE_INPUT_N_NUMBER.replace("N", 7),
    (val) => {
      if (val === undefined) return !val;
      return val?.length === 7;
    }
  ),
  addressDepartmentNumber: Yup.string()
    .test("addressDepartmentNumber", ERROR_MESSAGES.REQUIRED, (val) => {
      if (val === undefined) return !val;
      return val?.length > 0;
    })
    .test(
      "isFullWidth",
      ERROR_MESSAGES.PLEASE_INPUT_VALID_FULL_WIDTH,
      (value) => (value ? isFullWidth(value) : !value)
    ),
  addressDepartmentName: Yup.string().test(
    "isFullWidth",
    ERROR_MESSAGES.PLEASE_INPUT_VALID_FULL_WIDTH,
    (value) => (value ? isFullWidth(value) : !value)
  ),
  addressRoomNumber: Yup.string().test(
    "isFullWidth",
    ERROR_MESSAGES.PLEASE_INPUT_VALID_FULL_WIDTH,
    (value) => (value ? isFullWidth(value) : !value)
  ),
};

export const validateCompanyName = validateStringRequired
  .max(35, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 35))
  .test("isFullWidth", ERROR_MESSAGES.PLEASE_INPUT_VALID_FULL_WIDTH, (value) =>
    isFullWidth(value)
  );

export const validateCompanyKanaName = validateStringRequired.max(
  17,
  ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 17)
);

export const validateMailbox = Yup.string()
  .max(7, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 7))
  .test("isHalfWidth", ERROR_MESSAGES.PLEASE_INPUT_VALID_HALF_WIDTH, (value) =>
    isHalfWidth(value)
  );

export const validateSelectNumber = (defaultValue) =>
  Yup.number().test(
    "validateSelectNumber",
    ERROR_MESSAGES.PLEASE_SELECT,
    (val) => {
      return val !== parseInt(defaultValue);
    }
  );

export const validateSelectString = (defaultValue) =>
  Yup.string().test(
    "validateSelectNumber",
    ERROR_MESSAGES.PLEASE_SELECT,
    (val) => val !== defaultValue
  );

export const validateDateTimeWithCurrentTime = (
  yearInput,
  monthInput,
  dateInput,
  holidateList,
  time,
  errorMessage,
  isTimeInHoliDateErrorMessage,
  isOverThreeMonthErrorMessage,
  isTimeNotSelectErrorMessage,
  isPastimeErrorMessage
) =>
  Yup.string()
    .test("testDateTime", errorMessage || "", (val, ctx) =>
      isDatetimeisOlderThanCurrentTime(
        ctx.parent[yearInput],
        ctx.parent[monthInput],
        ctx.parent[dateInput],
        time
      )
    )
    .test("testDateTime", isTimeInHoliDateErrorMessage || "", (val, ctx) => {
      return !isTimeInList(
        ctx.parent[yearInput],
        ctx.parent[monthInput],
        ctx.parent[dateInput],
        holidateList
      );
    })
    .test("testDateTime", isOverThreeMonthErrorMessage || "", (val, ctx) => {
      return !isOverThreeMonth(
        ctx.parent[yearInput],
        ctx.parent[monthInput],
        ctx.parent[dateInput],
        time,
        holidateList
      );
    })
    .test("testDateTime", isTimeNotSelectErrorMessage || "", (val, ctx) => {
      return !isTimeNotSelect(
        ctx.parent[yearInput],
        ctx.parent[monthInput],
        ctx.parent[dateInput]
      );
    })
    .test("testDateTime", isPastimeErrorMessage || "", (val, ctx) =>
      isDatetimeisOlderThanCurrentTime(
        ctx.parent[yearInput],
        ctx.parent[monthInput],
        ctx.parent[dateInput]
      )
    );

export const validateStartDateTimewithEndTime = (
  startYearInput,
  startMonthInput,
  startDateInput,
  endYearInput,
  endMonthInput,
  endDateInput,
  holidateList,
  time,
  isDatetimeisOlderThanCurrentTimeErrorMessage,
  isDatetimeSameCurrentTimeErrorMessage,
  isTimeInHoliDateErrorMessage,
  isOverThreeMonthErrorMessage,
  isTimeNotSelectErrorMessage,
  isPastTimeErrorMessage
) =>
  Yup.string()
    .test(
      "testDateTime",
      isDatetimeisOlderThanCurrentTimeErrorMessage || "",
      (val, ctx) =>
        isDatetimeisOlderThanCurrentTime(
          ctx.parent[startYearInput],
          ctx.parent[startMonthInput],
          ctx.parent[startDateInput],
          time
        )
    )
    .test(
      "testDateTime",
      isDatetimeSameCurrentTimeErrorMessage || "",
      (val, ctx) =>
        isStartTimeSameEndTime(
          {
            year: ctx.parent[startYearInput],
            month: ctx.parent[startMonthInput],
            date: ctx.parent[startDateInput],
          },
          {
            year: ctx.parent[endYearInput],
            month: ctx.parent[endMonthInput],
            date: ctx.parent[endDateInput],
          }
        )
    )
    .test("testDateTime", isTimeInHoliDateErrorMessage || "", (val, ctx) => {
      return !isTimeInList(
        ctx.parent[startYearInput],
        ctx.parent[startMonthInput],
        ctx.parent[startDateInput],
        holidateList
      );
    })
    .test("testDateTime", isOverThreeMonthErrorMessage || "", (val, ctx) => {
      return !isOverThreeMonth(
        ctx.parent[startYearInput],
        ctx.parent[startMonthInput],
        ctx.parent[startDateInput],
        time,
        holidateList
      );
    })
    .test("testDateTime", isTimeNotSelectErrorMessage || "", (val, ctx) => {
      return !isTimeNotSelect(
        ctx.parent[startYearInput],
        ctx.parent[startMonthInput],
        ctx.parent[startDateInput]
      );
    })
    .test("testDateTime", isPastTimeErrorMessage || "", (val, ctx) =>
      isDatetimeisOlderThanCurrentTime(
        ctx.parent[startYearInput],
        ctx.parent[startMonthInput],
        ctx.parent[startDateInput]
      )
    );

export const validateEndDateTimewithStartDateTime = (
  startYearInput,
  startMonthInput,
  startDateInput,
  endYearInput,
  endMonthInput,
  endDateInput,
  holidateList,
  endTime,
  isDatetimeisOlderThanCurrentTimeErrorMessage,
  isDatetimeSameCurrentTimeErrorMessage,
  isTimeInHoliDateErrorMessage,
  isOverThreeMonthErrorMessage,
  isTimeNotSelectErrorMessage,
  isPastTimeErrorMessage
) =>
  Yup.string()
    .test(
      "testDateTime",
      isDatetimeisOlderThanCurrentTimeErrorMessage || "",
      (val, ctx) =>
        isDatetimeisOlderThanCurrentTime(
          ctx.parent[endYearInput],
          ctx.parent[endMonthInput],
          ctx.parent[endDateInput],
          endTime
        )
    )
    .test(
      "testDateTime",
      isDatetimeSameCurrentTimeErrorMessage || "",
      (val, ctx) =>
        isStartTimeSameEndTime(
          {
            year: ctx.parent[startYearInput],
            month: ctx.parent[startMonthInput],
            date: ctx.parent[startDateInput],
          },
          {
            year: ctx.parent[endYearInput],
            month: ctx.parent[endMonthInput],
            date: ctx.parent[endDateInput],
          }
        )
    )
    .test("testDateTime", isTimeInHoliDateErrorMessage || "", (val, ctx) => {
      return !isTimeInList(
        ctx.parent[endYearInput],
        ctx.parent[endMonthInput],
        ctx.parent[endDateInput],
        holidateList
      );
    })
    .test("testDateTime", isOverThreeMonthErrorMessage || "", (val, ctx) => {
      return !isOverThreeMonth(
        ctx.parent[endYearInput],
        ctx.parent[endMonthInput],
        ctx.parent[endDateInput],
        endTime,
        holidateList
      );
    })
    .test("testDateTime", isTimeNotSelectErrorMessage || "", (val, ctx) => {
      return !isTimeNotSelect(
        ctx.parent[endYearInput],
        ctx.parent[endMonthInput],
        ctx.parent[endDateInput]
      );
    })
    .test("testDateTime", isPastTimeErrorMessage || "", (val, ctx) =>
      isDatetimeisOlderThanCurrentTime(
        ctx.parent[endYearInput],
        ctx.parent[endMonthInput],
        ctx.parent[endDateInput]
      )
    );

export const validatePurposeUse = Yup.string()
  .max(17, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 17))
  .test(
    "isHalfWidth",
    ERROR_MESSAGES.PLEASE_INPUT_VALID_HALF_WIDTH_KANA,
    (value) => isHalfWidth(value)
  )
  .nullable(true);

export const validateBuildingName = Yup.string()
  .required(ERROR_MESSAGES.REQUIRED)
  .max(30, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 30))
  .test("isFullWidth", ERROR_MESSAGES.PLEASE_INPUT_VALID_FULL_WIDTH, (value) =>
    isFullWidth(value)
  );

export const validateRoomNumber = Yup.string()
  .required(ERROR_MESSAGES.REQUIRED)
  .max(6, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 6))
  .test("isFullWidth", ERROR_MESSAGES.PLEASE_INPUT_VALID_FULL_WIDTH, (value) =>
    isFullWidth(value)
  );

export const validateKeyword = Yup.string()
  .max(30, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 30))
  .test("isFullWidth", ERROR_MESSAGES.PLEASE_INPUT_VALID_FULL_WIDTH, (value) =>
    isFullWidth(value)
  );

export const validatePostCode = {
  firstPath: Yup.string().max(
    3,
    ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 3)
  ),
  secondPath: Yup.string().max(
    4,
    ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 4)
  ),
};

export const validateBuildingAddress = Yup.string()
  .max(80, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 80))
  .test("isFullWidth", ERROR_MESSAGES.PLEASE_INPUT_VALID_FULL_WIDTH, (value) =>
    isFullWidth(value)
  );

export const validateBlockNumber = Yup.string()
  .max(30, ERROR_MESSAGES.MAX_LENGTH_IS_N.replace("N", 30))
  .test("isFullWidth", ERROR_MESSAGES.PLEASE_INPUT_VALID_FULL_WIDTH, (value) =>
    isFullWidth(value)
  );
