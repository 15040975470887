<template>
  <div class="RegistrationUserInfoComponent">
    <CardComponent :width="'960px'" :height="'550px'" class="card">
      <TitleComponent class="card-title" :hasUnderLine="true">
        <template v-slot:content>ユーザー情報登録</template>
      </TitleComponent>
      <p class="card-description">氏名を登録してください。</p>
      <Form
        @submit="registration"
        class="form-registrationUserInfo"
        :validation-schema="schema"
        v-slot="{ meta }"
      >
        <div class="form-registrationUserInfo-groupInput">
          氏名
          <div class="form-registrationUserInfo-groupInput-input">
            <InputComponent
              class="w100"
              name="firstNameKanji"
              placeholder="田中"
              inputFilter="fullWidth"
              maxlength="35"
            />
          </div>
          <div class="form-registrationUserInfo-groupInput-input">
            <InputComponent
              class="w100"
              name="lastNameKanji"
              placeholder="一郎"
              inputFilter="fullWidth"
              maxlength="35"
            />
          </div>
        </div>
        <div class="form-registrationUserInfo-action-group">
          <ButtonComponent
            buttonType="submit"
            :disabled="!(meta.valid && meta.dirty)"
          >
            登録
          </ButtonComponent>
        </div>
      </Form>
    </CardComponent>
  </div>
</template>

<script>
import { Form } from "vee-validate";
import { STATUS } from "@/globals/enums";
import UserService from "@/services/user.service";
import { mapState } from "vuex";
import { registrationUserInfoSchema } from "@/validations/schemas/registrationUserInfoSchema";

export default {
  name: "RegistrationUserInfoComponent",
  components: {
    Form,
  },
  computed: mapState({
    profileInfo: (state) => state.settings.profileInfo,
  }),
  methods: {
    async registration(data) {
      await this.userService.editFirstProfileInfo(data).then((data) => {
        if (
          data.status === STATUS.SUCCESS &&
          this.profileInfo.adminFlg &&
          this.profileInfo.companyId?.length === 0
        ) {
          this.$router.push({ name: "CompanyEditComponent" });
        } else if (data.status === STATUS.SUCCESS) {
          this.$router.push({ name: "RegistrationSuccessComponent" });
        }
      });
    },
  },
  data: () => ({
    userService: new UserService(),
    schema: registrationUserInfoSchema,
  }),
};
</script>

<style scoped lang="scss">
.RegistrationUserInfoComponent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    &-title {
      font-size: 20px;
      font-weight: 500;
      font-weight: 500;
      border-bottom: solid 2px #d0d9df;
    }
    &-description {
      font-size: 14px;
      font-weight: 500;
      margin: 20px auto 40px auto;
    }
    .form-registrationUserInfo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &-groupInput {
        display: flex;
        align-items: center;
        justify-content: center;
        &-input {
          width: 170px;
          .InputComponent {
            margin-bottom: 0;
          }
          &:first-child {
            margin: 0 20px 0 72px;
          }
        }
      }
      &-action-group {
        margin-top: 40px;
      }
    }
  }
}
</style>
