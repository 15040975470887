<template>
  <div class="StatusComponent">
    <div class="status">
      <span v-if="status">
        <span
          v-if="statusValue.ID === STATUS_ELECTRICITY.WAITING.ID"
          class="icon-ico_warning"
        >
          <span class="path1"></span>
          <span class="path2"></span>
          <span class="path3"></span>
        </span>
        <span
          v-if="statusValue.ID === STATUS_ELECTRICITY.PROCESSING.ID"
          class="icon-ico_ijyo2"
        >
          <span class="path1"></span>
          <span class="path2"></span>
        </span>
        <span
          v-if="
            statusValue.ID === STATUS_ELECTRICITY.COMPLETED.ID &&
            isCompletedRender
          "
          class="icon-ico_seijyo"
        >
          <span class="path1"></span>
          <span class="path2"></span>
        </span>
        <span
          v-if="
            statusValue.ID === STATUS_ELECTRICITY.UNCONFIRMED.ID ||
            statusValue.ID === STATUS_ELECTRICITY.CONFIRMED.ID
          "
          class="icon-ico_close"
        >
          <span class="path1"></span>
          <span class="path2"></span>
        </span>
      </span>
      <span
        :class="{
          waiting: statusValue.ID === STATUS_ELECTRICITY.WAITING.ID,
          processing: statusValue.ID === STATUS_ELECTRICITY.PROCESSING.ID,
          error:
            statusValue.ID === STATUS_ELECTRICITY.UNCONFIRMED.ID ||
            statusValue.ID === STATUS_ELECTRICITY.CONFIRMED.ID,
        }"
        class="text"
      >
        {{
          statusValue.ID === STATUS_ELECTRICITY.COMPLETED.ID &&
          !isCompletedRender
            ? "ー"
            : statusValue.LABEL
        }}
      </span>
    </div>
  </div>
</template>

<script>
import { STATUS_ELECTRICITY } from "@/globals/enums";

export default {
  name: "StatusComponent",
  props: {
    status: {
      type: Number,
      default: null,
    },
    isCompletedRender: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.STATUS_ELECTRICITY = STATUS_ELECTRICITY;
  },
  mounted() {},
  computed: {
    statusValue() {
      switch (this.status) {
        case STATUS_ELECTRICITY.PROCESSING.ID:
          return this.STATUS_ELECTRICITY.PROCESSING;
        case STATUS_ELECTRICITY.WAITING.ID:
          return this.STATUS_ELECTRICITY.WAITING;
        case STATUS_ELECTRICITY.COMPLETED.ID:
          return STATUS_ELECTRICITY.COMPLETED;
        case STATUS_ELECTRICITY.UNCONFIRMED.ID:
          return STATUS_ELECTRICITY.UNCONFIRMED;
        case STATUS_ELECTRICITY.CONFIRMED.ID:
          return STATUS_ELECTRICITY.CONFIRMED;
        default:
          return {
            LABEL: "ー",
          };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.StatusComponent {
  .text {
    font-size: 14px;
    margin-left: 5px;
  }
  .waiting {
    color: #dcb46f;
  }
  .processing {
    color: #748cbb;
  }
  .error {
    color: #dc906f;
  }
  .completed {
    color: #74afbb;
  }
}
</style>
